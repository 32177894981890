import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";

import Button from "../../../common/form/button/components/button";

const BackButton = (props, { t }) => {
  return (
    <div className="mb-2 d-flex">
      <Button onClick={() => props.history.goBack()}>
        <FontAwesomeIcon icon={faArrowLeft} /> {t("BACK")}
      </Button>
    </div>
  );
};

BackButton.contextTypes = {
  t: PropTypes.func,
};

export default withRouter(BackButton);
