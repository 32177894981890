import { API_BASE_URL } from "../../common/constants";
import { Api } from "../../core/api";

import routesConstatns from "./routes";

const orderApi = {};

orderApi.getBotOrders = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_TRADE_ORDERS}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

orderApi.getActiveBotOrders = async (params, headers) => {
  const { botid, ...rest } = params;
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_ACTIVE_TRADE_ORDERS}/${botid}`,
    { params: rest },
    {
      needLoader: true,
    },
    headers
  );
};

orderApi.cancelOrder = async (params, headers) => {
  return await Api.post(
    `${API_BASE_URL}${routesConstatns.DEACTIVATE_ORDER}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

orderApi.retryOrder = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.RETRY_TRADE_ORDER}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

export default orderApi;
