import { useState } from "react";

export const useSort = (data) => {
  const [sortConfig, setSortConfig] = useState(null);
  const [sortedData, setSortedData] = useState(null);

  const requetSort = ({ field: key, type }) => {
    let direction = true;
    if (sortConfig && sortConfig.key === key && sortConfig.direction === true) {
      direction = false;
    }

    setSortedData(sortData(key, direction, data, type));
    setSortConfig({ key, direction });
  };

  return { sortConfig, requetSort, sortedData: sortedData || data };
};

const sortData = (key, direction, data, type) => {
  const sortedData = data.sort((a, b) => {
    if (type === "date" || type === "datetime") {
      return compareDates(a, b, key, direction);
    }

    if (type === "text") {
      compareStrings(a, b, key, direction);
    }

    return compareNumbers(a, b, key, direction);
  });
  return sortedData;
};

function compareStrings(a, b, key, direction) {
  var nameA = a[setKey(key)].toUpperCase(); // ignore upper and lowercase
  var nameB = b[setKey(key)].toUpperCase(); // ignore upper and lowercase
  return direction ? nameB - nameA : nameA - nameB;
}

function compareNumbers(a, b, key, direction) {
  return direction
    ? b[setKey(key)] - a[setKey(key)]
    : a[setKey(key)] - b[setKey(key)];
}

function compareDates(a, b, key, direction) {
  return direction
    ? new Date(b[setKey(key)]) - new Date(a[setKey(key)])
    : new Date(a[setKey(key)]) - new Date(b[setKey(key)]);
}

const setKey = (key) => {
  if (key === "sequence") {
    return "id";
  }
  return key;
};
