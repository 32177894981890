import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import { INITIAL_LIMIT_ORDER_DATA } from "../constants";

import Button from "../../../common/form/button/components/button";
import InputNumber from "../../../common/form/input/components/number-input";
import TabGroup from "../../../shared/components/tab-group/tab-group";

const LimitOrderConditionModal = (
  {
    show,
    onClose,
    avblCoins,
    avblMarkets,
    assetName,
    marketName,
    apiKeyId,
    quantity = 0,
    getAssetPrice,
    onSave,
  },
  { t }
) => {
  const [buyLimitData, setBuyLimitData] = useState(INITIAL_LIMIT_ORDER_DATA);
  const [sellLimitData, setSellLimitData] = useState(INITIAL_LIMIT_ORDER_DATA);

  useEffect(() => {
    if (marketName === "" || assetName === "" || !apiKeyId) return;

    async function fillAssetAmount() {
      const price = await getAssetPrice(marketName, assetName, apiKeyId);
      const total = Number(quantity) * price;
      setBuyLimitData({ ...buyLimitData, price, quantity, total });
      setSellLimitData({ ...sellLimitData, price, quantity, total });
    }

    fillAssetAmount();

    // eslint-disable-next-line
  }, [assetName, marketName, apiKeyId]);

  const onBuyAmountChange = (e) => {
    const { value } = e.target;
    if (!value)
      return setBuyLimitData({
        ...buyLimitData,
        total: "",
        quantity: 0,
      });

    setBuyLimitData({
      ...buyLimitData,
      quantity: parseFloat(value),
      total: Number(value) * buyLimitData.price,
    });
  };

  const onBuyTotalChange = (e) => {
    const { value } = e.target;
    if (!value)
      return setBuyLimitData({
        ...buyLimitData,
        total: "",
        quantity: 0,
      });

    setBuyLimitData({
      ...buyLimitData,
      quantity: Number(value) / buyLimitData.price,
      total: parseFloat(value),
    });
  };

  const onSellAmountChange = (e) => {
    const { value } = e.target;
    if (!value)
      return setSellLimitData({
        ...sellLimitData,
        total: "",
        quantity: 0,
      });

    setSellLimitData({
      ...sellLimitData,
      quantity: parseFloat(value),
      total: Number(value) * sellLimitData.price,
    });
  };

  const onSellTotalChange = (e) => {
    const { value } = e.target;
    if (!value)
      return setSellLimitData({
        ...sellLimitData,
        total: "",
        quantity: 0,
      });

    setSellLimitData({
      ...sellLimitData,
      quantity: Number(value) / sellLimitData.price,
      total: parseFloat(value),
    });
  };

  const onSellOrder = () => {
    onSave({ ...sellLimitData, assetName, marketName, side: 1 });
  };

  const onBuyOrder = () => {
    onSave({ ...buyLimitData, assetName, marketName, side: 0 });
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{t("CONDITION.LIMIT-ORDER-MODAL.TITLE")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-around">
          <Col
            md={5}
            className="mb-4 pt-4 pb-4"
            style={{ backgroundColor: "#f1f1f1", borderRadius: 10 }}
          >
            <div className="input-group mb-3 align-items-center justify-content-between">
              <h6 className="mb-1 pr-5">Buy</h6>
              <span className="mr-3" style={{ fontSize: 16, fontWeight: 600 }}>
                {avblMarkets} {marketName}
              </span>
            </div>
            <TabGroup
              tabs={["Limit"]}
              setTab={() => {}}
              selectedTab={"Limit"}
            />

            <div className="input-group mb-3">
              <span className="input-group-text input-form-border">Price</span>
              <input
                value={buyLimitData.price}
                onChange={(e) => {
                  setBuyLimitData({ ...buyLimitData, price: e.target.value });
                }}
                type="text"
                className="form-control"
              />
              <span
                style={{ fontWeight: 700 }}
                className="input-group-text input-end-form-border"
              >
                {marketName || "N/A"}
              </span>
            </div>
            <div className="input-group mb-5">
              <span className="input-group-text input-form-border">Amount</span>
              <InputNumber disabled={true} name="quantity" value={buyLimitData.quantity} onChange={onBuyAmountChange} />
              <span
                style={{ fontWeight: 700 }}
                className="input-group-text input-end-form-border"
              >
                {assetName || "N/A"}
              </span>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text input-form-border">Total</span>
              <InputNumber name="total" value={buyLimitData.total} onChange={onBuyTotalChange} />
              <span
                style={{ fontWeight: 700 }}
                className="input-group-text input-end-form-border"
              >
                {marketName || "N/A"}
              </span>
            </div>
            <button
              onClick={onBuyOrder}
              type="button"
              className="btn btn-success w-100 mb-2"
            >
              Buy {assetName}
            </button>
          </Col>
          <Col
            md={5}
            className="mb-4 pt-4 pb-4"
            style={{ backgroundColor: "#f1f1f1", borderRadius: 10 }}
          >
            <div className="input-group mb-3 align-items-center justify-content-between">
              <h6 className="mb-1 pr-5">Sell</h6>
              <span className="mr-3" style={{ fontSize: 16, fontWeight: 600 }}>
                {avblCoins} {assetName}
              </span>
            </div>
            <TabGroup
              tabs={["Limit"]}
              setTab={() => {}}
              selectedTab={"Limit"}
            />
            <div className="input-group mb-3">
              <span className="input-group-text input-form-border">Price</span>
              <input
                value={sellLimitData.price}
                onChange={(e) => {
                  setSellLimitData({ ...sellLimitData, price: e.target.value });
                }}
                type="text"
                className="form-control"
              />
              <span
                style={{ fontWeight: 700 }}
                className="input-group-text input-end-form-border"
              >
                {marketName || "N/A"}
              </span>
            </div>
            <div className="input-group mb-5">
              <span className="input-group-text input-form-border">Amount</span>
              <InputNumber name="sellQuantity" value={sellLimitData.quantity} onChange={onSellAmountChange} disabled={true} />
              <span
                style={{ fontWeight: 700 }}
                className="input-group-text input-end-form-border"
              >
                {assetName || "N/A"}
              </span>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text input-form-border">Total</span>
              <InputNumber name="sellTotal" value={sellLimitData.total} onChange={onSellTotalChange} />
              <span
                style={{ fontWeight: 700 }}
                className="input-group-text input-end-form-border"
              >
                {marketName || "N/A"}
              </span>
            </div>
            <button
              onClick={onSellOrder}
              type="button"
              className="btn btn-danger w-100"
            >
              Sell {assetName}
            </button>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

LimitOrderConditionModal.contextTypes = {
  t: PropTypes.func,
};

export default LimitOrderConditionModal;
