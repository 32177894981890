import { faChevronDown, faInfoCircle, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack } from "@mui/material";
import Popover from "@mui/material/Popover";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

import Button from "../../form/button/components/button";
import { HelpModusSwitch, useHelpModus } from "../../help-modus/help-modus-context";
import HelpModusModal from "../../help-modus/help.modus-modal";
import ProfileImage from "../../profile-image/components/profile-image";
import { ThemeSwitch, useTheme } from "../../theme/theme-context";

import { routes } from "../../../common/routes-constants";
import { history } from "../../../index";

import bnbFeesImage from "../../../common/assets/help-modus/help-modus-bnb-fees.png";
import logoNoText from "../../../common/assets/logo-no-text.png";
import whiteLogo from "../../../common/assets/logo-white.png";
import logo from "../../../common/assets/logo.png";

import userApi from "../../../api/user/actions";
import bnbIcon from '../../../common/assets/bnb-icon.svg';
import discordIcon from "../../../common/assets/discord.png";
import commonHelper from "../../../common/helpers/common-helper";
import UserHelper from "../../helpers/user-helper";
import "../style/navigation.scss";

const Navigation = ({ toggleMenu, logout, menuClick, language, setLanguage }, { t }) => {
  const { toggleTheme, isDarkMode } = useTheme();
  const { toggleHelpModus, isHelpModus } = useHelpModus();
  const [anchorEl, setAnchorEl] = useState(null);
  const [bnbAmount, setBnbAmount] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [showHelpModusModal, setShowHelpModusModal] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [modalTitle, setModalTitle] = useState('Information');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderNavbarBrand = (className) => {
    return (
      <Navbar.Brand as="div" className={`${className}`} onClick={() => history.push(routes.protectedRoutes.dashboard.fullPath)}>
        <img className="logo-text" src={isDarkMode ? whiteLogo : logo} alt="logo" />
        <img className="logo-no-text" src={logoNoText} alt="logo" />
      </Navbar.Brand>
    );
  };

  const renderNavbarBrandSm = (className) => {
    return (
      <Navbar.Brand as="div" className={`${className}`} onClick={() => history.push(routes.protectedRoutes.dashboard.fullPath)}>
        <img src={whiteLogo} alt="logo" />
      </Navbar.Brand>
    );
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const toggleHelpModusModal = (title, message) => {
    setShowHelpModusModal((showModal) => !showModal);
    setModalMessage(message);
    setModalTitle(title);
  };

  useEffect(() => {
    const getBnbAmount = async () => {
      try {
        const res = await userApi.getAssetAmount("bnb");
        if (res && res.data && res.data.code !== 1005) {
          setBnbAmount(`${commonHelper.showFullNumber(res.data.item1)} ${res.data.item2}`);
        }
      } catch (error) {
        console.error("Error fetching BNB amount:", error);
      }
    };

    getBnbAmount();
  }, []);

  return (
    <div className="main-navigation d-flex w-100">
      {renderNavbarBrand("d-none d-lg-flex d-xl-flex")}
      <Navbar bg="primary" expand={false} className="navbar-blue navbar-dark" sticky={true}>
        <div className="d-flex align-items-center">
          <Navbar.Toggle onClick={toggleMenu} />
          {bnbAmount && (
            <div className="logo-container ml-3 d-none d-lg-flex">
              <div className="d-flex">
                <img src={bnbIcon} alt="BNB Symbol logo" className="bnb-icon" />
                {isHelpModus && (
                  <div className="user-center-info-button" style={{ top: '-5px', right: '-20px' }}
                    onClick={() => {
                      const messageContent = (
                        <>
                          <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.FEES-BNB') }} />
                          <img src={bnbFeesImage} style={{ width: '100%' }} alt="Fees BNB" />          
                        </>
                      );
                      const messageTitle = (<>{t('HELP-MODUS.FEES-BNB-TITLE')}</>);
                      toggleHelpModusModal(messageTitle, messageContent);
                    }}>
                    <FontAwesomeIcon className="row" icon={faInfoCircle} />
                  </div>
                )}
              </div>
              <span className="mb-0" style={{ color: "#fff", fontSize: "18px", fontWeight: "500" }}>{bnbAmount}</span>
            </div>
          )}
        </div>
        {renderNavbarBrandSm("d-sm-flex d-lg-none")}
        <div className="d-flex align-items-center">
          <div className="d-none d-lg-flex language-dropdown-container">
            <select className="language-dropdown" value={language} onChange={handleLanguageChange}>
              <option value="en">EN</option>
              <option value="de">DE</option>
            </select>
          </div>
          {renderNavbarBrand("d-none")}
          <div className="d-flex align-items-center custom-dropdown-style" onClick={handleClick} style={{ cursor: "pointer" }}>
            <p className="d-none d-lg-flex m-0 pr-3" style={{ color: "#fff" }}>
              {UserHelper.getUserName()}
            </p>
            <ProfileImage src={UserHelper.getProfilePicture()} />
            <FontAwesomeIcon className="d-sm-flex d-lg-none" icon={faChevronDown} />
          </div>
          {isHelpModus && (
            <div className="user-center-info-button" 
              onClick={() => {
                const messageContent = (
                  <div>
                    <p>{t('HELP-MODUS.CONTEST-TITLE')}</p>
                  </div>
                );
                toggleHelpModusModal('Contest', messageContent);
              }}>
              <FontAwesomeIcon className="row" icon={faInfoCircle} />
            </div>
          )}
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          className="d-none d-lg-flex" 
        >
          <Stack direction="column" spacing={2} padding="20px" width={230}>
            <Box>
              <Link to={routes.protectedRoutes.editProfile.fullPath}> {t("PROFILE_IMAGE_MENU.EDIT_PROFILE")}</Link>
            </Box>
            <Box>
              <label>
                {t("PROFILE_IMAGE_MENU.TOGGLE-THEME")}
                <ThemeSwitch onChange={toggleTheme} checked={isDarkMode} size="medium" />
              </label>
            </Box>
            <Box>
              <label>
                {t("PROFILE_IMAGE_MENU.TOGGLE-HELP-MODUS")}
                <HelpModusSwitch onChange={toggleHelpModus} checked={isHelpModus} size="medium" />
              </label>
            </Box>
            <Box className="border-bottom">
            <a href="https://discord.com/invite/sUUMY72XCr" target="_blank">
                {t("PROFILE_IMAGE_MENU.DISCORD-COMMUNITY")}
                <img
                  style={{ marginLeft: "15px", paddingBottom: "5px" }}
                  height={25}
                  src={discordIcon}
                  alt="discordIcon"
                />
              </a>
            </Box>
            <Box>
              <Button onClick={logout} variant="danger">
                {t("PROFILE_IMAGE_MENU.LOGOUT")}
              </Button>
            </Box>
          </Stack>
        </Popover>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          className="d-sm-flex d-lg-none"          
        >
          <Stack direction="column" spacing={2} padding="20px" width={230} style={{ backgroundColor: "var(--menu-bg-color)", color: "var(--color-primary)" }}>
            <div className="d-flex language-dropdown-container justify-content-center">
              <select className="language-dropdown p-0 px-2" value={language} onChange={handleLanguageChange}
                style={{ border: "1px solid var(--color-primary)", borderRadius: "10px", color: "var(--color-primary)" }}>
                <option value="en">EN</option>
                <option value="de">DE</option>
              </select>
            </div>
            <hr style={{ background: "var(--color-primary)" }} />
            <Box>
              {UserHelper.getUserName()}
            </Box>
            <hr style={{ background: "var(--color-primary)" }} />
            <Box>
              <label className="d-flex justify-content-between">
                {t("PROFILE_IMAGE_MENU.TOGGLE-THEME")}
                <ThemeSwitch onChange={toggleTheme} checked={isDarkMode} size="medium" />
              </label>
            </Box>
            <Box>
              <label className="d-flex justify-content-between">
                {t("PROFILE_IMAGE_MENU.TOGGLE-HELP-MODUS")}
                <HelpModusSwitch onChange={toggleHelpModus} checked={isHelpModus} size="medium" />
              </label>
            </Box>
            <Box>
            <a href="https://discord.com/invite/sUUMY72XCr" target="_blank">
                {t("PROFILE_IMAGE_MENU.DISCORD-COMMUNITY")}
                <img
                  style={{ marginLeft: "15px", padding: "2px" }}
                  height={25}
                  src={discordIcon}
                  alt="discordIcon"
                />
              </a>
            </Box>
            <Box>
              <Link to={routes.protectedRoutes.editProfile.fullPath + '?tab=customer_data'} onClick={handleClose}> {t("EDIT_PROFILE.TAB.PROFILE")}</Link>
            </Box>
            <Box>
              <Link to={routes.protectedRoutes.editProfile.fullPath + '?tab=api_management'} onClick={handleClose}> {t("EDIT_PROFILE.TAB.API_MANAGEMENT")}</Link>
            </Box>
            <Box>
              <Link to={routes.protectedRoutes.editProfile.fullPath + '?tab=user_profit'} onClick={handleClose}> {t("USER-PROFIT.TITLE")}</Link>
            </Box>
            <Box>
              <Link to={routes.protectedRoutes.editProfile.fullPath + '?tab=notifications'} onClick={handleClose}> {t("EDIT_PROFILE.TAB.NOTIFICATIONS")}</Link>
            </Box>
            <Box>
              <Link to={routes.protectedRoutes.editProfile.fullPath + '?tab=membership'} onClick={handleClose}> {t("EDIT_PROFILE.TAB.MEMBERSHIP")}</Link>
            </Box>
            <Box className="mb-2">
              <Link to={routes.protectedRoutes.editProfile.fullPath + '?tab=affiliate'} onClick={handleClose}> {t("EDIT_PROFILE.TAB.AFFILIATE")}</Link>
            </Box>
            <hr style={{ background: "var(--color-primary)"}} />
            <Box>
              <Button className="d-none d-lg-flex" onClick={logout} variant="danger">
                {t("PROFILE_IMAGE_MENU.LOGOUT")}
              </Button>
              <Button className="d-sm-flex d-lg-none" onClick={logout} style={{ color: "var(--color-primary", backgroundColor: "transparent", border: "none" }}>
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />
                {t("PROFILE_IMAGE_MENU.LOGOUT")}
              </Button>
            </Box>
          </Stack>
        </Popover>
      </Navbar>
      {showHelpModusModal && (
        <HelpModusModal
          show={showHelpModusModal}
          onClose={toggleHelpModusModal}
          title={modalTitle}
          message={modalMessage}
        />
      )}
    </div>
  );
};

Navigation.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Navigation;
