const EMAIL_REGEX = /^[a-zA-Z0-9._%\-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
const PHONE_REGEX = /[^0-9 -/()+]/;
const IBAN_REGEX = /^[A-Z]{2}\d{2}[A-Z0-9]{1,35}$/;

const PERSISTOR_SECRET_KEY = "eWJOlLODu5ONV0D04ZAnBgCi8fjvZLuU";
const API_BASE_URL = process.env.REACT_APP_API_URL;
const INDENTITY_BASE_URL = process.env.REACT_APP_INDENTITY_URL;
const BLOB_STORAGE_BASE_URL = process.env.REACT_APP_BLOB_STORAGE_URL;

const ORDER_STATUS = {
  0: "Pending",
  1: "Active",
  2: "Done",
  3: "Error",
  4: "Canceled",
  5: "Canceled until Retry",
  6: "Canceled with Reentry done",
  7: "Condition Order",
  8: "Active",
  9: "Canceled",
  10: "Canceled until Retry"
};

const EXCHANGE_OPTIONS = [
  {
    id: 1,
    label: "Binance",
    value: 0,
   },
   {
    id: 2,
    label: "Binance-Demo",
    value: 1,
   },
   {
    id: 3,
    label: "MEXC",
    value: 2,
   }
];

const EXCHANGE = {
    0: "Binance",
    1: "Binance-Demo",
    2: "Mexc",
};

const ORDER_TYPE = {
  Limit: 0,
  Market: 1,
  StopLoss: 2,
  StopLossLimit: 3,
  TakeProfitLimit: 5
};

const SIDE = [
  { id: 0, name: "Buy" },
  { id: 1, name: "Sell" },
];

const ORDER_MODULE = {
  Buy: 0,
  Sell: 1,
};

const PAGE_SIZE_DEFAULT = 10;

//to do in env setup
const APPLICATION_BASE_URL = window.location.origin;

export {
  API_BASE_URL,
  APPLICATION_BASE_URL, BLOB_STORAGE_BASE_URL, EMAIL_REGEX, EXCHANGE, EXCHANGE_OPTIONS, IBAN_REGEX, INDENTITY_BASE_URL, ORDER_MODULE, ORDER_STATUS, ORDER_TYPE,
  PAGE_SIZE_DEFAULT, PASSWORD_REGEX, PERSISTOR_SECRET_KEY, PHONE_REGEX, SIDE
};

