import actionTypes from "../constants/action-types";

const loaderState = {
  isLoading: false,
};

export const loaderReducer = (state = loaderState, payload) => {
  switch (payload.type) {
    case actionTypes.HANDLE_GLOBAL_LOADER:
      return {
        ...state,
        isLoading: payload.data,
      };
    default:
      return state;
  }
};
