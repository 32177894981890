import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import Button from "../../../common/form/button/components/button";

import Input from "../../../common/form/input/components/input";
import { withRouter, Link } from "react-router-dom";

import { FORMS } from "../../../common/forms";
import { fields } from "../constants/constants";

import { validate } from "../validators/validate";

import { routes } from "../../../common/routes-constants";

let LoginForm = (props, context) => {
  const { t } = context;
  const { handleSubmit, history, logginErrorMessage, handleLoginError } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field
        id={fields.username}
        name={fields.username}
        component={Input}
        type="email"
        placeholder={t("LOGIN_FORM.EMAIL_PLACEHOLDER")}
        onChange={handleLoginError}
      />
      <Field
        id={fields.password}
        name={fields.password}
        component={Input}
        type="password"
        placeholder={t("LOGIN_FORM.PASSWORD_PLACEHOLDER")}
      />
      <Button
        className={"mt-2"}
        variant="outline-primary"
        block={true}
        type="submit"
        size="md"
        onChange={handleLoginError}
      >
        {t("LOGIN_FORM.SUBMIT_BUTTON")}
      </Button>
      <p className="err">{logginErrorMessage ? t(logginErrorMessage) : ""}</p>
      <Link
        to={routes.unProtectedRoutes.forgotPassword.fullPath}
        className="float-right"
      >
        {t("LOGIN_FORM.FORGOT_PASSWORD")}
      </Link>
      <h6 className="line-on-side text-muted text-center font-small-3 pt-2">
        <span>{t("LOGIN.OR_REGISTER")}</span>
      </h6>

      <Button
        className={"mt-2"}
        variant="outline-danger"
        block={true}
        type="button"
        size="md"
        onClick={() => {
          history.push(routes.unProtectedRoutes.register.fullPath);
        }}
      >
        {t("LOGIN_FORM.REGISTER")}
      </Button>
    </form>
  );
};

LoginForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

LoginForm = reduxForm({
  form: FORMS.login,
  validate,
})(LoginForm);

export default withRouter(LoginForm);
