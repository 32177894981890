import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { handleGetProfileData } from "../../../app/edit-profile/actions/profile-actions";
import { EXCHANGE } from "../../../common/constants";

import binanceImg from "../../../common/assets/help-modus/help-modus-binance.png";
import apiManagementImg from "../../../common/assets/help-modus/help-modus-api-management.png";
import enableSpotImg from "../../../common/assets/help-modus/help-modus-enable-spot.png";

const ApiKey = (
  { userApiKeys, getUserApiKeys, onChange, classList, disabled, value, isHelpModus, onInfoIconClick },
  { t }
) => {
  useEffect(() => {
    getUserApiKeys();

    // eslint-disable-next-line
  }, []);

  return (
    <div className={`input-group mb-3 ${classList || ""}`}>
      <label className="input-group-text form-border w-50">{t("API-KEY")}</label>
      <select
        disabled={disabled}
        name="apiKeyId"
        className="form-control w-50"
        style={{ borderRadius: "0 0.25rem 0.25rem 0" }}
        value={value}
        onChange={(e) => {
          const val = +e.target.value;
          const exchangeId = userApiKeys.find(apiKey => apiKey.id === val)?.exchangeId;
          onChange(val, exchangeId, e);
        }}
      >
        <option value={""}>{t("SELECT-KEY")}</option>
        {userApiKeys.map((apiKey) => (
          <option key={apiKey.id} value={apiKey.id}>
            {EXCHANGE[apiKey.exchangeId] || "Binance"} - {apiKey.name}
          </option>
        ))}
      </select>
      {isHelpModus && !!onInfoIconClick && (
        <div className="infoButton" style={{ position: "absolute", top: "-20px", right: "-20px" }}
             onClick={() => {
               const messageContent = (
                 <div>
                   <p>{t('HELP-MODUS.API-KEY-ONE')}</p>
                   <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.API-KEY-TWO') }} />
                   <img src={binanceImg} style={{ width: '100%' }} alt="Create API Key" />
                   <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.API-KEY-FOUR') }} />
                   <img src={apiManagementImg} style={{ width: '100%' }} alt="Add API Key" />
                   <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.API-KEY-FIVE') }} />
                   <img src={enableSpotImg} style={{ width: '100%' }} alt="Enable Spot & Margin Trading" />
                 </div>
               );
               onInfoIconClick('API Key', messageContent);
             }}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </div>
      )}
    </div>
  );
};

ApiKey.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userApiKeys: state.profileReducer.userApiKeys,
});

const mapDispatchToProps = (dispatch) => ({
  getUserApiKeys: () => dispatch(handleGetProfileData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiKey);
