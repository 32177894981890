import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Helpers from "../../../common/helpers/error-helpers";
import notificationConfigurationApi from "../../../api/notifications/actions";
import TradeTable from "../../../common/form/table/trade-table";
import Loader from "../../../common/loader/components/common-loader";
import { NOTIFICATION_CONFIGURATION_COLUMNS } from "../constants/constants";

const Notifications = (props, context) => {
  const { t } = context;
  const { onInfoIconClick } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [notificationConfiguration, setNotificationConfiguration] = useState([]);
  const [notificationTableData, setNotificationTableData] = useState([]);

  useEffect(() => {
    getNotificationConfiguration();
  }, []);

  const getNotificationConfiguration = async () => {
    try {
      setIsLoading(true);
      const response = await notificationConfigurationApi.getNotificationConfiguration();
      setIsLoading(false);
      setNotificationConfiguration(response.data.data?.sort((a, b) => a.provider - b.provider));

      // Reshape the data so it dynamically creates a column for each provider
      let tableData = [];
      response.data.data.forEach(config => {
        config.eventTriggers.forEach(trigger => {
          let row = tableData.find(r => r.eventType === trigger.eventType);
          if (!row) {
            row = { eventType: trigger.eventType, eventTypeName: trigger.eventTypeName };
            tableData.push(row);
          }
          row[`isActive${config.providerName}`] = trigger.isActive;
        });
      });

      // Group the retrieved data according to the eventTypeName prefix, so that the rows are separated in the table.
      tableData.sort((a, b) => a.eventType - b.eventType);
      const groupedData = tableData.map((item, i, arr) => {
        if (i === 0 || item.eventTypeName.slice(0, item.eventTypeName.indexOf(' ')) !== arr[i - 1].eventTypeName.slice(0, arr[i - 1].eventTypeName.indexOf(' '))) {
          let groupName;
          if (item.eventTypeName.startsWith('Bot')) {
            groupName = 'Bot';
          } else if (item.eventTypeName.startsWith('Campaign')) {
            groupName = 'Campaign';
          } else if (item.eventTypeName.startsWith('Scalp')) {
              groupName = 'Scalp';
          } else {
            groupName = 'General';
          }
          return [{ groupName: groupName }, item];
        } else {
          return [item];
        }
      }).reduce((acc, val) => acc.concat(val), []);

      setNotificationTableData(groupedData);
    } catch (err) {
      Helpers.parseError(err);
      setIsLoading(false);
    }
  };

  const toggleNotificationEventTriggerProvider = async (eventType, propertyName, provider) => {
    try {
      const index = notificationTableData.findIndex(x => x.eventType === eventType);
      notificationTableData[index][propertyName] = !notificationTableData[index][propertyName];
      setNotificationTableData([...notificationTableData]);
      await notificationConfigurationApi.toggleNotificationEventTriggerProvider({ provider, eventType });
      setIsLoading(false);
    } catch (err) {
      Helpers.parseError(err);
      setIsLoading(false);
    }
  };

  const updateNotificationConfiguration = async (provider, providerAuthToken) => {
    try {
      const targetItem = notificationConfiguration.find(x => x.provider === provider);
      targetItem.providerAuthToken = providerAuthToken;
      setNotificationConfiguration([targetItem, ...notificationConfiguration.filter(x => x.provider !== provider)].sort((a, b) => a.provider - b.provider));
      await notificationConfigurationApi.updateNotificationConfiguration({ provider, providerAuthToken });
      setIsLoading(false);
    } catch (err) {
      Helpers.parseError(err);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="d-none d-lg-flex p-4">
        <div style={{ width: '70%', margin: 'auto' }}>
          <TradeTable
            data={notificationTableData}
            columnDefs={NOTIFICATION_CONFIGURATION_COLUMNS(notificationConfiguration, toggleNotificationEventTriggerProvider, updateNotificationConfiguration, onInfoIconClick, t, false)}
          />
        </div>
        <Loader loading={isLoading} type="global" />
      </div>
      <div className="d-sm-flex d-lg-none">
        <div className="w-100" style={{ margin: 'auto' }}>
          <TradeTable
            data={notificationTableData}
            columnDefs={NOTIFICATION_CONFIGURATION_COLUMNS(notificationConfiguration, toggleNotificationEventTriggerProvider, updateNotificationConfiguration, onInfoIconClick, t, true)}
          />
        </div>
        <Loader loading={isLoading} type="global" />
      </div>
    </>
  );
};

Notifications.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Notifications;
