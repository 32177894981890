import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Helpers from "../../../common/helpers/error-helpers";
import invoiceApi from "../../../api/invoice/actions";
import TradeTable from "../../../common/form/table/trade-table";
import Loader from "../../../common/loader/components/common-loader";
import { PAGE_SIZE_DEFAULT } from "../../../common/constants";
import { USER_PROFIT_COLUMNS } from "../constants/constants";
import AppPagination from "../../../shared/components/pagination/pagination";

const PaymentMethodsForm = (props, context) => {
  const { t } = context;
  const [isLoading, setIsLoading] = useState(false);
  const [userProfit, setUserProfit] = useState([]);
  const [displayedUserProfit, setDisplayedUserProfit] = useState([]);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT);
  const [totalPages, setTotalPages] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    getUserProfit();
  }, []);

  useEffect(() => {
    setPagingData();
  }, [pageSize, pageNumber, userProfit]);

  const getUserProfit = async () => {
    try {
      setIsLoading(true);
      const response = await invoiceApi.getUserProfit(props?.userId);
      setIsLoading(false);
      setUserProfit(response.data);
    } catch (err) {
      Helpers.parseError(err);
      setIsLoading(false);
    }
  };

  const setPagingData = () => {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedUserProfit(userProfit.slice(startIndex, endIndex));
    setTotalPages(Math.ceil(userProfit.length / pageSize));
  };

  const renderBody = (isMobile) => {
    return (
      <div style={{ width: !!props?.userId || isMobile ? "100%" : "40%" }}>
        <TradeTable
          data={displayedUserProfit}
          columnDefs={USER_PROFIT_COLUMNS()}
        />
        <AppPagination
          page={pageNumber}
          totalPages={totalPages || 1}
          pageSize={pageSize}
          setPageSize={setPageSize}
          handlePaginationClick={(_, value) => setPageNumber(value)}
        />
      </div>
    );
  }

  return (
    <>
      <div className="d-none d-lg-flex p-4">
        {renderBody(false)}
        <Loader loading={isLoading} type="global" />
      </div>
      <div className="d-sm-flex d-lg-none">
        {renderBody(true)}
        <Loader loading={isLoading} type="global" />
      </div>
    </>
  );
};

PaymentMethodsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PaymentMethodsForm;
