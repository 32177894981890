/* eslint-disable no-restricted-globals */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import OrderOverview from "./order-overview";

class BotOrderOverview extends Component {
  render() {
    const { match, location } = this.props;
    const { id } = match.params;
    const queryParams = new URLSearchParams(location.search);
    const active = queryParams.get("active");

    return <OrderOverview id={id} active={active === "1" ? true : false} />;
  }
}

export default withRouter(BotOrderOverview);
