import PropTypes from "prop-types";
import React, { useRef } from "react";
import { Form } from "react-bootstrap";
import "../style/input.scss";

const Input = (props) => {
  const {
    input,
    label,
    type,
    placeholder,
    readOnly,
    meta,
    className,
    tooltipValidation,
    id,
    formClassName,
    max,
    min,
    disabled,
  } = props;

  const inputRef = useRef(null);

  const handleWheel = (event) => {
    event.preventDefault();
  };

  const handleWheelRef = useRef(handleWheel);

  // Attach event listener to the input element if type is "number"
  React.useEffect(() => {
    if (inputRef.current && type === "number") {
      inputRef.current.addEventListener("wheel", handleWheelRef.current, {
        passive: false,
      });
    }

    // Detach event listener on unmount
    return () => {
      if (inputRef.current && type === "number") {
        inputRef.current.removeEventListener(
          "wheel",
          handleWheelRef.current
        );
      }
    };
  }, [inputRef.current, type]);

  return (
    <Form.Group className={`${formClassName || ""}`}>
      <div className="label-control-wrapper">
        {label && !["checkbox", "radio"].includes(type) && (
          <Form.Label>{label}</Form.Label>
        )}
        <Form.Control
          {...{
            type,
            placeholder,
            id,
            className: `${className} ${meta && meta.error && meta.touched ? "border border-danger" : ""
              }`,
            readOnly,
            max,
            min,
            disabled,
            ref: inputRef,
            ...input,
          }}
        />
        {label && ["checkbox", "radio"].includes(type) && (
          <Form.Label htmlFor={id}>{label}</Form.Label>
        )}
      </div>
      <Form.Text className="text-danger validation">
        {!tooltipValidation &&
          meta &&
          meta.error &&
          meta.touched &&
          meta.error.label}
        {tooltipValidation && meta && meta.error && meta.touched && (
          <small className="invalid-tooltip">{meta.error.label}</small>
        )}
      </Form.Text>
    </Form.Group>
  );
};

Input.defaultProps = {
  label: null,
  type: "text",
  placeholder: "",
  tooltipValidation: false,
  className: "",
};

Input.propTypes = {
  type: PropTypes.oneOf([
    "text",
    "password",
    "email",
    "number",
    "checkbox",
    "radio",
  ]),
};

export default Input;
