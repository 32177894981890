import api from "../../../api/bot/actions";
import {
  getBots,
  saveBotSuccess,
  deleteBot,
  setCurrentBot,
} from "./action-creators";
import ErrorHelper from "../../../common/helpers/error-helpers";
import Helpers from "../../../common/helpers/error-helpers";

export const handleGetBotsOverviewData = (params, cb) => {
  return async (dispatch) => {
    try {
      const res = await api.get(params);
      dispatch(getBots(res.data));
      cb && cb(res.data);
    } catch (err) {
      Helpers.parseError(err);
    }
  };
};

// TODO: Make some generic call handler
export const handleSaveBotData = (params) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await api.save(params);
      dispatch(saveBotSuccess(res.data));
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export const handleUpdateBotData = (params) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await api.update(params);
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export const handleDeleteBotData = (params) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await api.deleteBot(params);
      dispatch(deleteBot(res.data));
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export const handleGetBotData = (id, headers = null) => {
  return async (dispatch) => {
    try {
      const res = await api.getSingle(id, headers);
      const isCustomErrCode = ErrorHelper.isCustomErrCode(res);
      if (isCustomErrCode) {
        dispatch(setCurrentBot(undefined));
      } else {
        dispatch(setCurrentBot(res.data));
        }
    } catch (e) {}
  };
};
