export const fields = {
  username: "username",
  password: "password",
};

export const additionalParams = {
  client_id: "spa",
  grant_type: "password",
  scope: "profile openid api email offline_access",
};
