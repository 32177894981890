import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { Field, isInvalid, submit, reduxForm } from "redux-form";

import userApi from "../../../api/user/actions";
import Input from "../../../common/form/input/components/input";
import { FORMS } from "../../../common/forms";
import Helpers from "../../../common/helpers/error-helpers";
import UserHelper from "../../../common/helpers/user-helper";
import Loader from "../../../common/loader/components/common-loader";
import { fields } from "../constants/constants";
import IBANInput from "../../../common/form/input/components/iban-input";
import ValidationHelper from "../../../common/helpers/validation-helper";
import { useEffect } from "react";

const RoleCard = ({ role, userRole, onSelect, t }) => (
  <div className={`card ${role.name === userRole ? 'active p-3' : 'p-3'}`} onClick={() => onSelect(role.normalizedName)}>
    <h3>{t(`EDIT_PROFILE.MEMBERSHIP.${role.normalizedName}.TITLE`)}</h3>
    <h2>{t(`EDIT_PROFILE.MEMBERSHIP.${role.normalizedName}.SUBTITLE`)}</h2>
    <hr className="w-100 my-1" />
    <p className="description mt-3">{t(`EDIT_PROFILE.MEMBERSHIP.${role.normalizedName}.DESCRIPTION`)}</p>
    <p className="price">{t(`EDIT_PROFILE.MEMBERSHIP.${role.normalizedName}.PRICE`)}</p>
    <p className="note">{t(`EDIT_PROFILE.MEMBERSHIP.${role.normalizedName}.NOTE`)}</p>
    <hr className="w-100 my-1" />
    <ul className="mt-3">
      {new Array(6).fill(null).map((_, index) => {
        if ((index > 3 && role.normalizedName !== 'PROFESSIONAL' && role.normalizedName !== 'ADVANCED')
          || (index > 4 && role.normalizedName !== 'PROFESSIONAL')) {
          return (<></>);
        }
        return (<li key = { index } > { t(`EDIT_PROFILE.MEMBERSHIP.${role.normalizedName}.BENEFIT.${ index }`)}</li>);
      })}
    </ul>
  </div>
);

const validate = values => {
  const errors = {};
  if (!values[fields.agreeToTermsAndConditions]) {
    errors[fields.agreeToTermsAndConditions] = 'Required';
  }
  if (!values[fields.agreeToPrivacyPolicy]) {
    errors[fields.agreeToPrivacyPolicy] = 'Required';
  }
  return errors;
};

let Membership = (props, context) => {
  const { t } = context;
  const { dispatch, handleSubmit } = props;
  const formInvalid = useSelector(state => isInvalid(FORMS.register)(state));
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const { role } = UserHelper.getUserInfo();
  const [userRole, setUserRole] = useState(role);
  const [isValid, setIsValid] = useState(true);
  const [ibanError, setIbanError] = useState("");
  const [userData, setUserData] = useState({});

  const handleSelectRole = (role) => {
    if (userRole === role.name) {
      return;
    }

    setSelectedRole(role);
    setIsModalOpen(true);
    setIsValid(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsValid(true);
  };

  const onSubmit = async () => {
    dispatch(submit(FORMS.register)); 

    if (formInvalid) {
      setIsValid(false);
    }
    else {
      setIsValid(true);
      setIsLoading(true);

      try {
        await userApi.updateUserData({ ...userData, roleName: selectedRole.normalizedName });
        await userApi.updateMyUserRole(selectedRole.normalizedName);
        UserHelper.updateRole(selectedRole.name);
        setUserRole(selectedRole.name);
        setIsModalOpen(false);
        window.location.reload()
      } catch (err) {
        Helpers.parseError(err);
      } finally {
        setIsLoading(false);
      }
    }    
  };

  const confirmRoleChange = () => {
    const isIbanValid = (!userData.iban && { isValid: false, label: "invalid iban brt" }) || ValidationHelper.isIbanValid(userData.iban, t);
    if (selectedRole.normalizedName !== 'TRIAL' && !isIbanValid.isValid) {
      setIbanError(isIbanValid.label);
    }
    else {
      setIbanError(null);
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      setIsLoading(true);
      const response = await userApi.getUserData();
      setIsLoading(false);
      setUserData(response.data);
    } catch (err) {
      Helpers.parseError(err);
      setIsLoading(false);
    }
  };

  const roles = [{ name: 'Trial', normalizedName: 'TRIAL' }, { name: 'Basic user', normalizedName: 'BASICUSER' }, { name: 'Advanced', normalizedName: 'ADVANCED' }, { name: 'Professional', normalizedName: 'PROFESSIONAL' }];

  return (
    <div className="p-4">
      <div className="membership-container p-2">
        {roles.map((item) => (
          <RoleCard
            key={item.normalizedName}
            role={item}
            userRole={userRole}
            onSelect={() => handleSelectRole(item)}
            t={t}
          />
        ))}
      </div>
      <Loader loading={isLoading} type="global" />
      {selectedRole && (
        <Modal show={isModalOpen} onHide={handleCloseModal} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title>{t('EDIT_PROFILE.MEMBERSHIP.MODAL_TITLE')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t('EDIT_PROFILE.MEMBERSHIP.CONFIRM_CHANGE')}</p>
            <form>
              <div className="d-flex justify-content-start flex-column col-xl-12 col-md-12 col-12 mb-2">
                {selectedRole.normalizedName !== 'TRIAL' && (<IBANInput
                  id={'iban'}
                  name={'iban'}
                  placeholder={t("REGISTER_FORM.IBAN")}
                  onValueChange={(newValue) => setUserData({ ...userData, iban: newValue })}
                  externalValue={userData.iban}
                  useExternalValue={true}
                  error={ibanError}
                />)}
              </div>
              <Col xl={12} md={12} xs={12} className="d-flex justify-content-start flex-column">
                <Field
                  name={fields.agreeToTermsAndConditions}
                  component={Input}
                  className="checkbox mb-0"
                  type="checkbox"
                  label={<a href="https://crypdesk.de/terms-of-use.pdf" target="_blank">{t("REGISTER_FORM.AGREE_TO_TERMS_AND_CONDITIONS")}</a>}
                />
              </Col>
              <Col xl={12} md={12} xs={12} className="d-flex justify-content-start flex-column">
                <Field
                  name={fields.agreeToPrivacyPolicy}
                  component={Input}
                  className="checkbox"
                  type="checkbox"
                  label={<a href="https://crypdesk.de/privacy-policy.pdf" target="_blank">{t("REGISTER_FORM.AGREE_TO_PRIVACY_POLICY")}</a>}
                />
              </Col>
              {!isValid && <p className="text-danger">{t("REGISTER_FORM.CONFIRMATION_REQUIRED")}</p>}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={confirmRoleChange}>
              {t('EDIT_PROFILE.MEMBERSHIP.CONFIRM')}
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              {t('EDIT_PROFILE.MEMBERSHIP.CANCEL')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

Membership.contextTypes = {
  t: PropTypes.func.isRequired,
};

Membership = reduxForm({
  form: FORMS.register,
  validate,
  enableReinitialize: true,
})(Membership);

const mapStateToProps = (state, ownProps) => ({
  initialValues: { Role: ownProps.selectedRole }
});

export default connect(mapStateToProps)(Membership);
