import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import botOrderApi from "../../api/trade-order/actions";
import { getAssetPrice, handleGetUserAssets } from "../user-assets/actions/user-assets-actions";

import { withRouter } from "react-router-dom";
import barImage from "../../common/assets/help-modus/help-modus-chart-bar.png";
import chartImage from "../../common/assets/help-modus/help-modus-chart.png";
import { ORDER_TYPE, PAGE_SIZE_DEFAULT } from "../../common/constants";
import Button from "../../common/form/button/components/button";
import InputNumber from "../../common/form/input/components/number-input";
import NumberLabel from "../../common/form/number-label/number-label";
import TradeTable from "../../common/form/table/trade-table";
import { useHelpModus } from "../../common/help-modus/help-modus-context";
import HelpModusModal from "../../common/help-modus/help.modus-modal";
import Helpers from "../../common/helpers/error-helpers";
import Loader from "../../common/loader/components/common-loader";
import Chart from "../../common/trading-chart/chart";
import NotificationProvider from "../../core/notification";
import ApiKey from "../../shared/components/api-key/api-key";
import AppPagination from "../../shared/components/pagination/pagination";
import {
  ACTIVE_AND_PENDING,
  INITIAL_ORDER_COUNT,
  INITIAL_SCALP_ORDER_DATA,
  ORDER_STATUS_ACTIVE,
  ORDER_STATUS_ALL,
  ORDER_STATUS_PENDING,
  SMART_TRADE_ORDER_COLUMNS
} from "./constants";
const TrailingTrade = (props, { t }) => {
  const { getUserAssets, userAssets, getAssetPrice } = props;

  const [marketPrice, setMarketPrice] = useState("");
  const [sellMarketPrice, setSellMarketPrice] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [userCurrentMarketValue, setUserCurrentMarketValue] = useState("N\\A");
  const [userCurrentCoinValue, setUserCurrentCoinValue] = useState("N\\A");
  const [userRealMarketValue, setUserRealMarketValue] = useState("N\\A");
  const [userRealCoinValue, setUserRealCoinValue] = useState("N\\A");
  const [order, setOrder] = useState(INITIAL_SCALP_ORDER_DATA);
  const [tab, setTab] = useState(0);
  const [orderTab, setOrderTab] = useState(0);
  const [orderCount, setOrderCount] = useState(INITIAL_ORDER_COUNT);
  const [exchangeId, setExchangeId] = useState(0);

  const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const [showHelpModusModal, setShowHelpModusModal] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [modalTitle, setModalTitle] = useState('Information');
  const { isHelpModus } = useHelpModus();

  const [id, setId] = useState(props.location.pathname.split("/")[3]);
  const [isEdit, setIsEdit] = useState(false);
  const [isMobile] = useState(window.innerWidth < 768);
  const [selectedType, setSelectedType] = useState('ratio');
  const [selectedTypeSell, setSelectedTypeSell] = useState('ratio');

  const columns = SMART_TRADE_ORDER_COLUMNS;
  const initialWidth = window.innerWidth;

  const handleOrientationChange = () => {
    if (window.innerWidth !== initialWidth) {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleOrientationChange);
    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    if (!!id) {
      getEditOrderData(+id);
      setIsEdit(true);
    }
    else {
      requestOrderCount();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isEdit || !!id) {
      return;
    }

    getOrders(1);
    // eslint-disable-next-line
  }, [orderTab]);

  useEffect(() => {
    if (isEdit || !!id) {
      return;
    }

    getOrders();
    // eslint-disable-next-line
  }, [pageNumber, pageSize]);

  useEffect(() => {
    if (isEdit && userAssets && order.marketName) {
      handleMarketSelectChange(order.marketName);
    }
  }, [userAssets, order.marketName]);

  const getEditOrderData = async (id) => {
    try {
      setIsLoading(true);
      const response = await botOrderApi.getOrder(id);

      if (!response) {
        throw new Error();
      }

      await getUserAssets(response.data.apiKeyId);
      mapOrderDataToFields(response.data);
      setOrderData({ data: response.data?.relatedOrders });
      setOrderCount({
        relatedOrders: response.data?.relatedOrders?.length
      });
      setIsLoading(false);
      setIsTableLoading(false);
    } catch (err) {
      Helpers.parseError(err);
      setIsLoading(false);
      setIsTableLoading(false);
    }
  };

  const getOrders = async (pageNo) => {
    try {
      setIsTableLoading(true);
      const response = await botOrderApi.getOrders({
        params: {
          orderTypeId: ORDER_TYPE.TakeProfitLimit,
          historyStatusId: orderTab === 0 ? ACTIVE_AND_PENDING : ORDER_STATUS_ALL,
          pageNumber: pageNo || pageNumber,
          pageSize,
          needLoader: false
        },
      });
      setOrderData(response.data);
      setIsTableLoading(false);
    } catch (err) {
      Helpers.parseError(err);
      setOrderData([]);
      setIsTableLoading(false);
    }
  };

  const mapOrderDataToFields = (data) => {
    setOrder({
      ...order,
      apiKeyId: data.apiKeyId,
      marketName: data.marketName,
      assetName: data.assetName,
      stopPrice: data.stopPrice,
      price: data.price,
      quantity: data.quantity,
      trailingDelta: data.trailingDelta,
      status: data.status
    });
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setOrder({
      ...order,
      trailValue: ""
    });
  };

  const handleTypeSellChange = (e) => {
    setSelectedTypeSell(e.target.value);
    setOrder({
      ...order,
      trailValue: ""
    });
  };

  const requestOrderCount = async () => {
    try {
      setIsTableLoading(true);
      const response = await botOrderApi.getOrders({
        params: {
          orderTypeId: ORDER_TYPE.TakeProfitLimit,
          pageNumber,
          pageSize: 10000,
          needLoader: false
        },
      });
      const orders = response.data.data;
      const openOrders = getActiveOrdersCount(orders);
      const orderHistory = getOrderHistoryCount(orders);
      setOrderCount({
        openOrders,
        orderHistory,
      });
      setIsTableLoading(false);
    } catch (err) {
      Helpers.parseError(err);
      setOrderCount(INITIAL_ORDER_COUNT);
      setIsTableLoading(false);
    }
  };

  const getActiveOrdersCount = (orders) => {
    return (orders.filter(({ status }) => status === ORDER_STATUS_ACTIVE || status === ORDER_STATUS_PENDING) || []).length;
  };

  const getOrderHistoryCount = (orders) => {
    return (orders.filter(({ status }) => (status !== ORDER_STATUS_ACTIVE) && status !== ORDER_STATUS_PENDING) || []).length;
  };

  const handleMarketSelectChange = (value) => {
    if (!value) {
      return setMarketPrice("");
    }

    let marketAmount = userAssets.markets.find((element) => element.name === value);
    setUserCurrentMarketValue(marketAmount === undefined ? 0 : marketAmount.amountAvailable);
    setUserRealMarketValue(marketAmount === undefined ? 0 : marketAmount.realAvailability);

    order.assetName && fillAssetPrice(value, order.assetName, (assetPrice) => setMarketPrice(assetPrice));
  };

  const handleSellMarketSelectChange = (value) => {
    if (!value) {
      return setSellMarketPrice("");
    }
    order.assetName && fillAssetPrice(value, order.assetName, (assetPrice) => setSellMarketPrice(assetPrice));
  };

  const onCoinChange = (value) => {
    if (!value) {
      return setMarketPrice("");
    }

    let coinAmount = userAssets.assets.find((element) => element.name === value);

    setUserCurrentCoinValue(coinAmount === undefined ? 0 : coinAmount.amountAvailable);
    setUserRealCoinValue(coinAmount === undefined ? 0 : coinAmount.realAvailability);

    order.marketName && fillAssetPrice(order.marketName, value, (assetPrice) => setMarketPrice(assetPrice));
    order.marketName && fillAssetPrice(order.marketName, value, (assetPrice) => setSellMarketPrice(assetPrice));
  };

  const fillAssetPrice = async (marketName, assetName, callback) => {
    let assetPrice = await getAssetPrice(marketName, assetName, order.apiKeyId);
    callback(assetPrice);
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setOrder({ ...order, [name]: value });
  };

  const onNumberChangeHandler = (e) => {
    const { name, value } = e.target;
    setOrder({ ...order, [name]: +value });
  };

  const onBuyOrder = async () => {
    try {
      const request = {
        ...order,
        type: ORDER_TYPE.TakeProfitLimit,
        side: 0
      };
      await botOrderApi.createOrder(request);
      getOrders();
      NotificationProvider.success("Order created");
    } catch (err) {
      Helpers.parseError(err);
      getOrders();
    }
  };

  const onSellOrder = async () => {
    try {
      const request = {
        ...order,
        type: ORDER_TYPE.TakeProfitLimit,
        quantity: order.sellQuantity,
        stopPrice: order.sellStopPrice,
        side: 1,
        trailingDelta: order.sellTrailingDelta,
        price: order.sellPrice,
      };
      await botOrderApi.createOrder(request);
      getOrders();
      NotificationProvider.success("Order created");
    } catch (err) {
      Helpers.parseError(err);
      getOrders();
    }
  };

  const onUpdateOrder = async () => {
    try {
      const request = {
        ...order,
        id
      };
      await botOrderApi.updateOrder(request);
      getOrders();
      NotificationProvider.success("Order updated");
    } catch (err) {
      Helpers.parseError(err);
      getOrders();
    }
  }

  const onCancelOrder = async () => {
    botOrderApi.deleteOrder({ id })
      .then(() => {
        NotificationProvider.success("Order successfully canceled");
        setOrder({
          ...order,
          status: 4
        });
      })
      .catch((err) => {
        Helpers.parseError(err);
      });
  }

  const renderSellOrderTabs = () => {
    return (
      <>
        {!isEdit && (
          <ul className="nav nav-tabs pt-2">
            <li className="nav-item">
              {/* eslint-disable-next-line */}
              <a className={`nav-link text-bold${orderTab === 0 ? " active" : ""}`} href="#" onClick={() => setOrderTab(0)}>
                Open Orders ({orderCount.openOrders})
              </a>
            </li>
            <li className="nav-item">
              {/* eslint-disable-next-line */}
              <a className={`nav-link text-bold${orderTab === 1 ? " active" : ""}`} href="#" onClick={() => setOrderTab(1)}>
                Order History ({orderCount.orderHistory})
              </a>
            </li>
            {isHelpModus && (
              <div className="infoButton asset-info-btn" style={{ fontSize: '16px' }}
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.SMART-TRADE-ORDERS') }} />
                    </div>
                  );
                  toggleHelpModusModal('Open Orders/Order History', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </ul>
        )}
        {isEdit && (
          <ul className="nav nav-tabs pt-2">
            <li className="nav-item">
              {/* eslint-disable-next-line */}
              <span className={`nav-link text-bold${orderTab === 0 ? " active" : ""}`}>
                Related Orders ({orderCount.relatedOrders})
              </span>
            </li>
            {/*{isHelpModus && (*/}
            {/*  <div className="infoButton asset-info-btn" style={{ fontSize: '16px' }}*/}
            {/*    onClick={() => {*/}
            {/*      const messageContent = (*/}
            {/*        <div>*/}
            {/*          <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.SMART-TRADE-ORDERS') }} />*/}
            {/*        </div>*/}
            {/*      );*/}
            {/*      toggleHelpModusModal('Open Orders/Order History', messageContent);*/}
            {/*    }}>*/}
            {/*    <FontAwesomeIcon className="row" icon={faInfoCircle} />*/}
            {/*  </div>*/}
            {/*)}*/}
          </ul>
        )}
      </>
    );
  };

  const toggleHelpModusModal = (title, message) => {
    setShowHelpModusModal((showModal) => !showModal);
    setModalMessage(message);
    setModalTitle(title);
  };

  /*const renderMexTrailValue = () => {
    return (
      <div className="input-group mb-3">
        <label className="input-group-text form-border w-50">{t("Select type")}</label>
        <select id="trailingType" value={selectedType} onChange={handleTypeChange} className="form-control w-50 br-025">
          <option value="ratio">Ratio</option>
          <option value="distance">Price distance</option>
        </select>
        <div id="trailingDeltaWrapper" className="mt-2">
          {selectedType === 'distance' && (
            <div className="input-group mb-3">
              <span className="input-group-text input-form-border" style={{ justifyContent: 'space-between' }}>
                Trailing Delta
              </span>
              <InputNumber
                type="number"
                id="usdcField"
                className="trailing-input"
                value={order.trailValue}
                disabled={isEdit}
                onChange={(e) => {
                  const { value } = e.target;
                  if (!value) return setOrder({ ...order, trailValue: "" });
                  setOrder({
                    ...order,
                    trailValue: parseFloat(value),
                  });
                }}
                placeholder="Price distance"
              />
              <span style={{ fontWeight: 700, width: 70 }} className="input-group-text input-end-form-border">
                {"USDC"}
              </span>
            </div>
          )}

          {selectedType === 'ratio' && (
            <div className="input-group mb-3">
              <span className="input-group-text input-form-border" style={{ justifyContent: 'space-between' }}>
                Trailing Delta
              </span>
              <InputNumber
                name="trailingDelta"
                value={order.trailValue.replace("%", "")}
                max={99.9}
                step={0.1}
                disabled={isEdit}
                onChange={(e) => {
                  const { value } = e.target;
                  if (!value) return setOrder({ ...order, trailValue: "" });
                  setOrder({
                    ...order,
                    trailValue: parseFloat(value) + "%",
                  });
                }}
              />
              <span style={{ fontWeight: 700, width: 40 }} className="input-group-text input-end-form-border">
                %
              </span>
              <div>
                <button
                  className="btn btn-outline-primary"
                  onClick={() => setOrder({ ...order, trailValue: 1 + "%" })}
                  disabled={isEdit}
                >
                  1%
                </button>
                <button
                  className="btn btn-outline-primary"
                  onClick={() => setOrder({ ...order, trailValue: 2 + "%" })}
                  disabled={isEdit}
                >
                  2%
                </button>
              </div>
            </div>

          )}
        </div>
      </div>
    );
  }
  const renderMexSellTrailValue = () => {
    return (
      <div className="input-group mb-3">
        <label className="input-group-text form-border w-50">{t("Select type")}</label>
        <select id="trailingTypeSell" value={selectedType} onChange={handleTypeSellChange} className="form-control w-50 br-025">
          <option value="ratio">Ratio</option>
          <option value="distance">Price distance</option>
        </select>

        <div id="trailingDeltaWrapper" className="mt-2">
          {selectedTypeSell === 'distance' && (
            <div className="input-group mb-3">
              <span className="input-group-text input-form-border" style={{ justifyContent: 'space-between' }}>
                Trailing Delta
              </span>
              <InputNumber
                type="number"
                id="usdcField"
                className="trailing-input"
                value={order.sellTrailValue}
                disabled={isEdit}
                onChange={(e) => {
                  const { value } = e.target;
                  if (!value) return setOrder({ ...order, sellTrailValue: "" });
                  setOrder({
                    ...order,
                    sellTrailValue: parseFloat(value),
                  });
                }}
                placeholder="Price distance"
              />
              <span style={{ fontWeight: 700, width: 70 }} className="input-group-text input-end-form-border">
                {"USDC"}
              </span>
            </div>
          )}

          {selectedTypeSell === 'ratio' && (
            <div className="input-group mb-3">
              <span className="input-group-text input-form-border" style={{ justifyContent: 'space-between' }}>
                Trailing Delta
              </span>
              <InputNumber
                name="trailingDelta"
                value={order.sellTrailValue.replace("%", "")}
                max={99.9}
                step={0.1}
                disabled={isEdit}
                onChange={(e) => {
                  const { value } = e.target;
                  if (!value) return setOrder({ ...order, sellTrailValue: "" });
                  setOrder({
                    ...order,
                    sellTrailValue: parseFloat(value) + "%",
                  });
                }}
              />
              <span style={{ fontWeight: 700, width: 40 }} className="input-group-text input-end-form-border">
                %
              </span>
              <div>
                <button
                  className="btn btn-outline-primary"
                  onClick={() => setOrder({ ...order, sellTrailValue: 1 + "%" })}
                  disabled={isEdit}
                >
                  1%
                </button>
                <button
                  className="btn btn-outline-primary"
                  onClick={() => setOrder({ ...order, sellTrailValue: 2 + "%" })}
                  disabled={isEdit}
                >
                  2%
                </button>
              </div>
            </div>

          )}
        </div>
      </div>
    );
  }*/


  return (
    <div className="smart-trade-layout">
      <h4 className="page-title d-flex">
        Trailing stop order
        {isHelpModus && (
          <div className="infoButton avblInfoButton" style={{ fontSize: '16px' }}
            onClick={() => {
              const messageContent = (
                <div>
                  <p>{t('HELP-MODUS.SMART-TRADE-TITLE')}</p>
                </div>
              );
              toggleHelpModusModal('Trailing stop order', messageContent);
            }}>
            <FontAwesomeIcon className="row" icon={faInfoCircle} />
          </div>
        )}
      </h4>
      <div className="d-flex">
        {!isMobile && <div className="pr-3 smart-trade-chart mb-1 d-flex" style={{ height: 500, width: "100%" }}>
          <Chart height={497} marketName={order.marketName} assetName={order.assetName} exchangeId={exchangeId} />
          {isHelpModus && (
            <div className="infoButton asset-info-btn"
              onClick={() => {
                const messageContent = (
                  <div>
                    <p>{t('HELP-MODUS.CREATE-BOT-CHART-FIRST')}</p>
                    <img src={barImage} style={{ width: '25%' }} alt="Create Bot Chart Bar" />
                    <p><br></br>{t('HELP-MODUS.CREATE-BOT-CHART-SECOND')}</p>
                    <img src={chartImage} style={{ width: '100%' }} alt="Create Bot Chart Bar" />
                    <p><br></br>{t('HELP-MODUS.CREATE-BOT-CHART-THIRD')}</p>
                  </div>
                );
                toggleHelpModusModal('Chart', messageContent);
              }}>
              <FontAwesomeIcon className="row" icon={faInfoCircle} />
            </div>
          )}
        </div>}
        <div className="smart-trade-form">
          <div className="fieldRow" style={{ justifyContent: 'right' }}>
            <span className="mr-2 asset-amount-lbl">{t("AVBL")}</span>
            {(tab === 0 || !tab) && (
              <h5>
                <NumberLabel value={userCurrentMarketValue} maxFractions={10} /> {order.marketName}
              </h5>
            )}
            {tab === 1 && (
              <h5>
                <NumberLabel value={userCurrentCoinValue} maxFractions={10} /> {order.assetName}
              </h5>
            )}
            {isHelpModus && (
              <div className="infoButton avblInfoButton"
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.SMART-TRADE-AVBL') }} />
                    </div>
                  );
                  const messageTitle = (<>{t("AVBL")}</>);
                  toggleHelpModusModal(messageTitle, messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </div>
          <div className="fieldRow" style={{ justifyContent: 'right' }}>
            <span className="mr-2 asset-amount-lbl">{t("REAL-AVBL")}</span>
            {(tab === 0 || !tab) && (
              <h5>
                <NumberLabel value={userRealMarketValue} maxFractions={10} /> {order.marketName}
              </h5>
            )}
            {tab === 1 && (
              <h5>
                <NumberLabel value={userRealCoinValue} maxFractions={10} /> {order.assetName}
              </h5>
            )}
          </div>
          <br />
          <Row className="w-100 m-0">
            <Col md={12} lg={12} sm={12} xs={12} className="p-0">
              <div className="d-flex align-items-center justify-content-center">
                <ApiKey
                  classList="w-75"
                  onChange={(value, exchangeId, e) => {
                    onNumberChangeHandler(e);
                    setExchangeId(exchangeId);
                    value && getUserAssets(value);
                  }}
                  isHelpModus={isHelpModus}
                  onInfoIconClick={toggleHelpModusModal}
                  exchangeId={exchangeId}
                  value={order.apiKeyId}
                  disabled={isEdit}
                />
              </div>
              <div className="d-flex align-items-center justify-content-center mb-4 mt-2">
                <div className="input-group w-50">
                  <select
                    name="assetName"
                    onChange={(e) => {
                      onChangeHandler(e);
                      onCoinChange(e.target.value);
                    }}
                    value={order.assetName}
                    className="form-control br-025"
                    disabled={isEdit}
                  >
                    <option value="">Select Coin</option>
                    {userAssets.assets?.map((asset) => (
                      <option key={asset.id} value={asset.name}>
                        {asset.name}
                      </option>
                    ))}
                  </select>
                  {isHelpModus && (
                    <div className="infoButton asset-info-btn"
                      onClick={() => {
                        const messageContent = (
                          <div>
                            <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.SMART-TRADE-ASSET') }} />
                          </div>
                        );
                        toggleHelpModusModal('Asset', messageContent);
                      }}>
                      <FontAwesomeIcon className="row" icon={faInfoCircle} />
                    </div>
                  )}
                </div>
                <div className="vertical-line mb-0"></div>
                <div className="input-group w-50">
                  <select
                    value={order.marketName}
                    name="marketName"
                    onChange={(e) => {
                      onChangeHandler(e);
                      handleMarketSelectChange(e.target.value);
                      handleSellMarketSelectChange(e.target.value);
                    }}
                    className="form-control br-025"
                    disabled={isEdit}
                  >
                    <option value={""}>Select Market</option>
                    {userAssets.markets?.map((market) => (
                      <option key={market.id} value={market.name}>
                        {market.name}
                      </option>
                    ))}
                  </select>
                  {isHelpModus && (
                    <div className="infoButton asset-info-btn"
                      onClick={() => {
                        const messageContent = (
                          <div>
                            <p>{t('HELP-MODUS.CREATE-BOT-MARKET')}</p>
                          </div>
                        );
                        toggleHelpModusModal('Asset', messageContent);
                      }}>
                      <FontAwesomeIcon className="row" icon={faInfoCircle} />
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          {isMobile && <div className="smart-trade-chart mb-1 d-flex" style={{ height: 400, width: "100%" }}>
            <Chart height={400} marketName={order.marketName} assetName={order.assetName} exchangeId={exchangeId} />
            {isHelpModus && (
              <div className="infoButton asset-info-btn"
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p>{t('HELP-MODUS.CREATE-BOT-CHART-FIRST')}</p>
                      <img src={barImage} style={{ width: '25%' }} alt="Create Bot Chart Bar" />
                      <p><br></br>{t('HELP-MODUS.CREATE-BOT-CHART-SECOND')}</p>
                      <img src={chartImage} style={{ width: '100%' }} alt="Create Bot Chart Bar" />
                      <p><br></br>{t('HELP-MODUS.CREATE-BOT-CHART-THIRD')}</p>
                    </div>
                  );
                  toggleHelpModusModal('Chart', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </div>}
          <div className="d-flex">
            <div className={`d-flex border-bottom align-items-center smart-type`} style={{ width: '100%' }}>
              <div style={{ color: "#15ed1d" }} className={`text-center w-50 p-3 ${tab === 0 ? "active" : ""}`}
                onClick={() => {
                  if (!isEdit) setTab(0);
                }}>
                Buy {order.assetName}
              </div>
              <div style={{ color: "red" }} className={`text-center w-50 p-3 ${tab === 1 ? "active" : ""}`} onClick={() => { if (!isEdit) setTab(1); }}>
                Sell {order.assetName}
              </div>
            </div>
            {isHelpModus && (
              <div className="infoButton asset-info-btn"
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.SMART-TRADE-BUY-SELL') }} />
                    </div>
                  );
                  toggleHelpModusModal('Buy/Sell', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </div>
          <Row className="w-100 m-0 p-0 pt-1">
            {tab === 0 && (
              <Col md={12} lg={12} sm={12} xs={12} className="p-0">
                {exchangeId != 2 && <div className="input-group mb-3">
                  <span className="input-group-text input-form-border">
                    Trailing Delta
                  </span>
                  <InputNumber
                    name="trailingDelta"
                    value={order.trailingDelta / 100}
                    max={99.9}
                    step={0.1}
                    disabled={isEdit}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!value) return setOrder({ ...order, trailingDelta: "" });
                      setOrder({
                        ...order,
                        trailingDelta: parseFloat(value) * 100,
                      });
                    }}
                  />
                  <span style={{ fontWeight: 700, width: 40 }} className="input-group-text input-end-form-border">
                    %
                  </span>
                  <div>
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => setOrder({ ...order, trailingDelta: 1 * 100 })}
                      disabled={isEdit}
                    >
                      1%
                    </button>
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => setOrder({ ...order, trailingDelta: 2 * 100 })}
                      disabled={isEdit}
                    >
                      2%
                    </button>
                  </div>
                </div>}
                {/*exchangeId == 2 && renderMexTrailValue()*/}
                <div className="input-group mb-3">
                  <span className="input-group-text input-form-border" style={{ justifyContent: 'space-between' }}>
                    Activation price
                    {/*{isHelpModus && (*/}
                    {/*  <div className="infoButton labelInfoButton" style={{ right: '0' }}*/}
                    {/*    onClick={() => {*/}
                    {/*      const messageContent = (*/}
                    {/*        <div>*/}
                    {/*          <p>{t('HELP-MODUS.SMART-TRADE-AMOUNT')}</p>*/}
                    {/*        </div>*/}
                    {/*      );*/}
                    {/*      toggleHelpModusModal('Price', messageContent);*/}
                    {/*    }}>*/}
                    {/*    <FontAwesomeIcon className="row" icon={faInfoCircle} />*/}
                    {/*  </div>*/}
                    {/*)}*/}
                  </span>
                  <InputNumber name="stopPrice" value={order.stopPrice}
                    disabled={isEdit}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!value) return setOrder({ ...order, stopPrice: "" });
                      setOrder({
                        ...order,
                        stopPrice: parseFloat(value)
                      });
                    }} />
                  <span style={{ fontWeight: 700, width: 70 }} className="input-group-text input-end-form-border">
                    {order.marketName || "N/A"}
                  </span>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text input-form-border" style={{ justifyContent: 'space-between' }}>
                    Amount
                    {isHelpModus && (
                      <div className="infoButton labelInfoButton" style={{ right: '0' }}
                        onClick={() => {
                          const messageContent = (
                            <div>
                              <p>{t('HELP-MODUS.SMART-TRADE-AMOUNT')}</p>
                            </div>
                          );
                          toggleHelpModusModal('Amount', messageContent);
                        }}>
                        <FontAwesomeIcon className="row" icon={faInfoCircle} />
                      </div>
                    )}
                  </span>
                  <InputNumber name="quantity" value={order.quantity}
                    disabled={isEdit}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!value) return setOrder({ ...order, quantity: "" });
                      setOrder({
                        ...order,
                        quantity: parseFloat(value),
                      });
                    }} />
                  <span style={{ fontWeight: 700, width: 70 }} className="input-group-text input-end-form-border">
                    {order.assetName || "N/A"}
                  </span>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text input-form-border" style={{ justifyContent: 'space-between' }}>
                    Limit
                    {isHelpModus && (
                      <div className="infoButton labelInfoButton" style={{ right: '0' }}
                        onClick={() => {
                          const messageContent = (
                            <div>
                              <p>{t('HELP-MODUS.TRAILING-TRADE-LIMIT')}</p>
                            </div>
                          );
                          toggleHelpModusModal('Limit', messageContent);
                        }}>
                        <FontAwesomeIcon className="row" icon={faInfoCircle} />
                      </div>
                    )}
                  </span>
                  <InputNumber name="price" value={order.price}
                    disabled={isEdit}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!value) return setOrder({ ...order, price: "" });
                      setOrder({
                        ...order,
                        price: parseFloat(value),
                      });
                    }} />
                  <span style={{ fontWeight: 700, width: 70 }} className="input-group-text input-end-form-border">
                    {order.marketName || "N/A"}
                  </span>
                </div>
                <div className="d-flex justify-content-center">
                  {isEdit ? (
                    <div className="mt-4  d-flex justify-content-center">
                      {![2, 3, 4].includes(order.status) && (
                        <Button className={"mt-2 pl-5 pr-5 mr-2"} variant="danger" type="button" size="md" onClick={onCancelOrder}>
                          {t("BUTTON.CANCEL")}
                        </Button>
                      )}
                      <Button className={"mt-2 pl-5 pr-5"} variant="primary" type="button" size="md" onClick={onUpdateOrder}>
                        {t("BUTTON.UPDATE")}
                      </Button>
                    </div>
                  ) : (
                    <button onClick={onBuyOrder} type="button" className="btn btn-success w-100 mb-3 mt-2" style={{ width: '100%' }} disabled={isEdit}>
                      Buy {order.assetName}
                    </button>
                  )}
                  {isHelpModus && (
                    <div className="infoButton avblInfoButton"
                      style={isEdit ? { top: "10px" } : {}}
                      onClick={() => {
                        const messageContent = (
                          <div>
                            <p>{t('HELP-MODUS.SMART-TRADE-BUY-OR-SELL')}</p>
                          </div>
                        );
                        toggleHelpModusModal('Buy or Sell', messageContent);
                      }}>
                      <FontAwesomeIcon className="row" icon={faInfoCircle} />
                    </div>
                  )}
                </div>
              </Col>
            )}
            {tab === 1 && (
              <Col md={12} lg={12} sm={12} xs={12} className="p-0">
                {exchangeId != 2 && <div className="input-group mb-3">
                  <span className="input-group-text input-form-border">
                    Trailing Delta
                  </span>
                  <InputNumber
                    name="sellTrailingDelta"
                    value={order.sellTrailingDelta / 100}
                    max={99.9}
                    step={0.1}
                    disabled={isEdit}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!value) return setOrder({ ...order, sellTrailingDelta: "" });
                      setOrder({
                        ...order,
                        sellTrailingDelta: parseFloat(value) * 100,
                      });
                    }}
                  />
                  <span style={{ fontWeight: 700, width: 40 }} className="input-group-text input-end-form-border">
                    %
                  </span>
                  <div>
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => setOrder({ ...order, sellTrailingDelta: 1 * 100 })}
                      disabled={isEdit}
                    >
                      1%
                    </button>
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => setOrder({ ...order, sellTrailingDelta: 2 * 100 })}
                      disabled={isEdit}
                    >
                      2%
                    </button>
                  </div>
                </div>}
                {/*exchangeId == 2 && renderMexSellTrailValue()*/}
                <div className="input-group mb-3">
                  <span className="input-group-text input-form-border" style={{ justifyContent: 'space-between' }}>
                    Activation price
                    {/*{isHelpModus && (*/}
                    {/*  <div className="infoButton labelInfoButton" style={{ right: '0' }}*/}
                    {/*    onClick={() => {*/}
                    {/*      const messageContent = (*/}
                    {/*        <div>*/}
                    {/*          <p>{t('HELP-MODUS.SMART-TRADE-AMOUNT')}</p>*/}
                    {/*        </div>*/}
                    {/*      );*/}
                    {/*      toggleHelpModusModal('Price', messageContent);*/}
                    {/*    }}>*/}
                    {/*    <FontAwesomeIcon className="row" icon={faInfoCircle} />*/}
                    {/*  </div>*/}
                    {/*)}*/}
                  </span>
                  <InputNumber name="sellStopPrice" value={order.sellStopPrice}
                    disabled={isEdit}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!value) return setOrder({ ...order, sellStopPrice: "" });
                      setOrder({
                        ...order,
                        sellStopPrice: parseFloat(value),
                      });
                    }} />
                  <span style={{ fontWeight: 700, width: 70 }} className="input-group-text input-end-form-border">
                    {order.assetName}
                  </span>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text input-form-border">Amount</span>
                  <InputNumber name="sellQuantity" value={order.sellQuantity}
                    disabled={isEdit}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!value)
                        return setOrder({
                          ...order,
                          sellQuantity: "",
                        });
                      setOrder({
                        ...order,
                        sellQuantity: parseFloat(value),
                      });
                    }} />
                  <span style={{ fontWeight: 700, width: 70 }} className="input-group-text input-end-form-border">
                    {order.assetName}
                  </span>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text input-form-border">Limit</span>
                  <InputNumber name="sellPrice" value={order.sellPrice}
                    disabled={isEdit}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!value)
                        return setOrder({
                          ...order,
                          sellPrice: "",
                        });
                      setOrder({
                        ...order,
                        sellPrice: parseFloat(value),
                      });
                    }} />
                  <span style={{ fontWeight: 700, width: 70 }} className="input-group-text input-end-form-border">
                    {order.marketName || "N/A"}
                  </span>
                </div>
                {isEdit ? (
                  <div className={`mt-4  d-flex justify-content-center`}>
                    <Button className={"mt-2 pl-5 pr-5 mr-2"} variant="danger" type="button" size="md" onClick={onCancelOrder}>
                      {t("BUTTON.CANCEL")}
                    </Button>
                    <Button className={"mt-2 pl-5 pr-5"} variant="primary" type="button" size="md" onClick={onUpdateOrder}>
                      {t("BUTTON.UPDATE")}
                    </Button>
                  </div>
                ) : (
                  <button onClick={onSellOrder} type="button" className="btn btn-danger w-100 mt-2 mb-3" disabled={isEdit}>
                    Sell {order.assetName}
                  </button>
                )}
              </Col>
            )}
          </Row>
        </div>
      </div>

      {renderSellOrderTabs()}
      <TradeTable data={orderData.data} columnDefs={columns} sequenceStep={(pageNumber - 1) * pageSize} isLoading={isTableLoading} />
      {!isEdit && (
        <AppPagination
          page={pageNumber}
          totalPages={orderData.totalPages || 1}
          pageSize={pageSize}
          setPageSize={setPageSize}
          handlePaginationClick={(_, value) => setPageNumber(value)}
        />
      )}
      {showHelpModusModal && (
        <HelpModusModal
          show={showHelpModusModal}
          onClose={toggleHelpModusModal}
          title={modalTitle}
          message={modalMessage}
        />
      )}
      <Loader loading={isLoading} type="global" />
    </div>
  );
};

TrailingTrade.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userAssets: state.userAssetsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getUserAssets: (params) => dispatch(handleGetUserAssets(params)),
  getAssetPrice: (marketName, coinName, apiKeyId) => dispatch(getAssetPrice(marketName, coinName, apiKeyId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrailingTrade)
);
