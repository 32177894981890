import { BLOB_STORAGE_BASE_URL } from "../../common/constants";

const USER_INFO = "USER_INFO";

class UserHelper {
  static setUserInfo(data) {
    const { id, firstname, lastname, role, username, profile_picture } = data;
    localStorage.setItem(
      USER_INFO,
      JSON.stringify({ id, firstName: firstname, lastName: lastname, role, nickname: username, profile_picture })
    );
  }

  static getUserName() {
    const { firstName, lastName, username } = this.getUserInfo();
    return firstName && lastName ? `${firstName} ${lastName}` : username;
  }

  static getUserInfo() {
    const user = JSON.parse(localStorage.getItem(USER_INFO)) || {};
    return user;
  }

  static getId() {
    const { id } = this.getUserInfo();
    return id;
  }

  static getProfilePicture() {
    const { profile_picture } = this.getUserInfo();
    const img = profile_picture
      ? `data:image/png;base64,${profile_picture}`
      : `${BLOB_STORAGE_BASE_URL}/user-images/${this.getId()}`;
    return img;
  }

  static updateProfilePicture(profile_picture) {
    const { id, firstName, lastName, role, nickname } = { ...this.getUserInfo(), profile_picture };
    localStorage.setItem(USER_INFO, JSON.stringify({ id, firstName, lastName, role, nickname, profile_picture }));
  }

  static updateRole(role) {
    const { id, firstName, lastName, nickname, profile_picture } = { ...this.getUserInfo(), role };
    localStorage.setItem(USER_INFO, JSON.stringify({ id, firstName, lastName, role, nickname, profile_picture }));
  }

  static isAdminUser() {
    const { role } = this.getUserInfo();
    return role === "Admin";
  }

  static removeUserInfo() {
    localStorage.removeItem(USER_INFO);
  }

  static isCampaignVisible() {
    const { role } = this.getUserInfo();
      return role === "Professional" || role === "CrypS" || role === "Admin";
  }
}
export default UserHelper;
