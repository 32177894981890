import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";

import { getAssetPrice } from "../../user-assets/actions/user-assets-actions";

import { ORDER_TYPE } from "../../../common/constants";
import { CONDITION_ORDER_TYPE, INITIAL_CONDITION_DATA } from "../constants";

import LimitOrderConditionModal from "../modals/limit-order-condition.modal";
import MarketOrderConditionModal from "../modals/market-order-condition.modal";
import ConditionItem from "./condition-item";
import ConditionForm from "./condition-form";

import "../style/condition.scss";

const Condition = (props, { t }) => {
  const {
    marketName,
    assetName,
    apiKeyId,
    getAssetPrice,
    availableUserCoins,
    availableUserMarkets,
    conditions = [],
    setConditions = (data) => {},
    isBotActive,
    isCampaign = false,
    isOrder = false,
  } = props;

  const [conditionData, setCondtionData] = useState(INITIAL_CONDITION_DATA);
  const [conditionOrderType, setConditionOrderType] = useState("");

  const conditionFormProps = {
    isBotActive,
    isCampaign,
    conditionData,
    setCondtionData,
    setConditionOrderType,
    assetName,
    marketName,
    isOrder,
  };

  const isConditionOrderType = (type) => {
    return conditionOrderType === type;
  };

  const createLimitOrderCondition = (data) => {
    console.log(data);
  };

  const createMarketOrderCondition = (data) => {
    const { price, quantity, side } = data;
    const condition = {
      ...conditionData,
      triggerPrice: price,
      conditionalOrderQuantity: quantity,
      conditionalOrderSide: side,
      conditionalOrderType: ORDER_TYPE.Market,
    };
    setCondtionData(condition);
    setConditionOrderType("");
  };

  const addCondtion = () => {
    setConditions([
      ...conditions,
      {
        ...conditionData,
        triggerPrice: +conditionData.triggerPrice,
        conditionPercentage: +conditionData.conditionPercentage,
        reentryPercentage: +conditionData.reentryPercentage,
        reentryPrice: +conditionData.reentryPrice,
      },
    ]);
    setCondtionData(INITIAL_CONDITION_DATA);
  };

  const deleteCondition = (index) => {
    const filtered = [...conditions.filter((c, key) => key !== index)];
    setConditions(filtered);
  };

  return (
    <div className="w-100 mt-2">
      <ConditionForm addCondtion={addCondtion} {...conditionFormProps} />
      <div className="conditions">
        {conditions.map((condition, key) => (
          <ConditionItem
            key={key}
            marketName={marketName}
            assetName={assetName}
            condition={condition}
            isCampaign={isCampaign}
            deleteCondition={() => deleteCondition(key)}
          />
        ))}
      </div>

      {isConditionOrderType(CONDITION_ORDER_TYPE.Limit) && (
        <LimitOrderConditionModal
          show={isConditionOrderType(CONDITION_ORDER_TYPE.Limit)}
          onClose={() => setConditionOrderType("")}
          assetName={assetName}
          marketName={marketName}
          apiKeyId={apiKeyId}
          quantity={conditionData.amount}
          avblCoins={availableUserCoins}
          avblMarkets={availableUserMarkets}
          getAssetPrice={getAssetPrice}
          onSave={createLimitOrderCondition}
        />
      )}

      {isConditionOrderType(CONDITION_ORDER_TYPE.Market) && (
        <MarketOrderConditionModal
          show={isConditionOrderType(CONDITION_ORDER_TYPE.Market)}
          onClose={() => setConditionOrderType("")}
          assetName={assetName}
          marketName={marketName}
          apiKeyId={apiKeyId}
          quantity={conditionData.amount}
          avblCoins={availableUserCoins}
          avblMarkets={availableUserMarkets}
          getAssetPrice={getAssetPrice}
          onSave={createMarketOrderCondition}
        />
      )}
    </div>
  );
};

Condition.contextTypes = {
  t: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  getAssetPrice: (marketName, coinName, apiKeyId) => dispatch(getAssetPrice(marketName, coinName, apiKeyId)),
});

export default connect(null, mapDispatchToProps)(Condition);
