import { get, isArray, isEmpty } from "lodash";
import NotificationProvider from "../../core/notification";
import {
  CUSTOM_ERROR_CODES,
  CUSTOM_ERROR_CODES_HANDLED_MANUALLY,
  CUSTOM_ERROR_SUB_CODES_HANDLED_MANUALLY,
} from "../error-constants";

class Helpers {
  //entity error mesagges
  static generateErrorLabel = (
    err,
    path = "response.data.error_description"
  ) => {
    const errType = get(err, path);

    return errType ? `ERROR.${errType}` : `ERROR.GENERIC`;
  };

  // custom error messages handled manually
  static isCustomErrCodeHandledManualy = (err) => {
    const code = get(err, "response.data.code");
    if (
      !isNaN(code) &&
      CUSTOM_ERROR_CODES_HANDLED_MANUALLY.indexOf(code) > -1
    ) {
      let message = `ERROR.${code}`;

      let subCode = get(err, "response.data.message");
      subCode = !isEmpty(subCode) && isArray(subCode) && subCode[0];

      if (subCode) {
        if (
          subCode.code &&
          CUSTOM_ERROR_SUB_CODES_HANDLED_MANUALLY.indexOf(subCode.code) > -1
        ) {
          message = `ERROR.${subCode.code}`;
        } else {
          console.error(subCode && subCode.code, "no error mapped");
          message = `ERROR.GENERIC`;
        }
      }

      return {
        ...err,
        cErr: {
          code,
          message,
        },
      };
    }
    return null;
  };

  static isCustomErrCode = (err) => {
    const code1 = get(err, "response.data.code");
    const code2 = get(err, "data.code");
    const code = code1 || code2;
    if (!isNaN(code) && CUSTOM_ERROR_CODES.indexOf(code) > -1) {
      let message = get(err, "response.data.message");

      const subCode = !isEmpty(message) && isArray(message) && message[0]
      if (subCode) {
        message = subCode.description ? subCode.description : subCode.errorMessage;
      }
      
      return {
        ...err,
        cErr: {
          code,
          message,
        },
      };
    }
    return null;
  };

  static parseError(error) {
    const customError = Helpers.isCustomErrCode(error);

    if (customError) {
      NotificationProvider.error(customError.cErr.message);
      return;
    }

    NotificationProvider.error("Ops! Something went wrong!");
  }
}

export default Helpers;
