import React, { useState } from "react";
import Pagination from "react-bootstrap/Pagination";

import "./pagination.scss";

const PER_PAGE = [10, 25, 50, 100];

const AppPagination = ({
  page = 0,
  totalPages = 0,
  pageSize = 0,
  handlePaginationClick,
  setPageSize,
  recordsPerPage = PER_PAGE,
  showPaginText = true,
  style,
}) => {
  const [pageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  let pageIncrementBtn = null;
  if (totalPages > maxPageNumberLimit) {
    pageIncrementBtn = (
      <li className="page-item">
        <button
          onClick={(e) => {
            handlePaginationClick(e, maxPageNumberLimit + 1);
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
          }}
          className="page-link"
        >
          &hellip;
        </button>
      </li>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = (
      <li className="page-item">
        <button
          onClick={(e) => {
            handlePaginationClick(e, minPageNumberLimit);
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
          }}
          className="page-link"
        >
          &hellip;
        </button>
      </li>
    );
  }

  const handleNextbtn = (e) => {
    handlePaginationClick(e, page + 1);
    if (page + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = (e) => {
    handlePaginationClick(e, page - 1);
    if ((page - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  return (
    <div className="app-pagination" style={style}>
      {showPaginText && (
        <span className="paginator">
          Showing {page === 1 ? page : pageSize * (page - 1) + 1} to{" "}
          {setPageSize && (
            <select
              className="paginator-dropdown"
              value={pageSize}
              onChange={(e) => {
                setPageSize(e.target.value);
                handlePaginationClick(e, 1);
              }}
            >
              {recordsPerPage.map((r) => (
                <option key={r} value={r}>
                  {r}
                </option>
              ))}
            </select>
          )}{" "}
          of <strong>{totalPages}</strong> pages
        </span>
      )}
      <Pagination>
        <Pagination.Prev
          disabled={page <= 1}
          onClick={(e) => {
            handlePrevbtn(e);
          }}
        />
        {pageDecrementBtn}
        {[...Array(totalPages)].map((_, i) => (
          <div key={i}>
            {i < maxPageNumberLimit && i >= minPageNumberLimit ? (
              <Pagination.Item
                active={i + 1 === page}
                key={i}
                onClick={(e) => handlePaginationClick(e, i + 1)}
              >
                {i + 1}
              </Pagination.Item>
            ) : null}
          </div>
        ))}
        {pageIncrementBtn}
        <Pagination.Next
          disabled={page === totalPages}
          onClick={(e) => {
            handleNextbtn(e);
          }}
        />
      </Pagination>
    </div>
  );
};

export default AppPagination;
