import actionTypes from "../constants/action-types";

const containerReducerState = {
  mainMenuCollapsed: true,
};

export const containerReducer = (state = containerReducerState, payload) => {
  switch (payload.type) {
    case actionTypes.HANDLE_MAIN_MENU_COLLAPSED:
      return { state, mainMenuCollapsed: payload.data };
    default:
      return state;
  }
};
