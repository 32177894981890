import React from "react";

import "./tab-group.scss";

const TabGroup = ({ tabs, setTab, selectedTab, suffix }) => {
  return (
    <div className="d-flex justify-content-center align-items-center action__type mb-3">
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={`action__type__item ${
            selectedTab === tab ? "active" : ""
          }`}
          onClick={() => setTab(tab)}
        >
          {tab}
          {suffix || ""}
        </div>
      ))}
    </div>
  );
};

export default TabGroup;
