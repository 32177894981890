import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Card, Row } from "react-bootstrap";
import whiteLogo from "../../../common/assets/logo-white.png";
import logo from "../../../common/assets/logo.png";
import Button from "../../../common/form/button/components/button";

import CommonHelper from "../../../common/helpers/common-helper";
import { routes } from "../../../common/routes-constants";
import { activateUser } from "../actions/activate-actions";

class Activate extends Component {
  state = {
    succesActivated: false,
    errorActivated: false,
  };

  componentDidMount = () => {
    const { activateUser, location } = this.props;
    const token = location.queryParams.token;
    const userId = location.queryParams.userId;
    activateUser(
      { token, userId },
      () => {
        this.setState({ succesActivated: true });
      },
      () => {
        this.setState({ errorActivated: true });
      }
    );
  };
  render() {
    const { t } = this.context;
    const { history } = this.props;
    const { succesActivated, errorActivated } = this.state;
    const isDarkMode = CommonHelper.getUserCurrentTheme() === 'dark';

    if (!succesActivated && !errorActivated) {
      return null;
    }

    return (
      <Row className="login align-items-center justify-content-center h-100">
        <Card className="col-sm-10 col-md-6 col-lg-4 col-xl-3">
          <Card.Body>
            <Card.Title className="text-center mb-5">
              <img src={isDarkMode ? whiteLogo : logo} alt={"logo"} />
              <img src={logo} alt={"logo"} />
            </Card.Title>
            <h6 className="line-on-side text-muted text-center font-small-3 pt-2">
              <span>{t("ACTIVATE.TITLE")}</span>
            </h6>
            {succesActivated && (
              <>
                <Card.Text>{t("ACTIVATE.ACTIVATE_SUCCESS")}</Card.Text>
                <Button
                  className={"mt-2"}
                  variant="primary"
                  block={true}
                  type="button"
                  size="md"
                  onClick={() => {
                    history.push(routes.unProtectedRoutes.login.fullPath);
                  }}
                >
                  {t("ACITVATE.LOGIN")}
                </Button>
              </>
            )}
            {errorActivated && (
              <>
                <Card.Text>{t("ACTIVATE.ACTIVATE_ERROR")}</Card.Text>
                <Button
                  className={"mt-2"}
                  variant="outline-danger"
                  block={true}
                  type="button"
                  size="md"
                  onClick={() => {
                    history.push(routes.unProtectedRoutes.register.fullPath);
                  }}
                >
                  {t("LOGIN_FORM.REGISTER")}
                </Button>
              </>
            )}
          </Card.Body>
        </Card>
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  activateUser: (params, successCallback, errorCallback) =>
    dispatch(activateUser(params, successCallback, errorCallback)),
});

Activate.contextTypes = {
  t: PropTypes.func.isRequired,
};

Activate = connect(null, mapDispatchToProps)(Activate);
export default withRouter(Activate);
