import { IBAN_REGEX } from '../constants';

export default class ValidationHelper {

  // Check that iban consists starts with a two-letter country code, followed by two check digits, and up to thirty-five alphanumeric characters.
  static isIbanValid(value, t) {
    const raw = value?.replace(/[^A-Z0-9]/g, '');
    const isCountryCodeValid = /^[A-Z]{2}/.test(raw);

    if (raw && !isCountryCodeValid) {
      return { isValid: false, label: t("REGISTER_FORM.IBAN_NOT_VALID") };
    }
    else if (raw && !IBAN_REGEX.test(raw)) {
      return { isValid: false, label: t("REGISTER_FORM.IBAN_TOO_SHORT") };
    }

    return { isValid: true };
  }
}
