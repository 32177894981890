import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Card, Row } from "react-bootstrap";
import whiteLogo from "../../../common/assets/logo-white.png";
import logo from "../../../common/assets/logo.png";
import SetPasswordForm from "./set-password-form";

import { setPassword } from "../actions/set-password-actions";

import { routes } from "../../../common/routes-constants";
import { fields } from "../constants/constants";

import CommonHelper from "../../../common/helpers/common-helper";
import "../style/set-password.scss";

class SetPassword extends Component {
  state = {
    email: null,
    token: null,
    errorMessage: "",
    resetSuccess: false,
  };

  componentDidMount = () => {
    const { location } = this.props;
    this.setState({
      email: location.queryParams.email,
      token: location.queryParams.token,
    });
  };

  handleSetPasswordSubmit = (values) => {
    const { token, email } = this.state;
    const { setPassword } = this.props;
    setPassword(
      {
        ...values,
        token: encodeURIComponent(token),
        email,
      },
      () => {
        this.setState({ resetSuccess: true });
      },
      (errorMessage) => {
        this.setState({ errorMessage });
      }
    );
  };

  render() {
    const { t } = this.context;
    const { email, errorMessage, resetSuccess } = this.state;
    const isDarkMode = CommonHelper.getUserCurrentTheme() === 'dark';

    return (
      <Row className="set-password align-items-center justify-content-center h-100 ">
        <Card className="col-sm-10 col-md-6 col-lg-4 col-xl-3">
          <Card.Body>
            <Card.Title className="text-center">             
              <img width={180} src={isDarkMode ? whiteLogo : logo} alt={"logo"} />
            </Card.Title>
            <h6 className="line-on-side text-muted text-center font-small-3 pt-2">
              <span>{t("SET_PASSWORD.TITLE")}</span>
            </h6>
            {/* <Card.Text>
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </Card.Text> */}

            {!resetSuccess && (
              <>
                <SetPasswordForm
                  onSubmit={this.handleSetPasswordSubmit}
                  t={t}
                  initialValues={{
                    [fields.email]: email,
                  }}
                  errorMessage={errorMessage}
                />

                <Link
                  to={routes.unProtectedRoutes.login.fullPath}
                  className="float-right mt-2"
                >
                  {t("FORGOT_PASSWORD.BACK_TO_LOGIN")}
                </Link>
              </>
            )}
            {resetSuccess && (
              <>
                <Card.Text>{t("SET_PASSWORD.SUCCESS_MESSAGE")}</Card.Text>

                <Link
                  to={routes.unProtectedRoutes.login.fullPath}
                  className="float-right mt-2"
                >
                  {t("FORGOT_PASSWORD.BACK_TO_LOGIN")}
                </Link>
              </>
            )}
          </Card.Body>
        </Card>
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setPassword: (params, handleSucessReset, handleErrorMessage) =>
    dispatch(setPassword(params, handleSucessReset, handleErrorMessage)),
});

SetPassword.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(connect(null, mapDispatchToProps)(SetPassword));
