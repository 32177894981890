import { ORDER_STATUS, ORDER_TYPE } from "../../../common/constants";

export const INITIAL_MARKET_ORDERS_ORDER_DATA = {
  apiKeyId: "",
  exchangeId: 0,
  assetName: "",
  marketName: "",
  sellMarketName: "",
  price: 0,
  total: "",
  quantity: 0,
  sellQuantity: 0,
  sellTotal: "",
  type: ORDER_TYPE.Market,
};

export const INITIAL_ORDER_COUNT = {
  openOrders: 0,
  orderHistory: 0,
};

export const ORDER_STATUS_ACTIVE = 1;
export const ORDER_STATUS_ALL = 2;

export const MARKET_ORDERS_ORDER_COLUMNS = [
  {
    field: "orderNo",
    headerName: "TRADE_ORDER_OVERVIEW_TABLE.ORDER_NO",
    width: 70,
    type: "sequence",
  },
  {
    sortable: true,
    field: "pair",
    headerName: "TRADE_ORDER_OVERVIEW_TABLE.PAIR",
    width: 120,
    cellRenderer: (data) => {
      return `${data.assetName}_${data.marketName}`;
    },
  },
  {
    sortable: true,
    field: "side",
    headerName: "TRADE_ORDER_OVERVIEW_TABLE.SIDE",
    width: 100,
    cellRenderer: (data) => {
      return data.side === 0 ? "BUY" : "SELL";
    },
  },
  {
    sortable: true,
    field: "dateCreatedUtc",
    headerName: "TRADE_ORDER_OVERVIEW_TABLE.DATE",
    type: "datetime",
  },
  {
    sortable: true,
    field: "price",
    headerName: "TRADE_ORDER_OVERVIEW_TABLE.PRICE",
  },
  {
    sortable: true,
    field: "quantity",
    headerName: "TRADE_ORDER_OVERVIEW_TABLE.AMOUNT",
  },
  {
    sortable: true,
    field: "total",
    headerName: "TRADE_ORDER_OVERVIEW_TABLE.TOTAL",
  },
  {
    sortable: true,
    field: "status",
    width: 90,
    headerName: "TRADE_ORDER_OVERVIEW_TABLE.STATUS",
    cellRenderer: (data) => {
      return (
        <span
          className={`status ${(
            ORDER_STATUS[data.status] || "Status"
          ).toLowerCase()}`}
        >
          {ORDER_STATUS[data.status] || "Status"}
        </span>
      );
    },
  },
  {
    field: "message",
    headerName: "TRADE_ORDER_OVERVIEW_TABLE.STATUS-ERROR",
    width: 280,
  },
];
