import {
    faFilter,
    faLongArrowAltDown,
    faLongArrowAltUp,
    faSortAmountDown,
    faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";

import Loader from "../../loader/components/common-loader";
import { useSort } from "./useSort";

import NumberLabel from "../number-label/number-label";
import "./table.scss";

const TradeTable = (
  {
    columnDefs = [],
    data = [],
    sequenceStep = 0,
    onRowClick = () => {},
    style,
    enableFilter,
    onFilterChanged = () => {},
    conditionExpressions = {},
    isLoading = false,
    tableHasVerticalScroll = false,
  },
  { t }
) => {
  const { sortConfig, requetSort, sortedData } = useSort(data);
  const [showFilters, setShowFilters] = useState(false);
  const [filterConfig, setFilterConfig] = useState({});
  const debouncedChangeHandler = useMemo(() => {
    return _debounce((e) => {
      const gridFilters = { ...filterConfig };
      const { name, value } = e.target;
      gridFilters[name] = value;
      setFilterConfig(gridFilters);
      onFilterChanged(gridFilters);
    }, 700);
  }, [filterConfig, onFilterChanged]);

  const scrollWraper = useRef(null);
  const headerRef = useRef(null);

  const renderToLocalTime = (time) => {
    return new Date(time).toLocaleDateString();
  };

  const renderToLocalDateTime = (time) => {
    return new Date(time).toLocaleString();
  };

  const renderToLocalDateTimeEurope = (time) => {
    return new Date(time).toLocaleString('en-GB');
  };

  const handleColumnType = (type, value, index) => {
    switch (type) {
      case "date":
        return `${renderToLocalTime(value)}`;
      case "datetime":
        return `${renderToLocalDateTime(value)}`;
      case "datetime-europe":
        return `${renderToLocalDateTimeEurope(value)}`;
      case "sequence":
        return ++index + sequenceStep;
      case "rounded-number":
        return <NumberLabel value={value} />;
      case "decimal-number":
        {
          if (value.toString().includes('-')) {
            const formattedValue = new Intl.NumberFormat('de-DE', {
              maximumFractionDigits: 20
            }).format(value);

            return formattedValue.replace(/,?0+$/, '');
          } else {
            return new Intl.NumberFormat('de-DE').format(value);
          }
        }
      case "number":
        return <NumberLabel value={value} />
      case "number-no-min":
        return <NumberLabel value={value} minFractions={'no-min'} maxFractions={'no-max'} />
      default:
        return value;
    }
  };

  useEffect(() => {
    const scrollableBody = scrollWraper.current;
    const scrollableHeader = headerRef.current;

    const onTableScroll = (e) => {
      if (scrollableHeader) {
        scrollableHeader.scrollLeft = e.target.scrollLeft;
      }
    };

    if (scrollableBody) {
      scrollableBody.addEventListener("scroll", onTableScroll);
    }

    return () => {
      if (scrollableBody) {
        scrollableBody.removeEventListener("scroll", onTableScroll);
      }
    };
  }, []);

  const renderTableFilters = () => {
    return columnDefs.map((column) =>
      column.filter ? (
        <th
          key={column.field}
          className={`col-filter ${
            column.type === "action" ? " action-cell" : ""
          }`}
        >
          {column.filterType === "select"
            ? renderSelectFilter(column)
            : renderInputFilter(column)}
        </th>
      ) : (
        <th
          key={column.field}
          className={`col-filter ${
            column.type === "action" ? " action-cell" : ""
          }`}
        ></th>
      )
    );
  };

  const renderSelectFilter = (column) => (
    <select
      className="form-control"
      onChange={debouncedChangeHandler}
      name={column.filterKey || column.field}
    >
      <option value="">Select</option>
      {column?.filterOptions?.map((item) => (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      ))}
    </select>
  );

  const renderInputFilter = (column) => (
    <input
      className={`form-control`}
      type={column.type || "text"}
      name={column.filterKey || column.field}
      onChange={debouncedChangeHandler}
      size="small"
      placeholder={`Search`}
    />
  );

  const handleSorting = (column) => {
    return sortConfig && sortConfig.key === column.field ? (
      <span className="p-1">
        <FontAwesomeIcon
          icon={sortConfig.direction ? faSortAmountDown : faSortAmountUp}
        />
      </span>
    ) : (
      <span className="p-1">
        <FontAwesomeIcon icon={faLongArrowAltUp} />
        <FontAwesomeIcon icon={faLongArrowAltDown} />
      </span>
    );
  };

  const renderTableData = (item, index) => {
    return columnDefs.map((col) => (
      <td
        className={`col-xs${col.type === "action" ? " action-cell" : ""}`}
        key={col.field}
        style={{
          width: col.width || "120px",
          textAlign: col.textAlign || "center",
          zIndex: 2
        }}
      >
        {col.cellRenderer
          ? col.cellRenderer(item)
          : handleColumnType(col.type, item[col.field], index)}
      </td>
    ));
  };

  const renderTableHeader = () => {
    return columnDefs.map((column) => {
      return (
        <th
          key={column.field}
          style={{
            width: column.width || "120px",
            textAlign: column.textAlign || "center",
          }}
          className={`col-xs col-highlight${
            sortConfig && sortConfig.key === column.field ? " sort-active" : ""
          }${column.type === "action" ? " action-cell" : ""}`}
          onClick={() => {
            data.length > 0 && column.sortable && requetSort(column);
          }}
        >
          {column.headerRenderer ? column.headerRenderer({ t }) : column.headerName ? t(column.headerName) : ""}
          {column.sortable && handleSorting(column)}
          {enableFilter && column.type === "action" && (
            <button
              className={`table-actions ${showFilters ? "active" : ""}`}
              title="Show/Hide filters"
              onClick={() => setShowFilters(!showFilters)}
            >
              <FontAwesomeIcon icon={faFilter} /> <span>Filter</span>
            </button>
          )}
        </th>
      );
    });
  };

  const setTableOverlayClass = () => {
    return data.length > 0
      ? "table-scrollable-view"
      : data.length === 0
      ? "table-overlay"
      : "table-w100";
  };

  const onTableScroll = (e) => {
    e.preventDefault();
    let header = document.querySelector(".scrollable-header");
    header.scrollLeft = e.target.scrollLeft;
  };

  const readConditionExpressions = (data) => {
    const keys = Object.keys(conditionExpressions);
    const classList = keys
      .map((key) => (conditionExpressions[key](data) ? key : ""))
      .join(" ");
    return classList;
  };

  return (
    <div className="scrollable-wrapper">
      <Loader loading={isLoading} type="table-loader" />
      <div className="scrollable-header" ref={headerRef}>
        <table className={"trade-table mb-0"}>
          <thead>
            <tr>{renderTableHeader()}</tr>
            <tr>{enableFilter && showFilters && renderTableFilters()}</tr>
          </thead>
        </table>
      </div>
      <div className={setTableOverlayClass()} style={style} ref={scrollWraper}>
        {data.length > 0 && (
          <table className={"trade-table"}>
            <tbody>
              {sortedData.map((item, key) => {
                return item.groupName ? <div className="ml-3 mt-3 mb-3"><h5>{item.groupName}</h5></div> : (
                  <tr
                    className={readConditionExpressions(item)}
                    onClick={() => onRowClick(item)}
                    key={key}
                  >
                    {renderTableData(item, key)}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

TradeTable.contextTypes = {
  t: PropTypes.func,
};

export default TradeTable;
