import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useEffect } from "react";
import { connect } from "react-redux";

import {
  getAssetPrice,
  handleGetUserAssets,
} from "../../user-assets/actions/user-assets-actions";
import botOrderApi from "../../../api/trade-order/actions";

import {
  ORDER_STATUS,
  ORDER_TYPE,
  PAGE_SIZE_DEFAULT,
} from "../../../common/constants";
import TradeTable from "../../../common/form/table/trade-table";
import NotificationProvider from "../../../core/notification";
import Helpers from "../../../common/helpers/error-helpers";
import InputNumber from "../../../common/form/input/components/number-input";
import ApiKey from "../../../shared/components/api-key/api-key";
import AppPagination from "../../../shared/components/pagination/pagination";

import "../style/stop-loss.scss";

const StopLoss = (props) => {
  const { getUserAssets, userAssets, getAssetPrice } = props;
  const [marketPrice, setMarketPrice] = useState("");
  const [sellMarketPrice, setSellMarketPrice] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [userCurrentMarketValue, setUserCurrentMarketValue] = useState("N\\A");
  const [userCurrentCoinValue, setUserCurrentCoinValue] = useState("N\\A");

  const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const [order, setOrder] = useState({
    apiKeyId: "",
    exchangeId: 0,
    assetName: "",
    marketName: "",
    sellMarketName: "",
    price: 0,
    stopPrice: 0,
    total: "",
    quantity: 0,
    sellStopPrice: 0,
    sellQuantity: 0,
    sellTotal: "",
    type: ORDER_TYPE.StopLossLimit,
  });

  const columns = [
    {
      field: "orderNo",
      headerName: "TRADE_ORDER_OVERVIEW_TABLE.ORDER_NO",
      width: 70,
      type: "sequence",
    },
    {
      sortable: true,
      field: "pair",
      headerName: "TRADE_ORDER_OVERVIEW_TABLE.PAIR",
      width: 100,
      cellRenderer: (data) => {
        return `${data.assetName}_${data.marketName}`;
      },
    },
    {
      sortable: true,
      field: "side",
      headerName: "TRADE_ORDER_OVERVIEW_TABLE.SIDE",
      width: 100,
      cellRenderer: (data) => {
        return data.side === 0 ? "BUY" : "SELL";
      },
    },
    {
      sortable: true,
      field: "dateCreatedUtc",
      headerName: "TRADE_ORDER_OVERVIEW_TABLE.DATE",
      type: "datetime",
    },
    {
      sortable: true,
      field: "stopPrice",
      headerName: "TRADE_ORDER_OVERVIEW_TABLE.STOP_PRICE",
    },
    {
      sortable: true,
      field: "price",
      headerName: "TRADE_ORDER_OVERVIEW_TABLE.LIMIT",
    },
    {
      sortable: true,
      field: "quantity",
      headerName: "TRADE_ORDER_OVERVIEW_TABLE.AMOUNT",
    },
    {
      sortable: true,
      field: "total",
      headerName: "TRADE_ORDER_OVERVIEW_TABLE.TOTAL",
    },
    {
      sortable: true,
      field: "status",
      width: 90,
      headerName: "TRADE_ORDER_OVERVIEW_TABLE.STATUS",
      cellRenderer: (data) => {
        return (
          <span
            className={`status ${(
              ORDER_STATUS[data.status] || "Status"
            ).toLowerCase()}`}
          >
            {ORDER_STATUS[data.status] || "Status"}
          </span>
        );
      },
    },
    {
      field: "message",
      headerName: "TRADE_ORDER_OVERVIEW_TABLE.STATUS-ERROR",
      width: 280,
    },
  ];

  useEffect(() => {
    getOrders();

    // eslint-disable-next-line
  }, [pageNumber, pageSize]);

  const getOrders = async () => {
    try {
      const response = await botOrderApi.getOrders({
        params: {
          orderTypeId: ORDER_TYPE.StopLossLimit,
          pageNumber,
          pageSize,
        },
      });
      setOrderData(response.data);
      setIsLoading(false);
    } catch (err) {
      setOrderData([]);
      setIsLoading(false);
      Helpers.parseError(err);
    }
  };

  const handleMarketSelectChange = (value) => {
    if (!value) {
      return setMarketPrice("");
    }

    let marketAmount = userAssets.markets.find(
      (element) => element.name === value
    );
    setUserCurrentMarketValue(
      marketAmount === undefined ? 0 : marketAmount.amountAvailable
    );

    order.assetName &&
      fillAssetPrice(value, order.assetName, (assetPrice) => {
        setMarketPrice(assetPrice);
      });
  };

  const handleSellMarketSelectChange = (value) => {
    if (!value) {
      return setSellMarketPrice("");
    }
    order.assetName &&
      fillAssetPrice(value, order.assetName, (assetPrice) => {
        setSellMarketPrice(assetPrice);
      });
  };

  const onCoinChange = (value) => {
    if (!value) {
      return setMarketPrice("");
    }

    let coinAmount = userAssets.assets.find(
      (element) => element.name === value
    );

    setUserCurrentCoinValue(
      coinAmount === undefined ? 0 : coinAmount.amountAvailable
    );

    order.marketName &&
      fillAssetPrice(order.marketName, value, (assetPrice) => {
        setMarketPrice(assetPrice);
      });
    order.sellMarketName &&
      fillAssetPrice(order.sellMarketName, value, (assetPrice) => {
        setSellMarketPrice(assetPrice);
      });
  };

  const fillAssetPrice = async (marketName, assetName, callback) => {
    let assetPrice = await getAssetPrice(marketName, assetName, order.apiKeyId);
    callback(assetPrice);
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setOrder({ ...order, [name]: value });
  };

  const onNumberChangeHandler = (e) => {
    const { name, value } = e.target;
    setOrder({ ...order, [name]: +value });
  };

  const onBuyOrder = async () => {
    try {
      const request = prepareBuyRequest(order);
      await botOrderApi.createOrder(request);
      getOrders();
      NotificationProvider.success("Order created");
    } catch (err) {
      Helpers.parseError(err);
      getOrders();
    }
  };

  const prepareBuyRequest = (order) => {
    return {
      apiKeyId: order.apiKeyId,
      exchangeId: 0,
      assetName: order.assetName,
      marketName: order.marketName,
      price: marketPrice,
      stopPrice: order.stopPrice,
      total: order.total,
      quantity: order.quantity,
      side: 0,
      type: ORDER_TYPE.StopLossLimit,
    };
  };

  const onSellOrder = async () => {
    try {
      const request = prepareSellRequest(order);
      await botOrderApi.createOrder(request);
      getOrders();
      NotificationProvider.success("Order created");
    } catch (err) {
      Helpers.parseError(err);
      getOrders();
    }
  };

  const prepareSellRequest = (order) => {
    return {
      apiKeyId: order.apiKeyId,
      exchangeId: 0,
      assetName: order.assetName,
      marketName: order.sellMarketName,
      price: sellMarketPrice,
      stopPrice: order.sellStopPrice,
      total: order.sellTotal,
      quantity: order.sellQuantity,
      side: 1,
      type: ORDER_TYPE.StopLossLimit,
    };
  };

  return (
    <div className="smart-trade-layout">
      <h4 className="page-title">Stop Loss</h4>
      <div style={{ margin: "auto", width: "60%" }}>
        <Row>
          <Col md={12} lg={6} sm={12} xs={12}>
            <ApiKey
              onChange={(value, e) => {
                onNumberChangeHandler(e);
                value && getUserAssets(value);
              }}
            />
            {/* <div className="input-group mb-3">
              <label className="input-group-text form-border">Exchanges</label>
              <select
                value={order.exchangeId}
                name="exchangeId"
                onChange={onChangeHandler}
                className="form-control"
              >
                <option>Select Exchange</option>
                {EXCHANGE_OPTIONS.map((ex) => (
                  <option key={ex.id} value={ex.value}>
                    {ex.label}
                  </option>
                ))}
              </select>
            </div> */}
            <div className="input-group mb-3">
              <span className="input-group-text form-border">Coin</span>
              <select
                name="assetName"
                onChange={(e) => {
                  onChangeHandler(e);
                  onCoinChange(e.target.value);
                }}
                value={order.assetName}
                className="form-control"
              >
                <option value="">Select Coin</option>
                {userAssets.assets?.map((asset) => (
                  <option key={asset.id} value={asset.name}>
                    {asset.name}
                  </option>
                ))}
              </select>
            </div>
          </Col>
        </Row>
        <Row
          className="mb-4 pt-4 pb-4"
          style={{ backgroundColor: "#f1f1f1", borderRadius: 10 }}
        >
          <Col md={6} lg={6} sm={12} xs={12}>
            <p className="d-flex justify-content-between align-items-center">
              <span style={{ fontSize: 14, color: "#a1a1a1" }}>Avbl</span>
              <span className="mr-3" style={{ fontSize: 14, fontWeight: 600 }}>
                {userCurrentMarketValue} {order.marketName}
              </span>
            </p>
            <div className="input-group mb-3">
              <label className="input-group-text input-form-border">
                Market
              </label>
              <select
                value={order.marketName}
                name="marketName"
                onChange={(e) => {
                  onChangeHandler(e);
                  handleMarketSelectChange(e.target.value);
                }}
                className="form-control"
              >
                <option value={""}>Select Market</option>
                {userAssets.markets?.map((market) => (
                  <option key={market.id} value={market.name}>
                    {market.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text input-form-border">Stop</span>
              <InputNumber name="stopPrice" value={order.stopPrice}
                onChange={(e) => {
                  const { value } = e.target;
                  setOrder({
                    ...order,
                    stopPrice: value ? parseFloat(value) : value,
                  });
                }} />
              <span
                style={{ fontWeight: 700 }}
                className="input-group-text input-end-form-border"
              >
                {order.marketName}
              </span>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text input-form-border">Limit</span>
              <InputNumber name="marketPrice" value={marketPrice}
                onChange={(e) => {
                  const { value } = e.target;
                  setMarketPrice(value ? parseFloat(value) : value);
                }} />
              <span
                style={{ fontWeight: 700 }}
                className="input-group-text input-end-form-border"
              >
                {order.marketName || "N/A"}
              </span>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text input-form-border">Amount</span>
              <InputNumber name="quantity" value={order.quantity}
                onChange={(e) => {
                  const { value } = e.target;
                  if (!value)
                    return setOrder({ ...order, total: "", quantity: "" });
                  setOrder({
                    ...order,
                    quantity: parseFloat(value),
                    total: Number(value) * marketPrice,
                  });
                }} />
              <span
                style={{ fontWeight: 700 }}
                className="input-group-text input-end-form-border"
              >
                {order.assetName}
              </span>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text input-form-border">Total</span>
              <InputNumber name="total" value={order.total}
                onChange={(e) => {
                  const { value } = e.target;
                  if (!value)
                    return setOrder({ ...order, total: "", quantity: 0 });
                  setOrder({
                    ...order,
                    quantity: Number(value) / marketPrice,
                    total: parseFloat(value),
                  });
                }} />
              <span
                style={{ fontWeight: 700 }}
                className="input-group-text input-end-form-border"
              >
                {order.marketName || "N/A"}
              </span>
            </div>
            <button
              onClick={onBuyOrder}
              type="button"
              className="btn btn-success w-100 mb-2"
            >
              Buy {order.assetName}
            </button>
          </Col>
          <Col md={6} lg={6} sm={12} xs={12}>
            <p className="d-flex justify-content-between align-items-center">
              <span style={{ fontSize: 14, color: "#a1a1a1" }}>Avbl</span>
              <span className="mr-3" style={{ fontSize: 14, fontWeight: 600 }}>
                {userCurrentCoinValue} {order.assetName}
              </span>
            </p>
            <div className="input-group mb-3">
              <label className="input-group-text input-form-border">
                Market
              </label>
              <select
                value={order.sellMarketName}
                name="sellMarketName"
                onChange={(e) => {
                  onChangeHandler(e);
                  handleSellMarketSelectChange(e.target.value);
                }}
                className="form-control"
              >
                <option value={""}>Select Market</option>
                {userAssets.markets?.map((market) => (
                  <option key={market.id} value={market.name}>
                    {market.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="input-group mb-3">
              <span className="input-group-text input-form-border">Stop</span>
              <InputNumber name="sellStopPrice" value={order.sellStopPrice}
                onChange={(e) => {
                  const { value } = e.target;
                  setOrder({
                    ...order,
                    sellStopPrice: value ? parseFloat(value) : value,
                  });
                }} />
              <span
                style={{ fontWeight: 700 }}
                className="input-group-text input-end-form-border"
              >
                {order.sellMarketName}
              </span>
            </div>

            <div className="input-group mb-3">
              <span className="input-group-text input-form-border">Limit</span>
              <InputNumber name="sellMarketPrice" value={sellMarketPrice}
                onChange={(e) => {
                  const { value } = e.target;
                  setSellMarketPrice(value ? parseFloat(value) : value);
                }} />
              <span
                style={{ fontWeight: 700 }}
                className="input-group-text input-end-form-border"
              >
                {order.sellMarketName || "N/A"}
              </span>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text input-form-border">Amount</span>
              <InputNumber name="sellQuantity" value={order.sellQuantity}
                onChange={(e) => {
                  const { value } = e.target;
                  if (!value)
                    return setOrder({
                      ...order,
                      sellTotal: "",
                      sellQuantity: "",
                    });
                  setOrder({
                    ...order,
                    sellQuantity: parseFloat(value),
                    sellTotal: Number(value) * sellMarketPrice,
                  });
                }} />
              <span
                style={{ fontWeight: 700 }}
                className="input-group-text input-end-form-border"
              >
                {order.assetName}
              </span>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text input-form-border">Total</span>
              <InputNumber name="sellTotal" value={order.sellTotal}
                onChange={(e) => {
                  const { value } = e.target;
                  if (!value)
                    return setOrder({
                      ...order,
                      sellTotal: "",
                      sellQuantity: 0,
                    });
                  setOrder({
                    ...order,
                    sellQuantity: Number(value) / sellMarketPrice,
                    sellTotal: parseFloat(value),
                  });
                }} />
              <span
                style={{ fontWeight: 700 }}
                className="input-group-text input-end-form-border"
              >
                {order.sellMarketName || "N/A"}
              </span>
            </div>
            <button
              onClick={onSellOrder}
              type="button"
              className="btn btn-danger w-100"
            >
              Sell {order.assetName}
            </button>
          </Col>
        </Row>
      </div>
      <AppPagination
        page={pageNumber}
        totalPages={orderData.totalPages || 1}
        pageSize={pageSize}
        setPageSize={setPageSize}
        handlePaginationClick={(_, value) => setPageNumber(value)}
      />
      <TradeTable
        data={orderData.data}
        columnDefs={columns}
        sequenceStep={(pageNumber - 1) * pageSize}
        isLoading={isLoading}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userAssets: state.userAssetsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getUserAssets: (params) => dispatch(handleGetUserAssets(params)),
  getAssetPrice: (marketName, coinName, apiKeyId) =>
    dispatch(getAssetPrice(marketName, coinName, apiKeyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StopLoss);
