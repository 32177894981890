import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from "@mui/material";
import discordImg from "../../../common/assets/help-modus/help-modus-discord.png";
import NumberLabel from "../../../common/form/number-label/number-label";

export const fields = {
  key: "key",
  agreeToTermsAndConditions: "agreeToTermsAndConditions",
  agreeToPrivacyPolicy: "agreeToPrivacyPolicy",
  iban: "iban"
};

export const AFFILIATE_COLUMNS = () => [
  {
    field: "nickname",
    headerName: "EDIT_PROFILE.AFFILIATE.NICKNAME",
    width: 50,
  },
  {
    field: "membership",
    headerName: "EDIT_PROFILE.TAB.MEMBERSHIP",
    width: 50,
  },
  {
    field: "parentAffiliateName",
    headerName: "EDIT_PROFILE.TAB.PARENT_AFFILIATE",
    width: 50,
  }
];

export const USER_PROFIT_COLUMNS = () => [
  {
    field: "symbol",
    headerName: "USER-PROFIT.TABLE.SYMBOL",
    width: 50,
  },
  {
    field: "market",
    headerName: "USER-PROFIT.TABLE.MARKET",
    width: 50,
  },
  {
    field: "profit",
    headerName: "USER-PROFIT.TABLE.PROFIT",
    width: 70,
    cellRenderer: (data) => (
      <NumberLabel value={data.profit}  />
    ),
  },
  {
    field: "profitInEuro",
    headerName: "USER-PROFIT.TABLE.PROFIT-IN-EURO",
    width: 70,
    cellRenderer: (data) => (
      <NumberLabel value={data.profitInEuro} />
    ),
  },
  {
    field: "botRoundProfitInEuro",
    headerName: "USER-PROFIT.TABLE.BOT-ROUND-PROFIT-IN-EURO",
    width: 110,
    cellRenderer: (data) => (
      <NumberLabel value={data.botRoundProfitInEuro} />
    ),
  },
];

export const NOTIFICATION_CONFIGURATION_COLUMNS = (providers, toggleNotificationEventTriggerProvider, updateNotificationConfiguration, onInfoIconClick, t, isMobile) => [
  {
    field: "eventTypeName",
    headerName: "",
    width: isMobile ? 50 : 150,
  },
  ...providers.map(item => ({
    field: `isActive${item.providerName}`,
    headerRenderer: () => (
      <div className="d-flex" style={{ alignItems: 'center', justifyContent: 'center', gap: '5px', flexDirection: 'column' }}>
        <span>{item.providerName}</span>
        <div className="d-flex" style={{ width: '80%' }}>
          <input
            className="form-control"
            style={{ height: '30px' }}
            type="text"
            defaultValue={item.providerAuthToken}
            onBlur={(e) => item.providerAuthToken !== e.target.value && updateNotificationConfiguration(item.provider, e.target.value)}
            placeholder={item.providerName === 'Discord' ? 'User Id (ex 1436768786818560019)' : ''}
          />
          {item.providerName === 'Discord' && (
            <div className="infoButton" style={{ top: '-15px' }}
              onClick={() => {
                const messageContent = (
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.DISCORD-ONE') }} />
                    <img src={discordImg} alt="Binance Demo Authenticate" />
                    <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.DISCORD-TWO') }} />
                  </div>
                );
                const title = (<>{t("HELP-MODUS.DISCORD-TITLE")}</>);
                onInfoIconClick(title, messageContent, 'lg');
              }}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </div>
          )}
        </div>        
      </div>
    ),
    cellRenderer: (data) => (
      <Switch
        checked={data[`isActive${item.providerName}`]}
        onChange={() => toggleNotificationEventTriggerProvider(data.eventType, `isActive${item.providerName}`, item.provider)}
      />
    ),
    width: 150
  }))
];