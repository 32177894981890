import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

export const getWalletColumns = (onAvailableBalanceRowClick) => [
  {
    field: "coinSymbol",
    headerName: "WALLET.TABLE.COIN-NAME",
    cellRenderer: (data) => {
      return (
        <div>
          {data.coinSymbol} - {data.name}
        </div>
      );
    },
  },
  {
    field: "balance",
    headerName: "WALLET.TABLE.BALANCE",
    type: "decimal-number",
  },
  {
    field: "amountAvailable",
    headerName: "WALLET.TABLE.AVAILABLE-BALANCE",
    type: "decimal-number",
    cellRenderer: (data) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {new Intl.NumberFormat('de-DE', { maximumFractionDigits: 8 }).format(data.amountAvailable)}
          <button
            style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '5px' }}
            onClick={() => onAvailableBalanceRowClick(data.coinSymbol)}
          >
            <FontAwesomeIcon icon={faChevronDown} style={{ color: 'var(--color-primary)' }} />
          </button>
        </div>
      );
    }
  },
  {
    field: "value",
    headerName: "WALLET.TABLE.VALUE",
    type: "rounded-number",
  },
  {
    field: "currency",
    headerName: "WALLET.TABLE.CURRENCY",
    width: 100,
  },
];

export const getLeftOverBuyPriceColumns = () => [
  {
    field: "symbol",
    headerName: "USER-PROFIT.TABLE.SYMBOL",
  },
  {
    field: "market",
    headerName: "USER-PROFIT.TABLE.MARKET",
    width: 80,
  },
  {
    field: "price",
    headerName: "CONDITION.FORM.PRICE",
    type: "rounded-number",
  },
  {
    field: "amount",
    headerName: "CONDITION.FORM.AMOUNT",
    width: 110,
    type: "rounded-number",
  },
];