const routesConstants = {
  GET_INVOICE_ALL: "/Invoice",
  GET_INVOICE_MAIN: "/Invoice/MainPdf",
  GET_INVOICE_DETAILS: "/Invoice/DetailsPdf",
  PAY_INVOICE: "/Invoice/Pay",
  CREATE_INVOICE: "/Invoice",
  GET_USER_PROFIT: "/Invoice/User/Profit",
  GET_DTA_CONTENT: "/Dta"
};

export default routesConstants;
