import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Multiselect from 'multiselect-react-dropdown';
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import orderApi from "../../../api/trade-order/actions";
import NotificationProvider from "../../../core/notification";
import { Button } from "react-bootstrap";
import Helpers from "../../../common/helpers/error-helpers";
import { useTheme } from "../../../common/theme/theme-context";
import { useHelpModus } from "../../../common/help-modus/help-modus-context";
import HelpModusModal from "../../../common/help-modus/help.modus-modal";
import ApiKey from "../../../shared/components/api-key/api-key";
import userApi from "../../../api/user/actions";
import Loader from "../../../common/loader/components/common-loader";

const EmergencySellModal = ({ show, onClose, getWallet, toggleLoader }, { t }) => {
  const [selectedCoins, setSelectedCoins] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState("");
  const [apiKeyId, setApiKeyId] = useState(0);
  const [sellAllDisabled, setSellAllDisabled] = useState(true);
  const [userAssets, setUserAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isDarkMode } = useTheme();

  const [showHelpModusModal, setShowHelpModusModal] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [modalTitle, setModalTitle] = useState('Information');
  const { isHelpModus } = useHelpModus();

  const toggleHelpModusModal = (title, message) => {
    setShowHelpModusModal((showModal) => !showModal);
    setModalMessage(message);
    setModalTitle(title);
  };

  const handleSellAll = async () => {
    try {
      onClose();
      await orderApi.emergencySell({ assets: selectedCoins.map(x => x.name), market: selectedMarket, apiKeyId }).then((response) => {
        setTimeout(() => {
          toggleLoader(true);
          getWallet().then(() => {
            if (!!response.data && !!response.data.failedOrders) {
              NotificationProvider.error(response.data.message);
            }
            else {
              NotificationProvider.success("Orders successfully created");
            }
            toggleLoader(false);
          });
          setSelectedCoins([]);
          setSelectedMarket("");
          setSellAllDisabled(true);
        }, 500);
      });
    } catch (error) {
      Helpers.parseError(error);
      toggleLoader(false);
    }
    finally {
      toggleLoader(false);
    }
  };

  const getUserAssets = async (apiKeyId) => {
    try {
      setIsLoading(true);
      const { data } = await userApi.getUserAssets(apiKeyId);
      setUserAssets(data);
      setIsLoading(false);
    } catch (error) {
      Helpers.parseError(error);
      setIsLoading(false);
      setUserAssets([]);
    }
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          {t('WALLET.EMERGENCY-SELL')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loader loading={isLoading} type="global" />
        <div className="d-flex flex-column justify-content-between align-items-center sell-row">
          <div className="input-group">
            <ApiKey
              classList="right-11"
              onChange={(value) => {
                setApiKeyId(value);
                if (!value) {
                  setUserAssets([]);
                }
                value && getUserAssets(value);
              }}
              isHelpModus={isHelpModus}
              onInfoIconClick={toggleHelpModusModal}
              value={apiKeyId}
            />
          </div>
          <div className="input-group mb-3 coin-multiselect">
            {isHelpModus && (
              <div className="infoButton" style={{ float: 'right', right: '-10px', top: '-17px' }}
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p>{t('HELP-MODUS.WALLET-SELECT-COIN-MARKET')}</p>
                    </div>
                  );
                  toggleHelpModusModal('Select Coin & Select Market', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
            <Multiselect
              displayValue="name"
              selectedValues={selectedCoins}
              placeholder={t("CAMPAIGN.FORM.SELECT-COIN")}
              options={userAssets.assets?.map((coin) => ({ name: coin.name, id: coin.name }))}
              onSelect={(list) => {
                setSelectedCoins(list);
                setSellAllDisabled(!selectedMarket || !list || !list.length);
              }}
              onRemove={(list) => {
                setSelectedCoins(list);
                setSellAllDisabled(!selectedMarket || !list || !list.length);
              }}
              style={{
                inputField: {
                  color: isDarkMode ? '#7f95b8' : '#1d1e37',
                },
                multiselectContainer: {
                  color: isDarkMode ? '#7f95b8' : '#1d1e37',
                  background: isDarkMode ? '#1a1f2c' : '#fff'
                },
                optionContainer: {
                  color: isDarkMode ? '#7f95b8' : '#1d1e37',
                  background: isDarkMode ? '#1a1f2c' : '#fff',
                  border: '1px solid #696766'
                }
              }}
              showCheckbox
            />
          </div>
          <div className="input-group mb-3 sell-row">
            <select
              value={selectedMarket}
              name="marketName"
              onChange={(e) => {
                setSelectedMarket(e.target.value);
                setSellAllDisabled(!e.target.value || !selectedCoins || !selectedCoins.length);
              }}
              className="form-control"
            >
              <option value={""}>{t("CAMPAIGN.FORM.SELECT-MARKET")}</option>
              {userAssets.markets?.map((market) => (
                <option key={market.name} value={market.name}>
                  {market.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={"mt-2 pl-5 pr-5 mr-2"}
          variant="outline-primary"
          type="button"
          onClick={handleSellAll}
          disabled={sellAllDisabled}>
          {t('WALLET.SELL-ALL')}
        </Button>
        {isHelpModus && (
          <div className="infoButton wallet-info-button"
            onClick={() => {
              const messageContent = (
                <div>
                  <p>{t('HELP-MODUS.WALLET-SELL-ALL')}</p>
                </div>
              );
              toggleHelpModusModal('Sell All', messageContent);
            }}>
            <FontAwesomeIcon className="row" icon={faInfoCircle} />
          </div>
        )}
        <Button className={"mt-2 pl-5 pr-5 mr-2"} variant="secondary" type="button" onClick={onClose}>
          {t("BOT_OVERVIEW.CLOSE")}
        </Button>
      </Modal.Footer>
      {showHelpModusModal && (
        <HelpModusModal
          show={showHelpModusModal}
          onClose={toggleHelpModusModal}
          title={modalTitle}
          message={modalMessage}
        />
      )}
    </Modal>
  );
};

EmergencySellModal.contextTypes = {
  t: PropTypes.func,
};

export default EmergencySellModal;
