import { faChevronDown, faChevronRight, faInfoCircle, faLock, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Switch from "@mui/material/Switch";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import Button from "../../../common/form/button/components/button";
import { normalizeValueToInt } from "../../../common/form/helper";
import Input from "../../../common/form/input/components/input";
import StoreHelper from "../../../common/helpers/store-helper";
import Chart from "../../../common/trading-chart/chart";
import NotificationProvider from "../../../core/notification";
import ApiKey from "../../../shared/components/api-key/api-key";
import BackButton from "../../../shared/components/back-button/back-button";
import { CREATE_REQ_FIELDS, EDIT_REQ_FIELDS, fields } from "../constants/constants";
import OrderBook from "./order-book";
import styles from "./styles.module.css";

import BotHelper from "../../../common/helpers/bot-helper";
import CommonHelper from "../../../common/helpers/common-helper";
import Condition from "../../condition/components/condition";

import orderApi from "../../../api/order/actions";
import barImage from "../../../common/assets/help-modus/help-modus-chart-bar.png";
import chartImage from "../../../common/assets/help-modus/help-modus-chart.png";
import { history } from "../../../index";

import FormNumberInput from "../../../common/form/input/components/form-input-number";
import NumberLabel from "../../../common/form/number-label/number-label";
import "../style/bot-form.scss";

class BotUnitForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      market: this.props?.initialValues?.marketName,
      coin: this.props?.initialValues?.assetName,
      startAt: 0,
      endAt: 0,
      lotCount: 0,
      isChartReady: false,
      type: 0,
      isEdit: !!this.props.readonly,
      userCurrentMarketValue: "N\\A",
      userCurrentCoinValue: "N\\A",
      userRealMarketValue: "N\\A",
      userRealCoinValue: "N\\A",
      riskProgress: 0,
      errors: {},
      apiKey: "",
      showSoftStop: false,
      showConditions: false,
      showDynamic: false,
      conditions: [],
      disableFullReinvest: false,
      enableIncrease: false,
      exchangeId: 0,
      totalAmount: 0,
      lockConditions: this.props.role === 'Basic user',
      provideDynamic: ['CrypS', 'Professional', 'Admin'].includes(this.props.role),
      isMobile: window.innerWidth < 768,
      initialWidth: window.innerWidth,
      botOrders: [],
      showBuyLines: false,
      showSellLines: false
    };
  }

  handleOrientationChange = () => {
    if (window.innerWidth !== this.state.initialWidth) {
      window.location.reload();
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleOrientationChange);
    if (this.props.id) {
      const { marketName, assetName, conditions, endAtPrice, startAtPrice, lotCount } = StoreHelper.getFormValues(this.props.form) || {};
      const updateState = { market: marketName, coin: assetName, conditions, startAt: +startAtPrice, endAt: endAtPrice, isEdit: true, lotCount };
      this.setState(updateState);
      this.setRiskAndOrderAmountOnEdit();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleOrientationChange);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isCopyBot !== this.props.isCopyBot) {
      const { marketName, assetName, conditions, keyId } = StoreHelper.getFormValues(this.props.form) || {};
      const updateState = {
        market: marketName,
        coin: assetName,
        conditions,
        apiKey: keyId,
      };
      this.setState(updateState);
      this.setRiskAndOrderAmountOnEdit();
    }

    const { marketName, assetName } = StoreHelper.getFormValues(this.props.form) || {};

    if (prevProps.userAssets?.assets != this.props.userAssets?.assets) {
      this.onCoinChange(assetName, true);
    }

    if (prevProps.userAssets?.markets != this.props.userAssets?.markets) {
      this.handleMarketSelectChange(marketName, true);
    }
  }

  setRiskAndOrderAmountOnEdit = () => {
    const { endAtPercentage, lotCount, marketAmount, type } = StoreHelper.getFormValues(this.props.form) || {};
    if (marketAmount && lotCount) {
      this.props.change(fields.OrderAmount, marketAmount / lotCount);
    } else {
      this.props.change(fields.OrderAmount, null);
    }
    if (endAtPercentage && lotCount) {
      const endValue = type === 0 ? -endAtPercentage : endAtPercentage;
      const step = endValue / lotCount;
      let riskProgress = Math.abs(Math.floor(lotCount * step));
      if (riskProgress > 100) riskProgress = 100;
      this.setState({ riskProgress });
    }
  };

  toggleSoftStop() {
    this.setState({ showSoftStop: !this.state.showSoftStop });
  }

  toggleConditions() {
    this.setState({ showConditions: !this.state.showConditions });
  }

  toggleDynamic() {
    this.setState({ showDynamic: !this.state.showDynamic });
  }

  toggleFullReinvest = (e) => {
    const values = StoreHelper.getFormValues(this.props.form) || {};

    const type = Number(values[fields.Type]);
    const profit = Number(e ? e.currentTarget.value : values[fields.Profit]);
    const disableFullReinvest = (type === 0 && profit === 1) || (type === 1 && profit === 0);

    if (disableFullReinvest && values[fields.Dynamic] === '100') {
      this.props.change(fields.Dynamic, undefined);
    }

    this.setState({ disableFullReinvest: disableFullReinvest });
  }

  handleSave = () => {
    if (this.props.isMaxBotCountReached) {
      NotificationProvider.warning("You cannot create bot. Maximum number of bots is reached.");
      return;
    }

    const { onSave } = this.props;
    const { userRealMarketValue, userRealCoinValue, conditions } = this.state;
    let values = StoreHelper.getFormValues(this.props.form) || {};

    const isValid = this.validateBotForm(CREATE_REQ_FIELDS);

    if (!BotHelper.validateSoftStop(values)) {
      return;
    }

    if (isValid && !BotHelper.checkHasFunds(userRealMarketValue, userRealCoinValue, values[fields.Type], conditions)) {
      NotificationProvider.warning("You cannot create bot.No enough funds!");
      return;
    }

    if (values && isValid) {
      return onSave({
        ...values,
        step: CommonHelper.toRegularFormat(values.step),
        startAtPercentage: CommonHelper.toRegularFormat(values.startAtPercentage),
        endAtPercentage: CommonHelper.toRegularFormat(values.endAtPercentage),
        profitType: Number(values[fields.Profit]),
        dynamic: Number(values[fields.Dynamic]),
        conditions: this.state.conditions,
      });
    }

    NotificationProvider.warning("Please fill required fields");
  };

  validateBotForm(requiredFields) {
    const values = StoreHelper.getFormValues(this.props.form) || {};
    let errors = {};
    requiredFields.forEach((field) => {
      errors[field] = values[field] || values[field] === 0 ? { valid: true } : { label: "Reqiured", valid: false };
    });
    this.setState({ errors });
    const valid = Object.keys(errors).every((item) => errors[item].valid);
    return valid;
  }

  validateRequiredField = (e) => {
    const { name, value } = e.target;
    const error = value ? { valid: true } : { label: "Reqiured", valid: false };
    const errors = { ...this.state.errors, [name]: error };
    this.setState({ errors });
  };

  validateRequiredNumberField = (name, value) => {
    const error = value ? { valid: true } : { label: "Reqiured", valid: false };
    const errors = { ...this.state.errors, [name]: error };
    this.setState({ errors });
  };

  handleDelete = () => {
    const { onDelete } = this.props;
    const values = StoreHelper.getFormValues(this.props.form) || {};
    if (values) {
      onDelete(values);
    }
  };

  handleUpdate = () => {
    const { onUpdate } = this.props;
    const values = StoreHelper.getFormValues(this.props.form) || {};

    if (!BotHelper.validateSoftStop(values)) {
      return;
    }

    const isValid = this.validateBotForm(EDIT_REQ_FIELDS);
    if (values && isValid) {
      return onUpdate({
        ...values,
        profitType: Number(values[fields.Profit]),
        dynamic: Number(values[fields.Dynamic]),
        conditions: this.state.conditions,
      });
    }

    NotificationProvider.warning("Please fill required fields");
  };

  handleMarketSelectChange = (value, isHookChange) => {
    if (!value) {
      this.props.change(fields.AssetPrice, null);
      this.setState({ enableIncrease: false });
      return;
    }
    const { assetName } = StoreHelper.getFormValues(this.props.form) || {};
    let marketAmount = this.props.userAssets.markets.find((element) => element.name === value);
    this.setState({
      market: value,
      userCurrentMarketValue: marketAmount === undefined ? 0 : marketAmount.amountAvailable,
      userRealMarketValue: marketAmount === undefined ? 0 : marketAmount.realAvailability,
      enableIncrease: value && assetName
    });
    assetName && this.fillAssetPrice(value, assetName, isHookChange);
  };

  onCoinChange = (value, isHookChange) => {
    if (!value) {
      this.props.change(fields.AssetPrice, null);
      this.setState({ enableIncrease: false });
      return;
    }
    let coinAmount = this.props.userAssets.assets.find((element) => element.name === value);
    const { marketName } = StoreHelper.getFormValues(this.props.form) || {};

    this.setState({
      coin: value,
      userCurrentCoinValue: coinAmount === undefined ? 0 : coinAmount.amountAvailable,
      userRealCoinValue: coinAmount === undefined ? 0 : coinAmount.realAvailability,
      enableIncrease: value && marketName
    });
    marketName && this.fillAssetPrice(marketName, value, isHookChange);
  };

  fillAssetPrice = async (marketName, assetName, isHookChange) => {
    if (!this.state.isEdit) {
      let assetPrice = await this.props.getAssetPrice(marketName, assetName, this.state.apiKey);
      assetPrice = CommonHelper.toRegularFormat(assetPrice);
      this.props.change(fields.AssetPrice, assetPrice);

      // Set startAt, endAand lotCount default values for the chart
      const { type } = StoreHelper.getFormValues(this.props.form) || {};
      this.setState({
        startAt: assetPrice,
        endAt: type === 0 ? assetPrice - (assetPrice * 0.05) : assetPrice + (assetPrice * 0.05),
        lotCount: !!this.state.lotCount ? this.state.lotCount : 2
      });
    }    

    if (!isHookChange) {
      this.handleStartAtChange(this.state.startAt);
      this.handleEndAtChange(this.state.endAt);
      this.handleLotChange(this.state.lotCount);
    }
  };

  onEndBlur = (value) => {
    const { lotCount, startAtPrice } = StoreHelper.getFormValues(this.props.form) || {};
    const formattedValue = CommonHelper.toRegularFormat(value);
    const formattedStartAtPrice = CommonHelper.toRegularFormat(startAtPrice);

    if (formattedValue && lotCount) {
      let increase = parseFloat(formattedValue - formattedStartAtPrice).toFixed(12);
      increase = (increase / formattedStartAtPrice) * 100;
      const endValue = Math.abs(increase).toFixed(1);
      const step = endValue / lotCount;
      this.props.change(fields.Step, Math.abs(step));
      this.props.change(fields.EndAtPercentage, endValue);
      let riskProgress = Math.abs(Math.floor(lotCount * step));
      if (riskProgress > 100) riskProgress = 100;
      this.setState({ riskProgress, endAt: formattedValue });
      return;
    }
    this.props.change(fields.Step, null);
    this.setState({ riskProgress: 0 });
  };

  onEndPercentageBlur = (endValue) => {
    const { lotCount, startAtPrice, type } = StoreHelper.getFormValues(this.props.form) || {};
    const formattedStartAtPrice = CommonHelper.toRegularFormat(startAtPrice);

    if (endValue && lotCount) {
      endValue = type === 0 ? -CommonHelper.toRegularFormat(endValue) : CommonHelper.toRegularFormat(endValue);
      const firstOrder = CommonHelper.toRegularFormat(formattedStartAtPrice * ((100 + parseFloat(endValue)) / 100));
      const step = Math.abs(endValue) / lotCount;
      this.props.change(fields.Step, Math.abs(step));
      this.props.change(fields.EndAtPrice, firstOrder);
      let riskProgress = Math.abs(Math.floor(lotCount * step));
      if (riskProgress > 100) riskProgress = 100;
      this.setState({ riskProgress, endAt: firstOrder });
      return;
    }
    this.props.change(fields.Step, null);
    this.setState({ riskProgress: 0 });
  };

  onStartAtPercentageBlur = (value) => {
    const { assetAmount, marketAmount } = StoreHelper.getFormValues(this.props.form) || {};
    if (assetAmount && value) {
      const firstOrder = CommonHelper.toRegularFormat(CommonHelper.toRegularFormat(assetAmount) * ((100 + CommonHelper.toRegularFormat(value)) / 100));
      this.props.change(fields.FirstOrder, firstOrder);
      this.props.change(fields.StartAtPrice, firstOrder);
      this.setState({ startAt: firstOrder, totalAmount: (!!marketAmount ? marketAmount : 1) * (this.state.type === 0 ? 1 : firstOrder) });
      this.setStepValue();
      return;
    }
    this.props.change(fields.FirstOrder, null);
  };

  onStartAtBlur = (startAt) => {
    const { assetAmount, marketAmount } = StoreHelper.getFormValues(this.props.form) || {};
    const formattedStartAt = CommonHelper.toRegularFormat(startAt);

    if (formattedStartAt) {
      let increase = parseFloat(formattedStartAt - assetAmount).toFixed(12);
      increase = (increase / assetAmount) * 100;
      this.props.change(fields.FirstOrder, formattedStartAt);
      this.props.change(fields.StartAtPercentage, increase.toFixed(1));
      this.setState({ startAt: formattedStartAt, totalAmount: (!!marketAmount ? marketAmount : 1) * (this.state.type === 0 ? 1 : formattedStartAt) });
      this.setStepValue();
      return;
    }
    this.props.change(fields.FirstOrder, null);
  };

  setStepValue = () => {
    const { lotCount, startAtPrice, endAtPrice } = StoreHelper.getFormValues(this.props.form) || {};

    if (!endAtPrice || !startAtPrice || !lotCount) {
      return;
    }

    const formattedStartAtPrice = CommonHelper.toRegularFormat(startAtPrice);
    const formattedEndAtPrice = CommonHelper.toRegularFormat(endAtPrice);
    let increase = CommonHelper.toRegularFormat((formattedEndAtPrice - formattedStartAtPrice));
    increase = (increase / formattedStartAtPrice) * 100;
    const endValue = Math.abs(increase).toFixed(1);
    const step = endValue / lotCount;
    this.props.change(fields.Step, Math.abs(step));
  }

  onLotBlur = (value) => {
    const { marketAmount, endAtPercentage } = StoreHelper.getFormValues(this.props.form) || {};

    if (marketAmount && value) {
      this.props.change(fields.OrderAmount, marketAmount / value);
    } else {
      this.props.change(fields.OrderAmount, null);
    }

    if (endAtPercentage && value) {
      const step = endAtPercentage / value;
      this.props.change(fields.Step, step);
      let riskProgress = Math.abs(Math.floor(value * step));
      if (riskProgress > 100) riskProgress = 100;
      this.setState({ riskProgress });
    } else {
      this.onEndBlur(this.state.endAt);
      this.props.change(fields.Step, null);
      this.setState({ riskProgress: 0 });
    }

    this.setState({ lotCount: value });
  };

  onTypeChange = (type) => {
    const { lotCount, startAtPrice, endAtPercentage } = StoreHelper.getFormValues(this.props.form) || {};

    if (endAtPercentage && lotCount) {
      const end = type === 0 ? -endAtPercentage : endAtPercentage;
      const firstOrder = CommonHelper.toCustomFixed(startAtPrice * ((100 + Number(end)) / 100));
      const step = end / lotCount;
      this.props.change(fields.Step, Math.abs(step));
      this.props.change(fields.EndAtPrice, firstOrder);
      let riskProgress = Math.abs(Math.floor(lotCount * step));
      if (riskProgress > 100) riskProgress = 100;
      this.setState({
        riskProgress,
        type,
        endAt: firstOrder,
        lotCount: lotCount,
      });

      return;
    }

    this.props.change(fields.Step, null);
    this.setState({ riskProgress: 0, type: type });
  };

  handleStartAtChange = (startAt) => {
    startAt = CommonHelper.toRegularFormat(startAt);
    this.props.change(fields.StartAtPrice, startAt);
    this.onStartAtBlur(startAt);
  };

  handleEndAtChange = (endAt) => {
    endAt = CommonHelper.toRegularFormat(endAt);
    this.props.change(fields.EndAtPrice, endAt);
    this.onEndBlur(endAt);
  };

  handleLotChange = (lotCount) => {
    this.props.change(fields.LotCount, lotCount);
    this.onLotBlur(lotCount);
  };

  handleShowBuyLinesChange = () => {
    this.setState({ showBuyLines: !this.state.showBuyLines });
  }

  handleShowSellLinesChange = () => {
    this.setState({ showSellLines: !this.state.showSellLines });
  }

  onChartReadyCallback = () => {
    if (!this.state.isEdit) {
      return;
    }

    orderApi.getBotOrders({ params: { botid: this.props.id } })
      .then(response => this.setState({ botOrders: response?.data?.data?.filter(bo => bo.orderStatus === 1) || [] }))
      .catch((err) => this.setState({ loading: false, error: err.message, botOrders: [] }));

    const { startAtPrice, endAtPrice, lotCount } = StoreHelper.getFormValues(this.props.form) || {};
    this.setState({
      startAt: startAtPrice,
      endAt: endAtPrice,
      lotCount,
      isChartReady: true
    });
  }

  render() {
    const { t } = this.context;
    const { id, readonly, userAssets, reset, getUserAssets, isSwingBotCampaignAction, onCampaignActionCancel, onIncreaseClick, onInfoIconClick, isHelpModus } = this.props;
    const { riskProgress, errors } = this.state;
    const { type, assetName, apiKeyId, keyId } = StoreHelper.getFormValues(this.props.form) || {};

    const renderCreateFlowButtons = (
      <div className={`mt-4 d-flex justify-content-center`}>
        <Button className={"mt-2 pl-5 pr-5 mr-2"} variant="primary" type="button" size="md" onClick={this.handleSave}>
          {t("BOT_OVERVIEW.SAVE")}
        </Button>
        <Button
          className={"mt-2 pl-5 pr-5 "}
          variant="outline-danger"
          type="button"
          size="md"
          onClick={() => {
            reset();
            this.setState({ apiKey: "", errors: {}, riskProgress: 0 });
          }}
        >
          {t("BUTTON.CANCEL")}
        </Button>
      </div>        
    );

    const renderEditFlowButtons = (
      <div className={`mt-4  d-flex justify-content-center`}>
        <Button className={"mt-2 pl-5 pr-5 mr-2"} variant="danger" type="button" size="md" onClick={this.handleDelete}>
          {t("BUTTON.DELETE")}
        </Button>
        <Button className={"mt-2 pl-5 pr-5"} variant="primary" type="button" size="md" onClick={this.handleUpdate}>
          {t("BUTTON.UPDATE")}
        </Button>
      </div>
    );

    const renderApiKeyField = (isSwingBotCampaignAction) => (
      <div className="d-flex" style={{ position: 'relative' }}>
        <ApiKey
          disabled={isSwingBotCampaignAction}
          classList="w-75 mr-auto ml-auto"
          value={isSwingBotCampaignAction ? apiKeyId : keyId}
          onChange={(value, exchangeId) => {
            this.validateRequiredField({
              target: { name: fields.KeyId, value },
            });

            this.props.change(fields.KeyId, +value || undefined);
            this.setState({ apiKey: value, exchangeId: exchangeId });
            value && getUserAssets(value);
          }}
          isHelpModus={isHelpModus}
          onInfoIconClick={onInfoIconClick}
        />
      </div>
    );

    const renderMarketField = (
      <div className={`w-50 d-flex ${errors[fields.Market] && !errors[fields.Market].valid ? styles.errorField : ""}`}>
        <Field
          component="select"
          name={fields.Market}
          className="form-control"
          disabled={readonly}
          onChange={(e) => {
            const { value } = e.currentTarget;
            this.handleMarketSelectChange(value);
            this.validateRequiredField(e);
          }}
        >
          <option value="">Select Market</option>
          {userAssets.markets?.map((market) => (
            <option key={market.id} value={market.name}>
              {market.name}
            </option>
          ))}
        </Field>
        {isHelpModus && (
          <div className="infoButton selectAssetInfoButton"
            onClick={() => {
              const messageContent = (
                <div>
                  <p>{t('HELP-MODUS.CREATE-BOT-MARKET')}</p>
                </div>
              );
              onInfoIconClick('Asset', messageContent);
            }}>
            <FontAwesomeIcon className="row" icon={faInfoCircle} />
          </div>
        )}
      </div>
    );

    const renderCoinField = (
      <div className={`w-50 d-flex ${errors[fields.Coin] && !errors[fields.Coin].valid ? styles.errorField : ""}`}>
        <Field
          name={fields.Coin}
          component="select"
          className="form-control"
          onChange={(e) => {
            const { value } = e.currentTarget;
            this.onCoinChange(value);
            this.validateRequiredField(e);
          }}
          disabled={readonly}
        >
          <option value="">Select Coin</option>
          {userAssets.assets?.map((assetCoin) => (
            <option key={assetCoin.id} value={assetCoin.name}>
              {assetCoin.name}
            </option>
          ))}
        </Field>
        {isHelpModus && (
          <div className="infoButton selectAssetInfoButton"
            onClick={() => {
              const messageContent = (
                <div>
                  <p>{t('HELP-MODUS.CREATE-BOT-COIN')}</p>
                </div>
              );
              onInfoIconClick('Asset', messageContent);
            }}>
            <FontAwesomeIcon className="row" icon={faInfoCircle} />
          </div>
        )}
      </div>
    );

    const renderAmountField = (
      <div className={`${styles.formElement} ${errors[fields.Amount] && !errors[fields.Amount].valid ? styles.errorField : ""}`}>
        <div className={styles.formElementName}>
          <label className={styles.formElementLabel} htmlFor={fields.Type} style={{ display: 'flex' }}>
            {t("BOT_OVERVIEW.AMOUNT")}
            {isHelpModus && (
              <div className="infoButton labelInfoButton"
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-AMOUNT') }} />
                    </div>
                  );
                  onInfoIconClick('Amount', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </label>
        </div>
        <div className={styles.formElementData}>
          <Field
            formClassName="m-0"
            className="input-transparent border-radius0"
            id={fields.Amount}
            name={fields.Amount}
            component={FormNumberInput}
            type="text"
            readOnly={readonly || isSwingBotCampaignAction}
            onChange={(value) => {
              this.validateRequiredNumberField(fields.Amount, value);
              if (!this.state.startAt) return;
              this.setState({ totalAmount: (!!value ? +value : 0) * (this.state.type === 0 ? 1 : +this.state.startAt) });
            }}
          />
        </div>
      </div>
    );

    const renderLotCountField = (lotSize, fieldSize) => (
      <div
        className={`${styles.formElement} ${lotSize || ""} ${
          errors[fields.LotCount] && !errors[fields.LotCount].valid ? styles.errorField : ""
        }`}
        style={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <div className={styles.formElementName} style={{ width: '63%' }}>
          <label className={styles.formElementLabel} htmlFor={fields.Type} style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            Lot
            {isHelpModus && (
              <div className="infoButton labelInfoButton" style={{ right: '0' }}
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-LOT') }} />
                    </div>
                  );
                  onInfoIconClick('Lot', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </label>
        </div>
        <div
          className={styles.formElementData}
          style={{
            width: fieldSize || "",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Field
            formClassName="m-0"
            className="input-transparent border-radius0"
            id={fields.LotCount}
            name={fields.LotCount}
            max={100}
            min={1}
            component={FormNumberInput}
            readOnly={readonly}
            normalize={normalizeValueToInt}
            onBlur={(e) => {
              this.validateRequiredNumberField(fields.LotCount, e);
              const { value } = e.currentTarget;
              this.onLotBlur(value);
            }}
          />
        </div>
      </div>
    );

    const renderStartAtField = (rangeSize, fieldSize, style) => (
      <div
        className={`${styles.formElement} ${rangeSize} ${
          errors[fields.StartAtPrice] && !errors[fields.StartAtPrice].valid ? styles.errorField : ""
        }`}
        style={style}
      >
        <div className={styles.formElementName} style={{ width: '50%' }}>
          <label className={styles.formElementLabel} htmlFor={fields.Type} style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            {t("BOT_OVERVIEW.RANGEFROM")}
            {isHelpModus && (
              <div className="infoButton labelInfoButton" style={{ right: '0' }}
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-START-AT') }} />
                    </div>
                  );
                  onInfoIconClick('Start at', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </label>
        </div>
        <div className={styles.formElementData} style={{ width: fieldSize || "" }}>
          <Field
            formClassName="m-0"
            className="input-transparent border-radius0"
            id={fields.StartAtPrice}
            name={fields.StartAtPrice}
            min={0}
            step="any"
            component={FormNumberInput}
            readOnly={readonly}
            onBlur={(e) => {
              this.validateRequiredNumberField(fields.StartAtPrice, e);
              const { value } = e.target;
              this.onStartAtBlur(value);
            }}
          />
        </div>
      </div>
    );

    const renderStartAtPercentageField = (rangeSize, fieldSize, style) => (
      <div
        className={`${styles.formElement} ${rangeSize} ${
          errors[fields.StartAtPercentage] && !errors[fields.StartAtPercentage].valid ? styles.errorField : ""
        }`}
        style={style}
      >
        <div
          className={styles.formElementData}
          style={{
            width: fieldSize || "",
            borderLeft: "none",
            borderRight: "1px solid #E2E5EB",
          }}
        >
          <Field
            formClassName="m-0"
            className="input-transparent  border-radius0"
            id={fields.StartAtPercentage}
            name={fields.StartAtPercentage}
            component={FormNumberInput}
            max={100}
            min={-100}
            readOnly={readonly}
            onBlur={(e) => {
              this.validateRequiredNumberField(fields.StartAtPercentage, e);
              const { value } = e.target;
              this.onStartAtPercentageBlur(value);
            }}
          />
        </div>
        <div className={styles.formElementName}>
          <label className={styles.formElementLabel} htmlFor={fields.Type} style={{ padding: 5 }}>
            %
          </label>
        </div>
      </div>
    );

    const renderStepField = (
      <div
        className={`${styles.formElement} w-50`}
        style={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          width: "60%",
        }}
      >
        <div className={styles.formElementName} style={{ width: '63%' }}>
          <label className={styles.formElementLabel} htmlFor={fields.Type} style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            {t("BOT_OVERVIEW_STEP")}
            {isHelpModus && (
              <div className="infoButton labelInfoButton" style={{ right: '0' }}
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-STEP') }} />
                    </div>
                  );
                  onInfoIconClick('Step', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </label>
        </div>
        <div className={`${styles.formElementData} pl-0`} style={{ width: "37%" }}>
          <Field
            formClassName="m-0"
            className="input-transparent pl-2 border-radius0"
            id={fields.Step}
            name={fields.Step}
            component={FormNumberInput}
            readOnly={true}
          />
        </div>
      </div>
    );

    const renderRangeToField = (rangeSize, fieldSize, style) => (
      <div
        className={`${styles.formElement} ${rangeSize} ${
          errors[fields.EndAtPrice] && !errors[fields.EndAtPrice].valid ? styles.errorField : ""
        }`}
        style={style}
      >
        <div className={styles.formElementName} style={{ width: '50%' }}>
          <label className={styles.formElementLabel} htmlFor={fields.Type} style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            {t("BOT_OVERVIEW.RANGETO")}
            {isHelpModus && (
              <div className="infoButton labelInfoButton" style={{ right: '0' }}
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-END-AT') }} />
                    </div>
                  );
                  onInfoIconClick('End at', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </label>
        </div>
        <div
          className={styles.formElementData}
          style={{
            width: fieldSize || "",
          }}
        >
          <Field
            formClassName="m-0"
            className="input-transparent border-radius0"
            id={fields.EndAtPrice}
            name={fields.EndAtPrice}
            component={FormNumberInput}
            min={0}
            readOnly={readonly}
            onBlur={(e) => {
              this.validateRequiredNumberField(fields.endAtPrice, e);
              const { value } = e.target;
              this.onEndBlur(value);
            }}
          />
        </div>
      </div>
    );

    const renderRangeToPercentageField = (rangeSize, fieldSize, style) => (
      <div
        className={`${styles.formElement} ${rangeSize} ${
          errors[fields.EndAtPercentage] && !errors[fields.EndAtPercentage].valid ? styles.errorField : ""
        }`}
        style={style}
      >
        <div
          className={styles.formElementData}
          style={{
            width: fieldSize || "",
            borderLeft: "none",
            borderRight: "1px solid #E2E5EB",
          }}
        >
          <Field
            formClassName="m-0"
            className="input-transparent border-radius0"
            id={fields.EndAtPercentage}
            name={fields.EndAtPercentage}
            component={FormNumberInput}
            max={100}
            min={0}
            readOnly={readonly}
            onBlur={(e) => {
              this.validateRequiredNumberField(fields.EndAtPercentage, e);
              const { value } = e.target;
              this.onEndPercentageBlur(value);
            }}
          />
        </div>
        <div className={styles.formElementName}>
          <label className={styles.formElementLabel} htmlFor={fields.Type} style={{ padding: 5 }}>
            %
          </label>
        </div>
      </div>
    );

    const renderOrderAmount = (
      <div className={`${styles.formElement}`}>
        <div className={styles.formElementName} style={{ width: '25%' }}>
          <label className={styles.formElementLabel} htmlFor={fields.Type} style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            {t("BOT_OVERVIEW.ORDER_AMOUNT")}
            {isHelpModus && (
              <div className="infoButton labelInfoButton" style={{ right: '0' }}
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-ORDER') }} />
                    </div>
                  );
                  onInfoIconClick('Order', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </label>
        </div>
        <div className={`${styles.formElementData} pl-0`}>
          <Field
            formClassName="m-0"
            className="input-transparent pl-2 border-radius0"
            id={fields.OrderAmount}
            name={fields.OrderAmount}
            component={FormNumberInput}
            readOnly={true}
          />
        </div>
      </div>
    );

    const lowSoftStopPrice = (
      <div className={`${styles.formElement}`}>
        <div className={styles.formElementName}>
          <label className={styles.formElementLabel} htmlFor={fields.LowSoftStopPrice}>
            {t("BOT_OVERVIEW.LOW_SOFT_STOP_PRICE")}
          </label>
        </div>
        <div className={styles.formElementData}>
          <Field
            formClassName="m-0"
            className="input-transparent border-radius0"
            id={fields.LowSoftStopPrice}
            name={fields.LowSoftStopPrice}
            component={FormNumberInput}
          />
        </div>
      </div>
    );

    const highSoftStopPrice = (
      <div className={`${styles.formElement}`}>
        <div className={styles.formElementName}>
          <label className={styles.formElementLabel} htmlFor={fields.HighSoftStopPrice}>
            {t("BOT_OVERVIEW.HIGH_SOFT_STOP_PRICE")}
          </label>
        </div>
        <div className={styles.formElementData}>
          <Field
            formClassName="m-0"
            className="input-transparent border-radius0"
            id={fields.HighSoftStopPrice}
            name={fields.HighSoftStopPrice}
            component={FormNumberInput}
          />
        </div>
      </div>
    );

    const lowSoftStopQuantity = (
      <div className={`${styles.formElement}`}>
        <div className={styles.formElementName}>
          <label className={styles.formElementLabel} htmlFor={fields.LowSoftStopQuantity}>
            {t("BOT_OVERVIEW.LOW_SOFT_STOP_QUANTITY")}
          </label>
        </div>
        <div className={styles.formElementData}>
          <Field
            formClassName="m-0"
            className="input-transparent border-radius0"
            id={fields.LowSoftStopQuantity}
            name={fields.LowSoftStopQuantity}
            component={FormNumberInput}
          />
        </div>
      </div>
    );

    const highSoftStopQuantity = (
      <div className={`${styles.formElement}`}>
        <div className={styles.formElementName}>
          <label className={styles.formElementLabel} htmlFor={fields.HighSoftStopQuantity}>
            {t("BOT_OVERVIEW.HIGH_SOFT_STOP_QUANTITY")}
          </label>
        </div>
        <div className={styles.formElementData}>
          <Field
            formClassName="m-0"
            className="input-transparent border-radius0"
            id={fields.HighSoftStopQuantity}
            name={fields.HighSoftStopQuantity}
            component={FormNumberInput}
          />
        </div>
      </div>
    );

    const renderCheckBox = (      
      <div className={`w-100 m-0 d-flex`}
        style={{ border: "1px solid rgb(226, 229, 236)", borderRadius: "4px" }}>
        <Fragment>
          <div className="d-flex p-2 justify-content-center profit-type-font"
            style={{
              borderRight: '1px solid rgb(226, 229, 236)',
              gap: '10px',
              width: '40%'
            }}
          >
            <Field
              formClassName="align-items-start m-0"
              name={fields.Profit}
              component={Input}
              type="radio"
              className="checkbox"
              label={this.state.coin ?? 'Asset'}
              value="1"
              onChange={this.toggleFullReinvest}
              disabled={this.state.isEdit}
            />

            <Field
              formClassName="align-items-start m-0 mr-1"
              name={fields.Profit}
              component={Input}
              type="radio"
              className="checkbox"
              label={this.state.market ?? 'Market'}
              value="0"
              onChange={this.toggleFullReinvest}
              disabled={this.state.isEdit}
            />
          </div>

          <div className="d-flex p-2 justify-content-center profit-type-font"
            style={{
              width: '60%'
            }}>
            <Field
              formClassName="align-items-start m-0 mr-2"
              name={fields.Dynamic}
              component={Input}
              type="radio"
              className="checkbox"
              label="0%"
              value="0"
              disabled={this.state.isEdit}
            />

            <Field
              formClassName="align-items-start m-0 mr-2"
              name={fields.Dynamic}
              component={Input}
              type="radio"
              className="checkbox"
              label="50%"
              value="50"
              disabled={this.state.isEdit}
            />

            <Field
              formClassName="align-items-start m-0 mr-2"
              name={fields.Dynamic}
              component={Input}
              type="radio"
              className="checkbox"
              label="100%"
              value="100"
              disabled={this.state.disableFullReinvest || this.state.isEdit}
            />
          </div>
        </Fragment>
      </div>      
    );

    const renderTooltip = (
      <Tooltip className="custom-tooltip">
        {t('UPGRADE_PACKAGE_TO_ACCESS')}
        <br /><br />
        {t('UPGRADE_PACKAGE_TO_ACCESS.CLICK')}
      </Tooltip>
    );

    const renderConditions = (
      <div className="d-flex">
        <div className="accordion mt-2" style={{ width: '100%' }}>
          <div className="card">
            <div className={`card-header d-flex ${this.state.lockConditions ? 'locked-item' : ''}`}>
              <h2 className="mb-0" style={{ width: "100%" }}>
                <button
                  className="btn btn-link btn-block text-left d-flex justify-content-between align-items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    if (this.state.lockConditions) {
                      history.push('/app/edit-profile?tab=membership');
                      return;
                    }
                    this.toggleConditions();
                  }}
                >
                  <span className="d-flex align-items-center" style={{ fontWeight: 500 }}>
                    Conditions
                    {this.state.lockConditions && <FontAwesomeIcon icon={faLock} className="ml-2" />}
                  </span>
                  <FontAwesomeIcon icon={this.state.showConditions ? faChevronDown : faChevronRight} />
                </button>
              </h2>
            </div>
            <div className={`collapse ${this.state.showConditions ? "show" : ""}`}>
              <div className="card-body">
                <Condition
                  marketName={this.state.market}
                  assetName={this.state.coin}
                  apiKeyId={this.state.apiKey}
                  availableUserMarkets={this.state.userRealMarketValue}
                  availableUserCoins={this.state.userRealCoinValue}
                  conditions={this.state.conditions}
                  setConditions={(conditions) => this.setState({ conditions })}
                />
              </div>
            </div>
          </div>
        </div>
        {isHelpModus && (
          <div className="infoButton labelInfoButton" style={{ right: '-15px' }}
            onClick={() => {
              const messageContent = (
                <div>
                  <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-CONDITIONS-ONE') }} />
                  <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-CONDITIONS-TWO') }} />
                  <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-CONDITIONS-THREE') }} />
                  <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-CONDITIONS-FOUR') }} />
                  <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-CONDITIONS-FIVE') }} />
                  <div className="ml-4" style={{ display: 'flex', justifyContent: 'space-between', width: '50%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <p style={{ marginBottom: 0 }}>Type:</p>
                      <p style={{ marginBottom: 0 }}>Condition:</p>
                      <p style={{ marginBottom: 0 }}>Setup Order:</p>
                      <p style={{ marginBottom: 0 }} >Action:</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <p style={{ marginBottom: 0 }}>Pre</p>
                      <p style={{ marginBottom: 0 }}>Order is fullfilled</p>
                      <p style={{ marginBottom: 0 }}>{t('HELP-MODUS.CREATE-BOT-CONDITIONS-SEVEN')}</p>
                      <p style={{ marginBottom: 0 }}>Start Bot</p>
                    </div>
                  </div>
                  <br />
                  <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-CONDITIONS-SIX') }} />
                  <div className="ml-4" style={{ display: 'flex', justifyContent: 'space-between', width: '50%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <p style={{ marginBottom: 0 }}>Type:</p>
                      <p style={{ marginBottom: 0 }}>Condition:</p>
                      <p style={{ marginBottom: 0 }} >Action:</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <p style={{ marginBottom: 0 }}>Post</p>
                      <p style={{ marginBottom: 0 }}>Price is below</p>
                      <p style={{ marginBottom: 0 }}>Close order & sell percentage &rarr; 100%</p>
                      <p style={{ marginBottom: 0 }}>Trailing stop</p>
                      <p style={{ marginBottom: 0 }}>Trailing stop & sell percentage</p>
                    </div>
                  </div>
                </div>
              );
              onInfoIconClick('Conditions', messageContent);
            }}>
            <FontAwesomeIcon className="row" icon={faInfoCircle} />
          </div>
        )}
      </div>
    );

    const renderDynamic = (
      <div className="d-flex">
        <div className="accordion mt-2" style={{ width: '100%' }}>
          <div className="card">
            <div className={`card-header d-flex ${this.state.provideDynamic ? '' : 'locked-item'}`}>
              <h2 className="mb-0" style={{ width: "100%" }}>
                <button
                  className="btn btn-link btn-block text-left d-flex justify-content-between align-items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!this.state.provideDynamic) {
                      history.push('/app/edit-profile?tab=membership');
                      return;
                    }
                    this.toggleDynamic();
                  }}
                >
                  <span className="d-flex align-items-center" style={{ fontWeight: 500 }}>
                    Dynamic bot
                    {!this.state.provideDynamic && <FontAwesomeIcon icon={faLock} className="ml-2" />}
                  </span>
                  <FontAwesomeIcon icon={this.state.showDynamic ? faChevronDown : faChevronRight} />
                </button>
              </h2>
            </div>
            <div className={`collapse ${this.state.showDynamic ? "show" : ""}`}>
              <div className="card-body">
                <div className={`${styles.formElement}`}>
                  <div className={styles.formElementName}>
                    <label className={styles.formElementLabel} htmlFor={fields.DynamicBotBuffer}>
                      {t("BOT_OVERVIEW.BUFFER")}
                    </label>
                  </div>
                  <div className={styles.formElementData}>
                    <Field
                      formClassName="m-0"
                      className="input-transparent border-radius0"
                      id={fields.DynamicBotBuffer}
                      name={fields.DynamicBotBuffer}
                      component={FormNumberInput}
                      showPercentage={true}
                    />
                  </div>
                </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    );

    const renderChart = (className, height) => {
      return (
        <div className={className}>
          <Chart
            height={height}
            marketName={this.state.market}
            assetName={this.state.coin}
            startAt={this.state.startAt}
            endAt={this.state.endAt}
            lotCount={this.state.lotCount}
            type={this.state.type}
            isEdit={this.state.isEdit}
            onStartAtChange={this.handleStartAtChange}
            onEndAtChange={this.handleEndAtChange}
            onLotChange={this.handleLotChange}
            exchangeId={this.state.exchangeId}
            onReadyCallback={this.onChartReadyCallback}
            isReady={this.state.isChartReady}
            botOrders={this.state.botOrders}
            showBuyLines={this.state.showBuyLines}
            showSellLines={this.state.showSellLines}
          />
          {isHelpModus && (
            <div className="infoButton avblInfoButton"
              onClick={() => {
                const messageContent = (
                  <div>
                    <p>{t('HELP-MODUS.CREATE-BOT-CHART-FIRST')}</p>
                    <img src={barImage} style={{ width: '25%' }} alt="Create Bot Chart Bar" />
                    <p><br></br>{t('HELP-MODUS.CREATE-BOT-CHART-SECOND')}</p>
                    <img src={chartImage} style={{ width: '100%' }} alt="Create Bot Chart Bar" />
                    <p><br></br>{t('HELP-MODUS.CREATE-BOT-CHART-THIRD')}</p>
                  </div>
                );
                onInfoIconClick('Chart', messageContent);
              }}>
              <FontAwesomeIcon className="row" icon={faInfoCircle} />
            </div>
          )}
        </div>
      );
    }

    // Render Create Bot Form
    const renderSaveFlowFields = (
      <Fragment>
        {!isSwingBotCampaignAction && (
          <div className={styles.fieldRow} style={{ justifyContent: 'right' }}>
            <span className="mr-2 asset-amount-lbl">{t("AVBL")}</span>
            {(type === 0 || !type) && (
              <h5>
                {this.state.market} <NumberLabel value={this.state.userCurrentMarketValue} maxFractions={10} />
              </h5>
            )}
            {type === 1 && (
              <h5>
                {this.state.coin} <NumberLabel value={this.state.userCurrentCoinValue} maxFractions={10} />
              </h5>
            )}
            {isHelpModus && (
              <div className="infoButton avblInfoButton"
                   onClick={() => {
                     const messageContent = (
                       <div>
                         <strong>{t("AVBL")}</strong>
                         <p>{t('HELP-MODUS.CREATE-BOT-AVBL')}</p>
                         <p>Buy-Bot &rarr; {t('HELP-MODUS.CREATE-BOT-BUY-BOT')}</p>
                         <p>Sell-Bot &rarr; {t('HELP-MODUS.CREATE-BOT-SELL-BOT')}</p>
                         <br></br>
                         <strong>{t("REAL-AVBL")}</strong>
                         <p>{t('HELP-MODUS.CREATE-BOT-REAL-AVBL')}</p>
                         <p>Buy-Bot &rarr; {t('HELP-MODUS.CREATE-BOT-BUY-BOT')}</p>
                         <p>Sell-Bot &rarr; {t('HELP-MODUS.CREATE-BOT-SELL-BOT')}</p>
                       </div>
                     );
                     const messageTitle = (<>{t("AVBL")}/{t("REAL-AVBL")}</>);
                     onInfoIconClick(messageTitle, messageContent);
                   }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </div>
        )}
        {!isSwingBotCampaignAction && (
          <div className={styles.fieldRow} style={{ justifyContent: 'right' }} >
            <span className="mr-2 asset-amount-lbl">{t("REAL-AVBL")}</span>
            {(type === 0 || !type) && (
              <h5>
                {this.state.market} <NumberLabel value={this.state.userRealMarketValue} maxFractions={10} />
              </h5>
            )}
            {type === 1 && (
              <h5>
                {this.state.coin} <NumberLabel value={this.state.userRealCoinValue} maxFractions={10} />
              </h5>
            )}
          </div>
          )}
        <br />
        {renderApiKeyField(isSwingBotCampaignAction)}
        <div className="d-flex align-items-center justify-content-center mb-4 mt-2">
          {renderCoinField}
          <div className={styles.verticalLine}></div>
          {renderMarketField}
        </div>
        {this.state.isMobile && renderChart("d-sm-flex", 400)}
        <div className="d-flex">
          <div className={`d-flex border-bottom align-items-center ${styles.botType}`} style={{ width: '100%' }}>
            {(!isSwingBotCampaignAction || type === 0) &&
              <div
                style={{ color: "#15ed1d" }}
                className={`text-center ${isSwingBotCampaignAction ? "w-100" : "w-50"} p-3 ${type === 0 ? styles.active : ""}`}
                onClick={() => {
                  this.validateRequiredField({
                    target: { name: fields.Type, value: 0 },
                  });
                  this.props.change(fields.Type, 0);
                  this.onTypeChange(0);
                  this.toggleFullReinvest();
                }}
              >
                Buy {assetName}
              </div>
            }
            {(!isSwingBotCampaignAction || type === 1) &&
              <div
                style={{ color: "red" }}
                className={`text-center ${isSwingBotCampaignAction ? "w-100" : "w-50"} p-3 ${type === 1 ? styles.active : ""}`}
                onClick={() => {
                  this.validateRequiredField({
                    target: { name: fields.Type, value: 1 },
                  });
                  this.props.change(fields.Type, 1);
                  this.onTypeChange(1);
                  this.toggleFullReinvest();
                }}
              >
                Sell {assetName}
              </div>
            }
            {isHelpModus && (
              <div className="infoButton rightSideInfoButton"
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-BUY-SELL') }} />
                    </div>
                  );
                  onInfoIconClick('Buy/Sell', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </div>
        </div>
        <div className="d-flex">
          <div className="p-0 mt-1">
            <Row>
              <Col md={12} lg={12} sm={12} xs={12} >
                <div className="form-group m-0">
                  <div className="p-0 pr-1 col-lg-9 col-md-10 col-sm-8 col-8">
                    {renderAmountField}
                  </div>
                  <Button className="pr-0 pl-0 col-lg-3 col-md-2 col-sm-4 col-4"
                    variant="success"
                    type="button"
                    size="md"
                    onClick={() => {
                      const { marketName, assetName, type } = StoreHelper.getFormValues(this.props.form) || {};
                      onIncreaseClick(assetName, marketName, this.state.apiKey, type);
                    }}
                    disabled={!this.state.enableIncrease}>
                    {t("CAMPAIGN.FORM.INCREASE")}
                    <FontAwesomeIcon className="pl-1" icon={faPlus} />
                  </Button>
                  {isHelpModus && (
                    <div className="infoButton rightSideInfoButton" style={{ top: '-20px' }}
                      onClick={() => {
                        const messageContent = (
                          <div>
                            <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-INCREASE') }} />
                          </div>
                        );
                        onInfoIconClick('Increase', messageContent);
                      }}>
                      <FontAwesomeIcon className="row" icon={faInfoCircle} />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <div className={`${styles.fieldRow} w-100`}>
              {renderLotCountField("w-50", "37%")}
              {renderStartAtField("w-50", "50%", {
                borderLeft: "none",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              })}
              {renderStartAtPercentageField("w-25", "65%", {
                borderLeft: "none",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              })}
            </div>
            <div className={`${styles.fieldRow} w-100`}>
              {renderStepField}
              {renderRangeToField("w-50", "50%", {
                borderLeft: "none",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              })}
              {renderRangeToPercentageField("w-25", "65%", {
                borderLeft: "none",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              })}
            </div>

            {/* {renderFirstOder} */}
            {renderOrderAmount}
            <div className="progress mb-2" style={{ height: 40 }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: `${riskProgress}%`,
                  backgroundColor: `${riskProgress < 25 ? "red" : riskProgress > 25 && riskProgress < 70 ? "rgb(248, 83, 5)" : "green"}`,
                }}
              >
                {riskProgress}%
              </div>
            </div>
            {isHelpModus && (
              <div className="infoButton rightSideInfoButton" style={{ top: '-60px', float: 'right' }}
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-WORKSPACE') }} />
                    </div>
                  );
                  onInfoIconClick('Workspace', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p style={{ marginBottom: 10, fontWeight: 500, marginRight: 5 }}>
            Profit type
          </p>

          <p style={{ marginBottom: 10, fontWeight: 500, marginRight: 5 }}>
            Reinvest
          </p>
          <div></div>
          <div></div>
        </div>
        {renderCheckBox}
        {isHelpModus && (
          <div className="infoButton labelInfoButton" style={{ float: 'right', top: '-65px', right: '-15px' }}
            onClick={() => {
              const messageContent = (
                <div>
                  <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-PROFIT-TYPE') }} />
                </div>
              );
              onInfoIconClick('Profit Type', messageContent);
            }}>
            <FontAwesomeIcon className="row" icon={faInfoCircle} />
          </div>
        )}

        <div className="d-flex">
          <div className="accordion mt-2" style={{ width: "100%" }}>
            <div className="card">
              <div className="card-header d-flex">
                <h2 className="mb-0" style={{ width: "100%" }}>
                  <button
                    className="btn btn-link btn-block text-left d-flex justify-content-between align-items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      this.toggleSoftStop();
                    }}
                  >
                    <span className="d-flex" style={{ fontWeight: 500 }}>
                      Soft Stop
                    </span>
                    <FontAwesomeIcon icon={this.state.showSoftStop ? faChevronDown : faChevronRight} />
                  </button>
                </h2>
              </div>
              <div className={`collapse ${this.state.showSoftStop ? "show" : ""}`}>
                <div className="card-body p-0 bg-gradient">
                  <div className="high-stop">
                    <h6>High Stop</h6>
                    {highSoftStopPrice}
                    {highSoftStopQuantity}
                  </div>

                  <div className="low-stop">
                    <h6>Low Stop</h6>
                    {lowSoftStopPrice}
                    {lowSoftStopQuantity}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isHelpModus && (
            <div className="infoButton labelInfoButton" style={{ right: '-15px' }} 
              onClick={() => {
                const messageContent = (
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-SOFT-STOP') }} />
                  </div>
                );
                onInfoIconClick('Soft Stop', messageContent);
              }}>
              <FontAwesomeIcon className="row" icon={faInfoCircle} />
            </div>
          )}
        </div>
        {
          this.state.lockConditions ? (
            <OverlayTrigger placement="left" overlay={renderTooltip}>
              {renderConditions}
            </OverlayTrigger>
          ) : (
            renderConditions
          )
        }
        {
          ((this.state.provideDynamic && renderDynamic) ||
            <OverlayTrigger placement="left" overlay={renderTooltip}>
              {renderDynamic}
            </OverlayTrigger>
          )
        }
      </Fragment>
    );

    // Render Edit Bot form
    const renderEditFlowFields = (
      <Fragment>
        <div className="d-flex align-items-center justify-content-center">
          <ApiKey
            disabled={true}
            classList="w-75"
            value={keyId}
            onChange={() => { }}
            isHelpModus={isHelpModus}
            onInfoIconClick={onInfoIconClick}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center mb-4 mt-2">
          {renderCoinField}
          <div className={styles.verticalLine}></div>
          {renderMarketField}
        </div>
        {this.state.isMobile && renderChart("d-sm-flex", 400)}
        <div className="d-flex">
          <div style={{ pointerEvents: "none" }} className={`d-flex align-items-center border-bottom ${styles.botType}`} style={{ width: '100%' }}>
            <div
              style={{ color: "#15ed1d" }}
              className={`text-center w-50 p-3 ${type === 0 ? styles.active : ""}`}
              onClick={() => {
                this.validateRequiredField({
                  target: { name: fields.Type, value: 0 },
                });
                this.props.change(fields.Type, 0);
                this.onTypeChange(0);
              }}
            >
              Buy {assetName}
            </div>
            <div
              style={{ color: "red" }}
              className={`text-center w-50 p-3 ${type === 1 ? styles.active : ""}`}
              onClick={() => {
                this.validateRequiredField({
                  target: { name: fields.Type, value: 1 },
                });
                this.props.change(fields.Type, 1);
                this.onTypeChange(1);
              }}
            >
              Sell {assetName}
            </div>
            {isHelpModus && (
              <div className="infoButton rightSideInfoButton"
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-BUY-SELL') }} />
                    </div>
                  );
                  onInfoIconClick('Buy/Sell', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </div>
        </div>
        <div class="d-flex flex-row w-100">
          <div class="d-flex flex-row w-50 align-items-center">
            {t("BOT.ACTIONS.SHOW-BUY-ORDERS")}
            <Switch
              size="medium"
              onChange={() => this.handleShowBuyLinesChange()}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
          <div class="d-flex flex-row w-50 align-items-center">
            {t("BOT.ACTIONS.SHOW-SELL-ORDERS")}
            <Switch
              size="medium"
              onChange={() => this.handleShowSellLinesChange()}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
        </div>
        <div
          className="p-0 mt-1"
          style={{
            pointerEvents: "none",
          }}
        >
          {renderAmountField}
          <div className={`${styles.fieldRow} w-100`}>
            {renderLotCountField("w-50", "37%")}
            {renderStartAtField("w-50", "50%", {
              borderLeft: "none",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            })}
            {renderStartAtPercentageField("w-25", "65%", {
              borderLeft: "none",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            })}
          </div>
          <div className={`${styles.fieldRow} w-100`}>
            {renderStepField}
            {renderRangeToField("w-50", "50%", {
              borderLeft: "none",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            })}
            {renderRangeToPercentageField("w-25", "65%", {
              borderLeft: "none",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            })}
          </div>
          {renderOrderAmount}
          <div className="progress mb-2" style={{ height: 40 }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: `${riskProgress}%`,
                backgroundColor: `${riskProgress < 25 ? "red" : riskProgress > 25 && riskProgress < 70 ? "rgb(248, 83, 5)" : "green"}`,
              }}
            >
              {riskProgress}%
            </div>
          </div>
          {isHelpModus && (
            <div className="infoButton rightSideInfoButton" style={{ top: '-60px', float: 'right' }}
              onClick={() => {
                const messageContent = (
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-WORKSPACE') }} />
                  </div>
                );
                onInfoIconClick('Workspace', messageContent);
              }}>
              <FontAwesomeIcon className="row" icon={faInfoCircle} />
            </div>
          )}
        </div>
        <div className='d-flex'>
          <p style={{ marginBottom: 10, marginTop: 15, fontWeight: 500 }}>Profit type</p>
        </div>
        {renderCheckBox}
        {isHelpModus && (
          <div className="infoButton labelInfoButton" style={{ float: 'right', top: '-65px', right: '-15px' }}
            onClick={() => {
              const messageContent = (
                <div>
                  <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-PROFIT-TYPE') }} />
                </div>
              );
              onInfoIconClick('Profit Type', messageContent);
            }}>
            <FontAwesomeIcon className="row" icon={faInfoCircle} />
          </div>
        )}
        <div className="d-flex">
          <div className="accordion mt-2" style={{ width: '100%' }}>
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link btn-block text-left d-flex justify-content-between align-items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      this.toggleSoftStop();
                    }}
                  >
                    <span style={{ fontWeight: 500 }}>Soft Stop</span>
                    <FontAwesomeIcon icon={this.state.showSoftStop ? faChevronDown : faChevronRight} />
                  </button>
                </h2>
              </div>
              <div className={`collapse ${this.state.showSoftStop ? "show" : ""}`}>
                <div className="card-body bg-gradient p-0">
                  <div className="high-stop">
                    <h6>High Stop</h6>
                    {highSoftStopPrice}
                    {highSoftStopQuantity}
                  </div>

                  <div className="low-stop">
                    <h6>Low Stop</h6>
                    {lowSoftStopPrice}
                    {lowSoftStopQuantity}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isHelpModus && (
            <div className="infoButton labelInfoButton" style={{ right: '-15px' }}
              onClick={() => {
                const messageContent = (
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-SOFT-STOP') }} />
                  </div>
                );
                onInfoIconClick('Soft Stop', messageContent);
              }}>
              <FontAwesomeIcon className="row" icon={faInfoCircle} />
            </div>
          )}
        </div>
        {
          this.state.lockConditions ? (
            <OverlayTrigger placement="left" overlay={renderTooltip}>
              {renderConditions}
            </OverlayTrigger>
          ) : (
              renderConditions
          )
        }
        {
          ((this.state.provideDynamic && renderDynamic) ||
            <OverlayTrigger placement="left" overlay={renderTooltip}>
              {renderDynamic}
            </OverlayTrigger>
          )
        }
      </Fragment>
    );

    const renderCampaignActionForm = (
      <>
        <div className="bot-layout w-100 campaign-modal-mobile">
          <div className="d-flex">
            <form onSubmit={this.handleUpdate} noValidate className="w-100">
              {renderSaveFlowFields}
            </form>
          </div>
        </div>
        <div className={`mt-2 d-flex justify-content-end`}>
          <Button className={"mt-2 pl-5 pr-5 mr-2"} variant="outline-danger" type="button" size="md" onClick={onCampaignActionCancel}>
            {t("BOT_OVERVIEW.CLOSE")}
          </Button>
          <Button className={"mt-2 pl-5 pr-5"} variant="primary" type="button" size="md" onClick={this.handleSave}>
            {t("BOT_OVERVIEW.SAVE")}
          </Button>
        </div>
      </>
    );

    return isSwingBotCampaignAction ? (
      renderCampaignActionForm
    ) : (
      <div className="bot-layout w-100">
        {id && <BackButton />}
          <h4 className="page-title d-flex">GRID Bot {id ? "Edit" : "Create"}
            {isHelpModus && (
              <div className="infoButton avblInfoButton" style={{ fontSize: '16px' }}
                   onClick={() => {
                     const messageContent = (
                       <div>
                         <p>{t('HELP-MODUS.CREATE-BOT-TITLE-FIRST')}</p>
                         <p>{t('HELP-MODUS.CREATE-BOT-TITLE-SECOND')}</p>
                       </div>
                     );
                     onInfoIconClick('GRID Bot Create', messageContent);
                   }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
        </h4>
        <div className="d-flex mobile-view">
          <div className="d-flex flex-column pr-3 chart-section" style={{ width: "100%" }}>
            {!this.state.isMobile && renderChart("trade-chart d-none d-md-flex", 780)}
            <div className="order-book pt-4">
              <OrderBook 
                  formData={StoreHelper.getFormValues(this.props.form) || {}}
                  exchangeId={this.state.exchangeId}
                  totalAmount={this.state.totalAmount}
                  orderType={this.state.type}
                  onInfoIconClick={() => {
                    const messageContent = (
                      <div>
                        <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.ORDER-BOOK') }} />
                      </div>
                    );
                    const title = (<>{t("HELP-MODUS.ORDER-BOOK-TITLE")}</>);
                    onInfoIconClick(title, messageContent);
                  }} />
            </div>
          </div>
          <div className="bot-form">
            <form onSubmit={this.handleUpdate} noValidate className="w-100">
              {id ? renderEditFlowFields : renderSaveFlowFields}
              {id ? renderEditFlowButtons : renderCreateFlowButtons}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const getFormName = (props) => (props.id ? `${props.formName}_${props.id}` : props.formName);

export default compose(
  connect((state, props) => ({
    form: getFormName(props),
    enableReinitialize: true,
  })),
  reduxForm()
)(BotUnitForm);

BotUnitForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

BotUnitForm.defaultProps = {
  readonly: false,
};

BotUnitForm.propTypes = {
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  id: PropTypes.number,
};
