import authApi from "../../../api/auth/actions";
import { setUser } from "./action-creators";

import qs from "qs";
import Helpers from "../../../common/helpers/error-helpers";

import { history } from "../../../index";
import { routes } from "../../../common/routes-constants";

import TokenHelper from "../../../common/helpers/token-helper";
import UserHelper from "../../../common/helpers/user-helper";

export const login = (params, handleLoginError) => {
  return async (dispatch) => {
    try {
      const res = await authApi.login(qs.stringify(params), {
        "Content-Type": "application/x-www-form-urlencoded;",
      });
      TokenHelper.setAuthTokens(res.data);
      dispatch(setUser(res.data));
      history.push(routes.protectedRoutes.dashboard.fullPath);
    } catch (e) {
      handleLoginError(Helpers.generateErrorLabel(e));
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    TokenHelper.removeAuthTokens();
    UserHelper.removeUserInfo();
    dispatch(setUser(null));
    history.push(routes.unProtectedRoutes.login.fullPath);
  };
};
