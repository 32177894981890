import React from "react";
import PropTypes from "prop-types";

import defaultProfileImage from "../../assets/user-avatar.png";

import "../style/profile-image.scss";

const ProfileImage = ({ size, shape, src }) => {
  return (
    <div className={`profile-image-wrapper ${size} ${shape}`}>
      <div className="profile-image" style={{ background: `url('${src ?? defaultProfileImage}') no-repeat center center` }}></div>
    </div>
  );
};

ProfileImage.defaultProps = {
  size: "sm",
  shape: "rounded",
};

ProfileImage.propTypes = {
  size: PropTypes.oneOf(["lg", "md", "sm"]),
  shape: PropTypes.oneOf(["rounded", "regular"]),
};

export default ProfileImage;
