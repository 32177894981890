import React from "react";
import { Router, Switch, Redirect, Route } from "react-router";

import { values } from "lodash";

import { routes } from "../common/routes-constants";

import DefaultContainer from "../common/containers/components/default-container";
import InAppContainer from "../common/containers/components/in-app-container";

import qs from "qs";

const CustomRoute = (params) => {
  if (params.requiredQParams && params.requiredQParams.length > 0) {
    let hasAllParams = false;
    for (var i = 0; i < params.requiredQParams.length; i++) {
      if (params.location.search.indexOf(params.requiredQParams[i]) === -1) {
        hasAllParams = false;
        break;
      } else {
        hasAllParams = true;
      }
    }
    if (!hasAllParams) {
      //return 404
      return null;
    } else {
      const qsString = params.location.search.substr(
        1,
        params.location.search.length
      );

      params = {
        ...params,
        location: {
          ...params.location,
          queryParams: qs.parse(qsString),
        },
      };
    }
  }

  return <Route {...params} />;
};

const ProtectedRoute = ({ user, ...restProps }) => {
  return user && user.access_token ? (
    <CustomRoute {...restProps} />
  ) : (
    <Redirect to={{ pathname: routes.unProtectedRoutes.login.fullPath }} />
  );
};

const UnprotectedRoute = ({ user, ...restProps }) => {
  return !user || !user.access_token ? (
    <CustomRoute {...restProps} />
  ) : (
    <Redirect to={{ pathname: routes.protectedRoutes.dashboard.fullPath }} />
  );
};

const Routes = ({ user, history, language, setLanguage }) => {
  const unProtectedRoutes = values(routes.unProtectedRoutes);
  const protectedRoutes = values(routes.protectedRoutes);

  return (
    <Router {...{ history }}>
      <Switch>
        {unProtectedRoutes.map(
          (
            {
              fullPath,
              component: Component,
              exact = false,
              requiredQParams,
              ...restProps
            },
            index
          ) => {
            return (
              <UnprotectedRoute
                exact={exact}
                path={fullPath}
                key={index}
                requiredQParams={requiredQParams}
                user={user}
              >
                <DefaultContainer {...restProps}>
                  <Component />
                </DefaultContainer>
              </UnprotectedRoute>
            );
          }
        )}
        {protectedRoutes.map(
          (
            {
              fullPath,
              component: Component,
              exact = false,
              requiredQParams,
              ...restProps
            },
            index
          ) => {
            return (
              <ProtectedRoute
                exact={exact}
                path={fullPath}
                key={index}
                requiredQParams={requiredQParams}
                user={user}
              >
                <InAppContainer {...restProps} language={language} setLanguage={setLanguage}>
                  <Component />
                </InAppContainer>
              </ProtectedRoute>
            );
          }
        )}
      </Switch>
    </Router>
  );
};

export default Routes;
