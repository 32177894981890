import jwt_decode from "jwt-decode";
import UserHelper from "./user-helper";

const AUTH_TOKENS = "AUTH_TOKENS";

class TokenHelper {
  static getRefreshToken() {
    const { refresh_token } = JSON.parse(localStorage.getItem(AUTH_TOKENS)) || {};
    return refresh_token || null;
  }

  static getUserTokens() {
    return JSON.parse(localStorage.getItem(AUTH_TOKENS)) || {};
  }

  static getAccessToken() {
    const { access_token } = JSON.parse(localStorage.getItem(AUTH_TOKENS)) || {};
    return access_token || null;
  }

  static getTokenType() {
    const { token_type } = JSON.parse(localStorage.getItem(AUTH_TOKENS)) || {};
    return token_type || "Bearer";
  }

  static removeAuthTokens() {
    localStorage.removeItem(AUTH_TOKENS);
  }

  static setAuthTokens(data) {
    const { access_token, refresh_token, token_type, expires_in } = data;
    var decoded = jwt_decode(access_token);
    localStorage.setItem(AUTH_TOKENS, JSON.stringify({ access_token, refresh_token, token_type, expires_in }));
    UserHelper.setUserInfo(decoded);
  }

  static updateAccessToken(accessToken) {
    let tokens = JSON.parse(localStorage.getItem(AUTH_TOKENS)) || {};
    tokens.access_token = accessToken;
    localStorage.setItem(AUTH_TOKENS, JSON.stringify(tokens));
  }
}
export default TokenHelper;
