import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from "@mui/material/Switch";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import campaignApi from "../../../api/campaign/actions";
import TradeTable from "../../../common/form/table/trade-table";
import { useHelpModus } from "../../../common/help-modus/help-modus-context";
import HelpModusModal from "../../../common/help-modus/help.modus-modal";
import Helpers from "../../../common/helpers/error-helpers";
import { routes } from "../../../common/routes-constants";
import NotificationProvider from "../../../core/notification";
import { CAMPAIGN_CONTEST_COLUMNS } from "../constants";
import CopyCampaignModal from "../modals/copy-campaign-modal";

import "../style/campaign.scss";

const CampaignContest = (props, { t }) => {
  const { history } = props;

  const [campaignData, setCampaignData] = useState([]);
  const [filteredCampaignData, setFilteredCampaignData] = useState([]);
  const [isRound, setIsRound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [showHelpModusModal, setShowHelpModusModal] = useState(false);
  const [showCopyCampaignModal, setShowCopyCampaignModal] = useState(false);
  const [copyCampaignId, setCopyCampaignId] = useState(0);
  const [copyCampaignAssetName, setCopyCampaignAssetName] = useState('N/A');
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('Information');
  const { isHelpModus } = useHelpModus();

  const [filters, setFilters] = useState({
    activeOnly: false,
    dateRange: 0
  });

  useEffect(() => {
    setIsLoading(true);
    getBestCampaigns();
    // eslint-disable-next-line
  }, [filters.activeOnly]);

  useEffect(() => {
    filterCampaignData();
    // eslint-disable-next-line
  }, [filters.dateRange, isRound]);

  const getBestCampaigns = async () => {
    try {
      const response = await campaignApi.getBestCampaigns({ params: { isActive: filters.activeOnly } });
      setCampaignData(response.data.campaigns);
      setFilteredCampaignData(response.data.campaigns);
      setIsLoading(false);
    } catch (err) {
      Helpers.parseError(err);
      setIsLoading(false);
    }
  };

  const toggleShowCopyCampaignModel = () => {
    setShowCopyCampaignModal((showModal) => !showModal);
  };

  const onCopyCampaign = (campaignDetails) => {
    toggleShowCopyCampaignModel();
    setCopyCampaignId(campaignDetails.id ?? 0);
    setCopyCampaignAssetName(campaignDetails.assetName ?? 'N/A');
  };

  const filterCampaignData = () => {
    let filteredData = [...campaignData];

    if (filters.activeOnly) {
      filteredData = filteredData.filter(campaign => campaign.status === 1);
    }

    const currentDate = new Date();

    if (filters.dateRange === 1) {
      const last7Days = new Date();
      last7Days.setDate(currentDate.getDate() - 7);
      filteredData = filteredData.filter(campaign => new Date(campaign.lastOrderDate) >= last7Days);
    }
    else if (filters.dateRange === 2) {
      const last31Days = new Date();
      last31Days.setDate(currentDate.getDate() - 31);
      filteredData = filteredData.filter(campaign => new Date(campaign.lastOrderDate) >= last31Days);
    }

    setFilteredCampaignData(filteredData);
  };

  const onRadioChanged = (e) => {
    setFilters({ ...filters, dateRange: parseInt(e.target.value) });
  };

  const toggleHelpModusModal = (title, message) => {
    setShowHelpModusModal((showModal) => !showModal);
    setModalMessage(message);
    setModalTitle(title);
  };

  const confirmCampaignAmount = async (assetAmount) => {
    try {
      const response = await campaignApi.copy({ copyCampaignId, assetAmount });
      setIsLoading(false);
      history.push(`${routes.protectedRoutes.campaignEdit.path}/${response.data.campaignId}`);
      NotificationProvider.success("Campaign successfully copied");
    } catch (err) {
      Helpers.parseError(err);
      setIsLoading(false);
    }
  };

  const renderFilters = () => (
    <>
      <label className="w-100">
        {t("CONTEST.FORM.PROFIT")}
        <Switch
          size="medium"
          checked={isRound}
          onChange={(e) => setIsRound(e.target.checked)}
          inputProps={{ "aria-label": "controlled" }}
        />
        {t("CONTEST.FORM.ROUND")}
      </label>
      <FormControl>
        <RadioGroup row name="position" value={filters.dateRange.toString()}>
          <FormControlLabel value="0" control={<Radio />} label="All time" labelPlacement="end" onChange={onRadioChanged} />
          <FormControlLabel value="1" control={<Radio />} label="Last 7 days" labelPlacement="end" onChange={onRadioChanged} />
          <FormControlLabel value="2" control={<Radio />} label="Last 31 days" labelPlacement="end" onChange={onRadioChanged} />
        </RadioGroup>
      </FormControl>
      <label className="ml-5">
        <Checkbox onChange={(e) => setFilters({ ...filters, activeOnly: e.target.checked })} checked={filters.activeOnly} />
        {t("CONTEST.FORM.FILTER-ONLY-ACTIVE")}
      </label>
    </>
  );

  return (
    <div className="contest-layout w-100">
      <h4 className="page-title d-flex">
        {t("CONTEST.FORM.PAGE-TITLE")}
        {isHelpModus && (
          <div className="infoButton avblInfoButton"
            onClick={() => {
              const messageContent = (
                <div>
                  <p>{t('HELP-MODUS.CONTEST-TITLE')}</p>
                </div>
              );
              toggleHelpModusModal('Contest', messageContent);
            }}>
            <FontAwesomeIcon className="row" icon={faInfoCircle} />
          </div>
        )}
      </h4>
      {renderFilters()}
      <Row className="m-t-5">
        <Col md={12} sm={12} xs={12}>
          {isHelpModus && (
            <div className="infoButton" style={{ float: 'right', top: '-20px' }}
              onClick={() => {
                const messageContent = (
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CONTEST-TABLE') }} />
                  </div>
                );
                toggleHelpModusModal('Tabelle', messageContent);
              }}>
              <FontAwesomeIcon className="row" icon={faInfoCircle} />
            </div>
          )}
          <TradeTable
            data={filteredCampaignData}
            columnDefs={CAMPAIGN_CONTEST_COLUMNS(onCopyCampaign)}
            enableFilter
            onFilterChanged={setFilters}
            tableHasVerticalScroll
            isLoading={isLoading}
          />
        </Col>
      </Row>
      {showHelpModusModal && (
        <HelpModusModal
          show={showHelpModusModal}
          onClose={toggleHelpModusModal}
          title={modalTitle}
          message={modalMessage}
        />
      )}
      {showCopyCampaignModal && (
        <CopyCampaignModal
          show={showCopyCampaignModal}
          onClose={toggleShowCopyCampaignModel}
          onConfirm={async (value) => await confirmCampaignAmount(value)}
          assetName={copyCampaignAssetName}
        />
      )}
    </div>
  );
};

CampaignContest.contextTypes = {
  t: PropTypes.func,
};

export default withRouter(CampaignContest);