export const fields = {
  company: "Company",
  firstName: "FirstName",
  lastName: "LastName",
  nickname: "Nickname",
  email: "Email",
  country: "Country",
  city: "City",
  street: "Street",
  streetNumber: "StreetNumber",
  role: "Role",
  zip: "Zip",
  returnUrl: "ReturnUrl",
  password: "Password",
  confirmPassword: "ConfirmPassword",
  agreeToTermsAndConditions: "agreeToTermsAndConditions",
  agreeToPrivacyPolicy: "agreeToPrivacyPolicy",
  affiliateId: "AffiliateId",
  iban: "Iban"
};
