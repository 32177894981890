import { INDENTITY_BASE_URL } from "../../common/constants";
import { Api } from "../../core/api";

import { generateQueryParams } from "../api-helpers";

import routesConstatns from "./routes";

const authApi = {};

authApi.login = async (params, headers) => {
  return await Api.post(
    `${INDENTITY_BASE_URL}${routesConstatns.LOGIN}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

authApi.activateUser = async (params, headers) => {
  return await Api.get(
    `${INDENTITY_BASE_URL}${routesConstatns.ACTIVATE}${generateQueryParams(
      params
    )}`,
    {
      needLoader: true,
    },
    headers
  );
};
authApi.forgotPassword = async (params) => {
  return await Api.post(
    `${INDENTITY_BASE_URL}${routesConstatns.FORGOT_PASSWORD}`,
    params,
    {
      needLoader: true,
    }
  );
};
authApi.register = async (params) => {
  return await Api.post(
    `${INDENTITY_BASE_URL}${routesConstatns.REGISTER}`,
    params,
    {
      needLoader: true,
    }
  );
};
authApi.setPassword = async (params) => {
  return await Api.post(
    `${INDENTITY_BASE_URL}${routesConstatns.SET_PASSWORD}`,
    params,
    {
      needLoader: true,
    }
  );
};

export default authApi;
