import actionTypes from '../constants/action-types';

const userAssetsState = {};

export const userAssetsReducer = (state = userAssetsState, payload) => {
  switch (payload.type) {
    case actionTypes.LOAD_USER_ASSETS:
      const data = payload.data ? { ...state, ...payload.data } : {};
      return { ...data };
    default:
      return state;
  }
};
