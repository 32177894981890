import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import Button from "../../../common/form/button/components/button";

import Input from "../../../common/form/input/components/input";

import { FORMS } from "../../../common/forms";
import { fields } from "../constants/constants";

import { validate } from "../validators/validate";

let SetPasswordForm = (props, context) => {
  const { t } = context;
  const { handleSubmit, errorMessage } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field
        id={fields.email}
        name={fields.email}
        component={Input}
        type="email"
        readOnly={true}
        placeholder={t("SET_PASSWORD_FORM.EMAIL")}
      />
      <Field
        id={fields.password}
        name={fields.password}
        component={Input}
        type="password"
        placeholder={t("SET_PASSWORD_FORM.PASSWORD")}
        tooltipValidation
      />
      <Field
        id={fields.confirmPassword}
        name={fields.confirmPassword}
        component={Input}
        type="password"
        placeholder={t("SET_PASSWORD_FORM.CONFIRM_PASSWORD")}
      />

      <Button
        className={"mt-2"}
        variant="outline-primary"
        block={true}
        type="submit"
        size="md"
      >
        {t("SET_PASSWORD_FORM.SUBMIT_BUTTON")}
      </Button>

      <p className="err">{errorMessage ? errorMessage : ""}</p>
    </form>
  );
};

SetPasswordForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

SetPasswordForm = reduxForm({
  form: FORMS.setPasswordForm,
  validate,
  enableReinitialize: true,
})(SetPasswordForm);

export default SetPasswordForm;
