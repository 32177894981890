import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { Link, withRouter } from "react-router-dom";

import { Card, Row } from "react-bootstrap";
import whiteLogo from "../../../common/assets/logo-white.png";
import logo from "../../../common/assets/logo.png";
import Button from "../../../common/form/button/components/button";
import RegisterForm from "./register-form";

import { register } from "../actions/register-actions";

import { APPLICATION_BASE_URL } from "../../../common/constants";
import { routes } from "../../../common/routes-constants";
import { fields } from "../constants/constants";

import CommonHelper from "../../../common/helpers/common-helper";
import "../style/register.scss";

class Register extends Component {
  state = {
    registerErrorMessage: null,
    registrationSucess: false,
    selectedRole: undefined,
  };

  componentDidMount() {
    const role = this.props.location.pathname.split("/")[2];
    const affiliateId = this.props.location.pathname.split("/")[3];
    if (role && role !== 'affiliate') {
      this.setState({ selectedRole: role.toUpperCase() });
    }

    if (affiliateId) {
      this.setState({ affiliateId });
    }
  }

  handleLoginSubmit = (values) => {
    const { register } = this.props;
    register(
      {
        ...values,
        [fields.returnUrl]: `${APPLICATION_BASE_URL}${routes.unProtectedRoutes.activate.path}`,
      },
      () => this.setState({ registrationSucess: true }),
      (registerErrorMessage) => this.setState({ registerErrorMessage })
    );
  };

  render() {
    const { t } = this.context;
    const { history } = this.props;
    const { registrationSucess, registerErrorMessage } = this.state;
    const isDarkMode = CommonHelper.getUserCurrentTheme() === 'dark';

    return (
      <Row className="register align-items-center justify-content-center h-100 ">
        <Card className="col-sm-10 col-md-6 col-lg-4 col-xl-3">
          <Card.Body>
            <Card.Title className="text-center">
              <img width={180} src={isDarkMode ? whiteLogo : logo} alt={"logo"} />
            </Card.Title>
            <h6 className="line-on-side text-muted text-center font-small-3 pt-2">
              <span>{t("REGISTER.TITLE")}</span>
            </h6>
            {/* <Card.Text>
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </Card.Text> */}
            {!registrationSucess && (
              <>
                <RegisterForm
                  onSubmit={this.handleLoginSubmit}
                  t={t}
                  registerErrorMessage={registerErrorMessage}
                  clearRegisterError={() => {
                    this.setState({ registerErrorMessage: null });
                  }}
                  selectedRole={this.state.selectedRole}
                  affiliateId={this.state.affiliateId}
                />
                <Link to={routes.unProtectedRoutes.login.fullPath} className="float-right">
                  {t("FORGOT_PASSWORD.BACK_TO_LOGIN")}
                </Link>
              </>
            )}
            {registrationSucess && (
              <>
                <Card.Text>{t("REGISTRATION.SUCCESS_MESSAGE")}</Card.Text>
                <Button
                  className={"mt-2"}
                  variant="outline-primary"
                  block={true}
                  type="button"
                  size="md"
                  onClick={() => {
                    history.push(routes.unProtectedRoutes.login.fullPath);
                  }}
                >
                  {t("REGISTRATION.LOGIN_BUTTON")}
                </Button>
              </>
            )}
          </Card.Body>
        </Card>
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  register: (params, setRegistrationSucess, registerErrorMessage) =>
    dispatch(register(params, setRegistrationSucess, registerErrorMessage)),
});

Register.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(connect(null, mapDispatchToProps)(Register));
