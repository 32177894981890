import Activate from "../app/activate/components/activate";
import BotsCreate from "../app/bots-create/components/bots-create";
import BotsEdit from "../app/bots-edit/components";
import BotsOverview from "../app/bots-overview/components/bots-overview";
import CampaignContest from "../app/campaign/campaign-contest/campaign-contest";
import CampaignOverview from "../app/campaign/campaign-overview/campaign-overview";
import Campaign from "../app/campaign/components/campaign";
import ChangeLog from "../app/change-log/change-log";
import Contest from "../app/contest/components/contest";
import Dashboard from "../app/dashboard/components/dashboard";
import EditProfile from "../app/edit-profile/components/edit-profile";
import ForgotPassword from "../app/forgot-password/components/forgot-password";
import Invoice from "../app/invoice/components/invoice";
import Login from "../app/login/components/login";
import MarketOrders from "../app/market-orders/components/market-orders";
import BotOrderOverview from "../app/order-overview/components/bot-order-overview";
import OrderOverview from "../app/order-overview/components/order-overview";
import Register from "../app/register/components/register";
import SetPassword from "../app/set-password/components/set-password";
import SmartTrade from "../app/smart-trade/components/smart-trade";
import SmartTradeOverview from "../app/smart-trade/components/smart-trade-overview";
import StopLoss from "../app/stop-loss/components/stop-loss";
import Strategy from "../app/strategy/components/strategy";
import TrailingTrade from "../app/trailing-order/trailing-trade";
import UserSettingsTabs from "../app/user-settings/components/user-settings-tabs";
import Wallet from "../app/wallet/components/wallet";
const appRoutePrefix = "/app";

export const routes = {
  unProtectedRoutes: {
    loginTemp: {
      path: "/",
      fullPath: "/",
      component: Login,
      exact: true,
    },
    login: {
      path: "/login",
      fullPath: "/login",
      component: Login,
    },
    register: {
      path: "/register",
      fullPath: "/register",
      component: Register,
    },
    registerWithParam: {
      path: "/register/:role",
      fullPath: appRoutePrefix + "/register/:role",
      component: Register,
    },
    registerWithLink: {
      path: "/register/affiliate/:affiliateId",
      fullPath: appRoutePrefix + "/register/affiliate/:affiliateId",
      component: Register,
    },
    activate: {
      path: "/activate",
      fullPath: "/activate",
      requiredQParams: ["token", "userId"],
      component: Activate,
    },
    forgotPassword: {
      path: "/forgot-password",
      fullPath: "/forgot-password",
      component: ForgotPassword,
    },
    setPassword: {
      path: "/set-password",
      fullPath: "/set-password",
      requiredQParams: ["token", "email"],
      component: SetPassword,
    },
  },
  protectedRoutes: {
    dashboard: {
      path: "dashboard",
      fullPath: appRoutePrefix + "/dashboard",
      pageTitle: "NAVIGATION.DASHBOARD",
      component: Dashboard,
    },
    strategy: {
      path: "strategy",
      fullPath: appRoutePrefix + "/strategy",
      component: Strategy,
    },
    editProfile: {
      path: "edit-profile",
      fullPath: appRoutePrefix + "/edit-profile",
      component: EditProfile,
    },
    botsOverview: {
      path: "bots-overview",
      fullPath: appRoutePrefix + "/bots-overview",
      component: BotsOverview,
    },
    botsCreate: {
      path: "bots-create",
      fullPath: appRoutePrefix + "/bots-create",
      component: BotsCreate,
    },
    botEdit: {
      path: "bots",
      fullPath: appRoutePrefix + "/bots/:id",
      component: BotsEdit,
    },
    orderOverview: {
      path: "order-overview",
      fullPath: appRoutePrefix + "/order-overview",
      component: OrderOverview,
    },
    botOrderOverview: {
      path: "bot-order-overview",
      fullPath: appRoutePrefix + "/bot-order-overview/:id",
      component: BotOrderOverview,
    },
    smartTradeView: {
      path: "smart-trade-create",
      fullPath: appRoutePrefix + "/smart-trade-create",
      component: SmartTrade,
    },
    smartTradeEdit: {
      path: "smart-trade",
      fullPath: appRoutePrefix + "/smart-trade/:id",
      component: SmartTrade,
    },
    smartTradeOverviewView: {
      path: "smart-trade-overview",
      fullPath: appRoutePrefix + "/smart-trade-overview",
      component: SmartTradeOverview,
    },
    trailingOrderView: {
      path: "trailing-orders",
      fullPath: appRoutePrefix + "/trailing-orders",
      component: TrailingTrade,
    },
    marketOrdersView: {
      path: "market-orders",
      fullPath: appRoutePrefix + "/market-orders",
      component: MarketOrders,
    },
    stopLossView: {
      path: "stop-loss",
      fullPath: appRoutePrefix + "/stop-loss",
      component: StopLoss,
    },
    campaign: {
      path: "campaign",
      fullPath: appRoutePrefix + "/campaign",
      component: Campaign,
    },
    campaignEdit: {
      path: "campaign-edit",
      fullPath: appRoutePrefix + "/campaign-edit/:id",
      component: Campaign,
    },
    campaignOverview: {
      path: "campaign-overview",
      fullPath: appRoutePrefix + "/campaign-overview",
      component: CampaignOverview,
    },
    campaignContest: {
      path: "campaign-contest",
      fullPath: appRoutePrefix + "/campaign-contest",
      component: CampaignContest,
    },
    wallet: {
      path: "wallet",
      fullPath: appRoutePrefix + "/wallet",
      component: Wallet,
    },
    invoices: {
      path: "invoices",
      fullPath: appRoutePrefix + "/invoices",
      component: Invoice,
    },
    changelog: {
      path: "change-log",
      fullPath: appRoutePrefix + "/change-log",
      component: ChangeLog,
    },
    contest: {
      path: "contest",
      fullPath: appRoutePrefix + "/contest",
      component: Contest,
    },
    userSettings: {
      path: "user-settings",
      fullPath: appRoutePrefix + "/user-settings",
      component: UserSettingsTabs,
    },
  },
};

export const defaultRoute = "/";
