export default class MathHelper {

  static calculatePowerOfTen(number) {
    number = number.toString();
    let [integer, decimal] = number.split('.');

    if (!decimal || decimal.match(/^0+$/)) {
      return 100;
    }

    decimal = decimal.replace(/0+$/, '');
    let power = Math.pow(10, decimal.length);

    return Math.max(100, power);
  }
}
