import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";

import { INITIAL_SELL_LIMIT_DATA } from "../constants";

import Button from "../../../common/form/button/components/button";
import InputNumber from "../../../common/form/input/components/number-input";
import NotificationProvider from "../../../core/notification";
import TabGroup from "../../../shared/components/tab-group/tab-group";

const SellLimitModal = (
  { show, onClose, getAvailableUserCoins, getAvailableUserMarkets, assetName, marketName, apiKeyId, quantity, marketTotal, type, getAssetPrice, onSave },
  { t }
) => {
  const [buyLimitData, setBuyLimitData] = useState(INITIAL_SELL_LIMIT_DATA);
  const [sellLimitData, setSellLimitData] = useState(INITIAL_SELL_LIMIT_DATA);

  const avblCoins = getAvailableUserCoins();
  const avblMarkets = getAvailableUserMarkets();

  useEffect(() => {
    if (marketName === "" || assetName === "" || !apiKeyId) return;

    async function fillAssetAmount() {
      const price = await getAssetPrice(marketName, assetName, apiKeyId);

      if (type === 0) {
        const quantity = Number(marketTotal) / price;
        setBuyLimitData({ ...buyLimitData, price, quantity, total: marketTotal });
      }
      else {
        const total = Number(quantity) * price;
        setSellLimitData({ ...sellLimitData, price, quantity, total });
      }
    }

    fillAssetAmount();

    // eslint-disable-next-line
  }, [assetName, marketName, apiKeyId]);

  const onBuyAmountChange = (e) => {
    const { value } = e.target;
    if (!value)
      return setBuyLimitData({
        ...buyLimitData,
        total: "",
        quantity: 0,
      });

    setBuyLimitData({
      ...buyLimitData,
      quantity: parseFloat(value),
      price: marketTotal / value
    });
  };

  const onBuyTotalChange = (e) => {
    const { value } = e.target;
    if (!value)
      return setBuyLimitData({
        ...buyLimitData,
        total: "",
        quantity: 0,
      });

    if (parseFloat(value) > marketTotal) {
      return NotificationProvider.warning("Value is greater than available");
    }

    setBuyLimitData({
      ...buyLimitData,
      quantity: Number(value) / buyLimitData.price,
      total: parseFloat(value),
    });
  };

  const onBuyPriceChange = (e) => {
    const { value } = e.target;

    setBuyLimitData({
      ...buyLimitData,
      price: value,
      quantity: marketTotal / value
    });
  }

  const onSellAmountChange = (e) => {
    const { value } = e.target;
    if (!value)
      return setSellLimitData({
        ...sellLimitData,
        total: "",
        quantity: 0,
      });

    if (parseFloat(value) > quantity) {
      return NotificationProvider.warning("Value is greater than available");
    }

    setSellLimitData({
      ...sellLimitData,
      quantity: parseFloat(value),
      total: Number(value) * sellLimitData.price,
    });
  };

  const onSellTotalChange = (e) => {
    const { value } = e.target;
    if (!value)
      return setSellLimitData({
        ...sellLimitData,
        total: "",
        quantity: 0,
      });

    setSellLimitData({
      ...sellLimitData,
      price: Number(value) / sellLimitData.quantity,
      total: parseFloat(value),
    });
  };

  const onSellPriceChange = (e) => {
    const { value } = e.target;

    setSellLimitData({
      ...sellLimitData,
      price: value,
      total: quantity * value
    });
  }

  const onSellOrder = () => {
    onSave({ ...sellLimitData, assetName, marketName, side: 1 });
  };

  const onBuyOrder = () => {
    onSave({ ...buyLimitData, assetName, marketName, side: 0 });
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{t("CAMPAIGN.SELL_LIMIT_ACTION_MODAL.TITLE")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-around">
          {type === 0 &&
            <Col md={5} className="mb-4 pt-4 pb-4 modal-box-content">
              <div className="input-group mb-3 align-items-center justify-content-between">
                <h6 className="mb-1 pr-5">Buy</h6>
                <span className="mr-3" style={{ fontSize: 16, fontWeight: 600 }}>
                  {avblMarkets} {marketName}
                </span>
              </div>
              <TabGroup tabs={["Limit"]} setTab={() => { }} selectedTab={"Limit"} />

              <div className="input-group mb-3">
                <span className="input-group-text input-form-border">Price</span>
                <InputNumber name="price" value={buyLimitData.price} onChange={onBuyPriceChange} />
                <span style={{ fontWeight: 700 }} className="input-group-text input-end-form-border">
                  {marketName || "N/A"}
                </span>
              </div>
              <div className="input-group mb-5">
                <span className="input-group-text input-form-border">Amount</span>
                <InputNumber name="quantity" value={buyLimitData.quantity} onChange={onBuyAmountChange} />
                <span style={{ fontWeight: 700 }} className="input-group-text input-end-form-border">
                  {assetName || "N/A"}
                </span>
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text input-form-border">Total</span>
                <InputNumber name="total" value={buyLimitData.total} onChange={onBuyTotalChange} disabled={true} />
                <span style={{ fontWeight: 700 }} className="input-group-text input-end-form-border">
                  {marketName || "N/A"}
                </span>
              </div>
              <button onClick={onBuyOrder} type="button" className="btn btn-success w-100 mb-2">
                Buy {assetName}
              </button>
            </Col>
          }
          {type === 1 &&
            <Col md={5} className="mb-4 pt-4 pb-4 modal-box-content">
              <div className="input-group mb-3 align-items-center justify-content-between">
                <h6 className="mb-1 pr-5">Sell</h6>
                <span className="mr-3" style={{ fontSize: 16, fontWeight: 600 }}>
                  {avblCoins} {assetName}
                </span>
              </div>
              <TabGroup tabs={["Limit"]} setTab={() => { }} selectedTab={"Limit"} />
              <div className="input-group mb-3">
                <span className="input-group-text input-form-border">Price</span>
                <InputNumber name="price" value={sellLimitData.price} onChange={onSellPriceChange} />
                <span style={{ fontWeight: 700 }} className="input-group-text input-end-form-border">
                  {marketName || "N/A"}
                </span>
              </div>
              <div className="input-group mb-5">
                <span className="input-group-text input-form-border">Amount</span>
                <InputNumber name="quantity" value={sellLimitData.quantity} onChange={onSellAmountChange} disabled={true} />
                <span style={{ fontWeight: 700 }} className="input-group-text input-end-form-border">
                  {assetName || "N/A"}
                </span>
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text input-form-border">Total</span>
                <InputNumber name="total" value={sellLimitData.total} onChange={onSellTotalChange} />
                <span style={{ fontWeight: 700 }} className="input-group-text input-end-form-border">
                  {marketName || "N/A"}
                </span>
              </div>
              <button onClick={onSellOrder} type="button" className="btn btn-danger w-100">
                Sell {assetName}
              </button>
            </Col>
          }
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SellLimitModal.contextTypes = {
  t: PropTypes.func,
};

export default SellLimitModal;
