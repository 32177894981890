import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPrint } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../common/form/button/components/button";

import { INVOICE_COLUMNS } from "../constants/invoice-constants";
import TradeTable from "../../../common/form/table/trade-table";
import Helpers from "../../../common/helpers/error-helpers";
import Loader from "../../../common/loader/components/common-loader";
import UserHelper from "../../../common/helpers/user-helper";

import invoiceApi from "../../../api/invoice/actions";
import AppPagination from "../../../shared/components/pagination/pagination";
import { PAGE_SIZE_DEFAULT } from "../../../common/constants";
import CommonHelper from "../../../common/helpers/common-helper";
import NotificationProvider from "../../../core/notification";
import CreateInvoiceModal from "../modals/create-invoice-modal";

import "../style/invoice.scss";

const Invoice = (props, { t }) => {
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT);
  const [pageNumber, setPageNumber] = useState(1);
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    getAllInvoices();
    // eslint-disable-next-line
  }, [pageSize, pageNumber]);

  const getAllInvoices = async () => {
    try {
      const { data } = await invoiceApi.getAllInvoices({
        params: {
          pageNumber,
          pageSize,
        },
      });
      data.data = data.data.map(d => ({ ...d, isSelected: selectedItems.some(item => item === d.id) || selectAll }));
      setInvoices(data);
      setIsLoading(false);
    } catch (error) {
      Helpers.parseError(error);
      setIsLoading(false);
    }
  };

  const printInvoiceDetails = async ({ id, name }) => {
    try {
      const params = { params: { invoiceId: id } };
      const { data } = await invoiceApi.getInvoiceDetails(params);
      CommonHelper.openPdfFile(data, name);
    } catch (error) {
      Helpers.parseError(error);
    }
  };

  const printInvoiceMain = async ({ id, name }) => {
    try {
      const params = { params: { invoiceId: id } };
      const { data } = await invoiceApi.getInvoiceMain(params);
      CommonHelper.openPdfFile(data, name);
    } catch (error) {
      Helpers.parseError(error);
    }
  };

  const downloadMainPdf = async ({ id, name }) => {
    try {
      const params = { params: { invoiceId: id } };
      const { data } = await invoiceApi.getInvoiceMain(params);
      CommonHelper.downloadPdfFile(data, name);
    } catch (error) {
      Helpers.parseError(error);
    }
  };

  const downloadDetailsPdf = async ({ id, name }) => {
    try {
      const params = { params: { invoiceId: id } };
      const { data } = await invoiceApi.getInvoiceDetails(params);
      CommonHelper.downloadPdfFile(data, name);
    } catch (error) {
      Helpers.parseError(error);
    }
  };

  const payInvoice = async ({ id }) => {
    try {
      await invoiceApi.payInvoice({ invoiceId: id });
      getAllInvoices();
    } catch (error) {
      Helpers.parseError(error);
    }
  };

  const toggleCreateInvoiceModal = () => {
    setShowCreateInvoiceModal((showModal) => !showModal);
  };

  const createInvoice = async (invoiceData) => {
    try {
      await invoiceApi.createInvoice({ ...invoiceData });
      getAllInvoices();
      toggleCreateInvoiceModal();
      NotificationProvider.success("Invoice successfully created");
    } catch (err) {
      Helpers.parseError(err);
      toggleCreateInvoiceModal();
    }
  };

  const generateDtaContent = async () => {
    try {
      const dtaContent = await invoiceApi.getDtaContent(selectedItems);
        CommonHelper.downloadBlob(dtaContent, 'dtaus1.txt');
    } catch (err) {
      Helpers.parseError(err);
    }
  }

  const updateDtaSelection = async (id, isChecked) => {
    const index = invoices.data.findIndex(d => d.id === id);
    invoices.data[index].isSelected = isChecked;
    setSelectedItems(isChecked ? [...selectedItems, id] : selectedItems.filter(item => item !== id));
  }

  const toggleAll = async (isChecked) => {
    setSelectAll(isChecked);
    invoices.data.forEach(d => d.isSelected = isChecked);
    setSelectedItems(isChecked ? invoices.data.map(d => d.id) : []);
  }

  return (
    <div className="invoice-layout">
      <h4 className="page-title">{t("INVOICE.PAGE-TITLE")}</h4>
      <div className="d-flex flex-row align-items-center">
        {UserHelper.isAdminUser() && <div className="d-flex w-25">
          <Button variant="outline-primary" type="button" size="md" onClick={generateDtaContent} disabled={!selectedItems || !selectedItems.length}>
            <FontAwesomeIcon icon={faPrint} /> Generate DTA
          </Button>
        </div>}
        <AppPagination
          page={pageNumber}
          totalPages={invoices.totalPages || 1}
          pageSize={pageSize}
          setPageSize={setPageSize}
          handlePaginationClick={(_, value) => setPageNumber(value)}
        />
      </div>
      <TradeTable
        data={invoices.data}
        columnDefs={INVOICE_COLUMNS(
          printInvoiceDetails,
          printInvoiceMain,
          downloadMainPdf,
          downloadDetailsPdf,
          payInvoice,
          updateDtaSelection,
          toggleAll
        )}
        onRowClick={(e) => updateDtaSelection(e.id, !e.isSelected)}
      />
      {
        UserHelper.isAdminUser() && (
        <Button variant="outline-primary" className="mt-4" type="button" size="md" onClick={toggleCreateInvoiceModal}>
          <FontAwesomeIcon icon={faPlus} /> Add  invoice
        </Button>)
      }
      <Loader loading={isLoading} type="global" />
      {showCreateInvoiceModal && (
        <CreateInvoiceModal
          show={showCreateInvoiceModal}
          onClose={toggleCreateInvoiceModal}
          onSave={createInvoice}
        />
      )}
    </div>
  );
};

Invoice.contextTypes = {
  t: PropTypes.func,
};

export default Invoice;
