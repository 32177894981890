import PropTypes from 'prop-types';
import Nav from 'react-bootstrap/Nav';
import { Link, withRouter } from 'react-router-dom';
import '../style/user-settings.scss';
import UserSettings from './user-settings';
import UserAffiliate from './user-affiliates';

const Tab = {
  UserSettings: 'user-settings',
  UserAffiliates: 'user-affiliates',
};

const UserSettingsTabs = (props, { t }) => {
  const { location } = props;
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get('tab') || Tab.UserSettings;

  const isActiveTab = (tab) => {
    return activeTab === tab;
  };

  const setActiveLink = (tab) => {
    return activeTab === tab ? 'active' : '';
  };

  return (
    <div className=".user-settings-layout w-100" style={{ margin: 'auto' }}>
      <h4 className="page-title d-flex">Settings</h4>
      <Nav fill variant="tabs" defaultActiveKey="/home">
        <Nav.Item>
          <Link
            className={`nav-link ${setActiveLink(
              Tab.UserSettings
            )} d-flex link-info-button`}
            to={`?tab=${Tab.UserSettings}`}
          >
            {t('USER-SETTINGS.FORM.PAGE-TITLE')}
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link
            className={`nav-link ${setActiveLink(
              Tab.UserAffiliates
            )} d-flex link-info-button`}
            to={`?tab=${Tab.UserAffiliates}`}
          >
            {t('USER-SETTINGS.TAB.USER_AFFILIATES')}
          </Link>
        </Nav.Item>
      </Nav>

      {isActiveTab(Tab.UserSettings) && <UserSettings />}

      {isActiveTab(Tab.UserAffiliates) && <UserAffiliate />}
    </div>
  );
};

UserSettingsTabs.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(UserSettingsTabs);
