import React from "react";
import userApi from "../../../api/user/actions";
import { ORDER_MODULE } from "../../../common/constants";
import styles from "./styles.module.css";

class OrderBookTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marketName: props.marketName,
      assetName: props.assetName,
      exchangeId: props.exchangeId,
      keyId: props.keyId,
      asks: [],
      bids: [],
      totalAmount: props.totalAmount,
      orderType: props.orderType,
      isEdit: props.isEdit
    };

    this.asksBuffer = {};
    this.bidsBuffer = {};
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.keyId !== prevState.keyId
      || nextProps.marketName !== prevState.marketName
      || nextProps.assetName !== prevState.assetName
      || nextProps.exchangeId !== prevState.exchangeId
      || nextProps.totalAmount !== prevState.totalAmount
      || nextProps.orderType !== prevState.orderType) {
      return {
        keyId: nextProps.keyId,
        marketName: nextProps.marketName,
        assetName: nextProps.assetName,
        exchangeId: nextProps.exchangeId,
        totalAmount: nextProps.totalAmount,
        orderType: nextProps.orderType
      };
    }

    return null;
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      let sortedAsks = Object.values(this.asksBuffer).sort((a, b) => a.price - b.price);
      let sortedBids = Object.values(this.bidsBuffer).sort((a, b) => b.price - a.price);

      this.setState({
        asks: sortedAsks,
        bids: sortedBids
      });
    }, 1000);
  }

  componentWillUnmount() {
    if (this.socket) {
      this.socket.close();
    }
    clearInterval(this.intervalId);
  }

  updateOrderBook = (prevOrders, updates) => {
    let newOrders = { ...prevOrders };

    updates.forEach(update => {
      const price = this.props.exchangeId === 2 ? update.p : update[0];
      const quantity = this.props.exchangeId === 2 ? update.v : update[1];

      if (quantity == 0) {
        delete newOrders[price];
      } else {
        newOrders[price] = { price, quantity };
      }
    });

    return newOrders;
  };

  updateOrderBookFromResponse = (updates) => {
    let newOrders = { };

    updates.forEach(update => {
      const price = update.price;
      const quantity = update.quantity;

      if (quantity == 0) {
        delete newOrders[price];
      } else {
        newOrders[price] = { price, quantity };
      }
    });

    return newOrders;
  };

  isEmpty = (str) => {
    return !str || str.length === 0;
  }

  componentDidUpdate(newState, prevState) {
    if (this.isEmpty(this.props.keyId) || this.isEmpty(this.props.marketName) || this.isEmpty(this.props.assetName)) return;

    if (this.props.keyId !== prevState.keyId
      || this.props.marketName !== prevState.marketName
      || this.props.assetName !== prevState.assetName
      || this.props.exchangeId !== prevState.exchangeId
      || (this.state.isEdit && !this.socket)) {

      if (this.socket) {
        this.socket.close();
      }

      const symbol = this.props.assetName + this.props.marketName;

      userApi.getOrderBook(this.props.keyId, symbol)
        .then(response => {
          this.asksBuffer = this.updateOrderBookFromResponse(response.data.asks);
          this.bidsBuffer = this.updateOrderBookFromResponse(response.data.bids);

          if (this.props.exchangeId === 2) { // MEXC
            this.socket = new WebSocket(`wss://wbs.mexc.com/ws`);
            this.socket.onopen = () => {
              this.socket.send(JSON.stringify({
                method: 'SUBSCRIPTION',
                params: [`spot@public.increase.depth.v3.api@${symbol.toUpperCase()}`]
              }));
            }
          } else { // Binance
            this.socket = new WebSocket(`wss://stream.binance.com:9443/ws/${symbol.toLowerCase()}@depth@1000ms`);
          }

          this.socket.onmessage = e => {
            const message = JSON.parse(e.data);

            if (this.props.exchangeId === 2) { // MEXC
              if (message?.d?.e !== "spot@public.increase.depth.v3.api") {
                return;
              }

              if (!!message?.d.bids) {
                this.bidsBuffer = this.updateOrderBook(this.bidsBuffer, message.d.bids);
              }

              if (!!message?.d.asks) {
                this.asksBuffer = this.updateOrderBook(this.asksBuffer, message.d.asks);
              }
            } else { // Binance
              if (message.e !== "depthUpdate") {
                return;
              }

              this.asksBuffer = this.updateOrderBook(this.asksBuffer, message.a);
              this.bidsBuffer = this.updateOrderBook(this.bidsBuffer, message.b);
            }
          };
        })
        .catch(e => {
          console.error(e);
          this.setState({
            asks: [],
            bids: []
          });
        });
    }
  }

  roundNumber = (num, digits) => {
    if (isNaN(num)) return;
    return num.toFixed(digits);
  }

  displayOrderDetails = (data, color, type) => {
    const dataArray = Object.values(data);
    let runningTotal = 0;

    return dataArray.length > 0
      ? dataArray.map((item, idx) => {
        const total = this.roundNumber(item.price * item.quantity, 4);
        runningTotal += +total;
        const isHighlighted = this.state.orderType === type && runningTotal - total < this.state.totalAmount;
        return (
          <tr key={idx} style={isHighlighted ? { backgroundColor: "gray" } : null}>
            <td style={{ width: "33%", color }}>{item.price}</td>
            <td style={{ width: "33%", textAlign: "right" }}>{item.quantity}</td>
            <td style={{ width: "33%", textAlign: "right" }}>{total}</td>
          </tr>
        );
      })
      : this.renderNoDataPreview(color);
  };

  renderNoDataPreview = (color) => {
    return Array(4)
      .fill()
      .map((i, idx) => (
        <tr key={idx}>
          <td style={{ width: "33%", color }}>-----</td>
          <td style={{ width: "33%", textAlign: "right" }}>-----</td>
          <td style={{ width: "33%", textAlign: "right" }}>-----</td>
        </tr>
      ));
  };

  render() {
    return (
      <><div className="mb-4 w-50 order-book-table">
        <table style={{ width: "100%" }}>
          <thead>
            <tr style={{ borderBottom: "1px solid #000" }}>
              <td style={{ width: "33%", color: "#97a0b3" }}>
                Price({this.props.marketName || "N\\A"})
              </td>
              <td
                style={{ width: "33%", textAlign: "center", color: "#97a0b3" }}
              >
                Amount({this.props.assetName || "N\\A"})
              </td>
              <td
                style={{ width: "33%", textAlign: "right", color: "#97a0b3" }}
              >
                Total
              </td>
            </tr>
          </thead>
        </table>
        <div
          style={{ maxHeight: "416px", overflowY: "auto" }}
          className="custom-scroll"
        >
          <table
            className={`table-striped ${styles.fontOrderBook}`}
            style={{ width: "100%" }}
          >
            <tbody>{this.displayOrderDetails(this.state.asks, "#62BEB6", ORDER_MODULE.Sell)}</tbody>
          </table>
        </div>
      </div>
        <div className="mb-4 w-50 order-book-table">
          <table style={{ width: "100%" }}>
            <thead>
              <tr style={{ borderBottom: "1px solid #000" }}>
                <td style={{ width: "33%", color: "#97a0b3" }}>
                  Price({this.props.marketName || "N\\A"})
                </td>
                <td
                  style={{ width: "33%", textAlign: "center", color: "#97a0b3" }}
                >
                  Amount({this.props.assetName || "N\\A"})
                </td>
                <td
                  style={{ width: "33%", textAlign: "right", color: "#97a0b3" }}
                >
                  Total
                </td>
              </tr>
            </thead>
          </table>
          <div
            style={{ maxHeight: "417px", overflowY: "auto" }}
            className="custom-scroll"
          >
            <table
              className={`table-striped ${styles.fontOrderBook}`}
              style={{ width: "100%", fontSize: 14 }}
            >
              <tbody>{this.displayOrderDetails(this.state.bids, "#F48D8B", ORDER_MODULE.Buy)}</tbody>
            </table>
          </div>
        </div>
      </>      
    );
  }
}

export default OrderBookTable;