import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";

import botOrderApi from "../../../api/trade-order/actions";
import { getAssetPrice, handleGetUserAssets } from "../../user-assets/actions/user-assets-actions";

import { ORDER_TYPE, PAGE_SIZE_DEFAULT } from "../../../common/constants";
import InputNumber from "../../../common/form/input/components/number-input";
import TradeTable from "../../../common/form/table/trade-table";
import { useHelpModus } from "../../../common/help-modus/help-modus-context";
import HelpModusModal from "../../../common/help-modus/help.modus-modal";
import Helpers from "../../../common/helpers/error-helpers";
import Chart from "../../../common/trading-chart/chart";
import NotificationProvider from "../../../core/notification";
import ApiKey from "../../../shared/components/api-key/api-key";
import AppPagination from "../../../shared/components/pagination/pagination";
import {
  INITIAL_MARKET_ORDERS_ORDER_DATA,
  INITIAL_ORDER_COUNT,
  MARKET_ORDERS_ORDER_COLUMNS,
  ORDER_STATUS_ACTIVE,
  ORDER_STATUS_ALL
} from "../constants";

import barImage from "../../../common/assets/help-modus/help-modus-chart-bar.png";
import chartImage from "../../../common/assets/help-modus/help-modus-chart.png";

import NumberLabel from "../../../common/form/number-label/number-label";
import CommonHelper from "../../../common/helpers/common-helper";
import "../style/market-orders.scss";

const MarketOrders = (props, { t }) => {
  const { getUserAssets, userAssets, getAssetPrice } = props;

  const [marketPrice, setMarketPrice] = useState("");
  const [sellMarketPrice, setSellMarketPrice] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [userCurrentMarketValue, setUserCurrentMarketValue] = useState("N\\A");
  const [userCurrentCoinValue, setUserCurrentCoinValue] = useState("N\\A");
  const [order, setOrder] = useState(INITIAL_MARKET_ORDERS_ORDER_DATA);
  const [tab, setTab] = useState(0);
  const [orderTab, setOrderTab] = useState(1);
  const [orderCount, setOrderCount] = useState(INITIAL_ORDER_COUNT);
  const [exchangeId, setExchangeId] = useState(0);

  const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const [showHelpModusModal, setShowHelpModusModal] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [modalTitle, setModalTitle] = useState('Information');
  const [isMobile] = useState(window.innerWidth < 768);
  const { isHelpModus } = useHelpModus();

  const columns = MARKET_ORDERS_ORDER_COLUMNS;
  const initialWidth = window.innerWidth;

  const handleOrientationChange = () => {
    if (window.innerWidth !== initialWidth) {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleOrientationChange);
    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    getOrders();

    // eslint-disable-next-line
  }, [orderTab, pageNumber, pageSize]);

  useEffect(() => {
    setOrder({
      ...order,
      total: CommonHelper.toRegularFormat(order.quantity * marketPrice),
    });

    // eslint-disable-next-line
  }, [marketPrice]);

  useEffect(() => {
    setOrder({
      ...order,
      sellTotal: CommonHelper.toRegularFormat(order.sellQuantity * sellMarketPrice),
    });

    // eslint-disable-next-line
  }, [sellMarketPrice]);

  const getOrders = async () => {
    try {
      setIsLoading(true);
      const response = await botOrderApi.getOrders({
        params: {
          orderTypeId: ORDER_TYPE.Market,
          historyStatusId: getOrderStatusFilter(),
          pageNumber,
          pageSize,
        },
      });
      setOrderData(response.data);
      setOrderCount({
        openOrders:
          orderTab === 0 ? response.data.totalRecords : orderCount.openOrders,
        orderHistory: orderTab
          ? response.data.totalRecords
          : orderCount.orderHistory,
      });
      setIsLoading(false);
    } catch (err) {
      Helpers.parseError(err);
      setOrderData([]);
      setOrderCount(INITIAL_ORDER_COUNT);
      setIsLoading(false);
    }
  };

  const getOrderStatusFilter = () => {
    if (orderTab === 0) return ORDER_STATUS_ACTIVE;
    return ORDER_STATUS_ALL;
  };

  const handleMarketSelectChange = (value) => {
    if (!value) {
      return setMarketPrice("");
    }

    let marketAmount = userAssets.markets.find((element) => element.name === value);
    setUserCurrentMarketValue(marketAmount === undefined ? 0 : marketAmount.amountAvailable);

    order.assetName && fillAssetPrice(value, order.assetName, (assetPrice) => setMarketPrice(CommonHelper.toRegularFormat(assetPrice)));
  };

  const handleSellMarketSelectChange = (value) => {
    if (!value) {
      return setSellMarketPrice("");
    }
    order.assetName && fillAssetPrice(value, order.assetName, (assetPrice) => setSellMarketPrice(CommonHelper.toRegularFormat(assetPrice)));
  };

  const onCoinChange = (value) => {
    if (!value) {
      return setMarketPrice("");
    }

    let coinAmount = userAssets.assets.find((element) => element.name === value);

    setUserCurrentCoinValue(coinAmount === undefined ? 0 : coinAmount.amountAvailable);

    order.marketName && fillAssetPrice(order.marketName, value, (assetPrice) => setMarketPrice(CommonHelper.toRegularFormat(assetPrice)));
    order.marketName && fillAssetPrice(order.marketName, value, (assetPrice) => setSellMarketPrice(CommonHelper.toRegularFormat(assetPrice)));
  };

  const fillAssetPrice = async (marketName, assetName, callback) => {
    let assetPrice = await getAssetPrice(marketName, assetName, order.apiKeyId);
    callback(assetPrice);
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setOrder({ ...order, [name]: value });
  };

  const onNumberChangeHandler = (e) => {
    const { name, value } = e.target;
    setOrder({ ...order, [name]: +value });
  };

  const onBuyOrder = async () => {
    try {
      const request = { ...order, price: marketPrice, side: 0 };
      await botOrderApi.createOrder(request);
      getOrders();
      NotificationProvider.success("Order created");
    } catch (err) {
      Helpers.parseError(err);
      getOrders();
    }
  };

  const onSellOrder = async () => {
    try {
      const request = {
        ...order,
        price: sellMarketPrice,
        quantity: order.sellQuantity,
        total: order.sellTotal,
        side: 1,
      };
      await botOrderApi.createOrder(request);
      getOrders();
      NotificationProvider.success("Order created");
    } catch (err) {
      Helpers.parseError(err);
      getOrders();
    }
  };

  const renderSellOrderTabs = () => {
    return (
      <ul className="nav nav-tabs pt-2">
        {/*<li className="nav-item">
          {/* eslint-disable-next-line 
          <a className={`nav-link text-bold${orderTab === 0 ? " active" : ""}`} href="#" onClick={() => setOrderTab(0)}>
            Open Orders ({orderCount.openOrders})
          </a>
        </li>*/}
        <li className="nav-item">
          {/* eslint-disable-next-line */}
          <a className={`nav-link text-bold${orderTab === 1 ? " active" : ""}`} href="#" onClick={() => setOrderTab(1)}>
            Order History ({orderCount.orderHistory})
          </a>
        </li>
        {isHelpModus && (
          <div className="infoButton asset-info-btn" style={{ fontSize: '16px' }}
            onClick={() => {
              const messageContent = (
                <div>
                  <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.SMART-TRADE-ORDERS') }} />
                </div>
              );
              toggleHelpModusModal('Open Orders/Order History', messageContent);
            }}>
            <FontAwesomeIcon className="row" icon={faInfoCircle} />
          </div>
        )}
      </ul>
    );
  };

  const toggleHelpModusModal = (title, message) => {
    setShowHelpModusModal((showModal) => !showModal);
    setModalMessage(message);
    setModalTitle(title);
  };

  return (
    <div className="smart-trade-layout">
      <h4 className="page-title d-flex">
        Market Orders
        {isHelpModus && (
          <div className="infoButton avblInfoButton" style={{ fontSize: '16px' }}
            onClick={() => {
              const messageContent = (
                <div>
                  <p>{t('HELP-MODUS.SMART-TRADE-TITLE')}</p>
                </div>
              );
              toggleHelpModusModal('Market Orders', messageContent);
            }}>
            <FontAwesomeIcon className="row" icon={faInfoCircle} />
          </div>
        )}
      </h4>
      <div className="d-flex">
        {!isMobile && <div className="pr-3 smart-trade-chart mb-1 d-flex" style={{ height: 500, width: "100%" }}>
            <Chart height={497} marketName={order.marketName} assetName={order.assetName} exchangeId={exchangeId} />
            {isHelpModus && (
              <div className="infoButton asset-info-btn"
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p>{t('HELP-MODUS.CREATE-BOT-CHART-FIRST')}</p>
                      <img src={barImage} style={{ width: '25%' }} alt="Create Bot Chart Bar" />
                      <p><br></br>{t('HELP-MODUS.CREATE-BOT-CHART-SECOND')}</p>
                      <img src={chartImage} style={{ width: '100%' }} alt="Create Bot Chart Bar" />
                      <p><br></br>{t('HELP-MODUS.CREATE-BOT-CHART-THIRD')}</p>
                    </div>
                  );
                  toggleHelpModusModal('Chart', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </div>}
        <div className="smart-trade-form">
          <div className="fieldRow" style={{ justifyContent: 'right' }}>
            <span className="mr-2 asset-amount-lbl">Avbl</span>
            {(tab === 0 || !tab) && (
              <h5>
                <NumberLabel value={userCurrentMarketValue} maxFractions={10} /> {order.marketName}
              </h5>
            )}
            {tab === 1 && (
              <h5>
                <NumberLabel value={userCurrentCoinValue} maxFractions={10} /> {order.assetName}
              </h5>
            )}
            {isHelpModus && (
              <div className="infoButton avblInfoButton"
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.SMART-TRADE-AVBL') }} />
                    </div>
                  );
                  const messageTitle = (<>{t("AVBL")}/{t("REAL-AVBL")}</>);
                  toggleHelpModusModal(messageTitle, messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </div>
          <br/>
          <Row className="w-100 m-0">
            <Col md={12} lg={12} sm={12} xs={12} className="p-0">
              <div className="d-flex align-items-center justify-content-center">
                <ApiKey
                  classList="w-75"
                  onChange={(value, exchangeId, e) => {
                    onNumberChangeHandler(e);
                    setExchangeId(exchangeId);
                    value && getUserAssets(value);
                  }}
                  isHelpModus={isHelpModus}
                  onInfoIconClick={toggleHelpModusModal}
                  exchangeId={exchangeId}
                />
              </div>
              <div className="d-flex align-items-center justify-content-center mb-4 mt-2">
                <div className="input-group mb-3 w-50">
                  <select
                    name="assetName"
                    onChange={(e) => {
                      onChangeHandler(e);
                      onCoinChange(e.target.value);
                    }}
                    value={order.assetName}
                    className="form-control"
                  >
                    <option value="">Select Coin</option>
                    {userAssets.assets?.map((asset) => (
                      <option key={asset.id} value={asset.name}>
                        {asset.name}
                      </option>
                    ))}
                  </select>
                  {isHelpModus && (
                    <div className="infoButton asset-info-btn"
                      onClick={() => {
                        const messageContent = (
                          <div>
                            <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.SMART-TRADE-ASSET') }} />
                          </div>
                        );
                        toggleHelpModusModal('Asset', messageContent);
                      }}>
                      <FontAwesomeIcon className="row" icon={faInfoCircle} />
                    </div>
                  )}
                </div>
                <div className="vertical-line"></div>
                <div className="input-group mb-3 w-50">
                  <select
                    value={order.marketName}
                    name="marketName"
                    onChange={(e) => {
                      onChangeHandler(e);
                      handleMarketSelectChange(e.target.value);
                      handleSellMarketSelectChange(e.target.value);
                    }}
                    className="form-control"
                  >
                    <option value={""}>Select Market</option>
                    {userAssets.markets?.map((market) => (
                      <option key={market.id} value={market.name}>
                        {market.name}
                      </option>
                    ))}
                  </select>
                  {isHelpModus && (
                    <div className="infoButton asset-info-btn"
                      onClick={() => {
                        const messageContent = (
                          <div>
                            <p>{t('HELP-MODUS.CREATE-BOT-MARKET')}</p>
                          </div>
                        );
                        toggleHelpModusModal('Asset', messageContent);
                      }}>
                      <FontAwesomeIcon className="row" icon={faInfoCircle} />
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          {isMobile && <div className="smart-trade-chart mb-1 d-flex" style={{ height: 400, width: "100%" }}>
              <Chart height={400} marketName={order.marketName} assetName={order.assetName} exchangeId={exchangeId} />
              {isHelpModus && (
                <div className="infoButton asset-info-btn"
                  onClick={() => {
                    const messageContent = (
                      <div>
                        <p>{t('HELP-MODUS.CREATE-BOT-CHART-FIRST')}</p>
                        <img src={barImage} style={{ width: '25%' }} alt="Create Bot Chart Bar" />
                        <p><br></br>{t('HELP-MODUS.CREATE-BOT-CHART-SECOND')}</p>
                        <img src={chartImage} style={{ width: '100%' }} alt="Create Bot Chart Bar" />
                        <p><br></br>{t('HELP-MODUS.CREATE-BOT-CHART-THIRD')}</p>
                      </div>
                    );
                    toggleHelpModusModal('Chart', messageContent);
                  }}>
                  <FontAwesomeIcon className="row" icon={faInfoCircle} />
                </div>
              )}
            </div>}
          <div className="d-flex">
            <div className={`d-flex border-bottom align-items-center scalp-type`} style={{ width: '100%' }}>
              <div style={{ color: "#15ed1d" }} className={`text-center w-50 p-3 ${tab === 0 ? "active" : ""}`} onClick={() => setTab(0)}>
                Buy {order.assetName}
              </div>
              <div style={{ color: "red" }} className={`text-center w-50 p-3 ${tab === 1 ? "active" : ""}`} onClick={() => setTab(1)}>
                Sell {order.assetName}
              </div>
            </div>
            {isHelpModus && (
              <div className="infoButton asset-info-btn"
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.SMART-TRADE-BUY-SELL') }} />
                    </div>
                  );
                  toggleHelpModusModal('Buy/Sell', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </div>
          <Row className="w-100 m-0 p-0 pt-1">
            {tab === 0 && (
              <Col md={12} lg={12} sm={12} xs={12} className="p-0">
                <div className="input-group mb-3">
                  <span className="input-group-text input-form-border" style={{ justifyContent: 'space-between' }}>
                    Market price
                    {isHelpModus && (
                      <div className="infoButton labelInfoButton" style={{ right: '0' }}
                        onClick={() => {
                          const messageContent = (
                            <div>
                              <p>{t('HELP-MODUS.SMART-TRADE-MARKET-PRICE')}</p>
                            </div>
                          );
                          toggleHelpModusModal('Market price', messageContent);
                        }}>
                        <FontAwesomeIcon className="row" icon={faInfoCircle} />
                      </div>
                    )}
                  </span>
                  <InputNumber disabled defaultValue={marketPrice} type="text" className="form-control" />
                  <span style={{ fontWeight: 700, width: 70 }} className="input-group-text input-end-form-border">
                    {order.marketName || "N/A"}
                  </span>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text input-form-border" style={{ justifyContent: 'space-between' }}>
                    Amount
                    {isHelpModus && (
                      <div className="infoButton labelInfoButton" style={{ right: '0' }}
                        onClick={() => {
                          const messageContent = (
                            <div>
                              <p>{t('HELP-MODUS.SMART-TRADE-AMOUNT')}</p>
                            </div>
                          );
                          toggleHelpModusModal('Amount', messageContent);
                        }}>
                        <FontAwesomeIcon className="row" icon={faInfoCircle} />
                      </div>
                    )}
                  </span>
                  <InputNumber name="quantity" value={order.quantity}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!value) return setOrder({ ...order, total: '', quantity: '' });
                      setOrder({
                        ...order,
                        quantity: CommonHelper.toRegularFormat(value),
                        total: CommonHelper.toRegularFormat(Number(value) * marketPrice),
                      });
                    }} />
                  <span style={{ fontWeight: 700, width: 70 }} className="input-group-text input-end-form-border">
                    {order.assetName}
                  </span>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text input-form-border" style={{ justifyContent: 'space-between' }}>
                    Total
                    {isHelpModus && (
                      <div className="infoButton labelInfoButton" style={{ right: '0' }}
                        onClick={() => {
                          const messageContent = (
                            <div>
                              <p>{t('HELP-MODUS.SMART-TRADE-TOTAL')}</p>
                            </div>
                          );
                          toggleHelpModusModal('Total', messageContent);
                        }}>
                        <FontAwesomeIcon className="row" icon={faInfoCircle} />
                      </div>
                    )}
                  </span>
                  <InputNumber name="total" value={order.total}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!value) return setOrder({ ...order, total: '', quantity: '' });
                      setOrder({
                        ...order,
                        quantity: CommonHelper.toRegularFormat(Number(value) / marketPrice),
                        total: CommonHelper.toRegularFormat(value),
                      });
                    }} />
                  <span style={{ fontWeight: 700, width: 70 }} className="input-group-text input-end-form-border">
                    {order.marketName || "N/A"}
                  </span>
                </div>
                <div className="d-flex">
                  <button onClick={onBuyOrder} type="button" className="btn btn-success w-100 mb-3 mt-2" style={{ width: '100%' }}>
                    Buy {order.assetName}
                  </button>
                  {isHelpModus && (
                    <div className="infoButton avblInfoButton"
                      onClick={() => {
                        const messageContent = (
                          <div>
                            <p>{t('HELP-MODUS.SMART-TRADE-BUY-OR-SELL')}</p>
                          </div>
                        );
                        toggleHelpModusModal('Buy or Sell', messageContent);
                      }}>
                      <FontAwesomeIcon className="row" icon={faInfoCircle} />
                    </div>
                  )}
                </div>
              </Col>
            )}
            {tab === 1 && (
              <Col md={12} lg={12} sm={12} xs={12} className="p-0">
                <div className="input-group mb-3">
                  <span className="input-group-text input-form-border">Market price</span>
                  <InputNumber disabled defaultValue={sellMarketPrice} type="text" className="form-control" />
                  <span style={{ fontWeight: 700, width: 70 }} className="input-group-text input-end-form-border">
                    {order.marketName || "N/A"}
                  </span>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text input-form-border">Amount</span>
                  <InputNumber name="sellQuantity" value={order.sellQuantity}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!value)
                        return setOrder({
                          ...order,
                          sellTotal: "",
                          sellQuantity: 0,
                        });
                      setOrder({
                        ...order,
                        sellQuantity: CommonHelper.toRegularFormat(value),
                        sellTotal: CommonHelper.toRegularFormat(Number(value) * sellMarketPrice),
                      });
                    }} />
                  <span style={{ fontWeight: 700, width: 70 }} className="input-group-text input-end-form-border">
                    {order.assetName}
                  </span>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text input-form-border">Total</span>
                  <InputNumber name="sellTotal" value={order.sellTotal}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!value)
                        return setOrder({
                          ...order,
                          sellTotal: "",
                          sellQuantity: 0,
                        });
                      setOrder({
                        ...order,
                        sellQuantity: CommonHelper.toRegularFormat(Number(value) / sellMarketPrice),
                        sellTotal: CommonHelper.toRegularFormat(value),
                      });
                    }} />
                  <span style={{ fontWeight: 700, width: 70 }} className="input-group-text input-end-form-border">
                    {order.marketName || "N/A"}
                  </span>
                </div>
                <button onClick={onSellOrder} type="button" className="btn btn-danger w-100 mt-2 mb-3">
                  Sell {order.assetName}
                </button>
              </Col>
            )}
          </Row>
        </div>
      </div>

      {renderSellOrderTabs()}

      <TradeTable data={orderData.data} columnDefs={columns} sequenceStep={(pageNumber - 1) * pageSize} isLoading={isLoading} />
      <AppPagination
        page={pageNumber}
        totalPages={orderData.totalPages || 1}
        pageSize={pageSize}
        setPageSize={setPageSize}
        handlePaginationClick={(_, value) => setPageNumber(value)}
      />
      {showHelpModusModal && (
        <HelpModusModal
          show={showHelpModusModal}
          onClose={toggleHelpModusModal}
          title={modalTitle}
          message={modalMessage}
        />
      )}
    </div>
  );
};

MarketOrders.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userAssets: state.userAssetsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getUserAssets: (params) => dispatch(handleGetUserAssets(params)),
  getAssetPrice: (marketName, coinName, apiKeyId) => dispatch(getAssetPrice(marketName, coinName, apiKeyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketOrders);
