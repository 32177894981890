import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import Button from "../form/button/components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const HelpModusModal = (
  {
    show,
    onClose,
    title,
    message,
    size = 'xl'
  },
  { t }
) => {
  return (
    <Modal show={show} onHide={onClose} backdrop="static" size={size} className="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
          <FontAwesomeIcon className={"ml-1 pb-2"} style={{ color: 'darkgray' }} icon={faInfoCircle} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Footer>
        <Button className={"mt-2 pl-5 pr-5 mr-2"} variant="secondary" type="button" size="md" onClick={onClose}>
          {t("BOT_OVERVIEW.CLOSE")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

HelpModusModal.contextTypes = {
  t: PropTypes.func,
};

export default HelpModusModal;
