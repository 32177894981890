import React, { useState } from 'react';
import { useModal } from '../modal-context';
import Modal from 'react-bootstrap/Modal';

const DescriptionModal = () => {
  const [description, setDescription] = useState('');
  const { submitDescription, cancelSubmit, isVisible } = useModal();

  return (
    <Modal id="descriptionModal" show={isVisible} onHide={() => cancelSubmit('')} backdrop={false} size="md" centered>
      <Modal.Header closeButton style={{ border: 'none' }}>
        <Modal.Title>Add description</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ background: '#fff' }}>
        <textarea
          className="form-control"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={{ width: '100%', height: '100px' }}
        ></textarea>
      </Modal.Body>
      <Modal.Footer style={{ border: 'none' }}>
        <button className="btn btn-light" onClick={() => cancelSubmit()}>Cancel</button>
        <button className="btn btn-primary" onClick={() => submitDescription(description)}>Submit</button>
      </Modal.Footer>
    </Modal>
  );
};

export default DescriptionModal;