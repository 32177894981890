import actionTypes from "../constants/action-types";

export const setUserProfile = (data) => {
  return {
    type: actionTypes.HANDLE_USER_PROFILE,
    data,
  };
};

export const setUserApiKeys = (data) => {
  return {
    type: actionTypes.USER_API_KEYS,
    data,
  };
};
