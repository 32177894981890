import { toast } from "react-toastify";

const NotificationProvider = {};

NotificationProvider.success = (msg) => {
  toast.success(msg);
};

NotificationProvider.warning = (msg) => {
  toast.warning(msg);
};

NotificationProvider.error = (msg) => {
  toast.error(msg);
};

NotificationProvider.info = (msg) => {
  toast.info(msg);
};

export default NotificationProvider;
