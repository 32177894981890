import React from "react";
import PropTypes from "prop-types";

import { Navbar, Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { isEmpty } from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from '@fortawesome/free-solid-svg-icons';
import SubMenu from "./sub-menu";

import { leftMenuItems } from "../constants/constants";

import "../style/left-menu.scss";
import { history } from "../../..";

const LeftMenu = ({ mainMenuCollapsed, menuClick }, { t }) => {

  const linkClick = (link, isDisabled) => {
    if (isDisabled) {
      history.push('/app/edit-profile?tab=membership');
    }
    if (link && !isDisabled) {
      history.push(link);
      menuClick();
    }
  };

  const renderTooltip = () => (
    <Tooltip className="custom-tooltip">
      {t('UPGRADE_PACKAGE_TO_ACCESS')}
      <br /><br />
      {t('UPGRADE_PACKAGE_TO_ACCESS.CLICK')}
    </Tooltip>
  );

  return (
    <div className={`left-menu`}>
      <Navbar>
        {leftMenuItems.map((item, index) => {
          const isHidden = item.hidden ? item.hidden() : false;
          const isAvailable = item.isForAdmin ? !item.isForAdmin() : true;
          
          const renderNavItemTitle = () => (
            <div className={`nav-item-title ${isHidden ? 'disabled' : ''}`} onClick={() => linkClick(item.link, isHidden)} style={{ opacity: isHidden ? 0.5 : 1 }}>
              {item.icon && <FontAwesomeIcon icon={item.icon} className="menu-icon" />}
              <div className="text">
                <h6 className="mb-0 ml-2 text">{t(item.text)}</h6>
              </div>
              {isHidden && <FontAwesomeIcon icon={faLock} className="ml-2" />}
            </div>
          );

          return (
            <Nav.Item key={index}>
              {isAvailable && (
                isEmpty(item.subItems) ? (
                  isHidden ? (
                    <OverlayTrigger placement="right" overlay={renderTooltip()}>
                      {renderNavItemTitle()}
                    </OverlayTrigger>
                  ) : (
                    renderNavItemTitle()
                  )
                ) : (
                  <SubMenu
                    title={isHidden ? (
                      <OverlayTrigger placement="right" overlay={renderTooltip()}>
                        {renderNavItemTitle()}
                      </OverlayTrigger>
                    ) : (
                      renderNavItemTitle()
                    )}
                    items={
                      <>
                        {item.subItems.map((subItem, subIndex) => (
                          <Nav.Item key={subIndex}>
                            <div className={`nav-item-title sub ${isHidden ? 'disabled' : ''}`} onClick={() => !isHidden && linkClick(subItem.link, isHidden)} style={{ opacity: isHidden ? 0.5 : 1 }}>
                              {t(subItem.text)}
                              {isHidden && <FontAwesomeIcon icon={faLock} className="ml-2" />}
                            </div>
                          </Nav.Item>
                        ))}
                      </>
                    }
                    mainMenuCollapsed={mainMenuCollapsed}
                  />
                )
              )}
            </Nav.Item>
          );
        })}
      </Navbar>
    </div>
  );
};

LeftMenu.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default LeftMenu;
