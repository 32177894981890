import { API_BASE_URL } from "../../common/constants";
import { Api } from "../../core/api";

import routesConstatns from "./routes";

const campaignApi = {};

campaignApi.getUserCampaigns = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_USER_CAMPAIGNS}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

campaignApi.getBestCampaigns = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_BEST_CAMPAIGNS}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

campaignApi.getCampaignById = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_CAMPAIGN_BY_ID}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

campaignApi.getCampaignActions = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_CAMPAIGN_ACTIONS}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

campaignApi.create = async (params, headers) => {
  return await Api.post(
    `${API_BASE_URL}${routesConstatns.CAMPAIGN}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

campaignApi.copy = async (params, headers) => {
  return await Api.post(
    `${API_BASE_URL}${routesConstatns.CAMPAIGN_COPY}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

campaignApi.update = async (params, headers) => {
  return await Api.put(
    `${API_BASE_URL}${routesConstatns.CAMPAIGN}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

campaignApi.cancelAction = async (params, headers) => {
  return await Api.delete(
    `${API_BASE_URL}${routesConstatns.CAMPAIGN_ACTION}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

campaignApi.hardStop = async (params, headers) => {
  return await Api.put(
    `${API_BASE_URL}${routesConstatns.CAMPAIGN}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

campaignApi.cancelCampaign = async (params, headers) => {
  return await Api.put(
    `${API_BASE_URL}${routesConstatns.CAMPAIGN_CANCEL}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

export default campaignApi;
