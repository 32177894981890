import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import "../style/sub-menu.scss";

const SubMenu = ({ title, items, mainMenuCollapsed }, { t }) => {
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    setOpen(false);
  }, [mainMenuCollapsed]);
  return (
    <div
      className={`sub-menu ${mainMenuCollapsed ? "sub-menu-side" : ""}`}
      onMouseEnter={!mainMenuCollapsed ? () => null : () => setOpen(true)}
      onMouseLeave={!mainMenuCollapsed ? () => null : () => setOpen(false)}
    >
      <div
        className="sub-menu-title"
        onClick={mainMenuCollapsed ? () => null : () => setOpen(!isOpen)}
      >
        {title}
        {!mainMenuCollapsed && (
          <FontAwesomeIcon
            icon={faChevronRight}
            className={`icon ${isOpen ? "open" : ""}`}
          />
        )}
      </div>
      <div className={`sub-menu-items ${!isOpen ? "collapsed" : "open"}`}>
        {items}
        {/* <div className={`sub-menu-items open`}></div> */}
      </div>
    </div>
  );
};

SubMenu.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SubMenu;
