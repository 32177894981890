import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "../theme/theme-context";
import { widget } from "../../common/charting_library";
import CommonHelper from "../helpers/common-helper";
import Helpers from "../helpers/error-helpers";
import BinanceDataFeed from "./binance-datafeed";
import MexcDataFeed from "./mexc-datafeed";
import chartApi from "../../api/chart/actions";
import routes from "../../api/chart/routes";
import ChartLayoutShareModal from "./share-chart-layout-modal";
import { EXCHANGE } from "../constants";
import DescriptionModal from "./description-modal";

const Chart = ({
  assetName, marketName, height = 450, startAt, endAt, lotCount, type, isEdit, onStartAtChange, onEndAtChange, onLotChange, exchangeId, onReadyCallback, isReady, botOrders, showBuyLines, showSellLines
}, context) => {
  const { t } = context;
  const { isDarkMode } = useTheme();

  const lastMinPriceRef = useRef(startAt);
  const secondLastMinPriceRef = useRef(startAt);

  const lastMaxPriceRef = useRef(endAt);
  const secondLastMaxPriceRef = useRef(endAt);

  const lotCountRef = useRef(lotCount);
  const widgetRef = useRef();
  const startAtLineRef = useRef();
  const endAtLineRef = useRef();
  const typeRef = useRef();
  const isChartReady = useRef();
  const lineSpan = useRef();
  const existingCharts = useRef([]);
  const showDescriptionRef = useRef(false);
  const descriptionRef = useRef(null);
  const shapeIds = useRef([]);
  const botOrderBuyLines = useRef([]);
  const botOrderSellLines = useRef([]);

  let startAtRetryCount = 5;
  let endAtRetryCount = 5;

  const availableCharts = useRef();
  const availableStudyTemplates = useRef();

  const [showSaveChartModal, setShowSaveChartModal] = useState();
  const [showDescription, setShowDescription] = useState();
  const [chartDecription, setChartDecription] = useState();

  const loadAvailableCharts = async () => {
    try {
      const response = await chartApi.getAvailableCharts();
      availableCharts.current = response.data;
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  const loadAvailableStudyTemplates = async () => {
    try {
      const response = await chartApi.getAvailableStudyTemplates();
      availableStudyTemplates.current = response.data;
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  const scrollToDescription = () => {
    if (descriptionRef.current) {
      descriptionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const chartOptions = useMemo(() => {

    return {
      autosize: true,
      symbol: `${assetName || "BTC"}${marketName || "USDT"}`,
      interval: "30",
      timezone: "Etc/UTC",
      theme: isDarkMode ? "dark" : "light",
      locale: "en",
      enable_publishing: false,
      allow_symbol_change: true,
      container_id: "crypdesk_chart_tv",
      datafeed: !exchangeId || EXCHANGE[exchangeId] === EXCHANGE[0] || EXCHANGE[exchangeId] === EXCHANGE[1] ? BinanceDataFeed : MexcDataFeed,
      library_path: "/charting_library/",
      save_load_adapter: {
        getAllCharts: async () => Promise.resolve(availableCharts.current),
        getChartContent: async (id) => {
          try {
            const response = await chartApi.getChart(id);
            setChartDecription(availableCharts.current.find(ac => ac.id === id)?.description);
            return Promise.resolve(JSON.stringify(response.data));
          } catch (err) {
            Helpers.parseError(err);
            return Promise.reject();
          }
        },
        saveChart: async (chartData) => {
          let description;
          try {
            description = await new Promise((resolve, reject) => {
              const event = new CustomEvent('requestSaveChartModal', {
                detail: {
                  onSubmit: (description) => {
                    resolve(description);
                  },
                  cancelSubmit: () => {
                    reject();
                  }
                }
              });
              window.dispatchEvent(event);
            });
          }
          catch {
            return;
          }

          chartData.description = description;

          try {
            // If Chart exists, then it is either being renamed or layout updated
            if (chartData.id) {
              const oldChart = availableCharts.current.find(x => x.id === chartData.id);
              await chartApi.update({ id: chartData.id, route: routes.CHART_BASE, name: chartData.name, content: chartData.content, description });

              // Remove the existing chart so the new one can be added
              const removeIndex = availableCharts.current?.indexOf(oldChart);
              availableCharts.current.splice(removeIndex, 1);
            }
            // If it does not exist, create a new one
            else {
              chartData = {
                ...chartData,
                timestamp: new Date().valueOf(),
                chartObject: chartData.content
              };

              const response = await chartApi.saveChart(chartData);
              setChartDecription(description);
              chartData.id = response.data;
            }

            // Add the new chart to the array in both cases
            availableCharts.current.push(chartData);
          } catch (err) {
            Helpers.parseError(err);
          }

          return Promise.resolve(chartData.id);
        },
        removeChart: async (id) => {
          try {
            await chartApi.removeChart(id);
            const removeIndex = availableCharts.current?.find(x => x.id === id);
            availableCharts.current.splice(removeIndex, 1);
            return Promise.resolve();
          } catch (err) {
            return Promise.reject();
          }
        },

        getAllStudyTemplates: async () => Promise.resolve(availableStudyTemplates.current),

        getStudyTemplateContent: async (studyTemplateData) => {
          try {
            const response = await chartApi.getStudyTemplate(studyTemplateData.name);
            return Promise.resolve(response?.data?.content);
          } catch (err) {
            Helpers.parseError(err);
            return Promise.reject();
          }
        },
        saveStudyTemplate: async (studyTemplateData) => {
          try {
            studyTemplateData = {
              name: studyTemplateData.name,
              studyTemplateObject: JSON.stringify(studyTemplateData)
            };

            const oldChart = availableStudyTemplates.current.find(x => x.name === studyTemplateData.name);

            // If StudyTemplate exists, then it is either being renamed or layout updated
            if (!!oldChart) {
              await chartApi.update({ ...studyTemplateData, route: routes.STUDY_TEMPLATE_BASE });

              // Remove the existing StudyTemplate so the new one can be added
              const removeIndex = availableStudyTemplates.current?.indexOf(oldChart);
              availableStudyTemplates.current.splice(removeIndex, 1);
            }
            // If it does not exist, create a new one
            else {
              const response = await chartApi.saveStudyTemplate(studyTemplateData);
              studyTemplateData = {
                ...studyTemplateData,
                id: response.data
              };
            }

            // Add the new chart to the array in both cases
            availableStudyTemplates.current.push(studyTemplateData);
          } catch (err) {
            Helpers.parseError(err);
          }

          return Promise.resolve(studyTemplateData.id);
        },
        removeStudyTemplate: async (studyTemplateData) => {
          try {
            await chartApi.removeStudyTemplate(studyTemplateData.name);
            const removeIndex = availableStudyTemplates.current?.findIndex(x => x.name === studyTemplateData.name);
            availableStudyTemplates.current.splice(removeIndex, 1);
            return Promise.resolve();
          } catch (err) {
            return Promise.reject();
          }
        },
      },
      enabled_features: ["side_toolbar_in_fullscreen_mode", "header_in_fullscreen_mode", "study_templates", "confirm_overwrite_if_chart_layout_with_name_exists", "right_toolbar", "keep_object_tree_widget_in_right_toolbar"],
    };
  }, [isDarkMode, exchangeId]);

  const calculateMidlinesSpan = (min, max) => {
    const range = max - min;
    const span = range / (lotCountRef.current - 1);
    lineSpan.current = span;
    return span;
  };

  const createMidLines = () => {
    const dfr = calculateMidlinesSpan(lastMinPriceRef.current, lastMaxPriceRef.current);
    const from = Date.now() / 1000 - 500 * 24 * 3600;

    for (let i = 1; i <= (lotCountRef.current - 2); i++) {
      let newId = widgetRef.current.activeChart().createMultipointShape(
        [{ time: from, price: CommonHelper.roundNumber(lastMinPriceRef.current + (dfr * i), 8) }],
        {
          shape: "horizontal_line",
          lock: true,
          disableSelection: true,
          disableSave: true,
          disableUndo: true,
          text: "text",
          overrides: { color: "green" },
        }
      );
      shapeIds.current.push(newId);
    }
  };

  const createOrderLine = (type, amount) => {
    const newId = widgetRef.current.activeChart().createOrderLine()
      .setText(type === 0 ? 'Buy' : 'Sell')
      .setLineStyle(0)
      .setLineLength(50)
      .setLineColor(type === 0 ? 'green' : 'red')
      .setQuantity()
      .setEditable(false)
      .setCancellable(false)
      .setPrice(amount);

    return newId;
  }

  const createOrderBuyMidLines = (toggle) => {
    if (!toggle) {
      botOrderBuyLines.current.forEach(function (value) {
        value.remove();
      });

      botOrderBuyLines.current = [];
      return;
    }

    for (let i = 0; i < botOrders.length; i++) {
      if (botOrders[i].type === 1) {
        continue;
      }

      const newId = createOrderLine(botOrders[i].type, botOrders[i].assetAmount);
      botOrderBuyLines.current.push(newId);
    }
  };

  const createOrderSellMidLines = (toggle) => {
    if (!toggle) {
      botOrderSellLines.current.forEach(function (value) {
        value.remove();
      });

      botOrderSellLines.current = [];
      return;
    }

    for (let i = 0; i < botOrders.length; i++) {
      if (botOrders[i].type === 0) {
        continue;
      }

      const newId = createOrderLine(botOrders[i].type, botOrders[i].assetAmount);
      botOrderSellLines.current.push(newId);
    }
  };

  const addMidLines = () => {
    if (!lastMinPriceRef.current || !lastMaxPriceRef.current || !widgetRef || !widgetRef.current) {
      return;
    }

    const shapes = widgetRef.current.activeChart().getAllShapes();
    for (let i = 0; i < shapes.length; i++) {
      if (shapeIds.current.includes(shapes[i].id)) {
        widgetRef.current.activeChart().removeEntity(shapes[i].id);
      }
    }
    shapeIds.current = [];
    createMidLines();
  };

  const createStartAtLine = () => {
    return new Promise((resolve, reject) => {
      try {
        const startAtOrderLine = widgetRef.current.chart().createOrderLine()
          .onMove(() => {
            secondLastMinPriceRef.current = lastMinPriceRef.current;
            const price = startAtOrderLine.getPrice();

            if (!!lastMaxPriceRef && ((typeRef.current === 0 && price <= lastMaxPriceRef.current) || (typeRef.current === 1 && price >= lastMaxPriceRef.current))) {
              lastMinPriceRef.current = secondLastMinPriceRef.current;
            } else {
              lastMinPriceRef.current = price;
            }

            startAtOrderLine.setQuantity(lastMinPriceRef.current);
            startAtOrderLine.setPrice(lastMinPriceRef.current);
            addMidLines();
            onStartAtChange(lastMinPriceRef.current);
          })
          .onCancel(() => {
            if (!lotCountRef.current) {
              return;
            }

            // If it is buy, move startAt to the position of midline below, else move it to the position of midline above
            if (lotCountRef.current > 2) {
              lastMinPriceRef.current += lineSpan.current;
              startAtOrderLine.setQuantity(lastMinPriceRef.current);
              startAtOrderLine.setPrice(lastMinPriceRef.current);
            }

            // Remove one midline/lot
            lotCountRef.current--;
            addMidLines(widgetRef.current);
            onStartAtChange(lastMinPriceRef.current);
            onLotChange(lotCountRef.current);
          })
          .setCancelTooltip("Reset")
          .setText("Start at")
          .setLineStyle(0)
          .setLineLength(50)
          .setLineColor("red")
          .setQuantity(lastMinPriceRef.current)
          .setEditable(!isEdit)
          .setCancellable(!isEdit)
          .setPrice(lastMinPriceRef.current);

        resolve(startAtOrderLine);
      } catch {
        if (startAtRetryCount > 0) {
          startAtRetryCount--;
          setTimeout(() => {
            createStartAtLine().then(resolve).catch(reject);
          }, 500);
        } else {
          reject(new Error('Failed after all retries'));
        }
      }
    });
  };

  const createEndAtLine = () => {
    return new Promise((resolve, reject) => {
      try {
        const endAtOrderLine = widgetRef.current.chart().createOrderLine()
          .onMove(() => {
            secondLastMaxPriceRef.current = lastMaxPriceRef.current;
            const price = endAtOrderLine.getPrice();

            if (!!lastMinPriceRef && ((typeRef.current === 0 && price >= lastMinPriceRef.current) || (typeRef.current === 1 && price <= lastMinPriceRef.current))) {
              lastMaxPriceRef.current = secondLastMaxPriceRef.current;
            } else {
              lastMaxPriceRef.current = price;
            }

            endAtOrderLine.setQuantity(lastMaxPriceRef.current);
            endAtOrderLine.setPrice(lastMaxPriceRef.current);
            addMidLines(widgetRef.current);
            onEndAtChange(lastMaxPriceRef.current);
          })
          .onCancel(() => {
            if (!lotCountRef.current) {
              return;
            }

            // If it is buy, move endAt to the position of midline above, else move it to the position of midline below
            if (lotCountRef.current > 2) {
              lastMaxPriceRef.current -= lineSpan.current;
              endAtOrderLine.setQuantity(lastMaxPriceRef.current);
              endAtOrderLine.setPrice(lastMaxPriceRef.current);
            }

            // Remove one midline/lot
            lotCountRef.current--;
            addMidLines(widgetRef.current);
            onEndAtChange(lastMaxPriceRef.current);
            onLotChange(lotCountRef.current);
          })
          .setCancelTooltip("Reset")
          .setText("End at")
          .setLineStyle(0)
          .setLineLength(50)
          .setLineColor("blue")
          .setQuantity(lastMaxPriceRef.current)
          .setEditable(!isEdit)
          .setCancellable(!isEdit)
          .setPrice(lastMaxPriceRef.current);

        resolve(endAtOrderLine);
      } catch {
        if (endAtRetryCount > 0) {
          endAtRetryCount--;
          setTimeout(() => {
            createEndAtLine().then(resolve).catch(reject);
          }, 500);
        } else {
          reject(new Error('Failed after all retries'));
        }
      }
    });
  };

  const useSafeEffect = (effect, deps) => {
    useEffect(() => {
      try {
        effect();
      } catch (error) {
        console.error('Chart error: ', error);
      }
    }, deps);
  };

  useSafeEffect(() => {
    if (widgetRef && widgetRef.current) {
      widgetRef.current.setSymbol(`${assetName}${marketName}`, '30', () => chartOptions);
    }
  }, [assetName, marketName])

  useSafeEffect(() => {
    if (!isChartReady.current || !widgetRef || !widgetRef.current) {
      return;
    }

    lastMinPriceRef.current = +startAt;

    const existingChartLine = existingCharts.current.find(x => x.type === 'startAt' && x.symbol === `${assetName}${marketName}`);
    if (!!existingChartLine) {
      existingChartLine.value.setQuantity(lastMinPriceRef.current);
      existingChartLine.value.setPrice(lastMinPriceRef.current);
      addMidLines(widgetRef.current);
    }
    else {
      setTimeout(() => {
        const delayedCheck = existingCharts.current.find(x => x.type === 'startAt' && x.symbol === `${assetName}${marketName}`);
        if (!!delayedCheck) {
          return;
        }

        createStartAtLine().then((value) => {
          startAtLineRef.current = value;
          addMidLines(widgetRef.current);
          existingCharts.current.push({ type: 'startAt', symbol: `${assetName}${marketName}`, value: startAtLineRef.current });
        }).catch((error) => {
          console.error(error);
        });
      }, 1000);
    }
  }, [startAt, isReady]);

  useSafeEffect(() => {
    if (!isChartReady.current || !widgetRef || !widgetRef.current) {
      return;
    }

    lastMaxPriceRef.current = +endAt;

    const existingChartLine = existingCharts.current.find(x => x.type === 'endAt' && x.symbol === `${assetName}${marketName}`);
    if (!!existingChartLine) {
      existingChartLine.value.setQuantity(lastMaxPriceRef.current);
      existingChartLine.value.setPrice(lastMaxPriceRef.current);
      addMidLines(widgetRef.current);
    }
    else {
      setTimeout(() => {
        const delayedCheck = existingCharts.current.find(x => x.type === 'endAt' && x.symbol === `${assetName}${marketName}`);
        if (!!delayedCheck) {
          return;
        }

        createEndAtLine().then((value) => {
          endAtLineRef.current = value;
          addMidLines(widgetRef.current);
          existingCharts.current.push({ type: 'endAt', symbol: `${assetName}${marketName}`, value: endAtLineRef.current });
        }).catch((error) => {
          console.error(error);
        });
      }, 1000);
    }
  }, [endAt, isReady]);

  useSafeEffect(() => {
    setTimeout(() => {
      lotCountRef.current = +lotCount;

      if (!widgetRef || !widgetRef.current) {
        return;
      }

      addMidLines(widgetRef.current);
    }, 100);
  }, [lotCount]);

  useSafeEffect(() => {
    typeRef.current = +type;
  }, [type]);

  useSafeEffect(() => {
    if (!isChartReady.current || !widgetRef || !widgetRef.current) {
      return;
    }

    createOrderBuyMidLines(showBuyLines);
  }, [showBuyLines]);

  useSafeEffect(() => {
    if (!isChartReady.current || !widgetRef || !widgetRef.current) {
      return;
    }

    createOrderSellMidLines(showSellLines);
  }, [showSellLines]);

  useSafeEffect(async () => {
    Promise.all([loadAvailableCharts(), loadAvailableStudyTemplates()])
      .catch(error => {
        Helpers.parseError(error);
      });

    const tvwidget = new widget(chartOptions);
    widgetRef.current = tvwidget;

    tvwidget.onChartReady(() => {
      if (!!lastMinPriceRef.current) {
        createStartAtLine().then((value) => {
          startAtLineRef.current = value;
        }).catch((error) => {
          console.error(error);
        });
      }

      if (!!lastMaxPriceRef.current) {
        createEndAtLine().then((value) => {
          endAtLineRef.current = value;
        }).catch((error) => {
          console.error(error);
        });
      }

      if (!!startAt && !!endAt) {
        createMidLines(tvwidget);
      }

      isChartReady.current = true;
      tvwidget.changeTheme(chartOptions.theme);
      onReadyCallback && onReadyCallback();
    });

    tvwidget.headerReady().then(function () {
      tvwidget.createButton({
        align: "right",
        useTradingViewStyle: true,
        text: "Share",
        title: "Click to share Chart layout or Study template",
        onClick: () => {
          setShowSaveChartModal(true);
        }
      });
    });

    tvwidget.headerReady().then(function () {
      tvwidget.createButton({
        align: "right",
        useTradingViewStyle: true,
        text: "Description",
        title: "Click to show Chart description",
        onClick: () => {
          showDescriptionRef.current = !showDescriptionRef.current;
          setShowDescription(showDescriptionRef.current);
          scrollToDescription();
        }
      });
    });

    return () => {
      if (tvwidget) {
        tvwidget.remove();
      }
    };
  }, [chartOptions]);

  return (
    <div className="d-flex" style={{ flexDirection: 'column', width: '100%' }}>
      <div style={{ height, width: '100%' }} id="crypdesk_chart_tv"></div>
      <DescriptionModal />
      {showSaveChartModal && (
        <ChartLayoutShareModal
          show={showSaveChartModal}
          onClose={() => setShowSaveChartModal(!showSaveChartModal)}
          studyTemplates={availableStudyTemplates.current}
          charts={availableCharts.current}
        />
      )}
      {showDescription && (
        <div id="chart-description" className="d-flex flex-column my-3" ref={descriptionRef}>
          <span>{t("CHART.DESCRIPTION")}</span>
          <p className="mb-0">{chartDecription || t("CHART.NO-DESCRIPTION")}</p>
        </div>
      )}
      <div className="d-flex" style={{ justifyContent: 'center', width: '100%', color: 'gray' }}>
        <span>{t("CHART.DISCLAIMER")}</span>
      </div>
    </div>
  );
};

Chart.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Chart;
