const routesConstatns = {
  GET_ORDERS: "/Order/Get",
  DEACTIVATE_ORDER: "/BotOrder/DeactivateOrder",
  GET_USER_BOT_COUNT: "/BotOrder/UserBotCount",
  CREATE_ORDER: "/Order/Create",
  UPDATE_ORDER: "/Order/Update",
  DELETE_ORDER: "/Order/Delete",
  GET_ACTIVE_TRADE_ORDERS: "/BotDetails/GetActiveBotOrders",
  EMERGENCY_SELL_ORDER:"/Order/EmergencySell"
};

export default routesConstatns;
