import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faUser, faUserSecret, faEllipsisV, faCoins } from "@fortawesome/free-solid-svg-icons";
import { Dropdown, DropdownButton } from "react-bootstrap";

import NotificationProvider from "../../../core/notification";
import TradeTable from "../../../common/form/table/trade-table";
import Helpers from "../../../common/helpers/error-helpers";
import userApi from "../../../api/user/actions";
import ProfitModal from "../modals/profit-modal";

import { COLUMNS } from "../constants";

const UserSettings = (props, { t }) => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [showProfitModal, setShowProfitModal] = useState();
  const [userId, setUserId] = useState();

  const columns = [
    ...COLUMNS,
    {
      field: "createInvoice",
      headerName: "USER-SETTINGS.TABLE.CREATE-INVOICE",
      width: 70,
      cellRenderer: (data) => {
        let isChecked = data.createInvoice;
        return (
          <div>
            <input type="checkbox" checked={isChecked} onChange={() => { isChecked = !isChecked; onCreateInvoiceChange(data.userId) }} />
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "USER-SETTINGS.TABLE.ACTIONS",
      width: 90,
      cellRenderer: (data) => (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon
            style={{
              opacity: data.verified ? 0.4 : 1,
              pointerEvents: data.verified ? "none" : "unset",
              cursor: data.verified ? "default" : "pointer",
            }}
            title={"Verify User"}
            className="mr-5"
            icon={faCheckCircle}
            onClick={() => (data.verified ? null : onVerified(data.userId))}
          />
          <DropdownButton variant="secondary"
                          id="dropdown-basic-button"
                          title={<FontAwesomeIcon icon={faEllipsisV} />}
                          drop={"down"}
                          className="fix-dropdown-margin">
            {roles.filter((r) => r.name !== data.roleName).map((role) => (
              <Dropdown.Item key={role.id} href="#" onClick={() => onRoleChange(data.userId, role)}>
                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={role.name === "Admin" ? faUserSecret : faUser} />{t("USER-SETTINGS.TABLE.ACTIONS.CHANGE-ROLE")} {role.name}
              </Dropdown.Item>
            ))}
            <Dropdown.Item href="#"
              onClick={() => {
                setUserId(data.userId);
                toggleProfitModal();
              }}>
              <FontAwesomeIcon icon={faCoins} /> {t("USER-SETTINGS.TABLE.ACTIONS.PROFIT")}
            </Dropdown.Item>
          </DropdownButton>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getAllUsers();
    getRoles();
  }, []);

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      const response = await userApi.getUsers();
      setUsers(response.data);
      setIsLoading(false);
    } catch (err) {
      Helpers.parseError(err);
      setIsLoading(false);
    }
  };

  const getRoles = async () => {
    try {
      const response = await userApi.getRoles();
      setRoles(response.data);
    } catch (err) {
      setRoles([]);
      Helpers.parseError(err);
    }
  };

  const onRoleChange = async (userId, role) => {
    try {
      await userApi.updateUserRole({ userId, roleId: role.id });
      getAllUsers();
      NotificationProvider.success("User role successfully changed");
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  const onCreateInvoiceChange = async (userId) => {
    try {
      await userApi.updateUserCreateInvoice(userId);
      getAllUsers();
      NotificationProvider.success("User eate Invoicece successfully changed");
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  const onVerified = async (id) => {
    try {
      await userApi.verifyUser(id);
      getAllUsers();
      NotificationProvider.success("User successfully verified");
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  const toggleProfitModal = () => {
    setShowProfitModal((showModal) => !showModal);
  };

  return (
    <div className="user-settings-layout w-100">
      <TradeTable data={users} columnDefs={columns} isLoading={isLoading} />
      {showProfitModal && (
        <ProfitModal
          show={showProfitModal}
          onClose={toggleProfitModal}
          userId={userId}
        />
      )}
    </div>
  );
};

UserSettings.contextTypes = {
  t: PropTypes.func,
};

export default UserSettings;
