import { API_BASE_URL } from "../../common/constants";
import { Api } from "../../core/api";
import { generateQueryParams } from "../api-helpers";

import routesConstatns from "./routes";

const profileApi = {};

profileApi.saveApiKey = async (params, headers) => {
  return await Api.post(
    `${API_BASE_URL}${routesConstatns.SAVE_USER_DETAILS_API_KEY}`,
    params,
    headers
  );
};

profileApi.updateApiKey = async (params, headers) => {
  return await Api.put(
    `${API_BASE_URL}${routesConstatns.UPDATE_USER_DETAILS_API_KEY}`,
    params,
    headers
  );
};

profileApi.deleteApiKey = async (id, headers) => {
  return await Api.delete(
    `${API_BASE_URL}${routesConstatns.DELETE_USER_DETAILS_API_KEY}/${id}`,
    null,
    headers
  );
};

profileApi.getApiKeys = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${
      routesConstatns.GET_USER_DETAILS_API_KEYS
    }${generateQueryParams(params)}`,
    {
      needLoader: true,
    },
    headers
  );
};

export default profileApi;
