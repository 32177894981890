import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import GlobalLoader from "./common/loader/components/global-loader";

import { useTheme } from "./common/theme/theme-context";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import { useEffect } from "react";
import { useModal } from "./common/modal-context";
import "./common/style/global-style.scss";
import "./common/style/overrides.scss";

const App = (props) => {
  let { children, userTokens } = props;
  const { currentTheme } = useTheme();
  const { requestModal } = useModal();

  children = { ...children, props: { ...children.props, user: userTokens } };

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", currentTheme);
  }, [currentTheme]);


  useEffect(() => {
    const handleSaveChartRequest = (e) => {
      requestModal(e.detail.onSubmit, e.detail.cancelSubmit);
    };

    window.addEventListener('requestSaveChartModal', handleSaveChartRequest);

    return () => {
      window.removeEventListener('requestSaveChartModal', handleSaveChartRequest);
    };
  }, [requestModal]);

  return (
    <div className={`main-wrapper`}>
        <GlobalLoader />
        <ToastContainer hideProgressBar autoClose={5000} />
        {children}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userTokens: state.userReducer.userTokens,
});

export default connect(mapStateToProps, null)(App);
