import { fields } from "../constants/constants";
import { EMAIL_REGEX } from "../../../common/constants";

export const validate = (values, { t }) => {
  const errors = {};

  if (!EMAIL_REGEX.test(values[fields.email])) {
    errors[fields.email] = { label: t("REGISTER_FORM.EMAIL_NOT_VALID") };
  }

  return errors;
};
