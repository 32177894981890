import { API_BASE_URL } from "../../common/constants";
import { Api } from "../../core/api";

import routesConstants from "./routes";

const invoiceApi = {};

invoiceApi.getAllInvoices = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstants.GET_INVOICE_ALL}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

invoiceApi.getInvoiceMain = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstants.GET_INVOICE_MAIN}`,
    params,
    {
      needLoader: true,
      responseType: "arraybuffer",
    },
    headers
  );
};

invoiceApi.getInvoiceDetails = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstants.GET_INVOICE_DETAILS}`,
    params,
    {
      needLoader: true,
      responseType: "arraybuffer",
    },
    headers
  );
};

invoiceApi.payInvoice = async (params, headers) => {
  return await Api.post(
    `${API_BASE_URL}${routesConstants.PAY_INVOICE}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

invoiceApi.createInvoice = async (params, headers) => {
  return await Api.post(
    `${API_BASE_URL}${routesConstants.CREATE_INVOICE}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

invoiceApi.getUserProfit = async (userId, headers) => {
  const param = !!userId ? `/${userId}` : '';
  return await Api.get(
    `${API_BASE_URL}${routesConstants.GET_USER_PROFIT}${param}`,
    {
      needLoader: true
    },
    headers
  );
};

invoiceApi.getDtaContent = async (invoiceIds, headers) => {
  const queryString = invoiceIds.map(id => `invoiceIds=${encodeURIComponent(id)}`).join('&');
  const response = await fetch(`${API_BASE_URL}${routesConstants.GET_DTA_CONTENT}?${queryString}`, {
    needLoader: true,
    method: 'GET',
    headers: headers,
  });

  if (!response.ok) {
    throw new Error();
  }

  return response.blob();
};

export default invoiceApi;
