import { fields } from "../../app/bots-overview/constants/constants";
import { PRE_CONDITION_TYPE } from "../../app/condition/constants";
import NotificationProvider from "../../core/notification";

export default class BotHelper {
  static TYPE_BUY = 0;
  static TYPE_SELL = 1;

  static validateSoftStop(values) {
    if (!values[fields.Profit] || !values[fields.Dynamic]) {
      NotificationProvider.warning("Profit type is required field");
      return;
    }

    if (
      values[fields.LowSoftStopPrice] &&
      values[fields.HighSoftStopPrice] &&
      values[fields.LowSoftStopPrice] >= values[fields.HighSoftStopPrice]
    ) {
      NotificationProvider.warning(
        "Low Stop Price cannot be greater than High Stop Price"
      );
      return;
    }

    if (
      values[fields.LowSoftStopQuantity] &&
      values[fields.HighSoftStopQuantity] &&
      values[fields.LowSoftStopQuantity] >= values[fields.HighSoftStopQuantity]
    ) {
      NotificationProvider.warning(
        "Low Stop Quantity cannot be greater than High Stop Quantity"
      );
      return;
    }

    if (values[fields.LowSoftStopQuantity] > values[fields.Amount]) {
      NotificationProvider.warning(
        "Low Stop Quantity cannot be greater than Amount"
      );
      return;
    }

    if (values[fields.HighSoftStopQuantity] > values[fields.Amount]) {
      NotificationProvider.warning(
        "High Stop Quantity cannot be greater than Amount"
      );
      return;
    }

    return true;
  }

  static checkHasFunds(marketValue, coinValue, type, conditions) {
    if (hasPreCondition(conditions)) return true;
    if (type === this.TYPE_BUY && !hasValue(marketValue)) return;
    if (type === this.TYPE_SELL && !hasValue(coinValue)) return;
    return true;
  }
}

const hasValue = (value) => value && value !== 0;

const hasPreCondition = (conditions) =>
  conditions.some((condition) => condition.type === PRE_CONDITION_TYPE);
