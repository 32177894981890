import actionTypes from "../constants/action-types";

export const getBots = (data) => {
  return {
    type: actionTypes.GET_BOTS,
    data,
  };
};

export const saveBotSuccess = (data) => {
  return {
    type: actionTypes.SAVE_BOT_SUCCESS,
    data,
  };
};

export const deleteBot = (data) => {
  return {
    type: actionTypes.DELETE_BOT_SUCCESS,
    data,
  };
};

export const setCurrentBot = (data) => {
  return {
    type: actionTypes.SET_CURRENT_BOT,
    data,
  };
};
