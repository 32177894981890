import verifiedIcon from "../../../common/assets/verified-icon.png";
import notVerifiedIcon from "../../../common/assets/not-verified-icon.png";
import ProfileImage from "../../../common/profile-image/components/profile-image";

export const COLUMNS = [
  {
    field: "imageBase64",
    headerName: "",
    width: 200,
    cellRenderer: (data) => {
      const src = data.imageBase64 ? `data:image/png;base64,${data.imageBase64}` : null;
      return (
        <div className="d-flex">
          <ProfileImage src={src} />
          <div>
            <img
              style={{ marginLeft: "25px", paddingRight: "5px" }}
              height={15}
              src={data.verified ? verifiedIcon : notVerifiedIcon}
              alt="verified badge"
            />
            {data.verified ? "verified" : "not verified"}
          </div>
        </div>
      );
    },
  },

  {
    field: "firstName",
    headerName: "USER-SETTINGS.TABLE.NAME",
    cellRenderer: (data) => {
      return `${data.firstName} ${data.lastName}`;
    },
  },
  {
    field: "nickname",
    headerName: "USER-SETTINGS.TABLE.NICKNAME",
  },
  {
    field: "email",
    headerName: "USER-SETTINGS.TABLE.EMAIL",
    width: 140,
  },
  {
    field: "country",
    headerName: "USER-SETTINGS.TABLE.COUNTRY",
  },
  {
    field: "city",
    headerName: "USER-SETTINGS.TABLE.ADDRESS",
    cellRenderer: (data) => {
      return getAddress(data);
    },
  },
  {
    field: "roleName",
    headerName: "USER-SETTINGS.TABLE.ROLE",
    width: 70,
  },
];

export const AFIILIATE_COLUMNS = [
  {
    field: 'userNickname',
    headerName: 'USER-SETTINGS.TABLE.NICKNAME',
  },
  {
    field: 'affiliateId',
    headerName: 'USER-SETTINGS.AFFILIATE.USER_AFFILIATE_ID',
  },
  {
    field: 'userAffiliateNickname',
    headerName: 'USER-SETTINGS.AFFILIATE.USER_AFFILIATE_NAME',
  },
];

const getAddress = ({ city, street, zip }) => {
  const address = [city, street, zip];
  return address.filter(Boolean).join(", ");
};
