import { ORDER_TYPE } from "../../../common/constants";

export const CONDITION_TYPE = [
  { code: 0, name: "PRE" },
  { code: 1, name: "POST" },
];

export const CONDITION_TYPE_NAME = {
  0: "PRE",
  1: "POST",
};

export const POST_CONDITION_TYPE = 1;
export const PRE_CONDITION_TYPE = 0;

export const CONDITION_ORDER_TYPE = {
  Limit: "LIMIT",
  Market: "MARKET",
};

export const INITIAL_LIMIT_ORDER_DATA = {
  exchangeId: 0,
  quantity: 0,
  total: "",
  price: 0,
  type: ORDER_TYPE.Limit,
};

export const INITIAL_MARKET_ORDER_DATA = {
  exchangeId: 0,
  quantity: 0,
  total: "",
  price: 0,
  type: ORDER_TYPE.Market,
};

export const INITIAL_CONDITION_DATA = {
  triggerPrice: 0,
  type: "",
  conditionRequirement: "",
  conditionAction: "",
  conditionalOrderQuantity: 0,
  conditionPercentage: "",
  conditionalOrderSide: ORDER_TYPE.Market,
  reentrySet: false,
  reentryPercentage: "",
  reentryPrice: "",
  reentryDelay: 0,
  trailingPercentage: 0
};

export const CONDITION_REQUIREMENT = [
  //{ code: 3, name: 'Bot start', isDisabledOnPre: true },
  { code: 0, name: 'Price is below' },
  { code: 1, name: 'Price is over' },
  { code: 2, name: 'Order is fulfilled' },
];

export const CONDITION_ACTION = (isCampaign, isOrder) => [
  {
    code: 0,
    name: isCampaign ? 'Close bots & orders' : 'Close order',
    isDisabledOnPre: isCampaign,
  },
  {
    code: 1,
    name: isCampaign
      ? 'Close bots & orders & sell percentage'
      : 'Close order & sell percentage',
    isDisabledOnPre: isOrder,
  },
  {
    code: 2,
    name: isCampaign ? 'Start campaign' : isOrder ? 'Start order' : 'Start bot',
    isDisabledOnPost: isOrder,
  },
  /*{
    code: 3,
    name: 'Trailing stop',
    isDisabledOnPre: true,
  },
  {
    code: 4,
    name: 'Trailing stop & sell percentage',
    isDisabledOnPre: true,
  },*/
];

export const CONDITION_REQUIREMENT_NAME = {
  //3: 'Bot start',
  0: 'Price is below',
  1: 'Price is over',
  2: 'Order is fulfilled'
};

export const CONDITION_ACTION_NAME = {
  0: 'Close order',
  1: 'Close order & sell percentage',
  2: 'Start bot',
  //3: 'Trailing stop',
  //4: 'Trailing stop & sell percentage',
};

export const CONDITION_ACTION_NAME_CAMPAIGN = {
  0: 'Close bots & orders',
  1: 'Close bots & orders & sell percentage',
  2: 'Start campaign',
 // 3: 'Trailing stop',
  //4: 'Trailing stop & sell percentage',
};

export const PRICE_IS_BELOW = 0;
export const PRICE_IS_OVER = 1;
export const ORDER_IS_FULFILED = 2;

export const ACTION_SELL_AMOUNT = 1;
export const TRAILING_SELL = 4
export const TRAILING_ORDER = 3
