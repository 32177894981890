import axios from "axios";

import { isEmpty } from "lodash";

import { dispatchGlobalLoaderdWired } from "../common/loader/actions/loader-actions";
import Helpers from "../common/helpers/error-helpers";
import TokenHelper from "../common/helpers/token-helper";
import { logout } from "../app/login/actions/login-actions";
import { store } from "./store";

const CancelToken = axios.CancelToken;
let cancelObj;

export class Api {
  static headers = async (headersIncome) => {
    let headers = {};

    headers["Authorization"] = `${TokenHelper.getTokenType()} ${TokenHelper.getAccessToken()}`;

    if (!isEmpty(headersIncome)) {
      headers = { ...headers, ...headersIncome };
    }
    return headers;
  };

  static get = (route, options, headers) => Api.xhr({ route, method: "GET", options, headersIncome: headers });

  static put = (route, data, options, headers) => {
    options = {
      ...options,
      cancelToken: new CancelToken(function executor(cancel) {
        // An executor function receives a cancel function as a parameter
        cancelObj = {
          cancel,
          timeStamp: data.timeStamp,
        };
      }),
    };

    return Api.xhr({
      route,
      method: "PUT",
      data,
      options,
      headersIncome: headers,
    });
  };

  static post = (route, data, options, headers) => Api.xhr({ route, method: "POST", data, options, headersIncome: headers });

  static patch = (route, data, options, headers) => Api.xhr({ route, method: "PATCH", data, options, headersIncome: headers });

  static delete = (route, options, headers) => Api.xhr({ route, method: "DELETE", options, headersIncome: headers });

  static xhr({ route, method, data, options = {}, headersIncome = {} }) {
    options = {
      needLoader: false,
      onUploadProgress: (p, cancelObj) => {},
      ...options,
    };

    if (options.needLoader) {
      dispatchGlobalLoaderdWired(true);
    }
    return Api.headers(headersIncome)
      .then((headers) => {
        return axios({
          method,
          url: route,

          ...options,
          headers: { ...headers, ...headersIncome },
          data: data,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);

            options.onUploadProgress(percentCompleted, cancelObj, progressEvent);
          },
        });
      })
      .then((response) => {
        if (options.needLoader) {
          dispatchGlobalLoaderdWired(false);
        }
        return response;
      })
      .catch((err) => {
        console.error(err);
        //  const origialConfig = err.config;
        if (options.needLoader) {
          dispatchGlobalLoaderdWired(false);
        }

        //for async validation
        const res = Helpers.isCustomErrCodeHandledManualy(err);
        if (res) {
          return Promise.reject(res);
        }
        //for global errors
        const res1 = Helpers.isCustomErrCode(err);
        if (res1) {
          return Promise.reject(res1);
          //open popup with message
        }

        //for 401 logout user
        if (err.response.status === 401) {
          store.dispatch(logout());
          // try {
          //   // getRefreshAccessToken();
          //   return axios(origialConfig);
          // } catch (_error) {
          //   return Promise.reject(_error);
          // }
        }

        return Promise.reject(err);
      });
  }
}

// const getRefreshAccessToken = async () => {
//   const response = await axios.post("/user/refresh", {
//     refresh_token: TokenHelper.getRefreshToken(),
//   });
//   TokenHelper.setAuthTokens(response.data.data);
// };
