export default class CommonHelper {
  static calculatePercentage(percentage, valueOf) {
    return valueOf * (Number(percentage) / 100);
  }

  static calculatePercentageIncrease(increase, originalNumber) {
    return (increase / originalNumber) * 100;
  }

  static toCustomFixed(num, decimals = 8) {
    if (!num || isNaN(num)) return "0";
    let parsedNum = convertExponentialToDecimal(num);
    var re = new RegExp("^-?\\d+(?:.\\d{0," + (decimals || -1) + "})?");
    return parsedNum.toString().match(re)[0];
  }

  static toRegularFormat(value) {
    const normalizedValue = typeof value === "string" ? value.replace(",", ".") : value;
    return Number(parseFloat(normalizedValue).toFixed(12).replace(/(?<=\.\d*[1-9])0+$|\.0*$/, ""));
  }

  static roundNumber(num, decimals = 2) {
    if (typeof num !== "number") {
      throw new Error("Value should be number");
    }
    return Number(num.toFixed(decimals));
  }

  static downloadPdfFile(base64, fileName) {
    var a = document.createElement("a");
    a.href = "data:file/pdf;base64," + base64;
    a.download = `${fileName}.pdf`;
    a.click();
  }

  static openPdfFile(base64, fileName) {
    let pdfWindow = window.open("");
    pdfWindow.document.write(
      `<title>${fileName}</title>
      <iframe width='100%' height='99%' src='data:application/pdf;base64,${encodeURI(base64)}'></iframe>`
    );
  }

  static camelCaseToTitleCase(text) {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  static sortDesc(a, b) {
    return b["value"] - a["value"];
  }

  static setPriceBorder(index, isCoinPriceHighest) {
    if (index === 0) return "price-border-bottom";
    if (index === 1 && !isCoinPriceHighest) return "price-border-bottom";
    return "price-border-top";
  }

  static getUserCurrentTheme() {
    return localStorage.getItem("theme") || "light";
  }

  static toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  static isBase64(str) {
    if (str === "" || str.trim() === "") {
      return false;
    }
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }

  static showFullNumber(num) {
    return num?.toString().includes('-') ? num.toFixed(20).replace(/\.?0+$/, '').replace('.', ',') : num;
  }

  static downloadBlob(blobContent, fileName) {
    const url = window.URL.createObjectURL(blobContent);
    const a = document.createElement('a');

    a.href = url;
    a.download = fileName;
    a.click();

    window.URL.revokeObjectURL(url);
  }
}

const convertExponentialToDecimal = (exponentialNumber) => {
  // sanity check - is it exponential number
  const str = exponentialNumber.toString();
  if (str.indexOf("e") !== -1) {
    // We use toFixed(), but there is a limit of 20.
    // (4.65661287307739e-10).toFixed(20);
    // ("0.00000000046566128731");
    const result = exponentialNumber.toFixed(20);
    return result;
  } else {
    return exponentialNumber;
  }
};
