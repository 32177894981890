const routesConstatns = {
  GET_BOTS: "/BotDetails/GetBots",
  GET_BOT: "/BotDetails/GetBot",
  SAVE_BOT: "/BotDetails/SaveBot",
  UPDATE_BOT: "/BotDetails/UpdateBot",
  DELETE_BOT: "/BotDetails/DeleteBot",
  CLOSE_BOT: "/BotDetails/DeactivateBot",
  COPY_BOT: "/BotDetails/Copy",
  GET_BEST_BOTS: "/BotDetails/GetBestBots",
};

export default routesConstatns;
