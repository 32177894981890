import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import Slider from "@mui/material/Slider";

import Button from "../../../common/form/button/components/button";
import CommonHelper from "../../../common/helpers/common-helper";
import InputNumber from "../../../common/form/input/components/number-input";
import NotificationProvider from "../../../core/notification";

import "../style/increase-modal.scss";

const CampaignHardStopModal = (
  { show, onClose, onConfirm, assetName, amount, marketName, apiKeyId, getAssetPrice },
  { t }
) => {
  const [quantity, setQuantity] = useState(amount);
  const [quanityPercentage, setQuantityPercentage] = useState(100);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    if (marketName === "" || assetName === "" || !apiKeyId) return;

    async function fillAssetAmount() {
      const price = await getAssetPrice(marketName, assetName, apiKeyId);
      setPrice(price);
    }

    fillAssetAmount();

    // eslint-disable-next-line
  }, [assetName, marketName, apiKeyId]);

  const valueText = (value) => {
    return `${value}%`;
  };

  const onAssetAmountPercentageChange = (e) => {
    const { value } = e.target;
    if (amount === 0) {
      return setQuantityPercentage(+value);
    }
    const assetAmount = CommonHelper.calculatePercentage(value, amount);
    setQuantityPercentage(+value);
    setQuantity(assetAmount);
  };

  const onAssetAmountChange = (e) => {
    const { value } = e.target;

    if (value > amount) {
      return NotificationProvider.warning("Value is greater than available");
    }

    const assetAmountPercentage = Math.floor(
      CommonHelper.calculatePercentageIncrease(value, amount)
    );
    setQuantity(+value);
    setQuantityPercentage(assetAmountPercentage);
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("CAMPAIGN.HARD_STOP_MODAL.TITLE")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="mb-4">Are you sure you want to sell all assets?</h5>
        <div className="input-group mb-3">
          <span className="input-group-text input-form-border">Price</span>
          <input
            type="text"
            className="form-control"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
          />
          <span
            style={{ fontWeight: 700 }}
            className="input-group-text input-end-form-border"
          >
            {marketName || "N/A"}
          </span>
        </div>
        <Row className="align-items-end pt-2 pb-4">
          <Col
            className="d-flex align-items-center"
            md={12}
            lg={6}
            sm={12}
            xs={12}
          >
            <label className="mb-1 pr-3">Quanity</label>
            <Slider
              name="quanityPercentage"
              value={quanityPercentage}
              valueLabelDisplay="auto"
              valueLabelFormat={valueText}
              onChange={onAssetAmountPercentageChange}
            />
          </Col>
          <Col md={12} lg={6} sm={12} xs={12}>
            <div className="input-group">
              <InputNumber name="quantity" value={quantity} onChange={onAssetAmountChange} />
              <span
                style={{ fontWeight: 700 }}
                className="input-group-text input-end-form-border"
              >
                {assetName || "N\\A"}
              </span>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onConfirm(price, quantity)}>
          Yes
        </Button>
        <Button variant="secondary" onClick={onClose}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CampaignHardStopModal.contextTypes = {
  t: PropTypes.func,
};

export default CampaignHardStopModal;
