import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import profileApi from "../../../api/profile/actions";
import { createApiKey, updateApiKey } from "../actions/profile-actions";

import { useHelpModus } from "../../../common/help-modus/help-modus-context";
import HelpModusModal from "../../../common/help-modus/help.modus-modal";
import Helpers from "../../../common/helpers/error-helpers";
import NotificationProvider from "../../../core/notification";
import Affiliate from "./affiliate";
import EditProfileForm from "./edit-profile-form";
import Membership from "./membership";
import Notifications from "./notifications";
import PaymentMethodsForm from "./payment-methods-form";

import "../style/edit-profile.scss";
import UserDataForm from "./user-data-form";

const Tab = {
  CustomerData: "customer_data",
  ApiManagement: "api_management",
  UserProfit: "user_profit",
  DesignSettings: "design_settings",
  Notifications: "notifications",
  Membership: "membership",
  Affiliate: "affiliate"
};

const EditProfile = (props, { t }) => {
  const { updateApiKey, createApiKey, location } = props;
  const [userKeys, setUserKeys] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab") || Tab.CustomerData;
  const [showHelpModusModal, setShowHelpModusModal] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [modalTitle, setModalTitle] = useState('Information');
  const [modalSize, setModalSize] = useState('xl');
  const { isHelpModus } = useHelpModus();

  useEffect(() => {
    getAllKeys();
  }, []);

  const getAllKeys = async () => {
    try {
      const res = await profileApi.getApiKeys();
      const userKeys = Array.isArray(res.data) ? res.data : [];
      setUserKeys(userKeys);
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  const onUpdate = (params) => {
    updateApiKey(params);
  };

  const onContentChanged = (e, index) => {
    const { name, value } = e.target;
    const updatedData = [
      ...userKeys.map((key, i) =>
        i === index
          ? {
              ...key,
              [name]: value,
            }
          : key
      ),
    ];
    setUserKeys(updatedData);
  };

  const addKey = () => {
    setUserKeys([...userKeys, { name: "", key: "", secret: "" }]);
  };

  const removeRow = (index) => {
    setUserKeys(userKeys.filter((key, i) => i !== index));
  };

  const onCreate = (params) => {
    createApiKey(params);
  };

  const deleteKey = async (id, index) => {
    try {
      await profileApi.deleteApiKey(id);
      NotificationProvider.success("Api key successfully deleted");
      removeRow(index);
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  const isActiveTab = (tab) => {
    return activeTab === tab;
  };

  const setActiveLink = (tab) => {
    return activeTab === tab ? "active" : "";
  };

  const toggleHelpModusModal = (title, message, size = 'xl') => {
    setShowHelpModusModal((showModal) => !showModal);
    setModalMessage(message);
    setModalTitle(title);
    setModalSize(size);
  };

  const renderBody = () => {
    return (
      <>
        <h4 className="page-title d-flex">
          Account info
          {isHelpModus && (
            <div className="infoButton avblInfoButton" style={{ fontSize: '16px' }}
              onClick={() => {
                const messageContent = (
                  <div>
                    <p>{t('HELP-MODUS.USER-CENTER-TITLE')}</p>
                  </div>
                );
                toggleHelpModusModal('Account info', messageContent);
              }}>
              <FontAwesomeIcon className="row" icon={faInfoCircle} />
            </div>
          )}
        </h4>
        <Nav fill variant="tabs" defaultActiveKey="/home" className="d-none d-lg-flex">
          <Nav.Item>
            <Link className={`nav-link ${setActiveLink(Tab.CustomerData)} d-flex link-info-button`} to={`?tab=${Tab.CustomerData}`}>
              {t('EDIT_PROFILE.TAB.PROFILE')}
              {isHelpModus && (
                <div className="infoButton avblInfoButton" style={{ fontSize: '16px' }}
                  onClick={() => {
                    const messageContent = (
                      <div>
                        <p>{t('HELP-MODUS.USER-CENTER-CUSTOMER-DATA')}</p>
                      </div>
                    );
                    const messageTitle = (<>{t('EDIT_PROFILE.TAB.PROFILE')}</>);
                    toggleHelpModusModal(messageTitle, messageContent);
                  }}>
                  <FontAwesomeIcon className="row" icon={faInfoCircle} />
                </div>
              )}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link className={`nav-link ${setActiveLink(Tab.ApiManagement)} d-flex link-info-button`} to={`?tab=${Tab.ApiManagement}`}>
              {t('EDIT_PROFILE.TAB.API_MANAGEMENT')}
              {isHelpModus && (
                <div className="infoButton avblInfoButton" style={{ fontSize: '16px' }}
                  onClick={() => {
                    const messageContent = (
                      <div>
                        <p>{t('HELP-MODUS.USER-CENTER-API-MANAGEMENT')}</p>
                      </div>
                    );
                    const messageTitle = (<>{t('EDIT_PROFILE.TAB.API_MANAGEMENT')}</>);
                    toggleHelpModusModal(messageTitle, messageContent);
                  }}>
                  <FontAwesomeIcon className="row" icon={faInfoCircle} />
                </div>
              )}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link className={`nav-link ${setActiveLink(Tab.UserProfit)} d-flex link-info-button`} to={`?tab=${Tab.UserProfit}`}>
              {t('USER-PROFIT.TITLE')}
              {isHelpModus && (
                <div className="infoButton avblInfoButton" style={{ fontSize: '16px' }}
                  onClick={() => {
                    const messageContent = (
                      <div>
                        <p>{t('HELP-MODUS.USER-CENTER-PAYMENT-METHODS')}</p>
                      </div>
                    );
                    const messageTitle = (<>{t('USER-PROFIT.TITLE')}</>);
                    toggleHelpModusModal(messageTitle, messageContent);
                  }}>
                  <FontAwesomeIcon className="row" icon={faInfoCircle} />
                </div>
              )}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link className={`nav-link ${setActiveLink(Tab.Notifications)} d-flex link-info-button`} to={`?tab=${Tab.Notifications}`}>
              {t('EDIT_PROFILE.TAB.NOTIFICATIONS')}
              {isHelpModus && (
                <div className="infoButton avblInfoButton" style={{ fontSize: '16px' }}
                  onClick={() => {
                    const messageContent = (
                      <div>
                        <p>{t('HELP-MODUS.NOTIFICATIONS')}</p>
                      </div>
                    );
                    const messageTitle = (<>{t('EDIT_PROFILE.TAB.PAYMENT_METHODS')}</>);
                    toggleHelpModusModal(messageTitle, messageContent);
                  }}>
                  <FontAwesomeIcon className="row" icon={faInfoCircle} />
                </div>
              )}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link className={`nav-link ${setActiveLink(Tab.Membership)} d-flex link-info-button`} to={`?tab=${Tab.Membership}`}>
              {t('EDIT_PROFILE.TAB.MEMBERSHIP')}
              {isHelpModus && (
                <div className="infoButton avblInfoButton" style={{ fontSize: '16px' }}
                  onClick={() => {
                    const messageContent = (
                      <div>
                        <p>{t('EDIT_PROFILE.TAB.MEMBERSHIP')}</p>
                      </div>
                    );
                    const messageTitle = (<>{t('EDIT_PROFILE.TAB.MEMBERSHIP')}</>);
                    toggleHelpModusModal(messageTitle, messageContent);
                  }}>
                  <FontAwesomeIcon className="row" icon={faInfoCircle} />
                </div>
              )}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link className={`nav-link ${setActiveLink(Tab.Affiliate)} d-flex link-info-button`} to={`?tab=${Tab.Affiliate}`}>
              {t('EDIT_PROFILE.TAB.AFFILIATE')}
              {isHelpModus && (
                <div className="infoButton avblInfoButton" style={{ fontSize: '16px' }}
                  onClick={() => {
                    const messageContent = (
                      <div>
                        <p>{t('EDIT_PROFILE.TAB.AFFILIATE')}</p>
                      </div>
                    );
                    const messageTitle = (<>{t('EDIT_PROFILE.TAB.AFFILIATE')}</>);
                    toggleHelpModusModal(messageTitle, messageContent);
                  }}>
                  <FontAwesomeIcon className="row" icon={faInfoCircle} />
                </div>
              )}
            </Link>
          </Nav.Item>
        </Nav>

        {isActiveTab(Tab.CustomerData) && <UserDataForm isHelpModus={isHelpModus} onInfoIconClick={toggleHelpModusModal} />}

        {isActiveTab(Tab.ApiManagement) && (
          <EditProfileForm
            t={t}
            onUpdate={onUpdate}
            data={userKeys}
            onValueChange={onContentChanged}
            addKey={addKey}
            removeRow={removeRow}
            onCreate={onCreate}
            deleteKey={deleteKey}
            isHelpModus={isHelpModus}
            onInfoIconClick={toggleHelpModusModal}
          />
        )}

        {isActiveTab(Tab.UserProfit) && <PaymentMethodsForm />}
        {isActiveTab(Tab.Notifications) && <Notifications onInfoIconClick={toggleHelpModusModal} />}
        {isActiveTab(Tab.Membership) && <Membership onInfoIconClick={toggleHelpModusModal} />}
        {isActiveTab(Tab.Affiliate) && <Affiliate onInfoIconClick={toggleHelpModusModal} />}

        {showHelpModusModal && (
          <HelpModusModal
            show={showHelpModusModal}
            onClose={toggleHelpModusModal}
            title={modalTitle}
            message={modalMessage}
            size={modalSize}
          />
        )}
      </>
    );
  }

  return (
    <>
      <div className="d-none d-lg-block edit-profile w-100" style={{ margin: "auto" }}>
        {renderBody()}
      </div>
      <div className="d-sm-flex d-lg-none flex-column edit-profile w-100" style={{ margin: "auto" }}>
        {renderBody()}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateApiKey: (params) => dispatch(updateApiKey(params)),
  createApiKey: (params) => dispatch(createApiKey(params)),
});
const mapStateToProps = (state) => ({});

EditProfile.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditProfile));
