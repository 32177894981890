import { faBolt, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { SIDE } from "../../../common/constants";
import NumberLabel from "../../../common/form/number-label/number-label";
import CommonHelper from "../../../common/helpers/common-helper";

export const BOT_COLUMNS = (copyBot) => [
  {
    width: 20,
    cellRenderer: (data) => {
      return (
        <div className="d-flex justify-content-end">
          {data.dynamicBotBuffer &&
            <span className="mb-2">
              <FontAwesomeIcon icon={faBolt} className="golden-bolt" />
            </span>}
        </div>        
      );
    },
  },
  {
    field: "botProfit",
    headerName: "CAMPAIGN.FORM_TABLE.PROFIT",
    cellRenderer: (data) => {
      return (
        <div>
          {
            <p className="m-0">
              <NumberLabel value={data.botProfit} />%
            </p>
          }
        </div>
      );
    },
    sortable: true,
    width: 80,
  },
  {
    sortable: false,
    field: "pair",
    headerName: "CAMPAIGN.FORM_TABLE.PAIR",
    cellRenderer: (data) => {
      return data.assetName && data.marketName
        ? `${data.assetName}_${data.marketName}`
        : "";
    },
    filter: true,
    filterKey: "symbol",
  },
  {
    field: "postition",
    headerName: "BOT_OVERVIEW.POSITION",
    width: 240,
    cellRenderer: (data) => {
      const {
        startAtPrice,
        coinPrice,
        endAtPrice,
        botPositionFirstOrder,
        botPositionLastOrder,
      } = data;

      const isCoinPriceHighest =
        coinPrice > startAtPrice && coinPrice > endAtPrice;

      const priceGraphData = [
        {
          key: 1,
          value: CommonHelper.toCustomFixed(startAtPrice),
          classList: (index) =>
            CommonHelper.setPriceBorder(index, isCoinPriceHighest),
        },
        {
          key: 2,
          value: CommonHelper.toCustomFixed(endAtPrice),
          classList: (index) =>
            CommonHelper.setPriceBorder(index, isCoinPriceHighest),
        },
        {
          key: 3,
          value: CommonHelper.toCustomFixed(coinPrice),
          classList: (index) => (index === 1 ? "center" : "outside"),
        },
      ];
      return (
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ width: "45%" }}>
            <p
              style={{
                color: botPositionLastOrder > 0 ? "#42f731" : "red",
              }}
              className="m-0"
            >
              {botPositionLastOrder > 0 ? "+" : ""}
              <NumberLabel value={botPositionLastOrder} />%
            </p>
            <p
              style={{
                color: botPositionFirstOrder > 0 ? "red" : "#42f731",
              }}
              className="m-0"
            >
              {botPositionFirstOrder > 0 ? "+" : ""}
              <NumberLabel value={botPositionFirstOrder} />%
            </p>
          </div>
          <div className="timeline">
            {priceGraphData.sort(CommonHelper.sortDesc).map((price, i) => (
              <div key={price.key} className="timeline-box">
                <div className={`content ${price.classList(i)}`}>
                  <p><NumberLabel value={price.value} maxFractions={6} /></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    },
  },
  {
    sortable: true,
    field: "rounds",
    headerName: "BOT_OVERVIEW.ROUND",
    width: 100,
  },
  {
    sortable: true,
    field: "lotCount",
    headerName: "BOT_OVERVIEW.LOT",
    width: 80,
  },
  {
    sortable: true,
    field: "step",
    headerName: "BOT_OVERVIEW.STEP",
    width: 90,
    type: "number-no-min"
  },
  {
    field: "tradeDate",
    headerName: "BOT_OVERVIEW.FIRST_LAST_TRADE",
    width: 140,
    cellRenderer: (data) => {
      return (
        <div>
          <p className="m-0">
            {data.firstTrade
              ? new Date(data.firstTrade).toLocaleDateString()
              : ""}
          </p>
          <p className="m-0">
            {data.lastTrade
              ? new Date(data.lastTrade).toLocaleDateString()
              : ""}
          </p>
        </div>
      );
    },
  },
  {
    sortable: true,
    field: "type",
    headerName: "ORDER_OVERVIEW_TABLE.SIDE",
    width: 120,
    cellRenderer: (data) => {
      return data.type === 0 ? "BUY" : "SELL";
    },
    filter: true,
    filterType: "select",
    filterKey: "side",
    filterOptions: SIDE,
  },
  {
    sortable: true,
    field: "dynamic",
    headerName: "BOT_OVERVIEW.PROFIT_TYPE",
    width: 130,
    cellRenderer: (data) => {
      return (
        <div>
          <p className="m-0">{data.profitType === 0 ? "Market" : "Asset"}</p>
          <p className="m-0">
            {data.dynamic > 0 ? "+" : ""}
            {data.dynamic}%
          </p>
        </div>
      );
    },
  },
  {
    sortable: true,
    field: "apiKeyUserNickname",
    headerName: "BOT_OVERVIEW.NICKNAME",
    width: 130,
  },
  {
    field: "actions",
    headerName: "ORDER_OVERVIEW_TABLE.ACTIONS",
    type: "action",
    cellRenderer: (data) => {
      return (
        <DropdownButton
          variant="secondary"
          id="dropdown-basic-button"
          title="Actions"
          drop={"left"}
          className="fix-dropdown-margin"
        >
          <Dropdown.Item onClick={() => copyBot(data)}>
            <FontAwesomeIcon icon={faCopy} /> Copy
          </Dropdown.Item>
        </DropdownButton>
      );
    },
  },
];

export const SKIP_FIELDS = [
  "apiKeyName",
  "apiKey",
  "keyId",
  "exchangeId",
  "profitType",
  "dynamic",
  "incomeActionId",
  "campaignId",
  "conditions",
  "firstTrade",
  "lastTrade",
];

export const BEST_PROFIT_BOTS = {
  0: "botsWithHighestProfit",
  1: "botsWithHighestProfit7",
  2: "botsWithHighestProfit31",
};

export const BEST_ROUND_BOTS = {
  0: "botsWithHighestRoundCount",
  1: "botsWithHighestRoundCount7",
  2: "botsWithHighestRoundCount31",
};
