import React, { useState } from 'react';
import ReactDOM from "react-dom";

import I18n, { setLanguage } from "redux-i18n";
import { translations } from "./core/translations";
import { Provider } from "react-redux";

import Routes from "./core/routes";
import { PersistGate } from "redux-persist/integration/react";

import { configureStore } from "./core/store";
import App from "./App";

import { createBrowserHistory } from "history";

import "./index.css";
import { ThemeProvider } from "./common/theme/theme-context";
import { HelpModusProvider } from './common/help-modus/help-modus-context';
import CacheHelper from './common/helpers/cache-helper';
import { ModalProvider } from './common/modal-context';

export const history = createBrowserHistory();
export const { persistor, store, config } = configureStore();

const Index = () => {
  const [localLanguage, setLocalLanguage] = useState(CacheHelper.getLanguage());

  const changeLanguage = (lang) => {
    store.dispatch(setLanguage(lang));
    setLocalLanguage(lang);
    CacheHelper.setLanguage(lang);
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18n translations={translations} initialLang={localLanguage} fallbackLang="en">
          <ThemeProvider>
            <HelpModusProvider>
              <ModalProvider>
                <App>
                  <Routes history={history} language={localLanguage} setLanguage={changeLanguage} />
                </App>
              </ModalProvider>
            </HelpModusProvider>
          </ThemeProvider>
        </I18n>
      </PersistGate>
    </Provider>
  );
};

ReactDOM.render(<Index />, document.getElementById("root"));
