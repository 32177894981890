import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EXCHANGE, SIDE } from "../../../common/constants";
import NumberLabel from "../../../common/form/number-label/number-label";
import CommonHelper from "../../../common/helpers/common-helper";

export const fields = {
  ID: "id",
  Amount: "marketAmount",
  AssetPrice: "assetAmount",
  LotCount: "lotCount",
  Type: "type",
  Step: "step",
  StartAtPrice: "startAtPrice",
  StartAtPercentage: "startAtPercentage",
  KeyId: "keyId",
  ApiKey: "apiKey",
  ApiKeyId: "apiKeyId",
  ApiKeyName: "apiKeyName",
  Market: "marketName",
  Coin: "assetName",
  IsActive: "isActive",
  IncomeActionId: "incomeActionId",
  EndAtPrice: "endAtPrice",
  EndAtPercentage: "endAtPercentage",
  Exchange: "exchange",
  Profit: "profitType",
  Dynamic: "dynamic",
  LowSoftStopPrice: "lowerStop",
  HighSoftStopPrice: "upperStop",
  LowSoftStopQuantity: "softStopLowerQuantity",
  HighSoftStopQuantity: "softStopUpperQuantity",
  DynamicBotBuffer: "dynamicBotBuffer",
  FirstOrder: "firstOrder",
  OrderAmount: "orderAmount",
};

export const CREATE_REQ_FIELDS = [
  fields.KeyId,
  fields.Market,
  fields.Coin,
  fields.AssetPrice,
  fields.Type,
  fields.Amount,
  fields.LotCount,
  fields.StartAtPrice,
  fields.StartAtPercentage,
  fields.Step,
  fields.EndAtPrice,
  fields.EndAtPercentage,
];

export const EDIT_REQ_FIELDS = [
  fields.Market,
  fields.Coin,
  fields.AssetPrice,
  fields.Type,
  fields.Amount,
  fields.LotCount,
  fields.StartAtPrice,
  fields.StartAtPercentage,
  fields.Step,
  fields.EndAtPrice,
  fields.EndAtPercentage,
];

const botProcessingSemaphore = {
  Green: 0,
  Yellow: 1,
  Red: 2,
  Gray: 3
}

export const BOT_OVERVIEW_COLUMNS = [
  {
    field: "orderErrorsCount",
    headerName: "BOT_OVERVIEW.STATUS",
    sortable: false,
    width: 50,
    cellRenderer: (data) => {
      const bgColor = Object.keys(botProcessingSemaphore).find(key => botProcessingSemaphore[key] === data.botProcessingSemaphore);;
      return (
        <div className="d-flex flex-column align-items-center">
          {!!data.dynamicBotBuffer &&
            <span className="mb-2">
              <FontAwesomeIcon icon={faBolt} className="golden-bolt" />
            </span>}
          <span style={{ padding: "3px 10px", color: "#fff", fontSize: 12, background: bgColor, height: "18px" }}
            className="badge rounded-pill">
            {data.orderErrorsCount ? data.orderErrorsCount : ' '}
          </span>
        </div>
      );
    },
  },
  {
    sortable: true,
    field: "botProfit",
    headerName: "BOT_OVERVIEW.PROFIT",
    width: 125,
    cellRenderer: (data) => {
      return (
        <div>
          <p className="m-0"><NumberLabel value={data.botProfit} />%</p>
          <p className="m-0">
            {data.profitType === 0
              ? `Market ${new Intl.NumberFormat('de-DE', { maximumFractionDigits: 8 }).format(data.marketProfit)} ${data.marketName}`
              : `Asset ${new Intl.NumberFormat('de-DE', { maximumFractionDigits: 8 }).format(data.coinProfit)} ${data.assetName}`}
          </p>
        </div>
      );
    },
  },
  {
    sortable: false,
    field: "pair",
    headerName: "BOT_OVERVIEW.PAIR",
    cellRenderer: (data) => {
      return `${data.assetName}_${data.marketName}`;
    },
    filter: true,
    filterKey: "symbol",
    width: 85,
  },
  {
    field: "postition",
    headerName: "BOT_OVERVIEW.POSITION",
    width: 180,
    cellRenderer: (data) => {
      const { startAtPrice, coinPrice, endAtPrice, botPositionFirstOrder, botPositionLastOrder } = data;
      const isCoinPriceHighest = coinPrice > startAtPrice && coinPrice > endAtPrice;

      const priceGraphData = [
        {
          key: 1,
          value: CommonHelper.toCustomFixed(startAtPrice),
          classList: (index) => CommonHelper.setPriceBorder(index, isCoinPriceHighest),
        },
        {
          key: 2,
          value: CommonHelper.toCustomFixed(endAtPrice),
          classList: (index) => CommonHelper.setPriceBorder(index, isCoinPriceHighest),
        },
        {
          key: 3,
          value: CommonHelper.toCustomFixed(coinPrice),
          classList: (index) => (index === 1 ? "center" : "outside"),
        },
      ];
      return (
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ width: "35%" }}>
            <p
              style={{
                color: botPositionLastOrder > 0 ?  "#42f731" : "red",
              }}
              className="m-0"
            >
              {botPositionLastOrder > 0 ? "+" : ""}
              <NumberLabel value={botPositionLastOrder} />%
            </p>
            <p
              style={{
                color: botPositionFirstOrder > 0 ? "red" :  "#42f731",
              }}
              className="m-0"
            >
              {botPositionFirstOrder > 0 ? "+" : ""}
              <NumberLabel value={botPositionFirstOrder} />%
            </p>
          </div>
          <div className="timeline" style={{ width: "65%" }}>
            {priceGraphData.sort(CommonHelper.sortDesc).map((price, i) => (
              <div key={price.key} className="timeline-box">
                <div className={`content ${price.classList(i)}`}>
                  <p><NumberLabel value={price.value} maxFractions={6} /></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    },
  },
  {
    sortable: true,
    field: "rounds",
    headerName: "BOT_OVERVIEW.ROUND",
    width: 80,
  },
  {
    sortable: true,
    field: "marketAmount",
    headerName: "BOT_OVERVIEW.AMOUNT",
    width: 100,
    type: "number"
  },
  {
    sortable: true,
    field: "lotCount",
    headerName: "BOT_OVERVIEW.LOT",
    width: 60,
  },
  {
    sortable: true,
    field: "step",
    headerName: "BOT_OVERVIEW.STEP",
    width: 60,
    type: "number-no-min"
  },
  {
    field: "tradeDate",
    headerRenderer: (props) => (<span>{props.t("BOT_OVERVIEW.FIRST_TRADE")}<br />{props.t("BOT_OVERVIEW.LAST_TRADE")}</span>),
    width: 90,
    cellRenderer: (data) => {
      return (
        <div>
          <p className="m-0 d-flex flex-column">
            <span>{data.firstTrade ? new Date(data.firstTrade).toLocaleDateString() : ""}</span>
            <span style={{ fontSize: '10px' }}>{data.firstTrade ? new Date(data.firstTrade).toLocaleTimeString() : ""}</span>
          </p>
          <p className="m-0 d-flex flex-column">
            <span>{data.lastTrade ? new Date(data.lastTrade).toLocaleDateString() : ""}</span>
            <span style={{ fontSize: '10px' }}>{data.lastTrade ? new Date(data.lastTrade).toLocaleTimeString() : ""}</span>
          </p>
        </div>
      );
    },
  },
  {
    sortable: true,
    field: "type",
    headerName: "ORDER_OVERVIEW_TABLE.SIDE",
    width: 80,
    cellRenderer: (data) => {
      return data.type === 0 ? "BUY" : "SELL";
    },
    filter: true,
    filterKey: "side",
    filterType: "select",
    filterOptions: SIDE,
  },
  {
    sortable: true,
    field: "dynamic",
    headerRenderer: (props) => (<span>{props.t("BOT_OVERVIEW.PROFIT")}<br />{props.t("BOT_OVERVIEW.TYPE")}</span>),
    width: 80,
    cellRenderer: (data) => {
      return (
        <div>
          <p className="m-0">{data.profitType === 0 ? "Market" : "Asset"}</p>
          <p className="m-0">
            {data.dynamic > 0 ? "+" : ""}
            {data.dynamic}%
          </p>
        </div>
      );
    },
  },
  {
    field: "fullApiKeyName",
    headerName: "BOT_OVERVIEW.EXCHANGE_API_KEY",
    width: 120,
    cellRenderer: (data) => {
      return (
        <div>
          <span>{EXCHANGE[data.exchangeId]}</span>
          <br />
          <span>{data.apiKeyName}</span>
        </div>
      );
    },
  },
  {
    sortable: true,
    field: "id",
    headerName: "BOT_OVERVIEW.ID",
    width: 60,
  },
];
