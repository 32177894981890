import { store } from "../../index";

const getState = () => store.getState();

const StoreHelper = {};

StoreHelper.getFormValues = (formName) => {
  if (!formName) return;

  const _state = getState();
  if (_state) {
    try {
      return _state["form"][formName]["values"];
    } catch (error) {
      return;
    }
  }
  return;
};

export default StoreHelper;
