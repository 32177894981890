import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import SwingBot from "../../bots-overview/components/bot";
import { FORMS } from "../../../common/forms";

import "../style/increase-modal.scss";

const BotActionModal = (
  {
    show,
    onClose,
    formData,
    userAssets,
    profileData,
    getAssetPrice,
    handleGetUserAssets,
    amount,
    type,
    onSave,
  },
  { t }
) => {
  const [assetAmount, setAssetAmount] = useState(0);

  useEffect(() => {
    if (formData.marketName === "" || formData.assetName === "") return;

    async function fillAssetAmount() {
      const price = await getAssetPrice(
        formData.marketName,
        formData.assetName,
        formData.apiKeyId
      );
      setAssetAmount(price);
    }

    fillAssetAmount();

    // eslint-disable-next-line
  }, [formData.assetName, formData.marketName]);

  return (
    <Modal show={show} onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t("CAMPAIGN.BOT_ACTION_MODAL.TITLE")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SwingBot
          isSwingBotCampaignAction
          userAssets={userAssets}
          formName={FORMS.botsCreate}
          profileData={profileData}
          onSave={onSave}
          getAssetPrice={getAssetPrice}
          initialValues={{
            isActive: true,
            incomeActionId: 1,
            exchange: "BINANCE",
            keyId: formData.apiKeyId,
            apiKeyId: formData.apiKeyId,
            marketName: formData.marketName,
            assetName: formData.assetName,
            marketAmount: amount,
            type: type,
            assetAmount,
          }}
          getUserAssets={handleGetUserAssets}
          onCampaignActionCancel={onClose}
        />
      </Modal.Body>
    </Modal>
  );
};

BotActionModal.contextTypes = {
  t: PropTypes.func,
};

export default BotActionModal;
