import usersApi from "../../../api/user/actions";
import Helpers from "../../../common/helpers/error-helpers";
import { setUserAssets } from "./action-creators";

export const handleGetUserAssets = (params) => {
  return async (dispatch) => {
    try {
      const res = await usersApi.getUserAssets(params);
      dispatch(setUserAssets(res.data));
    } catch (err) {
      if (err.cErr && err.cErr.code === 4001) {
        return
      }
      Helpers.parseError(err);
    }
  };
};

export const getAssetPrice = (marketName, coinName, apiKeyId) => () =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await usersApi.getAssetPrice(marketName, coinName, apiKeyId);
      resolve(res.data.price);
    } catch (e) {
      reject(e);
    }
  });
