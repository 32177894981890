import CommonHelper from "../../../common/helpers/common-helper";
import actionTypes from "../constants/action-types";

const initState = {
  list: [],
  current: null,
};

export const botsReducer = (state = initState, payload) => {
  switch (payload.type) {
    case actionTypes.GET_BOTS:
      return {
        ...state,
        list: payload.data,
      };
    case actionTypes.DELETE_BOT_SUCCESS:
      return {
        ...state,
      };
    case actionTypes.SET_CURRENT_BOT:
      const bot = {
        ...payload.data,
        profitType:
          payload.data.profitType !== null
            ? payload.data.profitType.toString()
            : null,
        dynamic:
          payload.data.dynamic !== null
            ? payload.data.dynamic.toString()
            : null,
        startAtPrice: payload.data.startAtPrice
          ? CommonHelper.toCustomFixed(payload.data.startAtPrice)
          : null,
        endAtPrice: payload.data.startAtPrice
          ? CommonHelper.toCustomFixed(payload.data.endAtPrice)
          : null,
      };
      return {
        ...state,
        current: bot,
      };
    default:
      return state;
  }
};
