import React from "react";
import PropTypes from "prop-types";

import TradeTable from "../../common/form/table/trade-table";
import LOGS from "../.././changelog.json";

import "./style/change-log.scss";

const columns = [
  {
    field: "changeDate",
    headerName: "CHANGELOG.TABLE.CHANGE-DATE",
    type: "date",
    width: 100,
  },
  {
    field: "changeText",
    headerName: "CHANGELOG.TABLE.CHANGE-TEXT",
    width: 600,
  },
];

const ChangeLog = (props, { t }) => {
  return (
    <div className="changelog-layout">
      <h4 className="page-title">{t("CHANGELOG.PAGE-TITLE")}</h4>
      {LOGS.map((log, key) => (
        <div key={key}>
          <p className="wallet-key-name">Version {log.version}</p>
          <TradeTable
            data={log.changeLog}
            columnDefs={columns}
            style={{ minHeight: "unset" }}
          />
        </div>
      ))}
    </div>
  );
};

ChangeLog.contextTypes = {
  t: PropTypes.func,
};

export default ChangeLog;
