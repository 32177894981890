import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { useHelpModus } from "../../../common/help-modus/help-modus-context";
import HelpModusModal from "../../../common/help-modus/help.modus-modal";
import Loader from "../../../common/loader/components/common-loader";

import Chart from "../../../common/trading-chart/chart";

import barImage from "../../../common/assets/help-modus/help-modus-chart-bar.png";
import chartImage from "../../../common/assets/help-modus/help-modus-chart.png";

const Strategy = (props, { t }) => {
  const [isLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [modalTitle, setModalTitle] = useState('Information');
  const [showHelpModusModal, setShowHelpModusModal] = useState();
  const { isHelpModus } = useHelpModus();
  const initialWidth = window.innerWidth;

  const toggleHelpModusModal = (title, message) => {
    setShowHelpModusModal((showModal) => !showModal);
    setModalMessage(message);
    setModalTitle(title);
  };

  const handleOrientationChange = () => {
    if (window.innerWidth !== initialWidth && window.innerWidth > window.innerHeight) {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleOrientationChange);
    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  return (
    <div className="invoice-layout">
      <h4 className="page-title">{t("LEFT_MENU_ITEM.STRATEGY")}</h4>

      <div className="trade-chart d-flex">
        <Chart
          marketName="USDT"
          assetName="BTC"
          height={720}
          exchangeId='0' />
        {isHelpModus && (
          <div className="infoButton avblInfoButton"
            onClick={() => {
              const messageContent = (
                <div>
                  <p>{t('HELP-MODUS.CREATE-BOT-CHART-FIRST')}</p>
                  <img src={barImage} style={{ width: '25%' }} alt="Create Bot Chart Bar" />
                  <p><br></br>{t('HELP-MODUS.CREATE-BOT-CHART-SECOND')}</p>
                  <img src={chartImage} style={{ width: '100%' }} alt="Create Bot Chart Bar" />
                  <p><br></br>{t('HELP-MODUS.CREATE-BOT-CHART-THIRD')}</p>
                </div>
              );
              toggleHelpModusModal('Chart', messageContent);
            }}>
            <FontAwesomeIcon className="row" icon={faInfoCircle} />
          </div>
        )}
      </div>

      {showHelpModusModal && (
        <HelpModusModal
          show={showHelpModusModal}
          onClose={toggleHelpModusModal}
          title={modalTitle}
          message={modalMessage}
        />
      )}

      <Loader loading={isLoading} type="global" />
    </div>
  );
};

Strategy.contextTypes = {
  t: PropTypes.func,
};

export default Strategy;
