import TokenHelper from "../../../common/helpers/token-helper";
import loginActionTypes from "../constants/action-types";

const userState = {
  userTokens: TokenHelper.getUserTokens(),
};

export const userReducer = (state = userState, payload) => {
  switch (payload.type) {
    case loginActionTypes.HANDLE_USER_LOGIN:
      const userTokens = payload.data || {};
      return { ...state, userTokens };
    default:
      return state;
  }
};
