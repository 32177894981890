import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import userApi from "../../../api/user/actions";
import { PAGE_SIZE_DEFAULT } from "../../../common/constants";
import TradeTable from "../../../common/form/table/trade-table";
import Helpers from "../../../common/helpers/error-helpers";
import Loader from "../../../common/loader/components/common-loader";
import AppPagination from "../../../shared/components/pagination/pagination";
import { AFFILIATE_COLUMNS } from "../constants/constants";

const Affiliate = (_, context) => {
  const { t } = context;
  const [isLoading, setIsLoading] = useState(false);
  const [userAffiliateData, setUserAffiliateData] = useState({
    ownAffiliateId: '',
    affiliates: [{ nickname: '', parentAffiliateName: '' }]
  });
  const [displayedUserAffiliateData, setDisplayedUserAffiliateData] = useState([]);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT);
  const [totalPages, setTotalPages] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const linkRef = useRef(null);

  useEffect(() => {
    getUserAffiliateData();
  }, []);

  useEffect(() => {
    setPagingData();
  }, [pageSize, pageNumber, userAffiliateData]);

  const getUserAffiliateData = async () => {
    try {
      setIsLoading(true);
      const response = await userApi.getUserAffiliateData();
      setIsLoading(false);
      setUserAffiliateData({
        ...response.data,
        ownAffiliateId: response.data.ownAffiliateId ?? '',
        affiliates: response.data.affiliates ?? []
      });
    } catch (err) {
      Helpers.parseError(err);
      setIsLoading(false);
    }
  };

  const setPagingData = () => {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedUserAffiliateData((userAffiliateData && userAffiliateData.affiliates?.slice(startIndex, endIndex)) || []);
    setTotalPages(Math.ceil(((userAffiliateData && userAffiliateData.affiliates) || []).length / pageSize));
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(linkRef.current.value);
  }

  const renderBody = () => {
    return (
      <>
        <div className="input-group mb-3">
          <span className="input-group-text input-form-border w-25">{t("EDIT_PROFILE.AFFILIATE.AFFILIATE-LINK")}</span>
          <input type="text"
            className="form-control"
            value={`${window.location.origin}/register/affiliate/${userAffiliateData.ownAffiliateId}`}
            readOnly
            ref={linkRef}
          />
          <button className="btn btn-outline-secondary affiliate-link" type="button" onClick={() => copyToClipboard()}>
            <FontAwesomeIcon className="row" icon={faCopy} />
          </button>
        </div>
        <h5 className="mb-3">{t("USER-AFFILIATES.TITLE")}</h5>
        <TradeTable
          data={displayedUserAffiliateData}
          columnDefs={AFFILIATE_COLUMNS()}
        />
        <AppPagination
          page={pageNumber}
          totalPages={totalPages || 1}
          pageSize={pageSize}
          setPageSize={setPageSize}
          handlePaginationClick={(_, value) => setPageNumber(value)}
        />
        <Loader loading={isLoading} type="global" />
      </>
    );
  }

  return (
    <>
      <div className="d-none d-lg-flex p-4">
        <div className="w-50">
          {renderBody()}
        </div>
      </div>
      <div className="d-sm-flex d-lg-none">
        <div className="w-100">
          {renderBody()}
        </div>
      </div>
    </>
  );
};

Affiliate.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Affiliate;
