import { createContext, useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

export const HelpModusContext = createContext();

export const useHelpModus = () => {
  const context = useContext(HelpModusContext);
  if (!context) {
    throw new Error("useHelpModus must be used within a HelpModusProvider");
  }
  return context;
};

export const HelpModusProvider = ({ children }) => {
  const [isHelpModus, setIsHelpModus] = useState(() => {
    const storedValue = localStorage.getItem("isHelpModus");
    return storedValue ? JSON.parse(storedValue) : false;
  });

  const toggleHelpModus = () => {
    const newIsHelpModus = !isHelpModus;
    setIsHelpModus(newIsHelpModus);
    localStorage.setItem("isHelpModus", JSON.stringify(newIsHelpModus));
  };

  return (
    <HelpModusContext.Provider value={{ isHelpModus, toggleHelpModus }}>
      {children}
    </HelpModusContext.Provider>
  );
};

export const HelpModusSwitch = styled(Switch)(() => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#8796A5",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#8796A5",
    borderRadius: 20 / 2,
  },
}));