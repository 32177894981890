import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { handleGetBotData, handleSaveBotData } from "../../bots-overview/actions/index";
import { handleGetProfileData } from "../../edit-profile/actions/profile-actions";
import { handleGetUserAssets, getAssetPrice } from "../../user-assets/actions/user-assets-actions";

import { FORMS } from "../../../common/forms";
import BotUnit from "../../bots-overview/components/bot";
import NotificationProvider from "../../../core/notification";
import { routes } from "../../../common/routes-constants";
import IncreaseModal from "../../campaign/modals/increase-modal";
import Helpers from "../../../common/helpers/error-helpers";
import botOrderApi from "../../../api/trade-order/actions";
import { useHelpModus } from "../../../common/help-modus/help-modus-context";
import HelpModusModal from "../../../common/help-modus/help.modus-modal";
import UserHelper from "../../../common/helpers/user-helper";

const BotsCreate = (props) => {
  const {
    handleGetProfileData,
    handleGetBotData,
    location,
    handleGetUserAssets,
    bot,
    profileData,
    userAssets,
    handleSaveBotData,
    getAssetPrice,
    history,
  } = props;

  const [showIncreaseModal, setShowIncreaseModal] = useState();
  const [showHelpModusModal, setShowHelpModusModal] = useState();
  const [assetName, setAssetName] = useState("");
  const [marketName, setMarketName] = useState();
  const [apiKeyId, setApiKeyId] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [modalTitle, setModalTitle] = useState('Information');
  const [isMaxBotCountReached, setIsMaxBotCountReached] = useState(false);
  const { isHelpModus } = useHelpModus();

  const queryParams = new URLSearchParams(location.search);
  const copyBotId = queryParams.get("copyBotId");
  const isCopyBot = copyBotId ? true : false;
  const userApiKey = copyBotId && bot && bot.keyId || !copyBotId && profileData && profileData[0]?.id;
  const { role } = UserHelper.getUserInfo();

  const initialValues =
    bot && isCopyBot
      ? {
          ...bot,
          id: 0,
          conditions: [],
        }
      : {
          isActive: true,
          incomeActionId: 1,
          type: 0,
          conditions: [],
        };

  useEffect(() => {
    handleGetProfileData();
    copyBotId && handleGetBotData(copyBotId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyBotId]);

  useEffect(() => {
    bot && userApiKey && handleGetUserAssets(userApiKey);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bot, userApiKey]);

  useEffect(() => {
    botOrderApi.getUserBotCount().then(result => {
      const hasReachedMax = result.data.userBotCount >= result.data.maxBotCount;
      setIsMaxBotCountReached(hasReachedMax);
      if (hasReachedMax) {
        NotificationProvider.warning("Maximum number of bots is reached.");
      }
    })
    .catch((err) => {
      Helpers.parseError(err);
    });
  }, []);

  const handleOnSave = (params) => {
    handleSaveBotData(params)
      .then(() => {
          NotificationProvider.success("GRID Bot successfully created");
        history.push(routes.protectedRoutes.botsOverview.fullPath);
      })
      .catch((err) => {
        Helpers.parseError(err);
      });
  };

  const toggleIncreaseModal = (assetName, marketName, apiKeyId) => {
    setShowIncreaseModal((showModal) => !showModal);
    setAssetName(assetName);
    setMarketName(marketName);
    setApiKeyId(apiKeyId);
  };

  const toggleHelpModusModal = (title, message) => {
    setShowHelpModusModal((showModal) => !showModal);
    setModalMessage(message);
    setModalTitle(title);
  };

  const createIncreaseOrder = async (increaseOrderData) => {
    try {
      const request = {
        ...increaseOrderData,
        apiKeyId: apiKeyId,
      };
      await botOrderApi.createOrder(request).then(() => {
        setTimeout(() => {
          handleGetUserAssets(apiKeyId);          
          toggleIncreaseModal();
          NotificationProvider.success("Increase order successfully created");
        }, 500);
      });
    } catch (err) {
      Helpers.parseError(err);
      toggleIncreaseModal();
    }
  };

  const getAvailableUserCoins = useCallback(() => {
    if (!assetName) {
      return "N\\A";
    }

    let coinAmount =
      userAssets &&
      userAssets.assets?.find(({ name }) => name === assetName);

    return coinAmount === undefined ? 0 : coinAmount.amountAvailable;
  }, [userAssets, assetName]);

  const getAvailableUserMarkets = useCallback(() => {
    if (!marketName) {
        return "N\\A";
    }

    let marketAmount =
      userAssets &&
      userAssets.markets?.find(({ name }) => name === marketName);

    return marketAmount === undefined ? 0 : marketAmount.amountAvailable;
  }, [userAssets, marketName]);

  return (
    <div>
      <div>{!profileData[0] ? <label style={{ color: "red", fontWeight: "bold" }}>Please add at least one API key</label> : null}</div>
      <BotUnit
        isCopyBot={isCopyBot}
        userAssets={userAssets}
        profileData={profileData}
        formName={FORMS.botsCreate}
        onSave={handleOnSave}
        getAssetPrice={getAssetPrice}
        initialValues={initialValues}
        getUserAssets={handleGetUserAssets}
        onIncreaseClick={toggleIncreaseModal}
        onInfoIconClick={toggleHelpModusModal}
        isHelpModus={isHelpModus}
        isMaxBotCountReached={isMaxBotCountReached}
        role={role}
      />

      {showIncreaseModal && (
        <IncreaseModal
          show={showIncreaseModal}
          onClose={toggleIncreaseModal}
          assetName={assetName}
          marketName={marketName}
          apiKeyId={apiKeyId}
          getAvailableUserCoins={getAvailableUserCoins}
          getAvailableUserMarkets={getAvailableUserMarkets}
          getAssetPrice={getAssetPrice}
          onSave={createIncreaseOrder}
        />
      )}
      {showHelpModusModal && (
        <HelpModusModal
          show={showHelpModusModal}
          onClose={toggleHelpModusModal}
          title={modalTitle}
          message={modalMessage}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  profileData: state.profileReducer.userApiKeys,
  userAssets: state.userAssetsReducer,
  bot: state.botsReducer.current,
});

const mapDispatchToProps = (dispatch) => ({
  handleGetBotData: (id) => dispatch(handleGetBotData(id, { "can-access-resource": true })),
  handleSaveBotData: (params) => dispatch(handleSaveBotData(params)),
  handleGetProfileData: () => dispatch(handleGetProfileData()),
  handleGetUserAssets: (params) => dispatch(handleGetUserAssets(params)),
  getAssetPrice: (marketName, coinName, apiKeyId) => dispatch(getAssetPrice(marketName, coinName, apiKeyId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BotsCreate));
