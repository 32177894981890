import { API_BASE_URL } from "../../common/constants";
import { Api } from "../../core/api";

import routesConstatns from "./routes";

const walletApi = {};

walletApi.getWalletInfo = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_WALLET_INFO}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

walletApi.getProfit = async (headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_PROFIT}`,
    {
      needLoader: false
    },
    headers
  );
};

walletApi.getLeftOverBuyPrice = async (headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_LEFT_OVER_BUY_PRICE}`,
    {
      needLoader: true,
    },
    headers
  );
};

export default walletApi;
