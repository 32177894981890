import { reducer as formReducer } from "redux-form";
import { i18nState } from "redux-i18n";
import { persistCombineReducers } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";
import { botsReducer } from "../app/bots-overview/reducers";
import { profileReducer } from "../app/edit-profile/reducers/profile-reducer";
import { userReducer } from "../app/login/reducers/user-reducer";
import { userAssetsReducer } from "../app/user-assets/reducers/user-assets-reducer";
import { PERSISTOR_SECRET_KEY } from "../common/constants";
import { containerReducer } from "../common/containers/reducers/container-reducer";
import { loaderReducer } from "../common/loader/reducers/loader-reducer";

const encryptor = encryptTransform({
  secretKey: PERSISTOR_SECRET_KEY,
});
const config = {
  key: "root",
  // for those we need to be saved (only these will be saved)
  whitelist: ["containerReducer"],
  storage,
  transforms: [encryptor],
};
const rootReducer = persistCombineReducers(config, {
  i18nState,
  userReducer,
  loaderReducer,
  containerReducer,
  profileReducer,
  botsReducer,
  userAssetsReducer,
  form: formReducer,
});

export { rootReducer, config };
