import { API_BASE_URL } from "../../common/constants";
import { Api } from "../../core/api";

import routesConstatns from "./routes";

const bothApi = {};

bothApi.get = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_BOTS}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

bothApi.save = async (params, headers) => {
  return await Api.post(
    `${API_BASE_URL}${routesConstatns.SAVE_BOT}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

bothApi.update = async (params, headers) => {
  return await Api.put(
    `${API_BASE_URL}${routesConstatns.UPDATE_BOT}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

bothApi.deleteBot = async (params, headers) => {
  return await Api.delete(
    `${API_BASE_URL}${routesConstatns.DELETE_BOT}/${params.id}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

bothApi.getSingle = async (id, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_BOT}/${id}`,
    {
      needLoader: true,
    },
    headers
  );
};

bothApi.closeBot = async (params, headers) => {
  return await Api.post(
    `${API_BASE_URL}${routesConstatns.CLOSE_BOT}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

bothApi.copy = async (params, headers) => {
  return await Api.post(
    `${API_BASE_URL}${routesConstatns.COPY_BOT}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

bothApi.getBestBots = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_BEST_BOTS}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

export default bothApi;
