import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import {
  faBullhorn,
  faCodeBranch,
  faCreditCard,
  faChartLine,
  faExchangeAlt,
  faHome,
  faPuzzlePiece,
  faRobot,
  faShoppingCart,
  faUsersCog
} from "@fortawesome/free-solid-svg-icons";
import UserHelper from "../../helpers/user-helper";
import { routes } from "../../routes-constants";

export const leftMenuItems = [
  {
    text: "LEFT_MENU_ITEM.DASHBOARD",
    icon: faHome,
    link: routes.protectedRoutes.dashboard.fullPath,
  },
  {
    text: "LEFT_MENU_ITEM.STRATEGY",
    icon: faPuzzlePiece,
    link: routes.protectedRoutes.strategy.fullPath,
  },
  {
    text: "LEFT_MENU_ITEM.CAMPAIGN",
    icon: faBullhorn,
    hidden: () => !UserHelper.isCampaignVisible(),
    subItems: [
      {
        text: "LEFT_MENU_SUB_ITEM.OVERVIEW",
        link: routes.protectedRoutes.campaignOverview.fullPath,
      },
      {
        text: "LEFT_MENU_SUB_ITEM.CREATE",
        link: routes.protectedRoutes.campaign.fullPath,
      },
      {
        text: "LEFT_MENU_ITEM.CONTEST",
        link: routes.protectedRoutes.campaignContest.fullPath,
      },
    ]
  },
  {
    text: "LEFT_MENU_ITEM.BOTS",
    icon: faRobot,
    subItems: [
      {
        text: "LEFT_MENU_SUB_ITEM.OVERVIEW",
        link: routes.protectedRoutes.botsOverview.fullPath,
      },
      {
        text: "LEFT_MENU_SUB_ITEM.CREATE",
        link: routes.protectedRoutes.botsCreate.fullPath,
      },
      {
        text: "LEFT_MENU_ITEM.CONTEST",
        link: routes.protectedRoutes.contest.fullPath,
      },
    ],
  },
  // {
  //   text: "LEFT_MENU_ITEM.ORDER-OVERVIEW",
  //   icon: faChartLine,
  //   link: routes.protectedRoutes.orderOverview.fullPath,
  // },

  {
    text: "LEFT_MENU_ITEM.SMART-TRADE",
    icon: faExchangeAlt,
    subItems: [
      {
        text: "LEFT_MENU_SUB_ITEM.OVERVIEW",
        link: routes.protectedRoutes.smartTradeOverviewView.fullPath,
      },
      {
        text: "LEFT_MENU_SUB_ITEM.CREATE",
        link: routes.protectedRoutes.smartTradeView.fullPath,
      },
    ],
  },
  {
    text: "LEFT_MENU_ITEM.TRAILING_ORDER",
    icon: faChartLine,
    link: routes.protectedRoutes.trailingOrderView.fullPath,
  },
  {
    text: "LEFT_MENU_ITEM.MARKET-ORDERS",
    icon: faShoppingCart,
    link: routes.protectedRoutes.marketOrdersView.fullPath,
  },
  // {
  //   text: "LEFT_MENU_ITEM.STOP-LOSS",
  //   icon: faChartArea,
  //   link: routes.protectedRoutes.stopLossView.fullPath,
  // },
  {
    text: "LEFT_MENU_ITEM.WALLET",
    icon: faCreditCard,
    link: routes.protectedRoutes.wallet.fullPath,
  },
  {
    text: "LEFT_MENU_ITEM.INVOICES",
    icon: faFilePdf,
    link: routes.protectedRoutes.invoices.fullPath,
  },
  {
    text: "LEFT_MENU_ITEM.USER-SETTINGS",
    icon: faUsersCog,
    link: routes.protectedRoutes.userSettings.fullPath,
    isForAdmin: () => !UserHelper.isAdminUser(),
  },
  {
    text: "LEFT_MENU_ITEM.CHANGELOG",
    icon: faCodeBranch,
    link: routes.protectedRoutes.changelog.fullPath,
  },
];
