export const normalizeValueToFloat = (value, previousValue) => {
  if (!value) {
    return value;
  }
  return parseFloat(value);
};

export const normalizeValueToInt = (value, previousValue) => {
  if (!value || value === '-') {
    return value;
  }
  return parseInt(value);
};
