const LANGUAGE_INFO = "LANGUAGE_INFO";

class CacheHelper {
  static setLanguage(language) {
    localStorage.setItem(
      LANGUAGE_INFO,
      language
    );
  }

  static getLanguage() {
    return localStorage.getItem("LANGUAGE_INFO") || "de";
  }
}
export default CacheHelper;
