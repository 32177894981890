import {
  faEuroSign,
  faFileDownload,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberLabel from "../../../common/form/number-label/number-label";
import UserHelper from "../../../common/helpers/user-helper";

export const INVOICE_COLUMNS = (
  printInvoiceDetails,
  printInvoiceMain,
  downloadMainPdf,
  downloadDetailsPdf,
  payInvoice,
  updateDtaSelection,
  toggleAll
) => [
  {
    field: "isSelected",
    headerRenderer: () => (
      <input
        id="masterCheckbox"
        type="checkbox"
        style={{ cursor: "pointer" }}
        onChange={(e) => toggleAll(e.target.checked)}></input>
    ),
    cellRenderer: (data) => (
      <input
        id={`checkbox-${data.id}`}
        checked={data.isSelected}
        onChange={(e) => { data.isSelected = e.target.checked; updateDtaSelection(data.id, e.target.checked); }}
        style={{ cursor: "pointer" }}
        type="checkbox"></input>
    ),
    width: 30,
  },
  {
    field: "id",
    headerName: "INVOICE.TABLE.ID",
    width: 50,
  },
  {
    field: "number",
    headerName: "INVOICE.TABLE.NUMBER",
    width: 50,
  },
  {
    field: "name",
    headerName: "INVOICE.TABLE.NAME",
  },
  {
    field: "country",
    headerName: "INVOICE.TABLE.COUNTRY",
    width: 50,
  },
  {
    field: "startDate",
    headerName: "INVOICE.TABLE.DATE-FROM",
    type: "date",
    width: 70,
  },
  {
    field: "endDate",
    headerName: "INVOICE.TABLE.DATE-TO",
    type: "date",
    width: 70,
  },
  {
    field: "isPaid",
    headerName: "INVOICE.TABLE.STATUS",
    cellRenderer: (data) =>
      data.isPaid ? (
        <span style={{ color: "#42F731", fontWeight: 500 }}>Yes</span>
      ) : (
        <span style={{ color: "#f76565", fontWeight: 500 }}>No</span>
      ),
    width: 70,
  },
  {
    field: "totalProfitInEuro",
    headerName: "INVOICE.TABLE.TOTAL-PROFIT",
    cellRenderer: (data) => <span><NumberLabel value={data.totalProfitInEuro} />&euro;</span>,
    width: 100,
  },
  {
    field: "invoiceActions",
    headerName: "INVOICE.TABLE.INVOICE-ACTIONS",
    cellRenderer: (data) => (
      <div>
        {
          UserHelper.isAdminUser() && (
          <FontAwesomeIcon
            title="Pay Invoice"
            className="mr-4"
            style={{ cursor: "pointer" }}
            icon={faEuroSign}
            onClick={(e) => {
              e.stopPropagation();
              payInvoice(data);
            }}
          />)
        }
        <FontAwesomeIcon
          title="Print Invoice"
          className="mr-4"
          style={{ cursor: "pointer" }}
          icon={faPrint}
          onClick={(e) => {
            e.stopPropagation();
            printInvoiceMain(data);
          }}
        />
        <FontAwesomeIcon
          title="Download pdf"
          style={{ cursor: "pointer" }}
          icon={faFileDownload}
          onClick={(e) => {
            e.stopPropagation();
            downloadMainPdf(data);
          }}
        />
      </div>
    ),

    width: 60,
  },
  // Commented actions retrieve data from Invoices table which is currently not in use
  //{
  //  field: "invoiceDetailsActions",
  //  headerName: "INVOICE.TABLE.INVOICE-DETAILS-ACTIONS",
  //  cellRenderer: (data) => (
  //    <div>
  //      <FontAwesomeIcon
  //        title="Print Invoice Details"
  //        className="mr-4"
  //        style={{ cursor: "pointer" }}
  //        icon={faPrint}
  //        onClick={() => printInvoiceDetails(data)}
  //      />
  //      <FontAwesomeIcon
  //        title="Download pdf"
  //        style={{ cursor: "pointer" }}
  //        icon={faFileDownload}
  //        onClick={() => downloadDetailsPdf(data)}
  //      />
  //    </div>
  //  ),

  //  width: 60,
  //},
];
