import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faBan, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { DropdownButton, Dropdown } from "react-bootstrap";

import campaignApi from "../../../api/campaign/actions";
import Helpers from "../../../common/helpers/error-helpers";
import TradeTable from "../../../common/form/table/trade-table";
import { routes } from "../../../common/routes-constants";
import AppPagination from "../../../shared/components/pagination/pagination";
import { PAGE_SIZE_DEFAULT } from "../../../common/constants";
import NotificationProvider from "../../../core/notification";
import { useHelpModus } from "../../../common/help-modus/help-modus-context";
import HelpModusModal from "../../../common/help-modus/help.modus-modal";

const CampaignOverview = (props, { t }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const [showHelpModusModal, setShowHelpModusModal] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [modalTitle, setModalTitle] = useState('Information');
  const { isHelpModus } = useHelpModus();

  const columns = [
    {
      field: "campaignNumber",
      headerName: "CAMPAIGN.FORM.CAMPAIGN-NO",
      width: 70,
      type: "sequence",
    },
    {
      sortable: true,
      field: "exchanges",
      headerName: "CAMPAIGN.FORM.EXCHANGES",
      cellRenderer: () => {
        return (
          <div>
            <p className="m-0">Binance</p>
          </div>
        );
      },
    },
    {
      sortable: true,
      field: "pair",
      headerName: "CAMPAIGN.FORM.PAIR",
      cellRenderer: (data) => {
        return `${data.assetName}_${data.marketName}`;
      },
    },
    {
      sortable: true,
      field: "type",
      headerName: "CAMPAIGN.FORM.TYPE",
      cellRenderer: (data) => {
        return data.type === 0 ? "BUY" : "SELL";
      },
    },
    {
      sortable: true,
      field: "assetAmount",
      headerName: "CAMPAIGN.FORM.AMOUNT",
    },
    {
      sortable: true,
      field: "status",
      headerName: "CAMPAIGN.FORM_TABLE.STATUS",
      cellRenderer: ({ status }) => {
        return (
          <span
            className={`status ${status === 0 ? "error" : "active"} ml-2 mb-2`}
          >
            {status === 0 ? "Inactive" : "Active"}
          </span>
        );
      },
    },
    {
      sortable: true,
      field: "id",
      headerName: "CAMPAIGN.FORM.ID",
    },
    {
      field: "actions",
      headerName: "ORDER_OVERVIEW_TABLE.ACTIONS",
      type: "action",
      width: 80,
      cellRenderer: (data) => {
        return (
          <DropdownButton
            variant="secondary"
            id="dropdown-basic-button"
            title="Actions"
            drop={"left"}
            className="fix-dropdown-margin"
          >
            <Dropdown.Item
              href={`${routes.protectedRoutes.campaignEdit.path}/${data.id}`}
            >
              <FontAwesomeIcon icon={faEdit} /> {t("CAMPAIGN.FORM.EDIT")}
            </Dropdown.Item>
            <Dropdown.Item
              disabled={data.status === 0}
              href="#"
              onClick={() => cancelCampaign(data.id)}
            >
              <FontAwesomeIcon icon={faBan} />{" "}
              {t("CAMPAIGN.OVERVIEW.CLOSE-CAMPAIGN")}
            </Dropdown.Item>
          </DropdownButton>
        );
      },
    },
  ];

  const initialWidth = window.innerWidth;

  const handleOrientationChange = () => {
    if (window.innerWidth !== initialWidth && window.innerWidth > window.innerHeight) {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleOrientationChange);
    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    getUserCampaigns();

    // eslint-disable-next-line
  }, [pageNumber, pageSize]);

  const getUserCampaigns = async () => {
    try {
      const response = await campaignApi.getUserCampaigns({
        params: { pageNumber, pageSize },
      });
      setCampaigns(response.data);
      setIsLoading(false);
    } catch (err) {
      Helpers.parseError(err);
      setCampaigns([]);
      setIsLoading(false);
    }
  };

  const cancelCampaign = async (id) => {
    try {
      await campaignApi.cancelCampaign({ id });
      getUserCampaigns();
      NotificationProvider.success("Campaign successfully canceled");
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  const toggleHelpModusModal = (title, message) => {
    setShowHelpModusModal((showModal) => !showModal);
    setModalMessage(message);
    setModalTitle(title);
  };

  return (
    <div className="order-overview-layout">
      {isHelpModus && (
        <div className="infoButton" style={{ float: 'left', left: '15px' }}
          onClick={() => {
            const messageContent = (
              <div>
                <p>{t('HELP-MODUS.CAMPAIGN-OVERVIEW')}</p>
              </div>
            );
            toggleHelpModusModal('Campaign Overview', messageContent);
          }}>
          <FontAwesomeIcon className="row" icon={faInfoCircle} />
        </div>
      )}
      <AppPagination
        page={pageNumber}
        totalPages={campaigns.totalPages || 1}
        pageSize={pageSize}
        setPageSize={setPageSize}
        handlePaginationClick={(_, value) => setPageNumber(value)}
      />
      <TradeTable
        data={campaigns.data}
        columnDefs={columns}
        sequenceStep={(pageNumber - 1) * pageSize}
        isLoading={isLoading}
      />
      {showHelpModusModal && (
        <HelpModusModal
          show={showHelpModusModal}
          onClose={toggleHelpModusModal}
          title={modalTitle}
          message={modalMessage}
        />
      )}
    </div>
  );
};

CampaignOverview.contextTypes = {
  t: PropTypes.func,
};

export default CampaignOverview;
