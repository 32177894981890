import loginActionTypes from "../constants/action-types";

const profileState = {
  userApiKeys: [],
};

export const profileReducer = (state = profileState, payload) => {
  switch (payload.type) {
    case loginActionTypes.HANDLE_USER_PROFILE:
      const data = payload.data ? { ...state, ...payload.data } : {};
      return { ...data };

    case loginActionTypes.USER_API_KEYS:
      const userApiKeys = payload.data ? payload.data : [];
      return {
        ...state,
        userApiKeys,
      };

    default:
      return state;
  }
};
