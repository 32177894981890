import { faInfoCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "@mui/material/Slider";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import Button from "../../../common/form/button/components/button";
import InputNumber from "../../../common/form/input/components/number-input";
import NumberLabel from "../../../common/form/number-label/number-label";
import CommonHelper from "../../../common/helpers/common-helper";
import Chart from "../../../common/trading-chart/chart";
import NotificationProvider from "../../../core/notification";
import ApiKey from "../../../shared/components/api-key/api-key";

import barImage from "../../../common/assets/help-modus/help-modus-chart-bar.png";
import chartImage from "../../../common/assets/help-modus/help-modus-chart.png";

const CampaignForm = (
  {
    formData = {},
    onChangeHandler,
    userAssets = {},
    getUserAssets,
    onIncreaseClick,
    setFormData,
    isHelpModus,
    toggleHelpModusModal,
    exchangeId,
    isMobile,
  },
  { t }
) => {
  const [userCurrentMarketValue, setUserCurrentMarketValue] = useState("N\\A");
  const [userRealMarketValue, setUserRealMarketValue] = useState("N\\A");
  const [userCurrentCoinValue, setUserCurrentCoinValue] = useState("N\\A");
  const [userRealCoinValue, setUserRealCoinValue] = useState("N\\A");

  useEffect(() => {
    if (!formData.id) return;
    const { marketName, assetName } = formData;

    handleMarketSelectChange(marketName);
    onCoinChange(assetName);

    // eslint-disable-next-line
  }, [formData.id]);

  const valueText = (value) => {
    return `${value}%`;
  };

  const handleMarketSelectChange = (value) => {
    if (!value) {
      setUserCurrentMarketValue("N\\A");
      setUserRealMarketValue("N\\A");
      return;
    }

    let marketAmount = userAssets.markets?.find(
      (element) => element.name === value
    );

    setUserCurrentMarketValue(
      marketAmount === undefined ? 0 : CommonHelper.roundNumber(marketAmount.amountAvailable, 4)
    );

    setUserRealMarketValue(
      marketAmount === undefined ? 0 : CommonHelper.roundNumber(marketAmount.realAvailability, 4)
    );
  };

  const onCoinChange = (value) => {
    if (!value) {
      setUserCurrentCoinValue("N\\A");
      userRealCoinValue("N\\A");
      return;
    }

    let coinAmount = userAssets.assets?.find(
      (element) => element.name === value
    );

    setUserCurrentCoinValue(
      coinAmount === undefined ? 0 : CommonHelper.roundNumber(coinAmount.amountAvailable, 4)
    );

    setUserRealCoinValue(
      coinAmount === undefined ? 0 : CommonHelper.roundNumber(coinAmount.realAvailability, 4)
    );
  };

  const onAssetAmountPercentageChange = (e) => {
    const { value } = e.target;
    if (userCurrentCoinValue === 0) {
      return onChangeHandler(e);
    }
    const assetAmount = CommonHelper.calculatePercentage(
      value,
      userCurrentCoinValue
    );
    setFormData({ ...formData, assetAmountPercentage: +value, assetAmount });
  };

  const onMarketAmountPercentageChange = (e) => {
    const { value } = e.target;
    if (userCurrentMarketValue === 0) {
      return onChangeHandler(e);
    }
    const marketAmount = CommonHelper.calculatePercentage(
      value,
      userCurrentMarketValue
    );
    setFormData({ ...formData, marketAmountPercentage: +value, marketAmount });
  };

  const onAssetAmountChange = (e) => {
    const { value } = e.target;
    if (isNaN(userCurrentCoinValue)) {
      return NotificationProvider.warning("Coin value is not present");
    }
    if (value > userCurrentCoinValue) {
      return NotificationProvider.warning("Value is greater than available");
    }

    const assetAmountPercentage = Math.floor(
      CommonHelper.calculatePercentageIncrease(value, userCurrentCoinValue)
    );
    setFormData({ ...formData, assetAmount: +value, assetAmountPercentage });
  };

  const onMarketAmountChange = (e) => {
    const { value } = e.target;
    if (isNaN(userCurrentMarketValue)) {
      return NotificationProvider.warning("Market value is not present");
    }
    if (value > userCurrentMarketValue) {
      return NotificationProvider.warning("Value is greater than available");
    }

    const marketAmountPercentage = Math.floor(
      CommonHelper.calculatePercentageIncrease(value, userCurrentMarketValue)
    );
    setFormData({ ...formData, marketAmount: +value, marketAmountPercentage });
  };

  return (
    <div className="w-100">
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} className="mb-2">
          <div className="d-flex" style={{ position: 'relative' }}>
            <ApiKey
              classList="w-75 mr-auto ml-auto"
              onChange={(value, exchangeId) => {
                const param = {
                  target: { name: "apiKeyId", value, exchangeId },
                };
                getUserAssets(value);
                onChangeHandler(param);
              }}
              isHelpModus={isHelpModus}
              onInfoIconClick={toggleHelpModusModal}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center my-2">
            <div className="d-flex w-50">
              <select name="assetName"
                onChange={(e) => {
                  onCoinChange(e.target.value);
                  onChangeHandler(e);
                }}
                value={formData.assetName}
                className="form-control br-025">
                <option value="">{t("CAMPAIGN.FORM.SELECT-COIN")}</option>
                {userAssets.assets?.map((asset) => (
                  <option key={asset.id} value={asset.name}>
                    {asset.name}
                  </option>
                ))}
              </select>
            </div>
            {isHelpModus && (
              <div className="infoButton avblInfoButton" style={{ top: '-25px' }}
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p>{t('HELP-MODUS.CREATE-BOT-COIN')}</p>
                    </div>
                  );
                  toggleHelpModusModal('Asset', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
            <div className="vertical-line mb-0"></div>
            <div className="d-flex w-50">
              <select name="marketName"
                onChange={(e) => {
                  handleMarketSelectChange(e.target.value);
                  onChangeHandler(e);
                }}
                value={formData.marketName}
                className="form-control br-025">
                <option value={""}>{t("CAMPAIGN.FORM.SELECT-MARKET")}</option>
                {userAssets.markets?.map((market) => (
                  <option key={market.id} value={market.name}>
                    {market.name}
                  </option>
                ))}
              </select>
            </div>
            {isHelpModus && (
              <div className="infoButton avblInfoButton" style={{ top: '-25px' }}
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p>{t('HELP-MODUS.CAMPAIGN-MARKET')}</p>
                    </div>
                  );
                  toggleHelpModusModal('Market', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </div>
        </Col>
        <Col md={12} lg={12} sm={12} xs={12}>
          <div className="d-flex">
            <div className="d-flex flex-column w-50">
              <div className="d-flex d-flex justify-content-end align-items-right">
                <span style={{ fontSize: 14, color: "#a1a1a1", textAlign: "right" }} className="mr-2">
                  {t("AVBL")}
                </span>
                <span className="mr-3" style={{ fontSize: 14, fontWeight: 600 }}>
                  <NumberLabel value={userCurrentMarketValue} maxFractions={10} /> {formData.marketName}
                </span>
                {isHelpModus && (
                  <div className="infoButton avblInfoButton" style={{ right: 0 }}
                    onClick={() => {
                      const messageContent = (
                        <div>
                          <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CAMPAIGN-AVBL') }} />
                        </div>
                      );
                      const messageTitle = (<>{t("AVBL")}/{t("REAL-AVBL")}</>);
                      toggleHelpModusModal(messageTitle, messageContent);
                    }}>
                    <FontAwesomeIcon className="row" icon={faInfoCircle} />
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-end align-items-right">
                <span style={{ fontSize: 14, color: "#a1a1a1", textAlign: "right" }} className="mr-2">
                  {t("REAL-AVBL")}
                </span>
                <span className="mr-3" style={{ fontSize: 14, fontWeight: 600 }}>
                  <NumberLabel value={userRealMarketValue} maxFractions={10} /> {formData.marketName}
                </span>
              </div>
            </div>
            <div className="d-flex flex-column w-50">
              <div className="d-flex justify-content-end align-items-right">
                <span style={{ fontSize: 14, color: "#a1a1a1", textAlign: "right" }} className="mr-2">
                  {t("AVBL")}
                </span>
                <span style={{ fontSize: 14, fontWeight: 600 }}>
                  <NumberLabel value={userCurrentCoinValue} maxFractions={10} /> {formData.assetName}
                </span>
                {isHelpModus && (
                  <div className="infoButton avblInfoButton"
                    onClick={() => {
                      const messageContent = (
                        <div>
                          <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CAMPAIGN-ASSET-AVBL') }} />
                        </div>
                      );
                      const messageTitle = (<>{t("AVBL")}/{t("REAL-AVBL")}</>);
                      toggleHelpModusModal(messageTitle, messageContent);
                    }}>
                    <FontAwesomeIcon className="row" icon={faInfoCircle} />
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-end align-items-right">
                <span style={{ fontSize: 14, color: "#a1a1a1", textAlign: "right" }} className="mr-2">
                  {t("REAL-AVBL")}
                </span>
                <span style={{ fontSize: 14, fontWeight: 600 }}>
                  <NumberLabel value={userRealCoinValue} maxFractions={10} /> {formData.assetName}
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {isMobile &&
        <div className="trade-chart pt-3 d-flex" style={{ height: 400, width: "100%" }}>
          <Chart
            height={400}
            assetName={formData.assetName}
            marketName={formData.marketName}
            exchangeId={exchangeId}
          />
          {isHelpModus && (
            <div className="infoButton asset-info-btn"
              onClick={() => {
                const messageContent = (
                  <div>
                    <p>{t('HELP-MODUS.CREATE-BOT-CHART-FIRST')}</p>
                    <img src={barImage} style={{ width: '25%' }} alt="Create Bot Chart Bar" />
                    <p><br></br>{t('HELP-MODUS.CREATE-BOT-CHART-SECOND')}</p>
                    <img src={chartImage} style={{ width: '100%' }} alt="Create Bot Chart Bar" />
                    <p><br></br>{t('HELP-MODUS.CREATE-BOT-CHART-THIRD')}</p>
                  </div>
                );
                toggleHelpModusModal('Chart', messageContent);
              }}>
              <FontAwesomeIcon className="row" icon={faInfoCircle} />
            </div>
          )}
        </div>
      }
      <Row className="align-items-center mt-4">
        <label className="col-12">{t('CAMPAIGN.FORM.COIN')}</label>
        <Col
          className="d-flex align-items-center mb-2"
          md={12}
          lg={12}
          sm={12}
          xs={12}
        >
          <label className="mb-1 pr-3"> {t("CAMPAIGN.FORM.AMOUNT")}</label>
          <Slider
            name="assetAmountPercentage"
            value={formData.assetAmountPercentage}
            valueLabelDisplay="auto"
            valueLabelFormat={valueText}
            onChange={onAssetAmountPercentageChange}
          />
          {isHelpModus && (
            <div className="infoButton avblInfoButton" style={{ top: '-20px' }}
              onClick={() => {
                const messageContent = (
                  <div>
                    <p>{t('HELP-MODUS.CAMPAIGN-AMOUNT')}</p>
                  </div>
                );
                toggleHelpModusModal('Amount', messageContent);
              }}>
              <FontAwesomeIcon className="row" icon={faInfoCircle} />
            </div>
          )}
        </Col>
        <Col md={12} lg={12} sm={12} xs={12} className="mb-2">
          <div className="input-group">
            <InputNumber name="assetAmount" value={formData.assetAmount} onChange={onAssetAmountChange} step={0.01} />
            <span
              style={{ fontWeight: 700, width: 100 }}
              className="input-group-text input-end-form-border"
            >
              {formData.assetName || "N\\A"}
            </span>
            <Button
              className="ml-2 pl-3 pr-3"
              variant="success"
              type="button"
              size="md"
              onClick={onIncreaseClick}
              disabled={!formData.assetName || !formData.marketName}
            >
              {t("CAMPAIGN.FORM.INCREASE")}
              <FontAwesomeIcon className="pl-1" icon={faPlus} />
            </Button>
            {isHelpModus && (
              <div className="infoButton avblInfoButton"
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CAMPAIGN-INCREASE') }} />
                    </div>
                  );
                  toggleHelpModusModal('Increase', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row className="align-items-center mt-4">
        <label className="col-12">{t('CAMPAIGN.FORM.MARKET')}</label>
        <Col className="d-flex align-items-center mb-2" md={12} lg={12} sm={12} xs={12}>
          <label className="mb-1 pr-3"> {t("CAMPAIGN.FORM.AMOUNT")}</label>
          <Slider
            name="assetMarketPercentage"
            value={formData.marketAmountPercentage}
            valueLabelDisplay="auto"
            valueLabelFormat={valueText}
            onChange={onMarketAmountPercentageChange}
          />
          {isHelpModus && (
            <div className="infoButton avblInfoButton" style={{ top: '-20px' }}
              onClick={() => {
                const messageContent = (
                  <div>
                    <p>{t('HELP-MODUS.CAMPAIGN-AMOUNT')}</p>
                  </div>
                );
                toggleHelpModusModal('Amount', messageContent);
              }}>
              <FontAwesomeIcon className="row" icon={faInfoCircle} />
            </div>
          )}
        </Col>
        <Col md={12} lg={12} sm={12} xs={12} className="mb-2">
          <div className="input-group">
            <InputNumber name="marketAmount" value={formData.marketAmount} onChange={onMarketAmountChange} step={0.01} />
            <span
              style={{ fontWeight: 700, width: 100 }}
              className="input-group-text input-end-form-border"
            >
              {formData.marketName || "N\\A"}
            </span>
            {isHelpModus && (
              <div className="infoButton avblInfoButton"
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CAMPAIGN-INCREASE') }} />
                    </div>
                  );
                  toggleHelpModusModal('Increase', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </div>
        </Col>
      </Row>
      {/*<Row className="align-items-center mt-3">*/}
      {/*  <label className="col-12">{t('CAMPAIGN.FORM.MARKET')}</label>*/}
      {/*  <Col*/}
      {/*    className="d-flex align-items-center mb-2"*/}
      {/*    md={12}*/}
      {/*    lg={12}*/}
      {/*    sm={12}*/}
      {/*    xs={12}*/}
      {/*  >*/}
      {/*    <label className="mb-1 pr-3"> {t("CAMPAIGN.FORM.AMOUNT")}</label>*/}
      {/*    <Slider*/}
      {/*      name="marketAmountPercentage"*/}
      {/*      value={formData.marketAmountPercentage}*/}
      {/*      valueLabelDisplay="auto"*/}
      {/*      valueLabelFormat={valueText}*/}
      {/*      onChange={onMarketAmountPercentageChange}*/}
      {/*    />*/}
      {/*    {isHelpModus && (*/}
      {/*      <div className="infoButton avblInfoButton" style={{ top: '-20px' }}*/}
      {/*        onClick={() => {*/}
      {/*          const messageContent = (*/}
      {/*            <div>*/}
      {/*              <p>{t('HELP-MODUS.CAMPAIGN-AMOUNT')}</p>*/}
      {/*            </div>*/}
      {/*          );*/}
      {/*          toggleHelpModusModal('Amount', messageContent);*/}
      {/*        }}>*/}
      {/*        <FontAwesomeIcon className="row" icon={faInfoCircle} />*/}
      {/*      </div>*/}
      {/*    )}*/}
      {/*  </Col>*/}
      {/*  <Col md={12} lg={12} sm={12} xs={12} className="mb-2">*/}
      {/*    <div className="input-group">*/}
      {/*      <InputNumber name="marketAmount" value={formData.marketAmount} onChange={onMarketAmountChange} step={0.01} />*/}
      {/*      <span*/}
      {/*        style={{ fontWeight: 700, width: 100 }}*/}
      {/*        className="input-group-text input-end-form-border"*/}
      {/*      >*/}
      {/*        {formData.marketName || "N\\A"}*/}
      {/*      </span>*/}
      {/*      <Button*/}
      {/*        className="ml-2 pl-3 pr-3"*/}
      {/*        variant="success"*/}
      {/*        type="button"*/}
      {/*        size="md"*/}
      {/*        onClick={onIncreaseClick}*/}
      {/*        disabled={!formData.marketName || !formData.marketName}*/}
      {/*      >*/}
      {/*        {t("CAMPAIGN.FORM.INCREASE")}*/}
      {/*        <FontAwesomeIcon className="pl-1" icon={faPlus} />*/}
      {/*      </Button>*/}
      {/*      {isHelpModus && (*/}
      {/*        <div className="infoButton avblInfoButton"*/}
      {/*          onClick={() => {*/}
      {/*            const messageContent = (*/}
      {/*              <div>*/}
      {/*                <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CAMPAIGN-INCREASE') }} />*/}
      {/*              </div>*/}
      {/*            );*/}
      {/*            toggleHelpModusModal('Increase', messageContent);*/}
      {/*          }}>*/}
      {/*          <FontAwesomeIcon className="row" icon={faInfoCircle} />*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </div>
  );
};

CampaignForm.contextTypes = {
  t: PropTypes.func,
};

export default CampaignForm;
