import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { Card, Row } from "react-bootstrap";
import whiteLogo from "../../../common/assets/logo-white.png";
import logo from "../../../common/assets/logo.png";
import LoginForm from "./login-form";

import { login } from "../actions/login-actions";

import { additionalParams } from "../constants/constants";

import CommonHelper from "../../../common/helpers/common-helper";
import "../style/login.scss";

class Login extends Component {
  state = {
    logginErrorMessage: null,
  };

  handleLoginSubmit = (values) => {
    const { login } = this.props;
    login({ ...values, ...additionalParams }, (msg) => this.setState({ logginErrorMessage: msg }));
  };

  render() {
    const { t } = this.context;
    const { logginErrorMessage } = this.state;
    const isDarkMode = CommonHelper.getUserCurrentTheme() === 'dark';

    return (
      <Row className="login align-items-center justify-content-center h-100">
        <Card className="col-sm-10 col-md-6 col-lg-4 col-xl-3">
          <Card.Body className="w-100">
            <Card.Title className="text-center">
              <img width={180} src={isDarkMode ? whiteLogo : logo} alt={"logo"} />
            </Card.Title>
            <h6 className="line-on-side text-muted text-center font-small-3 pt-2">
              <span>{t("LOGIN.TITLE")}</span>
            </h6>
            {/* <Card.Text>
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </Card.Text> */}
            <LoginForm
              onSubmit={this.handleLoginSubmit}
              logginErrorMessage={logginErrorMessage}
              t={t}
              handleLoginError={() => this.setState({ logginErrorMessage: null })}
            />
          </Card.Body>
        </Card>
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  login: (params, handleLoginError) => dispatch(login(params, handleLoginError)),
});

Login.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(Login);
