import profileApi from "../../../api/profile/actions";
import { setUserApiKeys } from "./action-creators";
import NotificationProvider from "../../../core/notification";
import Helpers from "../../../common/helpers/error-helpers";

export const createApiKey = (params) => {
  return async (dispatch) => {
    try {
      await profileApi.saveApiKey(params);
      NotificationProvider.success("Api key successfully created");
    } catch (err) {
      Helpers.parseError(err);
    }
  };
};

export const updateApiKey = (params) => {
  return async (dispatch) => {
    try {
      await profileApi.updateApiKey(params);
      NotificationProvider.success("Api key successfully updated");
    } catch (err) {
      Helpers.parseError(err);
    }
  };
};

export const handleGetProfileData = (params) => {
  return async (dispatch) => {
    try {
      const res = await profileApi.getApiKeys(params);
      const response = Array.isArray(res.data) ? res.data : [];
      dispatch(setUserApiKeys(response));
    } catch (err) {
      Helpers.parseError(err);
    }
  };
};
