import { fields } from "../constants/constants";
import { EMAIL_REGEX } from "../../../common/constants";

export const validate = (values, { t }) => {
  const errors = {};

  if (!EMAIL_REGEX.test(values[fields.username])) {
    errors[fields.username] = { label: t("LOGIN_FORM.USERNAME_NOT_VALID") };
  }

  if (!values[fields.username]) {
    errors[fields.username] = { label: t("LOGIN_FORM.USERNAME_REQUIRED") };
  }

  if (!values[fields.password]) {
    errors[fields.password] = { label: t("LOGIN_FORM.PASSWORD_REQUIRED") };
  }

  return errors;
};
