import { faBan, faEdit, faEllipsisV, faExternalLinkAlt, faHistory, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Switch from "@mui/material/Switch";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NotificationProvider from "../../../core/notification";

import { routes } from "../../../common/routes-constants";
import { handleGetBotsOverviewData } from "../actions";

import bothApi from "../../../api/bot/actions";
import { PAGE_SIZE_DEFAULT } from "../../../common/constants";
import TradeTable from "../../../common/form/table/trade-table";
import { useHelpModus } from "../../../common/help-modus/help-modus-context";
import HelpModusModal from "../../../common/help-modus/help.modus-modal";
import Helpers from "../../../common/helpers/error-helpers";
import AppPagination from "../../../shared/components/pagination/pagination";
import { BOT_OVERVIEW_COLUMNS } from "../constants/constants";
import styles from "./styles.module.css";

const BotsOverview = (props, { t }) => {
    const { handleGetBotsOverviewData, botsList } = props;

    const initialPageSize = sessionStorage.getItem("pageSize") || PAGE_SIZE_DEFAULT;

    const [filters, setFilters] = useState({ side: "", symbol: "" });
    const [pageSize, setPageSize] = useState(parseInt(initialPageSize, 10));
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [onlyActive, setOnlyActive] = useState(true);
    let latestZIndex = 3;

    const [showHelpModusModal, setShowHelpModusModal] = useState();
    const [modalMessage, setModalMessage] = useState();
    const [modalTitle, setModalTitle] = useState('Information');
    const { isHelpModus } = useHelpModus();

    const columns = useMemo(
        () => [
            ...BOT_OVERVIEW_COLUMNS,
            {
                field: "actions",
                headerName: "ORDER_OVERVIEW_TABLE.ACTIONS",
                type: "action",
                width: 68,
                cellRenderer: (data) => {
                    return (
                        <DropdownButton
                            variant="secondary"
                            id="dropdown-basic-button"
                            title={<FontAwesomeIcon icon={faEllipsisV} />}
                            drop={"left"}
                            className="fix-dropdown-margin"
                            onClick={(e) => {
                                const td = e.target.closest("td");
                                td.style.zIndex = latestZIndex;
                                latestZIndex++;
                            }}
                        >
                            <Dropdown.Item
                                href={`${routes.protectedRoutes.botEdit.path}/${data.id}`}
                            >
                                <FontAwesomeIcon icon={faEdit} /> Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                                href={`${routes.protectedRoutes.botOrderOverview.path}/${data.id}?active=1`}
                            >
                                <FontAwesomeIcon icon={faExternalLinkAlt} />{" "}
                                {t("BOT.ACTIONS.ORDER-STATUS")}
                            </Dropdown.Item>
                            <Dropdown.Item
                                href={`${routes.protectedRoutes.botOrderOverview.path}/${data.id}`}
                            >
                                <FontAwesomeIcon icon={faHistory} />{" "}
                                {t("BOT.ACTIONS.ORDER-HISTORY")}
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => closeBot(data)}>
                                <FontAwesomeIcon icon={faBan} /> {t("BOT.ACTIONS.CLOSE-BOT")}
                            </Dropdown.Item>
                        </DropdownButton>
                    );
                },
            },
        ],
        // eslint-disable-next-line
        []
    );

    useEffect(() => {
        handleGetBotsOverviewData(
            {
                params: {
                    pageNumber,
                    pageSize,
                    hideCancelled: onlyActive,
                    ...filters,
                },
            },
            () => {
                setIsLoading(false);
            }
        );

        // eslint-disable-next-line
    }, [pageNumber, pageSize, onlyActive, filters.side, filters.symbol]);

    const closeBot = async (data) => {
        try {
            await bothApi.closeBot({ id: data.id });
            NotificationProvider.success("Bot closed");
        } catch (err) {
            Helpers.parseError(err);
        }
    };

    const handleChange = (event) => {
        const { checked } = event.target;
        setOnlyActive(checked);
        setPageNumber(1);
    };

    const toggleHelpModusModal = (title, message) => {
      setShowHelpModusModal((showModal) => !showModal);
      setModalMessage(message);
      setModalTitle(title);
    };

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
        sessionStorage.setItem("pageSize", newPageSize);
    };

    return (
        <div className={`${styles.layout} pt-3`}>
            {isHelpModus && (
              <div className="infoButton" style={{ float: 'left', left: '15px' }}
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p>{t('HELP-MODUS.BOT-OVERVIEW')}</p>
                    </div>
                  );
                  toggleHelpModusModal('Bot Overview', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
            <div className="d-flex align-items-center mb-2">
                <label className="w-50 d-flex" style={{ alignItems: 'center' }}>
                    <Switch
                        size="medium"
                        checked={onlyActive}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                    {t("BOT_OVERVIEW.HIDE_CANCELED")}
                    {isHelpModus && (
                    <div className="infoButton" style={{ right: '-20px' }}
                        onClick={() => {
                          const messageContent = (
                            <div>
                              <p>{t('HELP-MODUS.HIDE-CANCEL')}</p>
                            </div>
                          );
                          toggleHelpModusModal('Hide canceled', messageContent);
                        }}>
                        <FontAwesomeIcon className="row" icon={faInfoCircle} />
                      </div>
                    )}
                </label>
                <AppPagination
                    page={pageNumber}
                    totalPages={botsList.totalPages}
                    pageSize={pageSize}
                    setPageSize={handlePageSizeChange}
                    handlePaginationClick={(_, value) => setPageNumber(value)}
                />
            </div>
            <TradeTable
                // style={{ maxHeight: "calc(100vh - 308px)" }}
                data={botsList.data}
                columnDefs={columns}
                conditionExpressions={{
                  "gray-out": (data) => !data["isActive"],
                  "dynamic-bot": (data) => !!data.dynamicBotBuffer
                }}
                isLoading={isLoading}
                enableFilter
                onFilterChanged={setFilters}
                tableHasVerticalScroll
            />
            {showHelpModusModal && (
              <HelpModusModal
                show={showHelpModusModal}
                onClose={toggleHelpModusModal}
                title={modalTitle}
                message={modalMessage}
              />
            )}
        </div>
    );
};

BotsOverview.contextTypes = {
    t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    botsList: state.botsReducer.list,
});

const mapDispatchToProps = (dispatch) => ({
    handleGetBotsOverviewData: (params, cb) =>
        dispatch(handleGetBotsOverviewData(params, cb)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BotsOverview)
);
