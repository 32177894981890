import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';

const Select = (props) => {
  const {
    input,
    label,
    type,
    placeholder,
    readOnly,
    meta,
    className,
    tooltipValidation,
    id,
    formClassName,
    options,
    disabled,
  } = props;

  return (
    <Form.Group className={`${formClassName || ""}`}>
      <div className="label-control-wrapper">
        {label && <Form.Label>{label}</Form.Label>}
        <Form.Control
          as="select"
          {...{
            placeholder,
            id,
            className: `${className} ${meta && meta.error && meta.touched ? "border border-danger" : ""}`,
            readOnly,
            disabled,
            ...input,
          }}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Control>
      </div>
      <Form.Text className="text-danger validation">
        {!tooltipValidation &&
          meta &&
          meta.error &&
          meta.touched &&
          meta.error.label}
        {tooltipValidation && meta && meta.error && meta.touched && (
          <small className="invalid-tooltip">{meta.error.label}</small>
        )}
      </Form.Text>
    </Form.Group>
  );
};

Select.defaultProps = {
  label: null,
  placeholder: "",
  tooltipValidation: false,
  className: "",
  options: [],
};

Select.propTypes = {
  type: PropTypes.oneOf(["select"]),
};

export default Select;
