import chartApi from "../../api/chart/actions";

const MexcWebSocketURL = 'wss://wbs.mexc.com/ws';
let debounceTimeout = null;

const MexcDataFeed = {
  socket: null,
  subscriptions: {},
  pingInterval: null,

  onReady: function (callback) {
    setTimeout(() => {
      callback({
        supported_resolutions: ['1', '5', '15', '30', '60', '240', '480', '720', '1D', '1W', '1M'],
        exchanges: [{ value: 'MEXC', name: 'MEXC', desc: 'MEXC Exchange' }],
      });
    }, 0);
  },

  searchSymbols: async function (userInput, exchange, symbolType, onResultReadyCallback) {
    clearTimeout(debounceTimeout);  // clear the previous timer

    debounceTimeout = setTimeout(async () => {
      try {
        const response = await chartApi.getDefaultSymbols();
        const data = response.data;

        const filteredSymbols = data.data.filter((symbol) => {
          return symbol.toLowerCase().includes(userInput.toLowerCase());
        });

        const symbols = filteredSymbols.map((symbol) => {
          return {
            symbol: symbol,
            full_name: symbol,
            description: symbol,
            exchange: 'MEXC',
            ticker: symbol,
            type: 'crypto',
          };
        });
        onResultReadyCallback(symbols);
      } catch (error) {
        console.error(error);
      }
    }, 600);
  },

  getBars: async function (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
    try {
      const params = new URLSearchParams({
        symbol: symbolInfo.name,
        interval: this.resolutionToMexcInterval(resolution),
        startTime: periodParams.from * 1000,
        endTime: periodParams.to * 1000
      });
      const response = await chartApi.getKlines(params);
      const data = response.data;

      onHistoryCallback(data.map((bar) => ({
        time: bar[0],
        low: bar[3],
        high: bar[2],
        open: bar[1],
        close: bar[4],
        volume: bar[5]
      })), { noData: false });
    } catch (error) {
      console.error(error);
      onErrorCallback(error);
    }
  },

  resolveSymbol: async function (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
    try {
      const response = await chartApi.getExchangeInfo(`symbol=${symbolName}`);
      const data = response.data?.symbols[0];

      onSymbolResolvedCallback({
        name: data.symbol,
        description: `${data.baseAsset} / ${data.quoteAsset}`,
        type: 'crypto',
        session: '24x7',
        timezone: 'Etc/UTC',
        exchange: 'MEXC',
        minmov: 1,
        pricescale: (10 ** data.quoteAssetPrecision) < 100 ? 100 : (10 ** data.quoteAssetPrecision),
        has_intraday: true,
        has_empty_bars: false,
        has_weekly_and_monthly: false,
        supported_resolutions: ['1', '5', '15', '30', '60', '240', '480', '720', '1D', '1W', '1M'],
        volume_precision: data.baseAssetPrecision,
      });
    } catch (error) {
      onResolveErrorCallback(error);
    }
  },

  subscribeBars: function (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
    const channel = `spot@public.kline.v3.api@${symbolInfo.name}@${this.resolutionToMexcKlineInterval(resolution)}`;

    this.subscriptions[subscriberUID] = {
      channel,
      onRealtimeCallback,
    };

    if (!this.socket) {
      this.socket = new WebSocket(MexcWebSocketURL);
      this.socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message?.d?.e === 'spot@public.kline.v3.api') {
          const bar = {
            time: message.d.k.t * 1000,
            low: parseFloat(message.d.k.l),
            high: parseFloat(message.d.k.h),
            open: parseFloat(message.d.k.o),
            close: parseFloat(message.d.k.c),
            volume: parseFloat(message.d.k.v),
          };
          const subscription = Object.values(this.subscriptions).find((sub) => sub.channel.toLowerCase() === (message.c).toLowerCase())
          subscription?.onRealtimeCallback(bar);
        }
      };

      this.socket.onopen = () => {
        this.socket.send(JSON.stringify({ method: 'SUBSCRIPTION', params: [channel], id: 1 }));
        this.startPingPong();
      };
    } else {
      this.socket.send(JSON.stringify({ method: 'SUBSCRIPTION', params: [channel], id: 1 }));
    }
  },

  unsubscribeBars: function (subscriberUID) {
    const subscription = this.subscriptions[subscriberUID];
    if (!subscription) {
      return;
    }
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ method: 'UNSUBSCRIPTION', params: [subscription.channel], id: 1 }));
    }
    delete this.subscriptions[subscriberUID];
  },

  resolutionToMexcInterval: function (resolution) {
    const mapping = {
      '1': '1m',
      '3': '3m',
      '5': '5m',
      '15': '15m',
      '30': '30m',
      '60': '1h',
      '120': '2h',
      '240': '4h',
      '360': '6h',
      '720': '12h',
      '1D': '1d',
      '3D': '3d',
      '1W': '1w',
      '1M': '1M',
    };
    return mapping[resolution];
  },

  resolutionToMexcKlineInterval: function (resolution) {
    const mapping = {
      '1': 'Min1',
      '5': 'Min5',
      '15': 'Min15',
      '30': 'Min30',
      '60': 'Min60',
      '120': '2h',
      '240': 'Hour4',
      '360': 'Hour8',
      '720': 'Hour12',
      '1D': 'Day1',
      '3D': 'Day3',
      '1W': 'Week1',
      '1M': 'Month1',
    };
    return mapping[resolution];
  },

  startPingPong: function () {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
    this.pingInterval = setInterval(() => {
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ method: 'PING', id: 1 }));
      }
    }, 30000); // sending ping every 30 seconds to keep the connection alive.
  },
};

export default MexcDataFeed;
