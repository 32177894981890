import { faHandHoldingUsd, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import Button from "../../../common/form/button/components/button";
import InputNumber from "../../../common/form/input/components/number-input";

import {
  ACTION_SELL_AMOUNT,
  CONDITION_ACTION,
  CONDITION_ORDER_TYPE,
  CONDITION_REQUIREMENT,
  CONDITION_TYPE,
  ORDER_IS_FULFILED,
  POST_CONDITION_TYPE,
  PRICE_IS_BELOW,
  PRICE_IS_OVER
} from '../constants';

const ConditionForm = (props, { t }) => {
  const { setCondtionData, conditionData, isBotActive, isCampaign, addCondtion, setConditionOrderType, marketName, assetName, isOrder } = props;

  const isBotActiveConditionsType = (conditionType) => {
    return isBotActive ? conditionType.code === POST_CONDITION_TYPE : true;
  };

  const onSelectChange = (e) => {
    const { name, value } = e.target;
    const condData =
      name === "conditionRequirement"
        ? {
            ...conditionData,
            triggerPrice: "",
            conditionalOrderQuantity: 0,
          }
        : { ...conditionData };

    setCondtionData({ ...condData, [name]: +value });
  };

  const isOrderFulfiled = conditionData.conditionRequirement === ORDER_IS_FULFILED;

  const isPriceOverOrBelow = [PRICE_IS_BELOW, PRICE_IS_OVER].includes(conditionData.conditionRequirement);

  const isSellAmount = conditionData.conditionAction === ACTION_SELL_AMOUNT;
  const isPostCondition = conditionData.type === POST_CONDITION_TYPE;
  const isTrailingSell = false; //conditionData.conditionAction === TRAILING_SELL;
  const isTrailingOrder = false; //conditionData.conditionAction === TRAILING_ORDER;

  return (
    <Row>
      <Col md={12} lg={12} sm={12} xs={12}>
        <div className="input-group mb-3">
          <label className="input-group-text form-border">{t("CONDITION.FORM.TYPE")}</label>
          <select name="type" onChange={onSelectChange} value={conditionData.type} className="form-control">
            <option value={-1}>{t("APP.FORM.SELECT")}</option>
            {CONDITION_TYPE.filter(isBotActiveConditionsType).map((type) => (
              <option key={type.code} value={type.code}>
                {type.name}
              </option>
            ))}
          </select>
        </div>
      </Col>
      <Col md={12} lg={12} sm={12} xs={12}>
        <div className="input-group mb-3">
          <label className="input-group-text form-border">{t("CONDITION.FORM.CONDITION")}</label>
          <select name="conditionRequirement" onChange={onSelectChange} value={conditionData.conditionRequirement} className="form-control">
            <option value={-1}>{t("APP.FORM.SELECT")}</option>
            {CONDITION_REQUIREMENT.map((type) => (
              <option key={type.code} value={type.code}
              disabled={!isPostCondition && type.isDisabledOnPre}>
                {type.name}
              </option>
            ))}
          </select>
        </div>
        {(isOrderFulfiled || isPriceOverOrBelow) && (
          <div className="input-group mb-3">
            <span className="input-group-text input-form-border">{t("CONDITION.FORM.PRICE")}</span>
            <InputNumber disabled={isOrderFulfiled} name="triggerPrice"
              onChange={(e) => {
                setCondtionData({
                  ...conditionData,
                  triggerPrice: e.target.value,
                });
              }}
              value={conditionData.triggerPrice} />
            <span style={{ fontWeight: 700, width: 75 }} className="input-group-text input-end-form-border">
              {marketName || "N/A"}
            </span>
          </div>
        )}
        {isOrderFulfiled && (
          <div className="input-group mb-3">
            <span className="input-group-text input-form-border">{t("CONDITION.FORM.AMOUNT")}</span>
            <InputNumber name="conditionalOrderQuantity" disabled={true} onChange={() => {}} value={conditionData.conditionalOrderQuantity} />
            <span style={{ fontWeight: 700, width: 75 }} className="input-group-text input-end-form-border">
              {assetName || "N/A"}
            </span>
          </div>
        )}
        <div className="input-group mb-3">
          <label className="input-group-text form-border">{t("CONDITION.FORM.ACTION")}</label>
          <select name="conditionAction" onChange={onSelectChange} value={conditionData.conditionAction} className="form-control">
            <option value={-1}>{t("APP.FORM.SELECT")}</option>
            {CONDITION_ACTION(isCampaign, isOrder).map((type) => (
              <option disabled={(isPostCondition && type.isDisabledOnPost) || (!isPostCondition && type.isDisabledOnPre)} key={type.code} value={type.code}>
                {type.name}
              </option>
            ))}
          </select>
        </div>
        {(isTrailingSell || isTrailingOrder) && (
          <div className="input-group mb-3">
            <span className="input-group-text input-form-border">
              {t('CONDITION.FORM.TRAILING')}
            </span>
            <InputNumber
              name="conditionPercentage"
              value={conditionData.trailingPercentage}
              onChange={(e) => {
                setCondtionData({
                  ...conditionData,
                  trailingPercentage: e.target.value,
                });
              }}
            />
            <span
              style={{ fontWeight: 700, width: 75 }}
              className="input-group-text input-end-form-border"
            >
              %
            </span>
          </div>
        )}
        {(isSellAmount || isTrailingSell) && (
          <div className="input-group mb-3">
            <span className="input-group-text input-form-border">{t("CONDITION.FORM.PERCENTAGE")}</span>
            <InputNumber name="conditionPercentage" value={conditionData.conditionPercentage}
              onChange={(e) => {
                setCondtionData({
                  ...conditionData,
                  conditionPercentage: e.target.value,
                });
              }} />
            <span style={{ fontWeight: 700, width: 75 }} className="input-group-text input-end-form-border">
              %
            </span>
          </div>
        )}
        {(isSellAmount || isTrailingSell) && (
          <div className="input-group mb-3">
            <span className="input-group-text input-form-border">
              {t("CONDITION.FORM.REENTRY")}
              <Checkbox
                style={{ marginLeft: "10px", height: "20px" }}
                checked={conditionData.reentrySet === true}
                onChange={(e) => {
                  const checked = e.target.checked;
                  checked
                    ? setCondtionData({
                        ...conditionData,
                        reentrySet: e.target.checked,
                        reentryPrice: conditionData.triggerPrice,
                        reentryDelay: 120
                      })
                    : setCondtionData({
                        ...conditionData,
                        reentrySet: e.target.checked,
                        reentryPrice: "",
                        reentryPercentage: "",
                        reentryDelay: 0,
                      });
                }}
              />
            </span>
            <InputNumber disabled={!conditionData.reentrySet} name="reentryPercentage" value={conditionData.reentryPercentage}
              onChange={(e) => {
                setCondtionData({
                  ...conditionData,
                  reentryPercentage: e.target.value,
                });
              }} />
            <span style={{ fontWeight: 700, width: 75 }} className="input-group-text input-end-form-border">
              %
            </span>
          </div>
        )}
        {conditionData.reentrySet && (
          <div className="input-group mb-3">
            <span className="input-group-text input-form-border">
              {t("CONDITION.FORM.REENTRY-DELAY-TIME")}
            </span>
            <select name="delayTime" className="form-control" value={conditionData.reentryDelay}
              onChange={(e) => {
                setCondtionData({
                  ...conditionData,
                  reentryDelay: e.target.value,
                });
              }}
            >
              {[...Array(12).keys()].map((i) => {
                const value = (i + 1) * 15;
                return (
                  <option key={value} value={value}>
                    {value}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        {(isSellAmount || isTrailingSell) && (
          <div className="input-group mb-3">
            <span className="input-group-text input-form-border">{t("CONDITION.FORM.REENTRY-PRICE")}</span>
            <InputNumber disabled={true} name="reentryPrice" value={conditionData.reentryPrice}
              onChange={(e) => {
                setCondtionData({
                  ...conditionData,
                  reentryPrice: e.target.value,
                });
              }} />
            <span style={{ fontWeight: 700, width: 75 }} className="input-group-text input-end-form-border">
              {marketName || "N/A"}
            </span>
          </div>
        )}
        <div className="d-flex justify-content-end">
          {isOrderFulfiled && (
            <DropdownButton variant="secondary" id="dropdown-basic-button" title={t("CONDITION.ORDER.CREATE-ACTION")}>
              <Dropdown.Item
                href="#"
                onClick={() => {
                  setConditionOrderType(CONDITION_ORDER_TYPE.Market);
                }}>
                <FontAwesomeIcon icon={faHandHoldingUsd} />&nbsp;{t("CONDITION.ACTIONS.MARKET-ORDER")}
              </Dropdown.Item>
              {/*<Dropdown.Item*/}
              {/*  href="#"*/}
              {/*  onClick={() => {*/}
              {/*    setConditionOrderType(CONDITION_ORDER_TYPE.Limit);*/}
              {/*  }}>*/}
              {/*  <FontAwesomeIcon icon={faExchangeAlt} />&nbsp;{t("CONDITION.ACTIONS.LIMIT-ORDER")}*/}
              {/*</Dropdown.Item> */}
            </DropdownButton>
          )}

          <Button
            className="ml-2 pl-3 pr-3"
            variant="success"
            type="button"
            size="md"
            disabled={(!conditionData.triggerPrice && conditionData.conditionRequirement != 3) || (conditionData.conditionRequirement == 3 && (conditionData.conditionAction === "" || conditionData.conditionAction === -1)) }
            onClick={addCondtion}
          >
            <FontAwesomeIcon className="pr-1" icon={faPlusCircle} />
            {t("CONDITION.FORM.ADD")}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

ConditionForm.contextTypes = {
  t: PropTypes.func,
};

export default ConditionForm;
