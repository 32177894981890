import React from "react";
import { connect } from "react-redux";
import Loader from "./common-loader";

const GlobalLoader = (props) => {
  return <Loader {...props} type={"global"} />;
};

const mapStateToProps = (state) => ({
  loading: state.loaderReducer.isLoading,
});

export default connect(mapStateToProps, null)(GlobalLoader);
