import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faSave, faPlus, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../common/form/button/components/button";
import { EXCHANGE_OPTIONS } from "../../../common/constants";

import binanceImg from "../../../common/assets/help-modus/help-modus-binance.png";
import apiManagementImg from "../../../common/assets/help-modus/help-modus-api-management.png";
import enableSpotImg from "../../../common/assets/help-modus/help-modus-enable-spot.png";
import binanceDemoAuthImg from "../../../common/assets/help-modus/help-modus-binance-demo-api-authenticate.png";
import binanceDemoAuthrImg from "../../../common/assets/help-modus/help-modus-binance-demo-api-authorize.png";
import binanceDemoSignInImg from "../../../common/assets/help-modus/help-modus-binance-demo-api-sign-in.png";
import UserHelper from "../../../common/helpers/user-helper";

const EditProfileForm = (props, context) => {
  const { t } = context;
  const { onUpdate, data, onValueChange, onCreate, addKey, removeRow, deleteKey, isHelpModus, onInfoIconClick } = props;

  const [selectedExchanges, setSelectedExchanges] = useState({});
  const handleSelectChange = (i, e) => {
    setSelectedExchanges({
      ...selectedExchanges,
      [i]: +e.target.value,
    });
  };

  const renderSecretKeys = (isMobile) => {
    const { role } = UserHelper.getUserInfo();
    return data.map((item, i) => (
      <>
        <tr key={i}>
          <td style={{ width: isMobile ? 150 : 240 }}>
            <input
              type="text"
              className="form-control"
              name="name"
              value={item.name}
              onChange={(e) => {
                onValueChange(e, i);
              }}
            />
          </td>
          <td style={{ width: 150 }}>
            <input
              type="text"
              className="form-control"
              name="key"
              value={item.key}
              onChange={(e) => {
                onValueChange(e, i);
              }}
            />
          </td>
          <td style={{ width: 150 }}>
            <input
              type={item.id ? "password" : "text"}
              className="form-control"
              name="secret"
              value={item.secret}
              onChange={(e) => {
                if (e.target.type === "password") {
                  e.target.value = e.target.value.slice(-1);
                  e.target.type = "text";
                }
                onValueChange(e, i);
              }}
            />
          </td>
          <td style={{ width: isMobile ? 150 : 200 }}>
            <select
              value={item.exchangeId}
              name="exchangeId"
              onChange={(e) => {
                const evt = {
                  target: { name: "exchangeId", value: +e.target.value },
                };
                onValueChange(evt, i);
                handleSelectChange(i, e);
              }}
              className="form-control"
            >
              <option>Select Exchange</option>
              {EXCHANGE_OPTIONS.filter(e => role !== "Trial" || e.value === 1).map((ex) => (
                <option key={ex.id} value={ex.value}>
                  {ex.label}
                </option>
              ))}
            </select>
          </td>
          <td style={{ width: 100 }}>
            <Button
              className={" mr-2"}
              variant="outline-danger"
              type="button"
              size="md"
              onClick={() => (item.id ? deleteKey(item.id, i) : removeRow(i))}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
            <Button
              variant="outline-success"
              type="button"
              size="md"
              disabled={!item.name || !item.key || !item.secret}
              onClick={() => (item.id ? onUpdate(item) : onCreate(item))}
            >
              <FontAwesomeIcon icon={faSave} />
            </Button>
          </td>
        </tr>
        {selectedExchanges[i] === 0 && (
          <tr>
            <td colSpan="5">
              {t("EDIT_PROFILE.QUESTION")} {t("EDIT_PROFILE.CLICK")}{" "}
              <a href="https://accounts.binance.com/register?ref=463671007" target="_blank" rel="noopener noreferrer">
                {t("EDIT_PROFILE.HERE")}
              </a>{" "}
              {t("EDIT_PROFILE.REGISTER")}
            </td>
          </tr>
        )}
        {selectedExchanges[i] === 1 && (
          <tr>
            <td colSpan="5">
              <div class="d-flex">{t("EDIT_PROFILE.QUESTION-BINANCE-DEMO")}
                <div className="infoButton" style={{ top: '-15px' }}
                  onClick={() => {
                    const messageContent = (
                      <div>
                        <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.BINANCE-DEMO-API-ONE') }} />
                        <img src={binanceDemoAuthImg} alt="Binance Demo Authenticate" />
                        <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.BINANCE-DEMO-API-TWO') }} />
                        <div className="d-flex">
                          <img src={binanceDemoSignInImg} alt="Binance Demo Sign-in" />
                          <img src={binanceDemoAuthrImg} alt="Binance Demo Authorize" />
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.BINANCE-DEMO-API-THREE') }} />
                        <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.BINANCE-DEMO-API-FOUR') }} />
                      </div>
                    );
                    const title = (<>{t("HELP-MODUS.BINANCE-DEMO-API-TITLE")}</>);
                    onInfoIconClick(title, messageContent, 'lg');
                  }}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </div>
              </div>              
            </td>
          </tr>
        )}
        {selectedExchanges[i] === 2 && (
          <tr>
            <td colSpan="5">
              {t("EDIT_PROFILE.QUESTION-MEXC")} {t("EDIT_PROFILE.CLICK")}{" "}
              <a href="https://www.mexc.com/de-DE/register?inviteCode=mexc-1epaw" target="_blank" rel="noopener noreferrer">
                {t("EDIT_PROFILE.HERE")}
              </a>{" "}
              {t("EDIT_PROFILE.REGISTER")}
            </td>
          </tr>
        )}
      </>      
    ));
  };

  const renderBody = (isMobile) => {
    return (
      <div className="d-flex flex-column">
        <table className={`account-info-table`}>
          <thead style={{ borderBottom: isMobile ? "none" : "" }}>
            <tr className={isMobile ? "d-flex flex-row" : ""}>
              <th style={{ width: isMobile ? 150 : 240 }}>{t("EDIT_PROFILE.NAME_PLACEHOLDER")}</th>
              <th style={{ width: 150 }}>{t("EDIT_PROFILE.API_KEY_PLACEHOLDER")}</th>
              <th style={{ width: 150 }}>{t("EDIT_PROFILE.SECRET_KEY_PLACEHOLDER")}</th>
              <th style={{ width: isMobile ? 150 : 200 }}>{t("EDIT_PROFILE.EXCHANGE_PLACEHOLDER")}</th>
              <th style={{ width: 100 }}>{t("EDIT_PROFILE.ACTIONS")}</th>
            </tr>
          </thead>
          <tbody className={isMobile ? "d-flex flex-column" : ""}>
            {renderSecretKeys(isMobile)}
            <tr className={isMobile ? "d-flex flex-column" : ""}>
              <td className="d-flex">
                {isHelpModus && !!onInfoIconClick && (
                  <div className="infoButton" style={{ top: '-15px' }}
                    onClick={() => {
                      const messageContent = (
                        <div>
                          <p>{t('HELP-MODUS.USER-CENTER-ADD-KEY-ONE')}</p>
                          <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.API-KEY-TWO') }} />
                          <img src={binanceImg} style={{ width: '100%' }} alt="Create API Key" />
                          <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.API-KEY-FOUR') }} />
                          <img src={apiManagementImg} style={{ width: '100%' }} alt="Add API Key" />
                          <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.API-KEY-FIVE') }} />
                          <img src={enableSpotImg} style={{ width: '100%' }} alt="Enable Spot & Margin Trading" />
                        </div>
                      );
                      onInfoIconClick('API Key', messageContent);
                    }}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <Button variant="outline-success" type="button" size="md" style={{ width: "25%" }} className="add-key-btn btn btn-outline-success" onClick={addKey}>
          <FontAwesomeIcon icon={faPlus} /> Add key
        </Button>
      </div>
    );
  }

  return (
    <>
      <div className="d-none d-lg-flex p-4">
        {renderBody(false)}
      </div>
      <div className="d-sm-flex d-lg-none">
        {renderBody(true)}
      </div>
    </>
  );
};

EditProfileForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default EditProfileForm;
