import { API_BASE_URL } from "../../common/constants";
import { Api } from "../../core/api";
import routes from "./routes";

const chartApi = {};

chartApi.saveChart = async (params, headers) => {
  return await Api.post(
    `${API_BASE_URL}${routes.CHART_BASE}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

chartApi.saveStudyTemplate = async (params, headers) => {
  return await Api.post(
    `${API_BASE_URL}${routes.STUDY_TEMPLATE_BASE}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

chartApi.getAvailableCharts = async (headers) => {
  return await Api.get(
    `${API_BASE_URL}${routes.GET_AVAILABLE_CHARTS}`,
    {
      needLoader: false,
    },
    headers
  );
};

chartApi.getAvailableStudyTemplates = async (headers) => {
  return await Api.get(
    `${API_BASE_URL}${routes.GET_AVAILABLE_STUDY_TEMPLATES}`,
    {
      needLoader: false,
    },
    headers
  );
};

chartApi.getChart = async (id, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routes.CHART_BASE}/${id}`,
    {
      needLoader: false,
    },
    headers
  );
};

chartApi.getExchangeInfo = async (query, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routes.DATAFEED_BASE}/exchangeInfo?${query}`,
    {
      needLoader: false,
    },
    headers
  );
};

chartApi.getKlines = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routes.DATAFEED_BASE}/klines?${params}`,
    {
      needLoader: false,
    },
    headers
  );
};

chartApi.getDefaultSymbols = async (headers) => {
  return await Api.get(
    `${API_BASE_URL}${routes.DATAFEED_BASE}/defaultSymbols`,
    {
      needLoader: false,
    },
    headers
  );
};

chartApi.getStudyTemplate = async (name, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routes.STUDY_TEMPLATE_BASE}/${name}`,
    {
      needLoader: false,
    },
    headers
  );
};

chartApi.removeChart = async (id, headers) => {
  return await Api.delete(
    `${API_BASE_URL}${routes.CHART_BASE}/${id}`,
    {
      needLoader: false,
    },
    headers
  );
};

chartApi.removeStudyTemplate = async (name, headers) => {
  return await Api.delete(
    `${API_BASE_URL}${routes.STUDY_TEMPLATE_BASE}/${name}`,
    {
      needLoader: false,
    },
    headers
  );
};

chartApi.update = async (params, headers) => {
  return await Api.put(
    `${API_BASE_URL}${params.route}`,
    params,
    {
      needLoader: false,
    },
    headers
  );
};

chartApi.send = async (params, headers) => {
  return await Api.post(
    `${API_BASE_URL}${params.route}/send`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

chartApi.share = async (params, headers) => {
  return await Api.patch(
    `${API_BASE_URL}${params.route}/${params.id}`,
    {
      needLoader: true,
    },
    headers
  );
};

export default chartApi;
