import { faCopy, faExchangeAlt, faRobot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { ORDER_STATUS, ORDER_TYPE } from "../../../common/constants";
import NumberLabel from "../../../common/form/number-label/number-label";
import CommonHelper from "../../../common/helpers/common-helper";

export const INITIAL_CAMPAIGN_FORM_DATA = {
  exchangeId: 0,
  assetAmount: 0,
  assetAmountPercentage: 0,
  marketAmount: 0,
  marketAmountPercentage: 0,
  marketName: "",
  assetName: "",
  comment: "",
  hardStopQuantity: 0,
  hardStopPrice: 0,
  status: 1,
  conditions: [],
};

export const INITIAL_AMOUNT_FORM_DATA = {
  amount: 0,
  amountPercentage: 0,
  marketAmount: 0,
  marketAmountPercentage: 0
};

export const INITIAL_SELL_LIMIT_DATA = {
  exchangeId: 0,
  quantity: 0,
  total: "",
  price: 0,
  type: ORDER_TYPE.Limit,
};

export const INITIAL_INCREASE_ORDER_DATA = {
  exchangeId: 0,
  quantity: 0,
  total: "",
};

export const CAMPAIGN_ACTION_TYPE = {
  0: "GRID Bot",
  1: "Sell Limit",
};

export const CAMPAIGN_ACTION_TYPE_ICON = {
  0: faRobot,
  1: faExchangeAlt,
};

export const SWING_BOT_ACTION = CAMPAIGN_ACTION_TYPE[0];
export const SELL_LIMIT_ACTION = CAMPAIGN_ACTION_TYPE[1];

const isSwingBotAction = (type) => {
  return CAMPAIGN_ACTION_TYPE[type] === SWING_BOT_ACTION;
};

export const CAMPAIGN_ACTIONS_COLUMNS = [
  {
    field: "type",
    headerName: "CAMPAIGN.FORM_TABLE.ACTION",
    width: 100,
    cellRenderer: (data) => {
      return <FontAwesomeIcon icon={CAMPAIGN_ACTION_TYPE_ICON[data.type]} />;
    },
  },
  {
    field: "botProfit",
    headerName: "CAMPAIGN.FORM_TABLE.PROFIT",
    cellRenderer: (data) => {
      return (
        <div>
          {<p className="m-0">{data.botProfit || 0}%</p>}
          {isSwingBotAction(data.type) && (
            <p className="m-0">
              {data.profit === 0
                ? `Market ${data.marketProfit} ${data.marketName}`
                : `Asset ${data.coinProfit} ${data.assetName}`}
            </p>
          )}
        </div>
      );
    },
    sortable: true,
  },
  {
    sortable: true,
    field: "pair",
    headerName: "CAMPAIGN.FORM_TABLE.PAIR",
    cellRenderer: (data) => {
      return data.assetName && data.marketName
        ? `${data.assetName}_${data.marketName}`
        : "";
    },
  },
  {
    field: "postition",
    headerName: "BOT_OVERVIEW.POSITION",
    width: 220,
    cellRenderer: (data) => {
      const {
        startAtPrice,
        coinPrice,
        endAtPrice,
        botPositionFirstOrder,
        botPositionLastOrder,
        type,
      } = data;
      const isCoinPriceHighest =
        coinPrice > startAtPrice && coinPrice > endAtPrice;

      const priceGraphData = [
        {
          key: 1,
          value: CommonHelper.toCustomFixed(startAtPrice),
          classList: (index) =>
            CommonHelper.setPriceBorder(index, isCoinPriceHighest),
        },
        {
          key: 2,
          value: CommonHelper.toCustomFixed(endAtPrice),
          classList: (index) =>
            CommonHelper.setPriceBorder(index, isCoinPriceHighest),
        },
        {
          key: 3,
          value: CommonHelper.toCustomFixed(coinPrice),
          classList: (index) => (index === 1 ? "center" : "outside"),
        },
      ];
      return (
        isSwingBotAction(type) ? (
          <div className="d-flex align-items-center justify-content-between">
            <div style={{ width: "45%" }}>
              <p
                style={{
                  color: botPositionLastOrder > 0 ? "#42f731" : "red",
                }}
                className="m-0"
              >
                {botPositionLastOrder > 0 ? "+" : ""}
                {CommonHelper.roundNumber(botPositionLastOrder)}%
              </p>
              <p
                style={{
                  color: botPositionFirstOrder > 0 ?  "red" :  "#42f731",
                }}
                className="m-0"
              >
                {botPositionFirstOrder > 0 ? "+" : ""}
                {CommonHelper.roundNumber(botPositionFirstOrder)}%
              </p>
            </div>
            <div className="timeline">
              {priceGraphData.sort(CommonHelper.sortDesc).map((price, i) => (
                <div key={price.key} className="timeline-box">
                  <div className={`content ${price.classList(i)}`}>
                    <p><NumberLabel value={price.value} /></p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : <NumberLabel value={data.startAtPrice} />
      );
    },
  },
  {
    sortable: true,
    field: "rounds",
    headerName: "BOT_OVERVIEW.ROUND",
    width: 100,
  },
  {
    sortable: true,
    field: "amount",
    headerName: "BOT_OVERVIEW.AMOUNT_SELLCOIN_BUYMARKET",
    width: 180,
    type: "number-no-min"
  },
  {
    sortable: true,
    field: "lotCount",
    headerName: "BOT_OVERVIEW.LOT",
    width: 80,
  },
  {
    sortable: true,
    field: "step",
    headerName: "BOT_OVERVIEW.STEP",
    width: 90,
    type: "number-no-min"
  },
  {
    field: "tradeDate",
    headerName: "BOT_OVERVIEW.FIRST_LAST_TRADE",
    width: 160,
    cellRenderer: (data) => {
      return (
        <div>
          <p className="m-0">
            {data.firstTrade
              ? new Date(data.firstTrade).toLocaleDateString()
              : ""}
          </p>
          <p className="m-0">
            {data.lastTrade
              ? new Date(data.lastTrade).toLocaleDateString()
              : ""}
          </p>
        </div>
      );
    },
  },
  {
    sortable: true,
    field: "side",
    headerName: "ORDER_OVERVIEW_TABLE.SIDE",
    width: 120,
    cellRenderer: (data) => {
      return data.side === 0 ? "BUY" : "SELL";
    },
  },
  {
    sortable: true,
    field: "dynamic",
    headerName: "BOT_OVERVIEW.PROFIT_TYPE",
    width: 130,
    cellRenderer: (data) => {
      return (
        <div>
          <p className="m-0">{data.profitType === 0 ? "Market" : "Asset"}</p>
          <p className="m-0">
            {data.dynamic > 0 ? "+" : ""}
            {data.dynamic}%
          </p>
        </div>
      );
    },
  },
  {
    field: "fullApiKeyName",
    headerName: "BOT_OVERVIEW.EXCHANGE_API_KEY",
    width: 170,
  },
  // {
  //   field: "startPercentage",
  //   headerName: "CAMPAIGN.FORM_TABLE.START",
  //   cellRenderer: (data) => {
  //     return (
  //       <>
  //         <div>{data.startPrice}</div>
  //         {data.startPercentage ? (
  //           <div>({Math.round(data.startPercentage)}%)</div>
  //         ) : (
  //           ""
  //         )}
  //       </>
  //     );
  //   },
  //   sortable: true,
  // },
  // {
  //   field: "endPercentage",
  //   headerName: "CAMPAIGN.FORM_TABLE.END",
  //   cellRenderer: (data) => {
  //     return (
  //       <>
  //         <div>{data.endPrice}</div>
  //         {data.endPercentage ? (
  //           <div>({Math.round(data.endPercentage)}%)</div>
  //         ) : (
  //           ""
  //         )}
  //       </>
  //     );
  //   },
  //   sortable: true,
  // },
  // {
  //   field: "amount",
  //   headerName: "CAMPAIGN.FORM_TABLE.AMOUNT",
  //   sortable: true,
  // },

  // {
  //   field: "quantity",
  //   headerName: "CAMPAIGN.FORM_TABLE.AB",
  // },
  // {
  //   field: "orderErrorsCount",
  //   headerName: "CAMPAIGN.FORM_TABLE.ERROR-COUNT",
  //   sortable: true,
  //   width: 100,
  //   cellRenderer: (data) => {
  //     return data.orderErrorsCount ? (
  //       <span
  //         style={{ padding: "3px 10px", color: "#fff", fontSize: 12 }}
  //         className="badge rounded-pill bg-danger"
  //       >
  //         {data.orderErrorsCount}
  //       </span>
  //     ) : (
  //       ""
  //     );
  //   },
  // },

  // {
  //   field: "message",
  //   headerName: "CAMPAIGN.FORM_TABLE.ERROR-MESSAGE",
  //   width: 280,
  // },
  // {
  //   field: "orderStatus",
  //   headerName: "ORDER_OVERVIEW_TABLE.STATUS",
  //   cellRenderer: (data) => {
  //     return renderCampaignActionStatus(data);
  //   },
  // },
  {
    field: "orderStatus",
    width: 280,
    headerName: "ORDER_OVERVIEW_TABLE.STATUS",
    cellRenderer: (data) => {
      return (
        <div>
          {renderCampaignActionStatus(data)}
          {renderErrorMsg(data)}
          <p>{data.message}</p>
        </div>
      );
    },
  },
];

export const CAMPAIGN_CONTEST_COLUMNS = (copyCampaign) => [
  {
    field: "campaignNumber",
    headerName: "CAMPAIGN.FORM.CAMPAIGN-NO",
    width: 70,
    type: "sequence",
  },
  {
    field: "totalBotsProfit",
    headerName: "CAMPAIGN.FORM_TABLE.PROFIT",
    cellRenderer: (data) => {
      return (
        <div>
          {
            <p className="m-0">
              <NumberLabel value={data.totalBotsProfit} />%
            </p>
          }
        </div>
      );
    },
    sortable: true,
    width: 100,
  },
  {
    sortable: true,
    field: "totalBotRounds",
    headerName: "BOT_OVERVIEW.ROUND",
    width: 100,
  },
  {
    sortable: true,
    field: "exchanges",
    headerName: "CAMPAIGN.FORM.EXCHANGES",
    cellRenderer: () => {
      return (
        <div>
          <p className="m-0">Binance</p>
        </div>
      );
    },
  },
  {
    sortable: true,
    field: "pair",
    headerName: "CAMPAIGN.FORM.PAIR",
    cellRenderer: (data) => {
      return `${data.assetName}_${data.marketName}`;
    },
  },
  {
    sortable: true,
    field: "type",
    headerName: "CAMPAIGN.FORM.TYPE",
    cellRenderer: (data) => {
      return data.type === 0 ? "BUY" : "SELL";
    },
  },
  {
    sortable: true,
    field: "assetAmount",
    headerName: "CAMPAIGN.FORM.AMOUNT",
  },
  {
    sortable: true,
    field: "status",
    headerName: "CAMPAIGN.FORM_TABLE.STATUS",
    cellRenderer: ({ status }) => {
      return (
        <span
          className={`status ${status === 0 ? "error" : "active"} ml-2 mb-2`}
        >
          {status === 0 ? "Inactive" : "Active"}
        </span>
      );
    },
  },
  {
    sortable: true,
    field: "id",
    headerName: "CAMPAIGN.FORM.ID",
  },
  {
    field: "actions",
    headerName: "ORDER_OVERVIEW_TABLE.ACTIONS",
    type: "action",
    cellRenderer: (data) => {
      return (
        <DropdownButton
          variant="secondary"
          id="dropdown-basic-button"
          title="Actions"
          drop={"left"}
          className="fix-dropdown-margin"
        >
          <Dropdown.Item onClick={() => copyCampaign(data)}>
            <FontAwesomeIcon icon={faCopy} /> Copy
          </Dropdown.Item>
        </DropdownButton>
      );
    },
  }
];

const renderErrorMsg = (data) => {
  return (
    <span
      style={{
        marginLeft: 10,
        padding: "3px 10px",
        color: "#fff",
        fontSize: 12,
      }}
      className="badge rounded-pill bg-danger"
    >
      {data.orderErrorsCount}
    </span>
  );
};

const renderCampaignActionStatus = (data) => {
  return data.type === 1 ? (
    <span
      className={`status ${(
        ORDER_STATUS[data.orderStatus] || "Active"
      ).toLowerCase()}`}
    >
      {ORDER_STATUS[data.orderStatus] || "Status"}
    </span>
  ) : (
    <span
      className={`status ${(data.botActive
        ? "active"
        : "canceled"
      ).toLowerCase()}`}
    >
      {data.botActive ? "Active" : "Inactive"}
    </span>
  );
};