import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Card, Row } from "react-bootstrap";
import whiteLogo from "../../../common/assets/logo-white.png";
import logo from "../../../common/assets/logo.png";
import ForgotPasswordForm from "./forgot-password-form";

import { forgotPassword } from "../actions/forgot-password";

import { APPLICATION_BASE_URL } from "../../../common/constants";
import { routes } from "../../../common/routes-constants";
import { fields } from "../constants/constants";

import CommonHelper from "../../../common/helpers/common-helper";
import "../style/forgot-password.scss";

class ForgotPassword extends Component {
  state = {
    responseErrorMsg: "",
    responseSuccess: false,
  };

  handleResetPasswordSubmit = (values) => {
    const { forgotPassword } = this.props;
    forgotPassword(
      {
        ...values,
        [fields.returnUrl]: `${APPLICATION_BASE_URL}${routes.unProtectedRoutes.setPassword.path}`,
      },
      () => {
        this.setState({ responseSuccess: true });
      },
      (responseErrorMsg) => {
        this.setState({ responseErrorMsg });
      }
    );
  };

  render() {
    const { t } = this.context;
    const { responseErrorMsg, responseSuccess } = this.state;
    const isDarkMode = CommonHelper.getUserCurrentTheme() === 'dark';

    return (
      <Row className="forgot-password align-items-center justify-content-center h-100 ">
        <Card className="col-sm-10 col-md-6 col-lg-4 col-xl-3">
          <Card.Body>
            <Card.Title className="text-center">
              <img width={180} src={isDarkMode ? whiteLogo : logo} alt={"logo"} />
            </Card.Title>
            <h6 className="line-on-side text-muted text-center font-small-3 pt-2">
              <span>{t("FORGOT_PASSWORD.TITLE")}</span>
            </h6>

            {!responseSuccess && (
              <>
                <ForgotPasswordForm
                  onSubmit={this.handleResetPasswordSubmit}
                  t={t}
                  responseErrorMsg={responseErrorMsg}
                  clearError={() => this.setState({ responseErrorMsg: null })}
                />
                <Link
                  to={routes.unProtectedRoutes.login.fullPath}
                  className="float-right mt-2"
                >
                  {t("FORGOT_PASSWORD.BACK_TO_LOGIN")}
                </Link>
              </>
            )}

            {responseSuccess && (
              <>
                <Card.Text>{t("FORGOT_PASSWORD.SUCCESS_MESSAGE")}</Card.Text>
                <Link
                  to={routes.unProtectedRoutes.login.fullPath}
                  className="float-right mt-2"
                >
                  {t("FORGOT_PASSWORD.BACK_TO_LOGIN")}
                </Link>
              </>
            )}
          </Card.Body>
        </Card>
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (params, setResponseSuccess, setResponseError) =>
    dispatch(forgotPassword(params, setResponseSuccess, setResponseError)),
});

ForgotPassword.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(connect(null, mapDispatchToProps)(ForgotPassword));
