import { fields } from "../constants/constants";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../../common/constants";

import ValidationHelper from "../../../common/helpers/validation-helper";

export const validate = (values, { t }) => {
  const errors = {};

  if (!EMAIL_REGEX.test(values[fields.email])) {
    errors[fields.email] = { label: t("REGISTER_FORM.EMAIL_NOT_VALID") };
  }

  if (!values[fields.firstName]) {
    errors[fields.firstName] = {
      label: t("REGISTER_FORM.FIRST_NAME_REQUIRED"),
    };
  }

  if (!values[fields.lastName]) {
    errors[fields.lastName] = { label: t("REGISTER_FORM.LAST_NAME_REQUIRED") };
  }

  if (!values[fields.nickname]) {
    errors[fields.nickname] = { label: t("REGISTER_FORM.NICK_NAME_REQUIRED") };
  }

  if (!PASSWORD_REGEX.test(values[fields.password] || !values[fields.password])) {
    errors[fields.password] = {
      label: t("REGISTER_FORM.PASSWORD_NOT_VALID"),
    };
  }
  //if pass is ok than compare it to confirm password
  if (PASSWORD_REGEX.test(values[fields.password])) {
    if (values[fields.password] !== values[fields.confirmPassword]) {
      errors[fields.confirmPassword] = {
        label: t("REGISTER_FORM.PASSWORDS_NOT_MATCH"),
      };
    }
  }

  if (!values[fields.country]) {
    errors[fields.country] = { label: t("REGISTER_FORM.COUNTRY_REQUIRED") };
  }

  if (!values[fields.city]) {
    errors[fields.city] = { label: t("REGISTER_FORM.CITY_REQUIRED") };
  }

  if (!values[fields.street]) {
    errors[fields.street] = { label: t("REGISTER_FORM.STREET_REQUIRED") };
  }

  if (!values[fields.zip]) {
    errors[fields.zip] = { label: t("REGISTER_FORM.ZIP_REQUIRED") };
  }

  if (!values[fields.agreeToTermsAndConditions]) {
    errors[fields.agreeToTermsAndConditions] = { label: t("REGISTER_FORM.CONFIRMATION_REQUIRED") };
  }

  if (!values[fields.agreeToPrivacyPolicy]) {
    errors[fields.agreeToPrivacyPolicy] = { label: t("REGISTER_FORM.CONFIRMATION_REQUIRED") };
  }

  if (!values[fields.role]) {
    errors[fields.role] = { label: t("REGISTER_FORM.ROLE_REQUIRED") };
  }

  if (!values[fields.iban] && !!values[fields.role] && values[fields.role] !== "TRIAL") {
    errors[fields.iban] = { label: t("REGISTER_FORM.IBAN_REQUIRED") };
  }

  const isIbanValid = ValidationHelper.isIbanValid(values[fields.iban], t);
  if (isIbanValid && !isIbanValid.isValid) {
    errors[fields.iban] = { label: isIbanValid.label };
  }

  return errors;
};
