import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Navbar from "../../navigation/components/navigation";
import LeftMenu from "../../navigation/components/left-menu";

import { logout } from "../../../app/login/actions/login-actions";
import { setMainMenuCollapsed } from "../actions/action-creators";

import "../styles/in-app-container.scss";

const mobileSize = 767;
const mobileViewClass = "mobile-view";

class InAppContainer extends Component {
  state = {
    isMobile: window.innerWidth <= mobileSize,
  };

  componentDidMount = () => {
    const { isMobile } = this.state;
    window.addEventListener("resize", this.onResize, true);
    if (isMobile) {
      document.body.classList.add(mobileViewClass);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onResize);
  };

  onResize = (e) => {
    e = e || { target: window };

    const { setMainMenuCollapsed } = this.props;
    const { isMobile } = this.state;
    if (e.target.innerWidth <= mobileSize && !isMobile) {
      document.body.classList.add(mobileViewClass);
      this.setState({ isMobile: true });
      setMainMenuCollapsed(true);
    }
    if (e.target.innerWidth > mobileSize && isMobile) {
      document.body.classList.remove(mobileViewClass);
      this.setState({ isMobile: false });
      setMainMenuCollapsed(true);
    }
  };

  render() {
    const { isMobile } = this.state;
    const { children, logout, mainMenuCollapsed, setMainMenuCollapsed, language, setLanguage } = this.props;

    return (
      <div
        className={`in-app-container ${
          mainMenuCollapsed ? "menu-collapsed" : ""
        }`}
      >
        <Navbar
          {...{
            logout,
            toggleMenu: () => {
              setMainMenuCollapsed(!mainMenuCollapsed);
            },
            menuClick: isMobile ? () => setMainMenuCollapsed(true) : () => null,
            language,
            setLanguage,
          }}
        />
        <LeftMenu
          {...{
            mainMenuCollapsed,
            menuClick: isMobile ? () => setMainMenuCollapsed(true) : () => null,
          }}
        />
        <div className="main-content p-3">{children}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mainMenuCollapsed: state.containerReducer.mainMenuCollapsed,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  setMainMenuCollapsed: (collapsed) =>
    dispatch(setMainMenuCollapsed(collapsed)),
});

InAppContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(InAppContainer);
