import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import Button from "../../../common/form/button/components/button";
import userApi from "../../../api/user/actions";
import Helpers from "../../../common/helpers/error-helpers";
import Loader from "../../../common/loader/components/common-loader";

import "../style/invoice.scss";
import InputNumber from "../../../common/form/input/components/number-input";

const CreateInvoiceModal = ({ show, onClose, onSave }, { t }) => {
   const [users, setUsers] = useState([]);
   const [userId, setUserId] = useState("");
   const [invoiceTitle, setInvoiceTitle] = useState("");
   const [invoiceContent, setInvoiceContent] = useState("");
   const [profit, setProfit] = useState("");
   const [isLoading, setIsLoading] = useState(true);
   const [formValid, setFormValid] = useState(false);

   useEffect(() => {
      async function getAllUsers() {
      setIsLoading(true);
      try {
         const response = await userApi.getUsers();
         setUsers(response.data);
         setIsLoading(false);
      } catch (err) {
         Helpers.parseError(err);
         setIsLoading(false);
      }
      }
      getAllUsers();
   }, []);

   const handleProfitChange = (e) => {
      // Regex to allow only 4 decimal places
      const regex = /^\d*\.?\d{0,4}$/;
      if (regex.test(e.target.value)) {
      setProfit(e.target.value);
      }
      setFormValid(!!userId && !!invoiceTitle && !!invoiceContent && !!e.target.value);
   };

   return (
      <Modal show={show} onHide={onClose} backdrop="static" size="m">
      <Modal.Header closeButton>
         <Modal.Title>{t("INVOICE.CREATE_INVOICE_MODAL.TITLE")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
         <div>
            <select
               name="userId"
               className="form-control mb-3"
               value={userId}
               onChange={(e) => {
                  setUserId(e.target.value);
                  setFormValid(!!e.target.value && !!invoiceTitle && !!invoiceContent && !!profit);
               }}
               >
               <option value="">{t("SELECT-USER")}</option>
               {users.map((user) => (
                  <option key={user.userId} value={user.userId}>
                     {user.firstName} {user.lastName}
                  </option>
               ))}
            </select>
            <input className="form-control mb-3"
                   type="text"
                   placeholder='Invoice title...'
                   name="invoiceTitle"
                   value={invoiceTitle}
                   onChange={(e) => {
                      setInvoiceTitle(e.target.value);
                      setFormValid(!!userId &&!!e.target.value && !!invoiceContent && !!profit);
                   }} />
            <textarea placeholder="Invoice content..."
                      value={invoiceContent}
                      className="form-control mb-3"
                      onChange={(e) => {
                         setInvoiceContent(e.target.value);
                         setFormValid(!!userId && !!invoiceTitle && !!e.target.value && !!profit);
                      }} />
            <InputNumber placeholder="Invoice amount..." value={profit} className="form-control mb-3" onChange={handleProfitChange} />
            <div className="d-flex justify-content-end">
            <Button variant="outline-secondary" className="mt-4 mr-2" type="button" size="md"
               onClick={() => {
                  onClose();
                  setInvoiceTitle(null)
                  setInvoiceContent(null);
                  setProfit(null)
                  setUserId(0);
               }}>
               Cancel
            </Button>
            <Button variant="outline-primary" className="mt-4" type="button" size="md"
               disabled={!formValid}
               onClick={() => onSave({ userId, invoiceTitle, invoiceContent, profit: parseFloat(profit) })}>
               Save
            </Button>
            </div>
         </div>
      </Modal.Body>
      <Loader loading={isLoading} type="global" />
      </Modal>
   );
};

CreateInvoiceModal.contextTypes = {
  t: PropTypes.func,
};

export default CreateInvoiceModal;