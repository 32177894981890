import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useHelpModus } from "../../../common/help-modus/help-modus-context";
import OrderBookTable from "./order-book-table";

const OrderBook = ({ formData, onInfoIconClick, exchangeId, totalAmount, orderType }) => {
  const { keyId, marketName, assetName } = formData;
  const isEdit = !!formData.id;
  const { isHelpModus } = useHelpModus();

  return (
    <div className="d-flex">
      <div className="d-flex mobile-order-book" style={{ gap: "25px", width: "100%" }}>
        <OrderBookTable
          marketName={marketName}
          assetName={assetName}
          exchangeId={exchangeId}
          keyId={keyId}
          totalAmount={totalAmount}
          orderType={orderType}
          isEdit={isEdit}></OrderBookTable>
      </div>
      {isHelpModus && (
        <div className="infoButton" onClick={onInfoIconClick} style={{ top: '-10px', right: '-10px' }}>
          <FontAwesomeIcon className="row" icon={faInfoCircle} />
        </div>
      )}
    </div>
  );
};

export default OrderBook;
