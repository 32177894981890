import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import PaymentMethodsForm from "../../edit-profile/components/payment-methods-form";

const ProfitModal = (
  {
    show,
    onClose,
    userId
  },
  { t }
) => {
  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{t("USER-PROFIT.TITLE")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PaymentMethodsForm userId={userId} />
      </Modal.Body>
    </Modal>
  );
};

ProfitModal.contextTypes = {
  t: PropTypes.func,
};

export default ProfitModal;
