import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faRobot,
  faExchangeAlt,
  faBan,
  faHistory,
  faExternalLinkAlt,
  faUnlink,
  faEdit,
  faChevronRight,
  faChevronDown,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";

import {
  getAssetPrice,
  handleGetUserAssets,
} from "../../user-assets/actions/user-assets-actions";
import campaignApi from "../../../api/campaign/actions";
import bothApi from "../../../api/bot/actions";
import botOrderApi from "../../../api/trade-order/actions";

import Helpers from "../../../common/helpers/error-helpers";
import NotificationProvider from "../../../core/notification";
import Chart from "../../../common/trading-chart/chart";
import Button from "../../../common/form/button/components/button";
import { routes } from "../../../common/routes-constants";
import {
  CAMPAIGN_ACTIONS_COLUMNS,
  CAMPAIGN_ACTION_TYPE,
  INITIAL_AMOUNT_FORM_DATA,
  INITIAL_CAMPAIGN_FORM_DATA,
  SELL_LIMIT_ACTION,
  SWING_BOT_ACTION,
} from "../constants";
import { useHelpModus } from "../../../common/help-modus/help-modus-context";
import HelpModusModal from "../../../common/help-modus/help.modus-modal";

import TradeTable from "../../../common/form/table/trade-table";
import CampaignForm from "./campaign-form";
import IncreaseModal from "../modals/increase-modal";
import BotActionModal from "../modals/bot-action-modal";
import SellLimitModal from "../modals/sell-limit-action-modal";
import AmountActionModal from "../modals/amount-action-modal";
import CampaignHardStopModal from "../modals/campaign-hard-stop-modal";

import "../style/campaign.scss";
import Condition from "../../condition/components/condition";
import { PRE_CONDITION_TYPE } from "../../condition/constants";

import barImage from "../../../common/assets/help-modus/help-modus-chart-bar.png";
import chartImage from "../../../common/assets/help-modus/help-modus-chart.png";

const Campaign = (
  {
    userAssets,
    getUserAssets,
    handleGetUserAssets,
    getAssetPrice,
    match: {
      params: { id },
    },
    history,
    location
  },
  { t }
) => {
  const [formData, setFormData] = useState(INITIAL_CAMPAIGN_FORM_DATA);
  const [showIncreaseModal, setShowIncreaseModal] = useState();
  const [showAmountActionModal, setShowAmountActionModal] = useState();
  const [showBotActionModal, setShowBotActionModal] = useState();
  const [showSellLimitActionModal, setShowSellLimitActionModal] = useState();
  const [campaignActionType, setCampaignActionType] = useState("");
  const [amountData, setAmountData] = useState(INITIAL_AMOUNT_FORM_DATA);
  const [campaignActions, setCampaignActions] = useState([]);
  // use this amount to prevent user increase campaign amount without saving data
  const [campaignAmount, setCampaignAmount] = useState(0);
  const [campaignMarket, setCampaignMarket] = useState(0);
  const [showHardStopModal, setShowHardStopModal] = useState();
  const [exchangeId, setExchangeId] = useState(0);

  const [showConditions, setShowConditions] = useState(false);
  // use this conditions to prevent disabling form while user add/remove conditions
  const [conditions, setConditions] = useState([]);

  const isCampaignActive = formData.id && formData.status === 1;

  const [showHelpModusModal, setShowHelpModusModal] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [modalTitle, setModalTitle] = useState('Information');
  const [isMobile] = useState(window.innerWidth < 768);
  const { isHelpModus } = useHelpModus();

  const queryParams = new URLSearchParams(location.search);
  const copyCampaignId = queryParams.get("copyCampaignId");
  const copyCampaignAmount = queryParams.get("amount");

  const columns = [
    ...CAMPAIGN_ACTIONS_COLUMNS,
    {
      field: "actions",
      headerName: "ORDER_OVERVIEW_TABLE.ACTIONS",
      type: "action",
      cellRenderer: (data) => {
        return (
          <DropdownButton
            variant="secondary"
            id="dropdown-basic-button"
            title="Actions"
            drop={"left"}
            className="fix-dropdown-margin"
          >
            <Dropdown.Item
              disabled={
                (isSwingBotAction(data.type) && !data.botActive) ||
                (isSellLimitAction(data.type) && data.orderStatus !== 1)
              }
              onClick={() => cancelAction(data)}
              href="#"
            >
              <FontAwesomeIcon icon={faUnlink} /> {t("CAMPAIGN.ACTIONS.CANCEL")}
            </Dropdown.Item>
            {isSwingBotAction(data.type) && getBotActions(data)}
          </DropdownButton>
        );
      },
    },
  ];

  const initialWidth = window.innerWidth;

  const handleOrientationChange = () => {
    if (window.innerWidth !== initialWidth) {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleOrientationChange);
    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    id && getCampaignById(id);

    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    copyCampaignId && getCampaignById(copyCampaignId);

    // eslint-disable-next-line
  }, [copyCampaignId]);

  const onChangeHandler = (e) => {
    const { name, value, exchangeId } = e.target;
    setFormData({ ...formData, [name]: value });
    setExchangeId(exchangeId);
  };

  const toggleIncreaseModal = () => {
    setShowIncreaseModal((showModal) => !showModal);
  };

  const toggleBotActionModal = () => {
    setShowBotActionModal((showModal) => !showModal);
  };

  const toggleSellLimitActionModal = () => {
    setShowSellLimitActionModal((showModal) => !showModal);
  };

  const toggleAmountActionModal = (actionType) => {
    setShowAmountActionModal((showModal) => !showModal);
    setCampaignActionType(actionType);
  };

  const toggleHardStopModal = () => {
    setShowHardStopModal((showModal) => !showModal);
  };

  const isSwingBotAction = (type) => {
    return CAMPAIGN_ACTION_TYPE[type] === SWING_BOT_ACTION;
  };

  const isSellLimitAction = (type) => {
    return CAMPAIGN_ACTION_TYPE[type] === SELL_LIMIT_ACTION;
  };

  const createCampaign = async () => {
    try {
      const response = await campaignApi.create(formData);
      const { campaignId } = response.data || {};
      history.push(`${routes.protectedRoutes.campaignEdit.path}/${campaignId}`);
      NotificationProvider.success("Campaign successfully created");
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  const getAvailableUserCoins = useCallback(() => {
    if (!formData.assetName) {
      return "N\\A";
    }

    let coinAmount =
      userAssets &&
      userAssets.assets?.find(({ name }) => name === formData.assetName);

    return coinAmount === undefined ? 0 : coinAmount.amountAvailable;
  }, [userAssets, formData.assetName]);

  const getAvailableUserMarkets = useCallback(() => {
    if (!formData.marketName) {
      return "N\\A";
    }

    let marketAmount =
      userAssets &&
      userAssets.markets?.find(({ name }) => name === formData.marketName);

    return marketAmount === undefined ? 0 : marketAmount.amountAvailable;
  }, [userAssets, formData.marketName]);

  const updateCampaign = async () => {
    try {
      await campaignApi.update(formData);
      NotificationProvider.success("Campaign successfully updated");
      setCampaignAmount(formData.assetAmount);
      setCampaignMarket(formData.marketAmount);
      setConditions(formData.conditions);
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  const getCampaignById = async (id) => {
    try {
      const response = await campaignApi.getCampaignById({ params: { id } });

      if (copyCampaignId && response.data) {
        response.data.assetAmount = copyCampaignAmount;
      }

      response.data && (await getUserAssets(response.data.apiKeyId));
      response.data && setFormData({ ...formData, ...response.data });
      response.data && setCampaignAmount(response.data.assetAmount);
      response.data && setCampaignMarket(response.data.marketAmount);
      response.data && setConditions(response.data.conditions);
      getCampaignActions(response.data.apiKeyId);
    } catch (err) {
      setCampaignActions([]);
      setCampaignAmount(0);
      Helpers.parseError(err);
    }
  };

  const getCampaignActions = async (keyId) => {
    const { apiKeyId } = formData;
    const { data } = await campaignApi.getCampaignActions({
      params: { campaignId: id, apiKeyId: apiKeyId || keyId },
    });
    Array.isArray(data) && setCampaignActions(data);
  };

  const createSwingBotAction = async (botData) => {
    if (!id) return;
    try {
      await bothApi.save({ ...botData, campaignId: +id });
      getCampaignActions();
      toggleBotActionModal();
        NotificationProvider.success("GRID Bot Action successfully created");
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  const createSellLimitAction = async (sellLimitData) => {
    if (!id) return;
    try {
      const request = {
        ...sellLimitData,
        apiKeyId: +formData.apiKeyId,
        campaignId: +id,
      };
      await botOrderApi.createOrder(request);
      getCampaignActions();
      toggleSellLimitActionModal();
      NotificationProvider.success("Sell Limit Action successfully created");
    } catch (err) {
      Helpers.parseError(err);
      getCampaignActions();
      toggleSellLimitActionModal();
    }
  };

  const createIncreaseOrder = async (increaseOrderData) => {
    try {
      const request = {
        ...increaseOrderData,
        apiKeyId: +formData.apiKeyId,
      };
      await botOrderApi.createOrder(request);
      toggleIncreaseModal();
      NotificationProvider.success("Increase order successfully created");
    } catch (err) {
      Helpers.parseError(err);
      toggleIncreaseModal();
    }
  };

  const cancelAction = async ({ type, id }) => {
    try {
      await campaignApi.cancelAction({
        params: { actionTypeId: type, id },
      });
      getCampaignActions();
        NotificationProvider.success("GRID Bot Action successfully cancelled");
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  const getBotActions = ({ id }) => {
    return (
      <>
        <Dropdown.Item
          href={`/app/${routes.protectedRoutes.botEdit.path}/${id}`}
        >
          <FontAwesomeIcon icon={faEdit} /> {t("BOT.ACTIONS.EDIT-BOT")}
        </Dropdown.Item>
        <Dropdown.Item
          href={`/app/${routes.protectedRoutes.botOrderOverview.path}/${id}?active=1`}
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} />{" "}
          {t("BOT.ACTIONS.ORDER-STATUS")}
        </Dropdown.Item>
        <Dropdown.Item
          href={`/app/${routes.protectedRoutes.botOrderOverview.path}/${id}`}
        >
          <FontAwesomeIcon icon={faHistory} /> {t("BOT.ACTIONS.ORDER-HISTORY")}
        </Dropdown.Item>
        <Dropdown.Item href="#" onClick={() => closeBot(id)}>
          <FontAwesomeIcon icon={faBan} /> {t("BOT.ACTIONS.CLOSE-BOT")}
        </Dropdown.Item>
      </>
    );
  };

  const closeBot = async (id) => {
    try {
      await bothApi.closeBot({ id });
      NotificationProvider.success("Bot successfully closed");
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  const getCampaignActionsAmountSum = () => {
    if (campaignActions.length === 0) return campaignAmount;
    const sum = campaignActions
      .filter(isCampaignActionActive)
      .map((action) => action.amount)
      .reduce((a, b) => a + b, 0);
    const amount = campaignAmount - sum;
    return amount < 0 ? 0 : amount;
  };

  const isCampaignActionActive = (action) => {
    return (
      (isSwingBotAction(action.type) && action.botActive) ||
      (isSellLimitAction(action.type) && action.orderStatus === 1)
    );
  };

  const hardStopCampaign = async (hardStopPrice, hardStopQuantity) => {
    try {
      await campaignApi.hardStop({
        ...formData,
        hardStopPrice,
        hardStopQuantity,
      });
      NotificationProvider.success("Campaign successfully updated");
      getCampaignById(id);
      toggleHardStopModal();
    } catch (err) {
      Helpers.parseError(err);
      toggleHardStopModal();
    }
  };

  const checkIfCampaignHasUnfinishedPreCondition = () => {
    return conditions.some(
      (condition) =>
        condition.type === PRE_CONDITION_TYPE && !condition.completed
    );
  };

  const setInactiveState = () => {
    return formData.status === 0 && !checkIfCampaignHasUnfinishedPreCondition()
      ? {
          pointerEvents: "none",
          opacity: ".7",
        }
      : {};
  };

  const toggleHelpModusModal = (title, message) => {
    setShowHelpModusModal((showModal) => !showModal);
    setModalMessage(message);
    setModalTitle(title);
  };

  const renderChart = () => {
    return (
      <div className="trade-chart pr-3 d-flex" style={{ height: 500, width: "100%" }}>
        <Chart
          height={500}
          assetName={formData.assetName}
          marketName={formData.marketName}
          exchangeId={exchangeId}
        />
        {isHelpModus && (
          <div className="infoButton asset-info-btn"
            onClick={() => {
              const messageContent = (
                <div>
                  <p>{t('HELP-MODUS.CREATE-BOT-CHART-FIRST')}</p>
                  <img src={barImage} style={{ width: '25%' }} alt="Create Bot Chart Bar" />
                  <p><br></br>{t('HELP-MODUS.CREATE-BOT-CHART-SECOND')}</p>
                  <img src={chartImage} style={{ width: '100%' }} alt="Create Bot Chart Bar" />
                  <p><br></br>{t('HELP-MODUS.CREATE-BOT-CHART-THIRD')}</p>
                </div>
              );
              toggleHelpModusModal('Chart', messageContent);
            }}>
            <FontAwesomeIcon className="row" icon={faInfoCircle} />
          </div>
        )}
      </div>
    );
  }

  const renderConditions = () => {
    return (
      <div className="accordion mt-2">
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left d-flex justify-content-between align-items-center"
                onClick={() => {
                  setShowConditions(!showConditions);
                }}
              >
                <span style={{ fontWeight: 500 }} className="d-flex">
                  Conditions
                  {isHelpModus && (
                    <div className="infoButton avblInfoButton"
                      onClick={() => {
                        const messageContent = (
                          <div>
                            <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CAMPAIGN-CONDITIONS-ONE') }} />
                            <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CAMPAIGN-CONDITIONS-TWO') }} />
                            <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CAMPAIGN-CONDITIONS-THREE') }} />
                            <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CAMPAIGN-CONDITIONS-FOUR') }} />
                            <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-CONDITIONS-FIVE') }} />
                            <div className="ml-4" style={{ display: 'flex', justifyContent: 'space-between', width: '50%' }}>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p style={{ marginBottom: 0 }}>Type:</p>
                                <p style={{ marginBottom: 0 }}>Condition:</p>
                                <p style={{ marginBottom: 0 }}>Setup Order:</p>
                                <p style={{ marginBottom: 0 }} >Action:</p>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p style={{ marginBottom: 0 }}>Pre</p>
                                <p style={{ marginBottom: 0 }}>Order is fullfilled</p>
                                <p style={{ marginBottom: 0 }}>{t('HELP-MODUS.CREATE-BOT-CONDITIONS-SEVEN')}</p>
                                <p style={{ marginBottom: 0 }}>Start Action</p>
                              </div>
                            </div>
                            <br />
                            <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CREATE-BOT-CONDITIONS-SIX') }} />
                            <div className="ml-4" style={{ display: 'flex', justifyContent: 'space-between', width: '50%' }}>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p style={{ marginBottom: 0 }}>Type:</p>
                                <p style={{ marginBottom: 0 }}>Condition:</p>
                                <p style={{ marginBottom: 0 }} >Action:</p>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p style={{ marginBottom: 0 }}>Post</p>
                                <p style={{ marginBottom: 0 }}>Price is below</p>
                                <p style={{ marginBottom: 0 }}>Close order & sell percentage &rarr; 100%</p>
                              </div>
                            </div>
                          </div>
                        );
                        toggleHelpModusModal('Action List', messageContent);
                      }}>
                      <FontAwesomeIcon className="row" icon={faInfoCircle} />
                    </div>
                  )}
                </span>
                <FontAwesomeIcon
                  icon={
                    showConditions || formData.status === 0
                      ? faChevronDown
                      : faChevronRight
                  }
                />
              </button>
            </h2>
          </div>
          <div
            className={`collapse ${
              showConditions || formData.status === 0 ? "show" : ""
            }`}
          >
            <div className="card-body">
              <Condition
                isCampaign
                // in this case we check is campaign active
                isBotActive={isCampaignActive}
                marketName={formData.marketName || "USDT"}
                assetName={formData.assetName || "BTC"}
                apiKeyId={formData.apiKeyId}
                availableUserMarkets={getAvailableUserMarkets()}
                availableUserCoins={getAvailableUserCoins()}
                conditions={formData.conditions}
                setConditions={(conditions) => {
                  onChangeHandler({
                    target: {
                      name: "conditions",
                      value: conditions,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="campaign-layout w-100" style={setInactiveState()}>
      <h4 className="page-title d-flex">
        {t("CAMPAIGN.FORM.PAGE-TITLE")}
        {formData.status === 0 && (
          <span className="status error ml-2 mb-2">Inactive</span>
        )}
        {isHelpModus && (
          <div className="infoButton avblInfoButton" style={{ fontSize: '16px' }}
            onClick={() => {
              const messageContent = (
                <div>
                  <p>{t('HELP-MODUS.CAMPAIGN-TITLE')}</p>
                </div>
              );
              toggleHelpModusModal('Campaign', messageContent);
            }}>
            <FontAwesomeIcon className="row" icon={faInfoCircle} />
          </div>
        )}
      </h4>
      <div className="d-flex flex-column">
        <div className="d-flex w-100 pr-0 pb-3">
          {!isMobile && renderChart("d-sm-flex", 400)}
          <div className="campaign position-relative">
            <CampaignForm
              formData={formData}
              userAssets={userAssets}
              getUserAssets={(value) => value && getUserAssets(value)}
              onChangeHandler={onChangeHandler}
              setFormData={setFormData}
              onIncreaseClick={toggleIncreaseModal}
              isHelpModus={isHelpModus}
              toggleHelpModusModal={toggleHelpModusModal}
              exchangeId={exchangeId}
              isMobile={isMobile}
            />
            <div className="d-flex justify-content-end">
              <DropdownButton
                variant="secondary"
                id="dropdown-basic-button"
                disabled={!Boolean(id)}
                title={
                  <>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    &nbsp;{t("CAMPAIGN.ACTIONS.CREATE-ACTION")}
                  </>
                }
              >
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    setAmountData(INITIAL_AMOUNT_FORM_DATA);
                    toggleAmountActionModal("swing_bot");
                  }}
                >
                  <FontAwesomeIcon icon={faRobot} />
                  &nbsp;{t("CAMPAIGN.ACTIONS.SWING-BOT")}
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    setAmountData(INITIAL_AMOUNT_FORM_DATA);
                    toggleAmountActionModal("sell_limit");
                  }}
                >
                  <FontAwesomeIcon icon={faExchangeAlt} />
                  &nbsp;{t("CAMPAIGN.ACTIONS.LIMIT-ORDER")}
                </Dropdown.Item>
              </DropdownButton>
              {isHelpModus && (
                <div className="infoButton avblInfoButton"
                  onClick={() => {
                    const messageContent = (
                      <div>
                        <p>{t('HELP-MODUS.CAMPAIGN-ACTION')}</p>
                      </div>
                    );
                    toggleHelpModusModal('Action', messageContent);
                  }}>
                  <FontAwesomeIcon className="row" icon={faInfoCircle} />
                </div>
              )}
            </div>
            <div className="campaign-actions d-flex justify-content-center">
              <Button
                className={"mr-2 pl-5 pr-5"}
                variant="outline-primary"
                type="button"
                size="md"
                onClick={id ? updateCampaign : createCampaign}
              >
                {t("CAMPAIGN.FORM.SAVE")}
              </Button>
              {isHelpModus && (
                <div className="infoButton avblInfoButton" style={{ top: '-20px', right: '0' }}
                  onClick={() => {
                    const messageContent = (
                      <div>
                        <p>{t('HELP-MODUS.CAMPAIGN-SAVE')}</p>
                      </div>
                    );
                    toggleHelpModusModal('Save', messageContent);
                  }}>
                  <FontAwesomeIcon className="row" icon={faInfoCircle} />
                </div>
              )}
              <Button
                className={"mr-2 pl-5 pr-5"}
                variant="outline-danger"
                type="button"
                size="md"
                disabled={!id}
                onClick={toggleHardStopModal}
              >
                {t("CAMPAIGN.FORM.HARD-STOP")}
              </Button>
              {isHelpModus && (
                <div className="infoButton avblInfoButton" style={{ top: '-20px', right: '0' }}
                  onClick={() => {
                    const messageContent = (
                      <div>
                        <p>{t('HELP-MODUS.CAMPAIGN-HARD-STOP')}</p>
                      </div>
                    );
                    toggleHelpModusModal('Hard Stop', messageContent);
                  }}>
                  <FontAwesomeIcon className="row" icon={faInfoCircle} />
                </div>
              )}
            </div>

            {showIncreaseModal && (
              <IncreaseModal
                show={showIncreaseModal}
                onClose={toggleIncreaseModal}
                assetName={formData.assetName}
                marketName={formData.marketName}
                apiKeyId={formData.apiKeyId}
                getAvailableUserCoins={getAvailableUserCoins}
                getAvailableUserMarkets={getAvailableUserMarkets}
                getAssetPrice={getAssetPrice}
                onSave={createIncreaseOrder}
              />
            )}
            {showAmountActionModal && (
              <AmountActionModal
                show={showAmountActionModal}
                onClose={toggleAmountActionModal}
                onConfirm={() => {
                  campaignActionType === "swing_bot"
                    ? toggleBotActionModal()
                    : toggleSellLimitActionModal();
                  toggleAmountActionModal();
                }}
                amountData={amountData}
                onChangeHandler={setAmountData}
                assetName={formData.assetName}
                marketName={formData.marketName}
                avblCoins={getCampaignActionsAmountSum()}
                avblMarket={formData.marketAmount}
              />
            )}
            {showBotActionModal && (
              <BotActionModal
                show={showBotActionModal}
                onClose={toggleBotActionModal}
                formData={formData}
                amount={amountData.amount || amountData.marketAmount}
                type={amountData.amount > 0 ? 1 : 0}
                userAssets={userAssets}
                handleGetUserAssets={handleGetUserAssets}
                getAssetPrice={getAssetPrice}
                onSave={createSwingBotAction}
              />
            )}
            {showSellLimitActionModal && (
              <SellLimitModal
                show={showSellLimitActionModal}
                onClose={toggleSellLimitActionModal}
                assetName={formData.assetName}
                marketName={formData.marketName}
                apiKeyId={formData.apiKeyId}
                quantity={amountData.amount}
                marketTotal={amountData.marketAmount}
                type={amountData.amount > 0 ? 1 : 0}
                getAvailableUserCoins={getAvailableUserCoins}
                getAvailableUserMarkets={getAvailableUserMarkets}
                getAssetPrice={getAssetPrice}
                onSave={createSellLimitAction}
              />
            )}
            {showHardStopModal && (
              <CampaignHardStopModal
                show={showHardStopModal}
                onClose={toggleHardStopModal}
                onConfirm={hardStopCampaign}
                amount={
                  formData.hardStopQuantity > 0
                    ? formData.hardStopQuantity
                    : formData.assetAmount
                }
                assetName={formData.assetName}
                marketName={formData.marketName}
                apiKeyId={formData.apiKeyId}
                getAssetPrice={getAssetPrice}
              />
            )}
          </div>
        </div>
        <div className="d-flex">
          <TradeTable
            data={campaignActions}
            columnDefs={columns}
            conditionExpressions={{
              "order-done": (data) =>
                data["type"] === 1 && data["orderStatus"] === 2,
            }}
          />
          {isHelpModus && (
            <div className="infoButton avblInfoButton" style={{ top: '-15px' }}
              onClick={() => {
                const messageContent = (
                  <div>
                    <p>{t('HELP-MODUS.CAMPAIGN-ACTION-LIST')}</p>
                  </div>
                );
                toggleHelpModusModal('Action List', messageContent);
              }}>
              <FontAwesomeIcon className="row" icon={faInfoCircle} />
            </div>
          )}
        </div>
        {renderConditions()}
        <div className="form-floating mt-2">
          <label className="d-flex">
            {t("CAMPAIGN.FORM.CAMPAIGN-COMMENT")}:
            {isHelpModus && (
              <div className="infoButton" style={{ right: '-15px' }}
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p>{t('HELP-MODUS.CAMPAIGN-COMMENT')}</p>
                    </div>
                  );
                  toggleHelpModusModal('Comment', messageContent);
                }}>
                <FontAwesomeIcon className="row" icon={faInfoCircle} />
              </div>
            )}
          </label>
          <textarea
            name="comment"
            value={formData.comment || ""}
            className="form-control"
            placeholder="Leave a comment here"
            rows={3}
            onChange={onChangeHandler}
          ></textarea>
        </div>
      </div>
      {showHelpModusModal && (
        <HelpModusModal
          show={showHelpModusModal}
          onClose={toggleHelpModusModal}
          title={modalTitle}
          message={modalMessage}
        />
      )}
    </div>
  );
};

Campaign.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userAssets: state.userAssetsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getUserAssets: (params) => dispatch(handleGetUserAssets(params)),
  handleGetUserAssets: (params) => dispatch(handleGetUserAssets(params)),
  getAssetPrice: (marketName, coinName, apiKeyId) =>
    dispatch(getAssetPrice(marketName, coinName, apiKeyId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Campaign)
);
