import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faSync } from "@fortawesome/free-solid-svg-icons";
import { DropdownButton, Dropdown } from "react-bootstrap";

import { ORDER_STATUS } from "../../../common/constants";
import TradeTable from "../../../common/form/table/trade-table";
import orderApi from "../../../api/order/actions";
import NotificationProvider from "../../../core/notification";
import Helpers from "../../../common/helpers/error-helpers";
import AppPagination from "../../../shared/components/pagination/pagination";
import BackButton from "../../../shared/components/back-button/back-button";

import "../style/order-overview.scss";
import CommonHelper from "../../../common/helpers/common-helper";

const OrderOverview = ({ id, active }) => {
  const [botOrders, setBotOrders] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      field: "orderNumber",
      headerName: "ORDER_OVERVIEW_TABLE.ORDER_NO",
      width: 70,
      cellRenderer: (data) => {
        return `${data.orderNumber}-${data.iteration}`;
      },
    },
    {
      sortable: true,
      field: "pair",
      headerName: "ORDER_OVERVIEW_TABLE.PAIR",
      width: 70,
      cellRenderer: (data) => {
        return `${data.assetName}_${data.marketName}`;
      },
    },
    {
      field: "type",
      headerName: "ORDER_OVERVIEW_TABLE.SIDE",
      width: 70,
      sortable: true,
      cellRenderer: (data) => {
        return data.type === 0 ? "BUY" : "SELL";
      },
    },
    {
      field: "dateCreatedUtc",
      headerName: "ORDER_OVERVIEW_TABLE.DATE",
      type: "datetime-europe",
      sortable: true,
    },
    {
      sortable: true,
      field: "quantity",
      headerName: "ORDER_OVERVIEW_TABLE.AMOUNT",
    },
    {
      sortable: true,
      field: "assetAmount",
      headerName: "ORDER_OVERVIEW_TABLE.START",
      width: 90,
    },
    {
      sortable: true,
      field: "Fee",
      headerName: "ORDER_OVERVIEW_TABLE.FEE",
      width: 100,
      cellRenderer: (data) => {
        return data.fee ? `${CommonHelper.showFullNumber(data.fee)} ${data.feeAsset}` : 0;
      },
    },
    {
      sortable: true,
      field: "iteration",
      headerName: "ORDER_OVERVIEW_TABLE.ROUND",
      width: 70,
    },

    {
      sortable: true,
      field: "orderStatus",
      headerName: "ORDER_OVERVIEW_TABLE.STATUS",
      cellRenderer: (data) => {
        return (
          <span className={`status ${(ORDER_STATUS[data.orderStatus] || "Active").toLowerCase()}`}>
            {ORDER_STATUS[data.orderStatus] || "Status"}
          </span>
        );
      },
    },
    {
      sortable: true,
      field: "botId",
      headerName: "ORDER_OVERVIEW_TABLE.BOT_ID",
      width: 100,
    },
    {
      field: "message",
      headerName: "ORDER_OVERVIEW_TABLE.STATUS-ERROR",
      width: 280,
    },
    {
      field: "actions",
      headerName: "ORDER_OVERVIEW_TABLE.ACTIONS",
      type: "action",
      cellRenderer: (data) => {
        return (
          <DropdownButton variant="secondary" id="dropdown-basic-button" title="Actions" drop={"left"} className="fix-dropdown-margin">
            <Dropdown.Item disabled={data.orderStatus !== 2} onClick={() => cancelOrder(data)} href="#">
              <FontAwesomeIcon icon={faBan} /> Cancel Order
            </Dropdown.Item>
            <Dropdown.Item onClick={() => checkOrder(data)} href="#">
              <FontAwesomeIcon icon={faSync} /> Check Order
            </Dropdown.Item>
          </DropdownButton>
        );
      },
    },
  ];

  const cancelOrder = async (data) => {
    try {
      await orderApi.cancelOrder({ id: data.id });
      NotificationProvider.success("Order canceled");
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  const checkOrder = async (data) => {
    try {
      await orderApi.retryOrder({ params: { orderId: data.id } });
      NotificationProvider.success("Order checked");
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  useEffect(() => {
    const getBotOrders = async (botid) => {
      try {
        const response = await orderApi.getBotOrders({
          params: { botid, pageNumber, pageSize },
        });
        setBotOrders(response.data);
        setIsLoading(false);
      } catch (err) {
        Helpers.parseError(err);
        setBotOrders([]);
        setIsLoading(false);
      }
    };

    const getActiveBotOrders = async (botid) => {
      try {
        const response = await orderApi.getActiveBotOrders({
          botid,
          pageNumber,
          pageSize,
        });
        setBotOrders(response.data);
        setIsLoading(false);
      } catch (err) {
        Helpers.parseError(err);
        setBotOrders([]);
        setIsLoading(false);
      }
    };

    active ? getActiveBotOrders(id) : getBotOrders(id);
  }, [id, active, pageSize, pageNumber]);

  return (
    <div className="order-overview-layout">
      {id && <BackButton />}
      <AppPagination
        page={pageNumber}
        totalPages={botOrders.totalPages || 1}
        pageSize={pageSize}
        setPageSize={setPageSize}
        handlePaginationClick={(_, value) => setPageNumber(value)}
      />
      <TradeTable data={botOrders.data} columnDefs={columns} sequenceStep={(pageNumber - 1) * pageSize} isLoading={isLoading} />
    </div>
  );
};

export default withRouter(OrderOverview);
