const NumberLabel = ({ value, minFractions, maxFractions }) => {

  if (value === 'N\\A') {
    return value;
  }

  const options = {};
  
  if (minFractions !== 'no-min') {
    options.minimumFractionDigits = minFractions || 2;
  }

  if (maxFractions !== 'no-max') {
    options.maximumFractionDigits = maxFractions || 2;
  }
  
  const formattedValue = new Intl.NumberFormat('de-DE', options).format(Number(value));

  return formattedValue;
};

export default NumberLabel;