import React, { Component, Fragment } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  handleDeleteBotData,
  handleGetBotData,
  handleUpdateBotData,
} from "../../bots-overview/actions";
import { handleGetProfileData } from "../../edit-profile/actions/profile-actions";
import { handleGetUserAssets } from "../../user-assets/actions/user-assets-actions";

import { FORMS } from "../../../common/forms";
import { HelpModusContext } from "../../../common/help-modus/help-modus-context";
import HelpModusModal from "../../../common/help-modus/help.modus-modal";
import Helpers from "../../../common/helpers/error-helpers";
import UserHelper from "../../../common/helpers/user-helper";
import { routes } from "../../../common/routes-constants";
import NotificationProvider from "../../../core/notification";
import BotUnit from "../../bots-overview/components/bot";

class BotsEdit extends Component {
  formName = FORMS.botOverview;
  state = {
    showHelpModusModal: false,
    modalTitle: 'Information',
    modalMessage: '',
    loading: true,
    error: null,
  };

  componentDidMount = () => {
    const { handleGetProfileData, handleGetBotData } = this.props;
    const { id } = this.props.match.params;

    handleGetProfileData();
    
    handleGetBotData(id)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false, error: err.message });
      });
  };

  handleUpdate = (params) => {
    const { handleUpdateBotData } = this.props;
    handleUpdateBotData(params)
      .then(() => {
        NotificationProvider.success("GRID Bot successfully updated");
      })
      .catch((err) => {
        Helpers.parseError(err);
      });
  };

  componentDidUpdate(prevProps) {
    const { handleGetUserAssets } = this.props;
    if (this.props.bot?.id !== prevProps.bot?.id) {
      handleGetUserAssets(this.props.bot.keyId);
    }
  }

  handleDelete = (params) => {
    const { handleDeleteBotData, history } = this.props;
    handleDeleteBotData(params)
      .then(() => {
        history.push(routes.protectedRoutes.botsOverview.fullPath);
        NotificationProvider.success("GRID Bot successfully deleted");
      })
      .catch((err) => {
        Helpers.parseError(err);
      });
  };

  render() {
    const { bot, profileData, userAssets } = this.props;
    const { showHelpModusModal, modalTitle, modalMessage, loading, error } = this.state;
    const { role } = UserHelper.getUserInfo();

    const renderError = (errorText) => (
      <label style={{ color: "red", fontWeight: "bold" }}> {errorText}</label>
    );

    const toggleHelpModusModal = (title, message) => {
      this.setState((prevState) => ({
        showHelpModusModal: !prevState.showHelpModusModal,
        modalMessage: message,
        modalTitle: title,
      }));
    };

    if (loading) {
      return <div>Loading...</div>;
    }

    if((!loading && !bot) || error) {
      return renderError("NO BOT!");
    }

    return (
      <HelpModusContext.Consumer>
        {({ isHelpModus }) => (
          <Fragment>
            <BotUnit
              key={bot.id}
              id={bot.id}
              formName={this.formName}
              initialValues={{
                ...bot,
              }}
              profileData={profileData}
              userAssets={userAssets}
              onUpdate={this.handleUpdate}
              onDelete={this.handleDelete}
              onInfoIconClick={toggleHelpModusModal}
              isHelpModus={isHelpModus}
              role={role}
              readonly
            />
            <Row className="login justify-content-center h-100"></Row>
            {showHelpModusModal && (
              <HelpModusModal
                show={showHelpModusModal}
                onClose={toggleHelpModusModal}
                title={modalTitle}
                message={modalMessage}
              />
            )}
          </Fragment>
        )}
      </HelpModusContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  bot: state.botsReducer.current,
  profileData: state.profileReducer.userApiKeys,
  userAssets: state.userAssetsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  handleGetBotData: (id) => dispatch(handleGetBotData(id)),
  handleUpdateBotData: (params) => dispatch(handleUpdateBotData(params)),
  handleDeleteBotData: (params) => dispatch(handleDeleteBotData(params)),
  handleGetProfileData: () => dispatch(handleGetProfileData()),
  handleGetUserAssets: (params) => dispatch(handleGetUserAssets(params)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BotsEdit)
);
