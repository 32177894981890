import PropTypes from "prop-types";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import Button from "../../../common/form/button/components/button";
import InputNumber from "../../../common/form/input/components/number-input";
import Loader from "../../../common/loader/components/common-loader";

import "../style/campaign.scss";

const CopyCampaignModal = (
  {
    show,
    onClose,
    onConfirm,
    assetName
  },
  { t }
) => {
  const [assetAmount, setAssetAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const onConfirmClick = async (assetAmount) => {
    setIsLoading(true);
    await onConfirm(assetAmount);
    setIsLoading(false);
  }

  return (
    <>
      <Modal show={show} onHide={onClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("CAMPAIGN.COPY_CAMPAIGN.TITLE")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column">
            <div className="d-flex flex-row mb-3 align-items-center w-100">
              <label className="pr-3 mb-0"> {t("CAMPAIGN.FORM.AMOUNT")}</label>
              <InputNumber name="assetAmount" value={assetAmount} step={0.01} onChange={event => setAssetAmount(event.target.value)} className="input-copy-amount" />
              <span className="input-group-text input-end-form-border asset-label">
                {assetName || "N\\A"}
              </span>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                className="w-25"
                variant="success"
                type="button"
                size="md"
                onClick={() => onConfirmClick(assetAmount)}
              >
                {t("CAMPAIGN.ACTIONS.CONFIRM")}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Loader loading={isLoading} type="global" />
    </>
  );
};

CopyCampaignModal.contextTypes = {
  t: PropTypes.func,
};

export default CopyCampaignModal;