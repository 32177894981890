import { API_BASE_URL } from "../../common/constants";
import { Api } from "../../core/api";
import routes from "./routes";

const notificationConfigurationApi = {};

notificationConfigurationApi.getNotificationConfiguration = async (headers) => {
  return await Api.get(
    `${API_BASE_URL}${routes.GET_NOTIFICATION_CONFIGURATION}`,
    {
      needLoader: true,
    },
    headers
  );
};

notificationConfigurationApi.toggleNotificationEventTriggerProvider = async (params, headers) => {
  return await Api.put(
    `${API_BASE_URL}${routes.UPDATE_NOTIFICATION_EVENT_TRIGGER}`,
    params,
    {
      needLoader: false,
    },
    headers
  );
};

notificationConfigurationApi.updateNotificationConfiguration = async (params, headers) => {
  return await Api.put(
    `${API_BASE_URL}${routes.UPDATE_NOTIFICATION_CONFIGURATION}`,
    params,
    {
      needLoader: false,
    },
    headers
  );
};

export default notificationConfigurationApi;
