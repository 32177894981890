import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { faKey, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

import { getWalletColumns } from "../constants/wallet-constants";
import { EXCHANGE } from "../../../common/constants";
import TradeTable from "../../../common/form/table/trade-table";
import walletApi from "../../../api/wallet/actions";
import Helpers from "../../../common/helpers/error-helpers";
import Loader from "../../../common/loader/components/common-loader";
import { useHelpModus } from "../../../common/help-modus/help-modus-context";
import HelpModusModal from "../../../common/help-modus/help.modus-modal";
import EmergencySellModal from "../modals/emergency-sell-modal";
import LeftOverBuyPriceModal from "../modals/left-over-buy-price-modal";

import "../style/wallet.scss";

const Wallet = (_, { t }) => {
  const [walletInfo, setWalletInfo] = useState([]);
  const [leftOverBuyPriceList, setLeftOverBuyPriceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showEmergencySellModal, setShowEmergencySellModal] = useState(false);
  const [showLeftOverBuyPriceModal, setShowLeftOverBuyPriceModal] = useState(false);
  const [leftOverBuyPriceCoin, setLeftOverBuyPriceCoin] = useState('');

  const [showHelpModusModal, setShowHelpModusModal] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [modalTitle, setModalTitle] = useState('Information');
  const { isHelpModus } = useHelpModus();

  const getWallet = async () => {
    try {
      const { data } = await walletApi.getWalletInfo();
      setWalletInfo(data);
    } catch (error) {
      Helpers.parseError(error);
      setIsLoading(false);
    }
  };

  const getLeftOverBuyPrice = async () => {
    try {
      const { data } = await walletApi.getLeftOverBuyPrice();
      setLeftOverBuyPriceList(data);
    } catch (error) {
      Helpers.parseError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);

    Promise.all([ getWallet(), getLeftOverBuyPrice() ])
      .catch(error => {
        Helpers.parseError(error);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const toggleHelpModusModal = (title, message) => {
    setShowHelpModusModal((showModal) => !showModal);
    setModalMessage(message);
    setModalTitle(title);
  };

  return (
    <div className="wallet-layout">
      <h4 className="page-title d-flex">
        {t("WALLET.PAGE-TITLE")}
        {isHelpModus && (
          <div className="infoButton avblInfoButton"
            onClick={() => {
              const messageContent = (
                <div>
                  <p>{t('HELP-MODUS.WALLET-TITLE')}</p>
                </div>
              );
              toggleHelpModusModal('Wallet', messageContent);
            }}>
            <FontAwesomeIcon className="row" icon={faInfoCircle} />
          </div>
        )}
      </h4>
      <div className="d-flex flex-row justify-content-between align-items-center sell-row">
        <div className="input-group mb-3 sell-row d-flex">
          <Button variant="outline-primary" type="button" size="md" onClick={() => setShowEmergencySellModal(true)}>
            {t('WALLET.EMERGENCY-SELL')}
          </Button>
        </div>
      </div>
      {isHelpModus && (
        <div className="infoButton" style={{ float: 'right', top: '-20px' }}
          onClick={() => {
            const messageContent = (
              <div>
                <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.WALLET-TABLE') }} />
              </div>
            );
            toggleHelpModusModal('Tabelle', messageContent);
          }}>
          <FontAwesomeIcon className="row" icon={faInfoCircle} />
        </div>
      )}
      {walletInfo.map((item) => (
        <div key={item.apiKeyId}>
          <p className="wallet-key-name">
            <FontAwesomeIcon
              icon={faKey}
              className="mr-1"
              style={{ color: "#ffd484", fontSize: 13 }}
            />
            <span>{item.apiKeyName}</span>
            <em> - {EXCHANGE[item.exchange || 0]}</em>
          </p>
          <TradeTable data={item.coinData} columnDefs={getWalletColumns(coinSymbol => {
            setShowLeftOverBuyPriceModal(true);
            setLeftOverBuyPriceCoin(coinSymbol);
          })} />
        </div>
      ))}
      <Loader loading={isLoading} type="global" />
      {showHelpModusModal && (
        <HelpModusModal
          show={showHelpModusModal}
          onClose={toggleHelpModusModal}
          title={modalTitle}
          message={modalMessage}
        />
      )}
      {showEmergencySellModal && (
        <EmergencySellModal
          show={showEmergencySellModal}
          onClose={() => setShowEmergencySellModal(false)}
          getWallet={getWallet}
          toggleLoader={(isLoading) => setIsLoading(isLoading)}
        />
      )}
      {showLeftOverBuyPriceModal && (
        <LeftOverBuyPriceModal
          show={showLeftOverBuyPriceModal}
          onClose={() => setShowLeftOverBuyPriceModal(false)}
          leftOverBuyPriceList={leftOverBuyPriceList}
          coinSymbol={leftOverBuyPriceCoin}
        />
      )}
    </div>
  );
};

Wallet.contextTypes = {
  t: PropTypes.func,
};

export default Wallet;
