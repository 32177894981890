import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Field, reduxForm } from "redux-form";

import Button from "../../../common/form/button/components/button";

import Input from "../../../common/form/input/components/input";
import Select from "../../../common/form/input/components/select";

import { FORMS } from "../../../common/forms";
import { fields } from "../constants/constants";

import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { validate } from "../validators/validate";

import IBANInput from "../../../common/form/input/components/iban-input";

let RegisterForm = (props, context) => {
  const { t } = context;
  const { handleSubmit, registerErrorMessage, clearRegisterError, selectedRole, affiliateId } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
      {affiliateId &&
        <div className="custom-label-width">
          <Field
            id={fields.affiliateId}
            name={fields.affiliateId}
            component={Input}
            type="text"
            label={'Affiliate Id'}
            disabled
          />
        </div>
      }
      <Field
        id={fields.company}
        name={fields.company}
        component={Input}
        type="text"
        placeholder={t("REGISTER_FORM.COMPANY")}
        onChange={clearRegisterError}
      />
      <Field
        id={fields.firstName}
        name={fields.firstName}
        component={Input}
        type="text"
        placeholder={t("REGISTER_FORM.FIRST_NAME")}
        onChange={clearRegisterError}
      />
      <Field
        id={fields.lastName}
        name={fields.lastName}
        component={Input}
        type="text"
        placeholder={t("REGISTER_FORM.LAST_NAME")}
        onChange={clearRegisterError}
      />
      <Field
        id={fields.nickname}
        name={fields.nickname}
        component={Input}
        type="text"
        placeholder={t("REGISTER_FORM.NICKNAME")}
        onChange={clearRegisterError}
      />
      <Field
        id={fields.email}
        name={fields.email}
        component={Input}
        type="email"
        placeholder={t("REGISTER_FORM.EMAIL")}
        onChange={clearRegisterError}
      />
      <Field
        id={fields.country}
        name={fields.country}
        component={Input}
        type="text"
        placeholder={t("REGISTER_FORM.COUNTRY")}
        onChange={clearRegisterError}
      />
      <Row>
        <Col md={8}>
          <Field
            id={fields.city}
            name={fields.city}
            component={Input}
            type="text"
            placeholder={t("REGISTER_FORM.CITY")}
            onChange={clearRegisterError}
          />
        </Col>
        <Col md={4} className="pl-0">
          <Field
            id={fields.zip}
            name={fields.zip}
            component={Input}
            type="text"
            placeholder={t("REGISTER_FORM.ZIP")}
            onChange={clearRegisterError}
          />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Field
            id={fields.street}
            name={fields.street}
            component={Input}
            type="text"
            placeholder={t("REGISTER_FORM.STREET")}
            onChange={clearRegisterError}
          />
        </Col>
        <Col md={4} className="pl-0">
          <Field
            id={fields.streetNumber}
            name={fields.streetNumber}
            component={Input}
            type="text"
            placeholder={t("REGISTER_FORM.STREET_NUMBER")}
            onChange={clearRegisterError}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Field
            id={fields.role}
            name={fields.role}
            component={Select}
            type="select"
            className="select form-control"
            onChange={clearRegisterError}
            options={[
              { label: t("REGISTER_FORM.SELECT-ROLE"), value: "" },
              { label: "Trial", value: "TRIAL" },
              { label: "Basic user", value: "BASICUSER" },
              { label: "Professional", value: "PROFESSIONAL" },
              { label: "Advanced", value: "ADVANCED" },
              { label: "CrypS", value: "CRYPS" },
            ]}
            disabled={!!selectedRole}
          />
        </Col>
      </Row>
      <Field
        id={fields.iban}
        name={fields.iban}
        component={IBANInput}
        type="text"
        placeholder={t("REGISTER_FORM.IBAN")}
        onChange={clearRegisterError}
      />
      <Field
        id={fields.password}
        name={fields.password}
        component={Input}
        type="password"
        placeholder={t("REGISTER_FORM.PASSWORD")}
        tooltipValidation
        onChange={clearRegisterError}
      />
      <Field
        id={fields.confirmPassword}
        name={fields.confirmPassword}
        component={Input}
        type="password"
        placeholder={t("REGISTER_FORM.CONFIRM_PASSWORD")}
        onChange={clearRegisterError}
      />

      <Fragment>
        <Col xl={12} md={12} xs={12} className="d-flex p-2 justify-content-start">
          <Field
            formClassName="align-items-start m-0 mr-2"
            id={fields.agreeToTermsAndConditions}
            name={fields.agreeToTermsAndConditions}
            component={Input}
            className="checkbox"
            type="checkbox"
            label={<a href="https://crypdesk.de/terms-of-use.pdf" target="_blank">{t("REGISTER_FORM.AGREE_TO_TERMS_AND_CONDITIONS")}</a>}
            onChange={clearRegisterError}
          />
        </Col>
        <Col xl={12} md={12} xs={12} className="d-flex p-2 justify-content-start">
          <Field
            formClassName="align-items-start m-0 mr-2"
            id={fields.agreeToPrivacyPolicy}
            name={fields.agreeToPrivacyPolicy}
            component={Input}
            className="checkbox"
            type="checkbox"
            label={<a href="https://crypdesk.de/privacy-policy.pdf" target="_blank">{t("REGISTER_FORM.AGREE_TO_PRIVACY_POLICY")}</a>}
            onChange={clearRegisterError}
          />
        </Col> 
      </Fragment>

      <Button className={"mt-2"} variant="outline-danger" block={true} type="submit" size="md">
        {t("REGISTER_FORM.SUBMIT_BUTTON")}
      </Button>
      <p className={`err`}>{registerErrorMessage ? t(registerErrorMessage) : ""}</p>
    </form>
  );
};

RegisterForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

RegisterForm = reduxForm({
  form: FORMS.register,
  validate,
  enableReinitialize: true,
})(RegisterForm);

const mapStateToProps = (_, ownProps) => ({
  initialValues: { Role: ownProps.selectedRole, AffiliateId: ownProps.affiliateId }
});

export default connect(mapStateToProps)(RegisterForm);
