import React from "react";
import PropTypes from "prop-types";

import { Button } from "react-bootstrap";

const CustomButton = (props) => {
  const { children, variant, size, block, className, ...restProps } = props;

  return (
    <Button
      className={`btn ${className}`}
      {...{ variant, size, block, ...restProps }}
    >
      {children}
    </Button>
  );
};

CustomButton.defaultProps = {
  size: "sm",
  variant: "primary",
  type: "button",
  block: false,
  className: "",
};

Button.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "warning",
    "danger",
    "info",
    "light",
    "dark",
    "outline-primary",
    "outline-secondary",
    "outline-success",
    "outline-warning",
    "outline-danger",
    "outline-info",
    "outline-light",
    "outline-dark",
  ]),
  type: PropTypes.oneOf(["button", "submit"]),
  size: PropTypes.oneOf(["lg", "md", "sm"]),
};

export default CustomButton;
