import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import Button from "../../../common/form/button/components/button";

import Input from "../../../common/form/input/components/input";

import { FORMS } from "../../../common/forms";
import { fields } from "../constants/constants";

import { validate } from "../validators/validate";

let ForgotPasswordForm = (props, context) => {
  const { t } = context;
  const { handleSubmit, responseErrorMsg, clearError } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field
        id={fields.email}
        name={fields.email}
        component={Input}
        type="email"
        placeholder={t("FORGOT_PASSWORD_FORM.EMAIL")}
        onChange={clearError}
      />

      <Button
        className={"mt-2"}
        variant="outline-primary"
        block={true}
        type="submit"
        size="md"
      >
        {t("FORGOT_PASSWORD_FORM.SUBMIT_BUTTON")}
      </Button>

      <p className={`err`}>{responseErrorMsg ? responseErrorMsg : ""}</p>
    </form>
  );
};

ForgotPasswordForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

ForgotPasswordForm = reduxForm({
  form: FORMS.forgotPassword,
  validate,
})(ForgotPasswordForm);

export default ForgotPasswordForm;
