import authApi from "../../../api/auth/actions";
import qs from "qs";

export const forgotPassword = (params, setResponseSuccess, setResponeError) => {
  return async () => {
    try {
      await authApi.forgotPassword(qs.stringify(params), {
        "Content-Type": "application/x-www-form-urlencoded;",
      });
      setResponseSuccess();
    } catch (e) {
      setResponeError(e.response.data);
    }
  };
};
