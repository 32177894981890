const routesConstatns = {
  GET_USER_ASSETS: '/UserAssets',
  GET_ALL_USER_ASSETS: '/UserAssets/AllAssets',
  GET_ASSET_PRICE: '/UserAssets/assetPrice',
  GET_ASSET_AMOUNT: '/UserAssets/assetAmount',
  GET_ORDER_BOOK: '/UserAssets/GetOrderBook',
  GET_USER_DATA: '/UserDetails',
  UPDATE_USER_DATA: '/UserDetails',
  UPDATE_USER_CREATE_INVOICE_FIELD: '/UserDetails/CreateInvoice',
  GET_ROLES: '/Roles',
  ASSIGN_ROLES: '/Roles',
  VERIFY_USER: '/UserDetails/Verify',
  GET_ALL_NICKNAMES: '/UserDetails/Nicknames',
  USER_AFFILIATE_DATA: '/UserDetails/affiliate',
  GET_ALL_USER_AFFILIATES: '/UserDetails/affiliate/all',
};

export default routesConstatns;
