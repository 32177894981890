import { API_BASE_URL } from "../../common/constants";
import { Api } from "../../core/api";

import routesConstatns from "./routes";

const botOrderApi = {};

botOrderApi.getOrders = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_ORDERS}`,
    params,
    {
      needLoader: params?.params.needLoader !== undefined ? params.needLoader : true,
    },
    headers
  );
};

botOrderApi.getOrder = async (orderId, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_ORDERS}/${orderId}`,
    {
      needLoader: true,
    },
    headers
  );
};

botOrderApi.getActiveBotOrders = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_ACTIVE_TRADE_ORDERS}/${params}`,
    {
      needLoader: true,
    },
    headers
  );
};

botOrderApi.getUserBotCount = async (headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_USER_BOT_COUNT}`,
    {
      needLoader: false,
    },
    headers
  )
}

botOrderApi.createOrder = async (params, headers) => {
  return await Api.post(
    `${API_BASE_URL}${routesConstatns.CREATE_ORDER}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

botOrderApi.updateOrder = async (params, headers) => {
  return await Api.put(
    `${API_BASE_URL}${routesConstatns.UPDATE_ORDER}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

botOrderApi.deleteOrder = async (params, headers) => {
  return await Api.delete(
    `${API_BASE_URL}${routesConstatns.DELETE_ORDER}/${params.id}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

botOrderApi.retryOrder = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.RETRY_TRADE_ORDER}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

botOrderApi.emergencySell = async (params, headers) => {
  return await Api.post(
    `${API_BASE_URL}${routesConstatns.EMERGENCY_SELL_ORDER}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

export default botOrderApi;
