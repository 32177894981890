import authApi from "../../../api/auth/actions";
import qs from "qs";

export const setPassword = (params, handleSucessReset, handleErrorMessage) => {
  return async (dispatch) => {
    try {
      await authApi.setPassword(qs.stringify(params), {
        "Content-Type": "application/x-www-form-urlencoded;",
      });
      handleSucessReset();
    } catch (e) {
      handleErrorMessage(e.response.data);
    }
  };
};
