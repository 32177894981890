import React from "react";
import PropTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";

import "../style/loader.scss";

const TrSpinner = (props) => {
  const { animation, size, children, type } = props;
  return (
    <div className={`loader ${type}`}>
      <Spinner {...{ animation, size }} role="status" />
      {children}
    </div>
  );
};

TrSpinner.contextTypes = {
  t: PropTypes.func.isRequired,
};

Spinner.defaultProps = {
  size: "lg",
  animation: "border",
  variant: "primary",
  type: "regular",
};

Spinner.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "warning",
    "danger",
    "info",
    "light",
    "dark",
  ]),
  animation: PropTypes.oneOf(["border", "grow"]),
  size: PropTypes.oneOf(["lg", "md", "sm"]),
  type: PropTypes.oneOf(["regular", "global", "table-loader"]),
};

const Loader = (props) => {
  return props.loading ? <TrSpinner {...props} /> : null;
};

export default Loader;
