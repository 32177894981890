import authApi from "../../../api/auth/actions";
import { handleGlobalLoader } from "../../../common/loader/actions/loader-actions";

export const activateUser = (params, successCallback, errorCallback) => {
  return async (dispatch) => {
    try {
      dispatch(handleGlobalLoader(true));
      await authApi.activateUser(params);
      successCallback();
    } catch (e) {
      //errorCallback
      dispatch(handleGlobalLoader(false));
    }
  };
};
