import React from "react";

const InputNumber = ({ name, value, onChange, step, defaultValue, disabled = false, className }) => {
  const [displayValue, setDisplayValue] = React.useState((value || defaultValue)?.toString().replace('.', ','));

  React.useEffect(() => {
    setDisplayValue((value || defaultValue)?.toString().replace('.', ',') || '');
  }, [value, defaultValue]);

  const handleChange = (event) => {
    const val = event.target.value;
    const regex = /^(\d+(,\d*)?(e[+-]?\d*)?)$/i;

    if (regex.test(val) || val === '') {
      setDisplayValue(val);
      const parsedValue = val.replace(',', '.');
      onChange({ target: { name, value: parsedValue } });
    }
  };

  const handleWheel = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    const inputElement = document.getElementsByName(name)[0];
    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel, { passive: false });
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, [handleWheel, name]);

  return (
    <input
      name={name}
      type="text"
      value={displayValue}
      className={`${className} form-control`}
      onChange={handleChange}
      step={step}
      disabled={disabled}
    />
  );
};

export default InputNumber;
