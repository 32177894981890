const routesConstatns = {
  CAMPAIGN: "/Campaign",
  CAMPAIGN_COPY: "/Campaign/copy",
  CAMPAIGN_ACTION: "/CampaignAction",
  GET_USER_CAMPAIGNS: "/Campaign/GetByUserId",
  GET_BEST_CAMPAIGNS: "/Campaign/GetBestCampaigns",
  GET_CAMPAIGN_BY_ID: "/Campaign/GetById",
  GET_CAMPAIGN_ACTIONS: "/CampaignAction/GetByCampaignId",
  CAMPAIGN_HARD_STOP: "/Campaign/HardStop",
  CAMPAIGN_CANCEL: "/Campaign/Cancel",
};

export default routesConstatns;
