import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import Helpers from "../../../common/helpers/error-helpers";
import userApi from "../../../api/user/actions";
import ProfileImage from "../../../common/profile-image/components/profile-image";
import Button from "../../../common/form/button/components/button";
import NotificationProvider from "../../../core/notification";
import CommonHelper from "../../../common/helpers/common-helper";
import UserHelper from "../../../common/helpers/user-helper";

import verifiedIcon from "../../../common/assets/verified-icon.png";
import notVerifiedIcon from "../../../common/assets/not-verified-icon.png";

import IBANInput from "../../../common/form/input/components/iban-input"

const UserDataForm = (props, context) => {
  const { t } = context;
  const { isHelpModus, onInfoIconClick } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    nickname: "",
    email: "",
    country: "",
    city: "",
    street: "",
    imageBase64: "",
    zip: "",
    iban: "",
    roleName: ""
  });
  const [ibanError, setIbanError] = useState("");

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      setIsLoading(true);
      const response = await userApi.getUserData();
      setIsLoading(false);
      setUserData(response.data);
    } catch (err) {
      Helpers.parseError(err);
      setIsLoading(false);
    }
  };

  const onSave = async () => {
    try {
      if (userData.roleName.toLocaleLowerCase() !== "trial" && !userData.iban) {
        setIbanError("Iban required");
        return;
      }
      await userApi.updateUserData(userData);
      UserHelper.updateProfilePicture(userData.imageBase64);
      NotificationProvider.success("User data successfully updated");
      window.location.reload();
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const onImageChange = async (e) => {
    const base64 = await CommonHelper.toBase64(e.target.files[0]);
    setUserData({ ...userData, imageBase64: base64.split(",")[1] });
  };

  const getImageSrc = () => {
    return `data:image/png;base64,${userData.imageBase64}` ?? null;
  };

  const handleIbanChange = (newIban) => {
    setUserData(prevState => ({
      ...prevState,
      iban: newIban
    }));
  };

  const renderBody = () => {
    return (
      <>
        <div className="mb-4">
          <div className="d-flex">
            <ProfileImage size="lg" src={getImageSrc()} />
            {!isLoading && (
              <>
                <img
                  style={{ marginLeft: "25px", paddingRight: "5px" }}
                  height={25}
                  src={userData.verified ? verifiedIcon : notVerifiedIcon}
                  alt="verified badge"
                />
                {userData.verified ? "verified" : "not verified"}
              </>
            )}
          </div>
          <div className="d-flex">
            <input type="file" onChange={onImageChange} style={{ marginTop: "10px" }} />
            {isHelpModus && !!onInfoIconClick && (
              <div className="infoButton" style={{ right: '310px' }}
                onClick={() => {
                  const messageContent = (
                    <div>
                      <p>{t('HELP-MODUS.USER-CENTER-PICTURE')}</p>
                    </div>
                  );
                  onInfoIconClick('API Key', messageContent);
                }}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </div>
            )}
          </div>
        </div>

        <div className="input-group mb-3">
          <span className="input-group-text input-form-border">{t("REGISTER_FORM.FIRST_NAME")}</span>
          <input name="firstName" value={userData.firstName} onChange={onChange} disabled type="text" className="form-control" />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text input-form-border">{t("REGISTER_FORM.LAST_NAME")}</span>
          <input name="lastName" value={userData.lastName} onChange={onChange} disabled type="text" className="form-control" />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text input-form-border">{t("REGISTER_FORM.NICKNAME")}</span>
          <input name="nickname" value={userData.nickname} onChange={onChange} type="text" className="form-control" />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text input-form-border">{t("REGISTER_FORM.EMAIL")}</span>
          <input name="email" value={userData.email} onChange={onChange} disabled type="text" className="form-control" />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text input-form-border">{t("REGISTER_FORM.COUNTRY")}</span>
          <input name="country" value={userData.country} onChange={onChange} type="text" className="form-control" />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text input-form-border">{t("REGISTER_FORM.CITY")}</span>
          <input name="city" value={userData.city} onChange={onChange} type="text" className="form-control" />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text input-form-border">{t("REGISTER_FORM.STREET")}</span>
          <input name="street" value={userData.street} onChange={onChange} type="text" className="form-control" />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text input-form-border">{t("REGISTER_FORM.ZIP")}</span>
          <input name="zip" value={userData.zip} onChange={onChange} type="text" className="form-control" />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text input-form-border">{t("REGISTER_FORM.IBAN")}</span>
          <IBANInput
            id={'iban'}
            name={'iban'}
            placeholder={t("REGISTER_FORM.IBAN")}
            onValueChange={handleIbanChange}
            externalValue={userData.iban}
            useExternalValue={true}
            error={ibanError}
          />
        </div>
        <div className="mt-5">
          <Button className={"mt-2 pl-5 pr-5 mr-2"} variant="primary" type="button" size="md" onClick={onSave}>
            Save
          </Button>
          <Button className={"mt-2 pl-5 pr-5 "} variant="outline-danger" type="button" size="md" onClick={fetchUserData}>
            Cancel
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="p-4 d-none d-lg-flex">
        <div style={{ width: "35%" }}>
          {renderBody()}
        </div>
      </div>
      <div className="d-sm-flex d-lg-none">
        <div className="w-100">
          {renderBody()}
        </div>
      </div>
    </>
  );
};

UserDataForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default UserDataForm;
