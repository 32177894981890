import MathHelper from "../helpers/math-helper";

const BinanceWebSocketURL = 'wss://stream.binance.com:9443/ws';
const BinanceRestAPIURL = 'https://api.binance.com/api/v1';

const BinanceDataFeed = {
  socket: null,
  subscriptions: {},

  onReady: function (callback) {
    setTimeout(() => {
      callback({
        supported_resolutions: ['1', '3', '5', '15', '30', '60', '120', '240', '360', '720', '1D', '3D', '1W', '1M'],
        exchanges: [{ value: 'Binance', name: 'Binance', desc: 'Binance Exchange' }],
      });
    }, 0);
  },

  searchSymbols: async function (userInput, exchange, symbolType, onResultReadyCallback) {
    try {
      const response = await fetch(`${BinanceRestAPIURL}/ticker/24hr`);
      const data = await response.json();

      const filteredSymbols = data.filter((symbol) => {
        return symbol.symbol.toLowerCase().includes(userInput.toLowerCase());
      });

      const symbols = filteredSymbols.map((symbol) => {
        return {
          symbol: symbol.symbol,
          full_name: symbol.symbol,
          description: symbol.symbol,
          exchange: 'Binance',
          ticker: symbol.symbol,
          type: 'crypto',
        };
      });

      onResultReadyCallback(symbols);
    } catch (error) {
      console.error('Error in searchSymbols:', error);
    }
  },

  resolveSymbol: async function (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
    try {
      const response = await fetch(`${BinanceRestAPIURL}/ticker/24hr?symbol=${symbolName}`);
      const data = await response.json();

      if (!data.symbol) {
        onResolveErrorCallback('Symbol not found');
        return;
      }

      const symbolInfo = {
        name: data.symbol,
        ticker: data.symbol,
        description: data.symbol,
        type: 'crypto',
        session: '24x7',
        exchange: 'Binance',
        listed_exchange: 'Binance',
        timezone: 'Etc/UTC',
        format: 'price',
        pricescale: MathHelper.calculatePowerOfTen(data.lastPrice),
        minmov: 1,
        has_intraday: true,
        supported_resolutions: ['1', '3', '5', '15', '30', '60', '120', '240', '360', '720', '1D', '3D', '1W', '1M'],
        intraday_multipliers: ['1', '3', '5', '15', '30', '60', '120', '240', '360', '720'],
        has_seconds: false,
        has_daily: true,
        has_weekly_and_monthly: true,
      };

      onSymbolResolvedCallback(symbolInfo);
    } catch (error) {
      onResolveErrorCallback('Error resolving symbol');
      console.error('Error in resolveSymbol:', error);
    }
  },

  getBars: async function (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
    try {
      const { from, to } = periodParams;
      const interval = this.resolutionToBinanceInterval(resolution);
      const response = await fetch(
        `${BinanceRestAPIURL}/klines?symbol=${symbolInfo.ticker}&interval=${interval}&startTime=${from * 1000}&endTime=${to * 1000}`
      );
      const data = await response.json();
      const bars = data.map((bar) => {
        return {
          time: bar[0],
          low: parseFloat(bar[3]),
          high: parseFloat(bar[2]),
          open: parseFloat(bar[1]),
          close: parseFloat(bar[4]),
          volume: parseFloat(bar[5]),
        };
      });

      if (bars.length) {
        onHistoryCallback(bars, { noData: false });
      } else {
        onHistoryCallback([], { noData: true });
      }
    } catch (error) {
      onErrorCallback('Error retrieving historical data');
      console.error('Error in getBars:', error);
    }
  },

  subscribeBars: function (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
    const interval = this.resolutionToBinanceInterval(resolution);
    const channel = `${symbolInfo.ticker.toLowerCase()}@kline_${interval}`;
    this.subscriptions[subscriberUID] = {
      channel,
      onRealtimeCallback,
    };

    if (!this.socket) {
      this.socket = new WebSocket(BinanceWebSocketURL);
      this.socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.e === 'kline') {
          const bar = {
            time: message.k.t,
            low: parseFloat(message.k.l),
            high: parseFloat(message.k.h),
            open: parseFloat(message.k.o),
            close: parseFloat(message.k.c),
            volume: parseFloat(message.k.v),
          };
          const subscription = Object.values(this.subscriptions).find((sub) => sub.channel.toLowerCase() === (message.s + '@kline_' + message.k.i).toLowerCase());
          if (subscription) {
            subscription.onRealtimeCallback(bar);
          }
        }
      };

      this.socket.onopen = () => {
        this.socket.send(JSON.stringify({ method: 'SUBSCRIBE', params: [channel], id: 1 }));
      };
    } else {
      this.socket.send(JSON.stringify({ method: 'SUBSCRIBE', params: [channel], id: 1 }));
    }
  },

  unsubscribeBars: function (subscriberUID) {
    const subscription = this.subscriptions[subscriberUID];
    if (!subscription) {
      return;
    }
    this.socket.send(JSON.stringify({ method: 'UNSUBSCRIBE', params: [subscription.channel], id: 1 }));
    delete this.subscriptions[subscriberUID];
  },

  resolutionToBinanceInterval: function (resolution) {
    const mapping = {
      '1': '1m',
      '3': '3m',
      '5': '5m',
      '15': '15m',
      '30': '30m',
      '60': '1h',
      '120': '2h',
      '240': '4h',
      '360': '6h',
      '720': '12h',
      '1D': '1d',
      '3D': '3d',
      '1W': '1w',
      '1M': '1M',
    };
    return mapping[resolution];
  },
};

export default BinanceDataFeed;