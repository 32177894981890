import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import Button from "../../../common/form/button/components/button";
import CommonHelper from "../../../common/helpers/common-helper";
import TabGroup from "../../../shared/components/tab-group/tab-group";
import InputNumber from "../../../common/form/input/components/number-input";

import { INITIAL_INCREASE_ORDER_DATA } from "../constants";
import { ORDER_TYPE } from "../../../common/constants";

import "../style/increase-modal.scss";
import NumberLabel from "../../../common/form/number-label/number-label";

const IncreaseModal = (
  { show, onClose, assetName, marketName, apiKeyId, getAvailableUserCoins, getAvailableUserMarkets, getAssetPrice, onSave },
  { t }
) => {
  const [buyType, setBuyType] = useState("Market");
  const [sellType, setSellType] = useState("Market");
  const [buyLimitData, setBuyLimitData] = useState(INITIAL_INCREASE_ORDER_DATA);
  const [sellLimitData, setSellLimitData] = useState(INITIAL_INCREASE_ORDER_DATA);

  const avblCoins = getAvailableUserCoins();
  const avblMarkets = getAvailableUserMarkets();

  useEffect(() => {
    if (marketName === "" || assetName === "" || !apiKeyId) return;

    async function fillAssetAmount() {
      const price = await getAssetPrice(marketName, assetName, apiKeyId);
      setBuyLimitData({ ...buyLimitData, price });
      setSellLimitData({ ...sellLimitData, price });
    }

    fillAssetAmount();

    // eslint-disable-next-line
  }, [assetName, marketName, apiKeyId]);

  const onBuyAmountChange = (e) => {
    const { value } = e.target;
    if (!value)
      return setBuyLimitData({
        ...buyLimitData,
        total: '',
        quantity: '',
      });

    setBuyLimitData({
      ...buyLimitData,
      quantity: parseFloat(value),
      total: Number(value) * buyLimitData.price,
    });
  };

  const onBuyTotalChange = (e) => {
    const { value } = e.target;
    if (!value)
      return setBuyLimitData({
        ...buyLimitData,
        total: '',
        quantity: '',
      });

    setBuyLimitData({
      ...buyLimitData,
      quantity: Number(value) / buyLimitData.price,
      total: parseFloat(value),
    });
  };

  const onSellAmountChange = (e) => {
    const { value } = e.target;
    if (!value)
      return setSellLimitData({
        ...sellLimitData,
        total: '',
        quantity: '',
      });

    setSellLimitData({
      ...sellLimitData,
      quantity: parseFloat(value),
      total: Number(value) * sellLimitData.price,
    });
  };

  const onSellTotalChange = (e) => {
    const { value } = e.target;
    if (!value)
      return setSellLimitData({
        ...sellLimitData,
        total: '',
        quantity: '',
      });

    setSellLimitData({
      ...sellLimitData,
      quantity: Number(value) / sellLimitData.price,
      total: parseFloat(value),
    });
  };

  const onAmountPercentageChange = (value) => {
    if (avblCoins === 0) {
      return;
    }
    return CommonHelper.calculatePercentage(value, avblCoins);
  };

  const onAmountPercentageMarketChange = (value) => {
    if (avblMarkets === 0) {
      return;
    }
    return CommonHelper.calculatePercentage(value, avblMarkets);
  };

  const onSellOrder = () => {
    const type = sellType === "Market" ? ORDER_TYPE.Market : ORDER_TYPE.Limit;
    onSave({ ...sellLimitData, assetName, marketName, side: 1, type });
  };

  const onBuyOrder = () => {
    const type = buyType === "Market" ? ORDER_TYPE.Market : ORDER_TYPE.Limit;
    onSave({ ...buyLimitData, assetName, marketName, side: 0, type });
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{t("CAMPAIGN.INCREASE_MODAL.TITLE")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-around">
          <Col md={5} className="mb-4 pt-4 pb-4 modal-box-content">
            <div className="input-group mb-3 align-items-center justify-content-between">
              <h6 className="mb-1 pr-5">Buy</h6>
              <span className="mr-3" style={{ fontSize: 16, fontWeight: 600 }}>
                <NumberLabel value={avblMarkets} /> {marketName}
              </span>
            </div>
            <TabGroup tabs={["Market", "Limit"]} setTab={(tab) => setBuyType(tab)} selectedTab={buyType} />
            {buyType === "Limit" && (
              <div className="input-group mb-3">
                <span className="input-group-text input-form-border">Price</span>
                <input
                  type="text"
                  className="form-control"
                  value={buyLimitData.price}
                  onChange={(e) => {
                    setBuyLimitData({ ...buyLimitData, price: e.target.value });
                  }}
                />
                <span style={{ fontWeight: 700 }} className="input-group-text input-end-form-border">
                  {marketName || "N/A"}
                </span>
              </div>
            )}
            <div className="input-group mb-3">
              <span className="input-group-text input-form-border">{t("CAMPAIGN.FORM.AMOUNT")}</span>
              <InputNumber name="quantity" value={buyLimitData.quantity} onChange={onBuyAmountChange} />
              <span style={{ fontWeight: 700 }} className="input-group-text input-end-form-border">
                {assetName || "N/A"}
              </span>
            </div>
            <TabGroup
              setTab={(value) => {
                const quantity = onAmountPercentageMarketChange(+value);
                onBuyTotalChange({ target: { value: quantity } });
              }}
              tabs={["10", "25", "50", "100"]}
              suffix="%"
            />
            <div className="input-group mb-3">
              <span className="input-group-text input-form-border">{t("TRADE_ORDER_OVERVIEW_TABLE.TOTAL")}</span>
              <InputNumber name="total" value={buyLimitData.total} onChange={onBuyTotalChange} />
              <span style={{ fontWeight: 700 }} className="input-group-text input-end-form-border">
                {marketName || "N/A"}
              </span>
            </div>
            <button onClick={onBuyOrder} type="button" className="btn btn-success w-100 mb-2">
              Buy {assetName}
            </button>
          </Col>
          <Col md={5} className="mb-4 pt-4 pb-4 modal-box-content">
            <div className="input-group mb-3 align-items-center justify-content-between">
              <h6 className="mb-1 pr-5">Sell</h6>
              <span className="mr-3" style={{ fontSize: 16, fontWeight: 600 }}>
                <NumberLabel value={avblCoins} /> {assetName}
              </span>
            </div>
            <TabGroup tabs={["Market", "Limit"]} setTab={(tab) => setSellType(tab)} selectedTab={sellType} />
            {sellType === "Limit" && (
              <div className="input-group mb-3">
                <span className="input-group-text input-form-border">Price</span>
                <input
                  type="text"
                  className="form-control"
                  value={sellLimitData.price}
                  onChange={(e) => {
                    setSellLimitData({
                      ...sellLimitData,
                      price: e.target.value,
                    });
                  }}
                />
                <span style={{ fontWeight: 700 }} className="input-group-text input-end-form-border">
                  {marketName || "N/A"}
                </span>
              </div>
            )}
            <div className="input-group mb-3">
              <span className="input-group-text input-form-border">{t("CAMPAIGN.FORM.AMOUNT")}</span>
              <InputNumber name="sellQuantity" value={sellLimitData.quantity} onChange={onSellAmountChange} />
              <span style={{ fontWeight: 700 }} className="input-group-text input-end-form-border">
                {assetName || "N/A"}
              </span>
            </div>
            <TabGroup
              setTab={(value) => {
                const quantity = onAmountPercentageChange(+value);
                onSellAmountChange({ target: { value: quantity } });
              }}
              tabs={["10", "25", "50", "100"]}
              suffix="%"
            />
            <div className="input-group mb-3">
              <span className="input-group-text input-form-border">{t("TRADE_ORDER_OVERVIEW_TABLE.TOTAL")}</span>
              <InputNumber name="sellTotal" value={sellLimitData.total} onChange={onSellTotalChange} />
              <span style={{ fontWeight: 700 }} className="input-group-text input-end-form-border">
                {marketName || "N/A"}
              </span>
            </div>
            <button onClick={onSellOrder} type="button" className="btn btn-danger w-100">
              Sell {assetName}
            </button>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

IncreaseModal.contextTypes = {
  t: PropTypes.func,
};

export default IncreaseModal;
