import { fields } from "../constants/constants";
import { PASSWORD_REGEX } from "../../../common/constants";

export const validate = (values, { t }) => {
  const errors = {};

  if (!PASSWORD_REGEX.test(values[fields.password])) {
    errors[fields.password] = {
      label: t("SET_PASSWORD_FORM.PASSWORD_NOT_VALID"),
    };
  }
  //if pass is ok than compare it to confirm password
  if (PASSWORD_REGEX.test(values[fields.password])) {
    if (values[fields.password] !== values[fields.confirmPassword]) {
      errors[fields.confirmPassword] = {
        label: t("SET_PASSWORD_FORM.PASSWORDS_NOT_MATCH"),
      };
    }
  }

  return errors;
};
