const FORMS = {
  // login
  login: "login-form",
  register: "register",
  forgotPassword: "forgotPasswords",
  setPasswordForm: "setPasswordForm",
  // bots
  botOverview: "botOverview",
  botsCreate: "botCreate",
};

export { FORMS };
