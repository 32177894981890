import { faChevronDown, faInfoCircle, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import { MarketOverview, MiniChart, Timeline } from "react-tradingview-embed";

import walletApi from "../../../api/wallet/actions";
import NumberLabel from "../../../common/form/number-label/number-label";
import HelpModusModal from "../../../common/help-modus/help.modus-modal";
import { useTheme } from "../../../common/theme/theme-context";
import Chart from "../../../common/trading-chart/chart";

import "../style/dashboard.scss";

const Dashboard = (props, { t }) => {
  const { isDarkMode } = useTheme();
  const colorTheme = isDarkMode ? "dark" : "light";
  const [profit, setProfit] = useState(null);
  const [showBotRoundProfit, setShowBotRoundProfit] = useState(true);

  const [showHelpModusModal, setShowHelpModusModal] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [modalTitle, setModalTitle] = useState('Information');

  useEffect(() => {
    const fetchProfit = async () => {
      try {
        const { data } = await walletApi.getProfit();
        setProfit(data);
      } catch (error) {
        console.error('Error fetching profit data:', error);
      }
    };

    fetchProfit();
  }, []); 

  const toggleHelpModusModal = (title, message) => {
    setShowHelpModusModal((showModal) => !showModal);
    setModalMessage(message);
    setModalTitle(title);
  };

  return (
    <Container className="dashboard-padding">
      {profit !== null && profit !== undefined &&
        <Row>
          <Col className="d-flex align-items-center justify-content-center monthly-profit-header">
            <Dropdown>
              <Dropdown.Toggle variant="transparent" className="profit-type-btn">
                <FontAwesomeIcon icon={faChevronDown} className="chevron-down-info" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setShowBotRoundProfit(prev => !prev)}>
                  {showBotRoundProfit ? t('USER-PROFIT.TABLE.PROFIT-IN-EURO') : t('USER-PROFIT.TABLE.BOT-ROUND-PROFIT-IN-EURO')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <FontAwesomeIcon icon={faTrophy} className="mr-2" />
            {showBotRoundProfit ? t('DASHBOARD.BOT-ROUND-PROFIT') : t('DASHBOARD.PROFIT')}: {(showBotRoundProfit && <NumberLabel value={profit.botRoundProfitInEuro} />) || (!showBotRoundProfit && <NumberLabel value={profit.profitInEuro} />)}&#8364;
            <FontAwesomeIcon icon={faTrophy} className="ml-2" />
            <div className="dashboard-info-btn"
              onClick={() => {
                const messageContent = (
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: showBotRoundProfit ? t('HELP-MODUS.BOT-ROUND-MONTHLY-PROFIT') : t('HELP-MODUS.MONTHLY-PROFIT') }} />
                  </div>
                );
                const messageTitle = (<>{showBotRoundProfit ? t('USER-PROFIT.TABLE.BOT-ROUND-PROFIT-IN-EURO') : t('USER-PROFIT.TABLE.PROFIT-IN-EURO')}</>);
                toggleHelpModusModal(messageTitle, messageContent);
              }}>
              <FontAwesomeIcon className="row" style={{ color: showBotRoundProfit ? 'lightgray' : 'salmon' }} icon={faInfoCircle} />
            </div>
          </Col>
        </Row>
      } 
      <Row>
        <Col sm={4}>
          <div className="mt-3 basic-crypto-card">
            <MiniChart
              widgetProps={{
                symbol: "BINANCE:BTCUSDT",
                height: 200,
                width: '100%',
                colorTheme,
              }}
            ></MiniChart>
          </div>
        </Col>
        <Col sm={4}>
          <div className="mt-3 basic-crypto-card">
            <MiniChart
              widgetProps={{
                symbol: "BINANCE:ETHUSDT",
                height: 200,
                width: '100%',
                colorTheme,
              }}
            ></MiniChart>
          </div>
        </Col>
        <Col sm={4}>
          <div className="mt-3 basic-crypto-card">
            <MiniChart
              widgetProps={{
                symbol: "BINANCE:XRPUSDT",
                height: 200,
                width: '100%',
                colorTheme,
              }}
            ></MiniChart>
          </div>
        </Col>
      </Row>
      {/* News & crypto status with chart */}
      <Row className="mt-3">
        {/* News and crypto */}
        <Col md={6} sm={12} xs={12}>
          <Tabs defaultActiveKey="news">
            <Tab eventKey="news" title="News">
              <div className="dashboard-timeline">
                <Timeline
                  widgetProps={{
                    colorTheme,
                    displayMode: "regular",
                    width: 370,
                    height: 500,
                  }}
                ></Timeline>
              </div>
            </Tab>
            <Tab eventKey="market-overview" title="Market Overview">
              <MarketOverview
                widgetProps={{
                  colorTheme,
                  width: 370,
                  height: 500,
                  dateRange: "1D",
                  tabs: [
                    {
                      symbols: [
                        {
                          s: "BINANCE:BTCUSD",
                        },
                        {
                          s: "BINANCE:BTCEUR",
                        },
                        {
                          s: "BINANCE:BTCGBP",
                        },
                        {
                          s: "BINANCE:ETHUSD",
                        },
                        {
                          s: "BINANCE:ETHEUR",
                        },
                        {
                          s: "BINANCE:ETHGBP",
                        },
                        {
                          s: "BINANCE:XRPUSD",
                        },
                        {
                          s: "BINANCE:XRPEUR",
                        },
                        {
                          s: "BINANCE:XRPGBP",
                        },
                      ],
                    },
                  ],
                }}
              ></MarketOverview>
            </Tab>
          </Tabs>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <div className="dashboard-advanced-chart">
            <Chart height={542} marketName={"USDT"} assetName={"BTC"} />
          </div>
        </Col>
      </Row>
      {showHelpModusModal && (
        <HelpModusModal
          show={showHelpModusModal}
          onClose={toggleHelpModusModal}
          title={modalTitle}
          message={modalMessage}
        />
      )}
    </Container>
  );
};

Dashboard.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Dashboard;
