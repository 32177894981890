import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import bothApi from "../../../api/bot/actions";

import TradeTable from "../../../common/form/table/trade-table";
import Helpers from "../../../common/helpers/error-helpers";
import { BEST_PROFIT_BOTS, BEST_ROUND_BOTS, BOT_COLUMNS } from "../constants";
import { routes } from "../../../common/routes-constants";
import { useHelpModus } from "../../../common/help-modus/help-modus-context";
import HelpModusModal from "../../../common/help-modus/help.modus-modal";

import "../style/contest.scss";

const Contest = (props, { t }) => {
  const { history } = props;

  const [botData, setBotData] = useState({});
  const [botFilter, setBotFilter] = useState(0);
  const [isRound, setIsRound] = useState(false);

  const [showHelpModusModal, setShowHelpModusModal] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [modalTitle, setModalTitle] = useState('Information');
  const { isHelpModus } = useHelpModus();

  const [filters, setFilters] = useState({
    side: "",
    symbol: "",
    innerWorkingArea: false,
    activeOnly: false,
  });

  useEffect(() => {
    getBestBot();

    // eslint-disable-next-line
  }, [filters.side, filters.symbol, filters.innerWorkingArea, filters.activeOnly]);

  const getBestBot = async () => {
    try {
      const response = await bothApi.getBestBots({ params: filters });
      setBotData(response.data);
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  const onCopyBot = (botDetails) => {
    history.push({
      pathname: `${routes.protectedRoutes.botsCreate.path}`,
      search: `?copyBotId=${botDetails.id}`,
    });
  };

  const onRadioChanged = (e) => {
    setBotFilter(e.target.value);
  };

  const toggleHelpModusModal = (title, message) => {
    setShowHelpModusModal((showModal) => !showModal);
    setModalMessage(message);
    setModalTitle(title);
  };

  const renderBotFilters = () => (
    <>
      <label className="w-100">
        {t("CONTEST.FORM.PROFIT")}
        <Switch
          size="medium"
          checked={isRound}
          onChange={(e) => setIsRound(e.target.checked)}
          inputProps={{ "aria-label": "controlled" }}
        />
        {t("CONTEST.FORM.ROUND")}
      </label>
      <FormControl>
        <RadioGroup row name="position" defaultValue="0">
          <FormControlLabel value="0" control={<Radio />} label="All time" labelPlacement="end" onChange={onRadioChanged} />
          <FormControlLabel value="1" control={<Radio />} label="Last 7 days" labelPlacement="end" onChange={onRadioChanged} />
          <FormControlLabel value="2" control={<Radio />} label="Last 31 days" labelPlacement="end" onChange={onRadioChanged} />
        </RadioGroup>
      </FormControl>
      <label className="ml-5">
        <Checkbox onChange={(e) => setFilters({ ...filters, innerWorkingArea: e.target.checked })} value={filters.innerWorkingArea} />
        {t("CONTEST.FORM.FILTER-ONLY-WORKING-AREA")}
      </label>
      <label className="ml-5">
        <Checkbox onChange={(e) => setFilters({ ...filters, activeOnly: e.target.checked })} value={filters.activeOnly} />
        {t("CONTEST.FORM.FILTER-ONLY-ACTIVE")}
      </label>
    </>
  );

  return (
    <div className="contest-layout w-100">
      <h4 className="page-title d-flex">
        {t("CONTEST.FORM.PAGE-TITLE")}
        {isHelpModus && (
          <div className="infoButton avblInfoButton"
            onClick={() => {
              const messageContent = (
                <div>
                  <p>{t('HELP-MODUS.CONTEST-TITLE')}</p>
                </div>
              );
              toggleHelpModusModal('Contest', messageContent);
            }}>
            <FontAwesomeIcon className="row" icon={faInfoCircle} />
          </div>
        )}
      </h4>
      {renderBotFilters()}
      <Row className="m-t-5">
        <Col md={12} sm={12} xs={12}>
          {isHelpModus && (
            <div className="infoButton" style={{ float: 'right', top: '-20px' }}
              onClick={() => {
                const messageContent = (
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: t('HELP-MODUS.CONTEST-TABLE') }} />
                  </div>
                );
                toggleHelpModusModal('Tabelle', messageContent);
              }}>
              <FontAwesomeIcon className="row" icon={faInfoCircle} />
            </div>
          )}
          <TradeTable
            data={botData[isRound ? BEST_ROUND_BOTS[botFilter] : BEST_PROFIT_BOTS[botFilter]]}
            columnDefs={BOT_COLUMNS(onCopyBot)}
            enableFilter
            onFilterChanged={setFilters}
            conditionExpressions={{
              "dynamic-bot": (data) => !!data.dynamicBotBuffer
            }}
            tableHasVerticalScroll
          />
        </Col>
      </Row>
      {showHelpModusModal && (
        <HelpModusModal
          show={showHelpModusModal}
          onClose={toggleHelpModusModal}
          title={modalTitle}
          message={modalMessage}
        />
      )}
    </div>
  );
};

Contest.contextTypes = {
  t: PropTypes.func,
};

export default withRouter(Contest);
