import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import '../style/input.scss';

const IBANInput = ({ input, label, type, placeholder, meta, className, id, formClassName, disabled, onValueChange, externalValue, useExternalValue, tooltipValidation, error }) => {
  const [localValue, setLocalValue] = useState('');

  useEffect(() => {
    if (!input) {
      setLocalValue(formatIBAN(externalValue));
    }
  }, [externalValue, input]);

  const formatIBAN = (inputValue) => {
    if (!inputValue) return '';

    let raw = inputValue.toUpperCase().replace(/[^A-Z0-9]/g, '');
    raw = raw.slice(0, 40);

    let formatted = raw.split('').filter((char, index) => {
      if (index < 2) return /[A-Z]/.test(char);
      return /[0-9]/.test(char);
    }).join('');

    return formatted.match(/.{1,4}/g)?.join(' ') ?? '';
  };

  const handleChange = (e) => {
    const formattedValue = formatIBAN(e.target.value);
    setLocalValue(formattedValue);

    if (input) {
      input.onChange(formattedValue.replace(/\s/g, ''));
    } else if (onValueChange) {
      onValueChange(formattedValue.replace(/\s/g, ''));
    }
  };

  const value = input ? formatIBAN(input.value) : localValue;


  const renderControl = (
    <Form.Control
      type={type}
      placeholder={placeholder}
      id={id}
      className={`${className} ${error || (meta && meta.error) ? "border border-danger" : ""}`}
      disabled={disabled}
      onChange={handleChange}
      value={value}
    />
  );

  return (
    <>
      {!useExternalValue && (<Form.Group className={`${formClassName || ''}`}>
        <div className="label-control-wrapper">
          {label && <Form.Label htmlFor={id}>{label}</Form.Label>}
          {renderControl}
        </div>
        <Form.Text className="text-danger validation">
          <small className="invalid-tooltip">{error || meta?.error?.label}</small>
          {error || (!tooltipValidation &&
            meta &&
            meta.error &&
            meta.error.label)}
          {(error || (tooltipValidation && meta && meta.error)) && (
            <small className="invalid-tooltip">{error || meta.error.label}</small>
          )}
        </Form.Text>
      </Form.Group>)}
      {useExternalValue && renderControl}
    </>
    
  );
};

IBANInput.defaultProps = {
  placeholder: '',
  label: null,
  className: '',
  formClassName: '',
  disabled: false,
  onValueChange: null,
  externalValue: ''
};

export default IBANInput;