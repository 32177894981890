import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "react-bootstrap";

import TradeTable from "../../../common/form/table/trade-table";
import { getLeftOverBuyPriceColumns } from "../constants/wallet-constants";

const LeftOverBuyPriceModal = ({ show, onClose, leftOverBuyPriceList, coinSymbol }, { t }) => {

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          {coinSymbol}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column justify-content-between align-items-center sell-row">
          <TradeTable data={leftOverBuyPriceList.filter(item => item.asset === coinSymbol)} columnDefs={getLeftOverBuyPriceColumns()} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className={"mt-2 pl-5 pr-5 mr-2"} variant="secondary" type="button" onClick={onClose}>
          {t("BOT_OVERVIEW.CLOSE")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

LeftOverBuyPriceModal.contextTypes = {
  t: PropTypes.func,
};

export default LeftOverBuyPriceModal;
