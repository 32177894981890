import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingUsd, faTimes } from "@fortawesome/free-solid-svg-icons";

import {
  ACTION_SELL_AMOUNT,
  CONDITION_ACTION_NAME,
  CONDITION_ACTION_NAME_CAMPAIGN,
  CONDITION_REQUIREMENT_NAME,
  CONDITION_TYPE_NAME,
  ORDER_IS_FULFILED,
} from "../constants";

const ConditionItem = (props, { t }) => {
  const { condition, deleteCondition, marketName, assetName, isCampaign } = props;

  const isOrderFulfiled = condition.conditionRequirement === ORDER_IS_FULFILED;
  const isSellAmount = condition.conditionAction === ACTION_SELL_AMOUNT;

  return (
    <div className="condition-item">
      <div className="order-type-icon">
        <FontAwesomeIcon icon={faHandHoldingUsd} />{" "}
        <span
          style={{
            color: condition.conditionalOrderSide === 0 ? "green" : "red",
          }}
          className="order-type"
        >
          {isOrderFulfiled && (condition.conditionalOrderSide === 0 ? "BUY" : "SELL")}
        </span>
        <span
          className="pl-2"
          style={{
            color: condition.completed ? "green" : "red",
          }}
        >
          {condition.completed ? "Completed" : "Active"}
        </span>
      </div>
      <div>
        <span>
          {t("CONDITION.FORM.TYPE")} | {t("CONDITION.FORM.CONDITION")}
        </span>
        <span className="value-text">
          {CONDITION_TYPE_NAME[condition.type]} | {CONDITION_REQUIREMENT_NAME[condition.conditionRequirement]}
        </span>
      </div>
      <div>
        <span>{t("CONDITION.FORM.ACTION")}</span>
        <span className="value-text">
          {isCampaign ? CONDITION_ACTION_NAME_CAMPAIGN[condition.conditionAction] : CONDITION_ACTION_NAME[condition.conditionAction]}
        </span>
      </div>
      <div>
        <span> {t("CONDITION.FORM.PRICE")}</span>
        <span className="value-text">
          {condition.triggerPrice} <span className="font-12">{marketName}</span>
        </span>
      </div>
      {isOrderFulfiled && (
        <div>
          <span> {t("CONDITION.FORM.AMOUNT")}</span>
          <span className="value-text">
            {condition.conditionalOrderQuantity} <span className="font-12">{assetName}</span>
          </span>
        </div>
      )}
      {isSellAmount && (
        <div>
          <span> {t("CONDITION.FORM.PERCENTAGE")}</span>
          <span className="value-text">
            {condition.conditionPercentage}
            <span className="font-12">%</span>
          </span>
        </div>
      )}
      {isSellAmount && condition.reentrySet && (
        <div>
          <span> {t("CONDITION.FORM.REENTRY-PERCENTAGE")}</span>
          <span className="value-text">
            {condition.reentryPercentage}
            <span className="font-12">%</span>
          </span>
        </div>
      )}
      {isSellAmount && condition.reentrySet && (
        <div>
          <span> {t("CONDITION.FORM.REENTRY-DELAY-TIME")}</span>
          <span className="value-text">
            {condition.reentryDelay}
          </span>
        </div>
      )}
      {isSellAmount && condition.reentrySet && (
        <div>
          <span> {t("CONDITION.FORM.REENTRY-PRICE")}</span>
          <span className="value-text">
            {condition.reentryPrice} <span className="font-12">{marketName}</span>
          </span>
        </div>
      )}
      {!condition.completed && (
        <button title="Delete condition" onClick={deleteCondition} className="delete-btn">
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}
    </div>
  );
};

ConditionItem.contextTypes = {
  t: PropTypes.func,
};

export default ConditionItem;
