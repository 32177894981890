import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, ListGroup, Form, Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Switch } from "@mui/material";
import userApi from "../../api/user/actions";
import Helpers from "../helpers/error-helpers";
import NotificationProvider from "../../core/notification";
import chartApi from "../../api/chart/actions";
import routes from "../../api/chart/routes";
import _ from 'lodash';

const ChartLayoutShareModal = ({ show, onClose, studyTemplates, charts }, { t }) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [shareChartToAll, setShareChartToAll] = useState(false);
  const [shareStudyTemplateToAll, setShareStudyTemplateToAll] = useState(false);
  const [selectedChart, setSelectedChart] = useState('');
  const [selectedStudyTemplate, setSelectedStudyTemplate] = useState('');
  const [key, setKey] = useState('studyTemplate');

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await userApi.getAllNicknames();
        const users = Object.entries(response.data).map(([id, name]) => ({ id: parseInt(id), name }));
        setUsers(users);
        setFilteredUsers(users);
      } catch (err) {
        Helpers.parseError(err);
      }
    };
    getUsers();
  }, []);

  const handleShareChartToAll = async (event) => {
    try {
      setShareChartToAll(event.target.checked);

      await chartApi.share({
        id: selectedChart,
        route: routes.CHART_BASE
      });

      const index = charts.findIndex(x => x.id == selectedChart);
      charts[index].isPublic = !charts[index].isPublic;

      NotificationProvider.success(`Chart successfully shared with all users`);
    } catch (err) {
      Helpers.parseError(err);
    }
  }

  const handleShareStudyTemplateToAll = async (event) => {
    try {
      setShareStudyTemplateToAll(event.target.checked);

      await chartApi.share({
        id: selectedStudyTemplate,
        route: routes.STUDY_TEMPLATE_BASE
      });

      const index = studyTemplates.findIndex(x => x.id == selectedStudyTemplate);
      studyTemplates[index].isPublic = !studyTemplates[index].isPublic;

      NotificationProvider.success(`Study Template successfully shared with all users`);
    } catch (err) {
      Helpers.parseError(err);
    }
  }

  const handleSendClick = async (user) => {
    try {
      const isStudyTemplate = key === 'studyTemplate';
      const tabName = isStudyTemplate ? 'Study Template' : 'Chart';

      if ((isStudyTemplate && !selectedStudyTemplate) || (!isStudyTemplate && !selectedChart)) {
        return;
      }

      await chartApi.send({
        id: isStudyTemplate ? selectedStudyTemplate : selectedChart,
        sendToUserId: user.id,
        route: isStudyTemplate ? routes.STUDY_TEMPLATE_BASE : routes.CHART_BASE
      });

      NotificationProvider.success(`${tabName} successfully sent to ${user.name}`);
    } catch (err) {
      Helpers.parseError(err);
    }
  };

  const handleFilterChange = _.debounce((value) => {
    setFilteredUsers(users.filter(user => user.name.toLowerCase().includes(value.toLowerCase())));
  }, 300);

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="l">
      <Modal.Header closeButton>
        <Modal.Title>{t("SHARE-CHART.TITLE")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '700px' }}>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => {
            setKey(k);
            setSelectedChart('');
            setSelectedStudyTemplate('');
            setShareChartToAll(false);
            setShareStudyTemplateToAll(false);
          }}
        >
          <Tab eventKey="studyTemplate" title="Study Template" className="share-chart-tab">
            <div className="input-group mb-3 mt-3 sell-row">
              <select
                value={selectedStudyTemplate}
                name="marketName"
                onChange={(e) => {
                  setSelectedStudyTemplate(+e.target.value);
                  setShareStudyTemplateToAll(studyTemplates.find(x => x.id === +e.target.value)?.isPublic);
                }}
                className="form-control"
                placeholder="Select a template..."
              >
                <option value="" disabled>{t("SHARE-CHART.SELECT")}</option>
                {studyTemplates?.map((template) => (
                  <option key={template.id} value={template.id}>
                    {template.name}
                  </option>
                ))}
              </select>
            </div>
            <label className="mb-3 share-chart-switch">
              <Switch
                checked={shareStudyTemplateToAll}
                onChange={handleShareStudyTemplateToAll}
                disabled={!selectedStudyTemplate}
              />
              {t("SHARE-CHART.PUBLIC-CHART")}
            </label>
          </Tab>
          <Tab eventKey="chart" title="Chart" className="share-chart-tab" >
            <div className="input-group mb-3 mt-3 sell-row">
              <select
                value={selectedChart}
                name="marketName"
                onChange={(e) => {
                  setSelectedChart(+e.target.value);
                  setShareChartToAll(charts.find(x => x.id === +e.target.value)?.isPublic);
                }}
                className="form-control"
              >
                <option value="" disabled>{t("SHARE-CHART.SELECT")}</option>
                {charts?.map((chart) => (
                  <option key={chart.id} value={chart.id}>
                    {chart.name}
                  </option>
                ))}
              </select>
            </div>
            <label className="mb-3 share-chart-switch">
              <Switch
                checked={shareChartToAll}
                onChange={handleShareChartToAll}
                disabled={!selectedChart}
              />
              {t("SHARE-CHART.PUBLIC-CHART")}
            </label>
          </Tab>
        </Tabs>
        <div style={{ opacity: (key === 'studyTemplate' && shareStudyTemplateToAll) || (key === 'chart' && shareChartToAll) ? '0.5' : '1' }}>
          <Form.Control
            type="text"
            placeholder={t("SHARE-CHART.FILTER-USERS")}
            disabled={(key === 'studyTemplate' && shareStudyTemplateToAll) || (key === 'chart' && shareChartToAll)}
            onChange={(e) => handleFilterChange(e.target.value)} className="mb-1" />
          <ListGroup style={{ maxHeight: '400px', overflowY: 'auto' }}>
            {filteredUsers.map(user => (
              <ListGroup.Item key={user.id} className="custom-list-group-item">
                {user.name}
                <Button
                  variant="primary"
                  onClick={() => handleSendClick(user)}
                  disabled={(key === 'studyTemplate' && shareStudyTemplateToAll) || (key === 'chart' && shareChartToAll)}>
                  <FontAwesomeIcon icon={faPaperPlane} /> {t("SHARE-CHART.SEND")}
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="button" size="md" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ChartLayoutShareModal.contextTypes = {
  t: PropTypes.func,
};

export default ChartLayoutShareModal;
