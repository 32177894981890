import authApi from "../../../api/auth/actions";

export const register = (
  params,
  setRegistrationSuccess,
  setRegisterErrorMessage
) => {
  return async () => {
    try {
      await authApi.register(params);
      setRegistrationSuccess();
    } catch (e) {
      if (e.cErr) {
        setRegisterErrorMessage(e.cErr.message);
      }
    }
  };
};
