import loadingActionTypes from "../constants/action-types";
import { store } from "../../../index";

export const handleGlobalLoader = (data) => {
  return {
    type: loadingActionTypes.HANDLE_GLOBAL_LOADER,
    data,
  };
};

export const dispatchGlobalLoaderdWired = (payload) =>
  store.dispatch(handleGlobalLoader(payload));
