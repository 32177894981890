import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "../styles/default-container.scss";

class DefaultContainer extends Component {
  render() {
    const { children } = this.props;
    return <div className="default-container">{children}</div>;
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => ({});

DefaultContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultContainer);
