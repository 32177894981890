import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import Slider from "@mui/material/Slider";

import Button from "../../../common/form/button/components/button";

import "../style/increase-modal.scss";
import CommonHelper from "../../../common/helpers/common-helper";
import InputNumber from "../../../common/form/input/components/number-input";
import NotificationProvider from "../../../core/notification";

const AmountActionModal = (
  {
    show,
    onClose,
    onConfirm,
    amountData,
    assetName,
    marketName,
    avblCoins,
    avblMarket,
    onChangeHandler,
  },
  { t }
) => {
  const valueText = (value) => {
    return `${value}%`;
  };

  const onAmountPercentageChange = (e) => {
    const { value } = e.target;
    if (avblCoins === 0) {
      return onChangeHandler({ ...amountData, amountPercentage: +value });
    }
    const amount = CommonHelper.calculatePercentage(value, avblCoins);
    onChangeHandler({
      ...amountData,
      amountPercentage: +value,
      amount,
      marketAmount: amount > 0 ? 0 : amountData.marketAmount,
      marketAmountPercentage: amount > 0 ? 0 : amountData.marketAmountPercentage
    });
  };

  const onMarketAmountPercentageChange = (e) => {
    const { value } = e.target;
    if (avblMarket === 0) {
      return onChangeHandler({ ...amountData, marketAmountPercentage: +value });
    }
    const marketAmount = CommonHelper.calculatePercentage(value, avblMarket);
    onChangeHandler({
      ...amountData,
      marketAmountPercentage: +value,
      marketAmount,
      amount: marketAmount > 0 ? 0 : amountData.amount,
      amountPercentage: marketAmount > 0 ? 0 : amountData.amountPercentage
    });
  };

  const onAmountChange = (e) => {
    const { value } = e.target;
    if (isNaN(avblCoins)) {
      return NotificationProvider.warning("Market value is not present");
    }
    if (value > avblCoins) {
      return NotificationProvider.warning("Value is greater than available");
    }

    const amountPercentage = Math.floor(
      CommonHelper.calculatePercentageIncrease(value, avblCoins)
    );
    onChangeHandler({
      ...amountData,
      amount: +value,
      amountPercentage,
      marketAmount: +value > 0 ? 0 : amountData.marketAmount,
      marketAmountPercentage: +value > 0 ? 0 : amountData.marketAmountPercentage
    });
  };

  const onMarketAmountChange = (e) => {
    const { value } = e.target;
    if (isNaN(avblMarket)) {
      return NotificationProvider.warning("Market value is not present");
    }
    if (value > avblMarket) {
      return NotificationProvider.warning("Value is greater than available");
    }

    const marketAmountPercentage = Math.floor(
      CommonHelper.calculatePercentageIncrease(value, avblMarket)
    );
    onChangeHandler({
      ...amountData,
      marketAmount: +value,
      marketAmountPercentage,
      amount: +value > 0 ? 0 : amountData.amount,
      amountPercentage: +value > 0 ? 0 : amountData.amountPercentage
    });
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("CAMPAIGN.AMOUNT_ACTION_MODAL.TITLE")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-end pt-2 pb-4 mb-2">
          <Col className="d-flex align-items-center" md={12} lg={6} sm={12} xs={12}>
            <label className="mb-1 pr-3">{t("CAMPAIGN.FORM.AMOUNT")}</label>
            <Slider
              name="quantity"
              value={amountData.amountPercentage}
              valueLabelDisplay="auto"
              valueLabelFormat={valueText}
              onChange={onAmountPercentageChange}
            />
          </Col>
          <Col md={12} lg={6} sm={12} xs={12}>
            <p className="d-flex justify-content-between align-items-center">
              <span style={{ fontSize: 14, color: "#a1a1a1" }}>{t("AVBL")}</span>
              <span className="mr-3" style={{ fontSize: 14, fontWeight: 600 }}>
                {avblCoins} {assetName || "N\\A"}
              </span>
            </p>
            <div className="input-group">
              <InputNumber name="amount" value={amountData.amount} onChange={onAmountChange} step={0.01} />
              <span
                style={{ fontWeight: 700 }}
                className="input-group-text input-end-form-border"
              >
                {assetName || "N\\A"}
              </span>
            </div>
          </Col>
        </Row>
        <Row className="align-items-end pt-2 pb-4">
          <Col className="d-flex align-items-center" md={12} lg={6} sm={12} xs={12}>
            <label className="mb-1 pr-3">{t("CAMPAIGN.FORM.AMOUNT")}</label>
            <Slider
              name="marketQuantity"
              value={amountData.marketAmountPercentage}
              valueLabelDisplay="auto"
              valueLabelFormat={valueText}
              onChange={onMarketAmountPercentageChange}
            />
          </Col>
          <Col md={12} lg={6} sm={12} xs={12}>
            <p className="d-flex justify-content-between align-items-center">
              <span style={{ fontSize: 14, color: "#a1a1a1" }}>{t("AVBL")}</span>
              <span className="mr-3" style={{ fontSize: 14, fontWeight: 600 }}>
                {avblMarket} {marketName || "N\\A"}
              </span>
            </p>
            <div className="input-group">
              <InputNumber name="marketAmount" value={amountData.marketAmount} onChange={onMarketAmountChange} step={0.01} />
              <span
                style={{ fontWeight: 700 }}
                className="input-group-text input-end-form-border"
              >
                {marketName || "N\\A"}
              </span>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {avblCoins !== 0 && (
          <Button variant="primary" onClick={onConfirm}>
            OK
          </Button>
        )}
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AmountActionModal.contextTypes = {
  t: PropTypes.func,
};

export default AmountActionModal;
