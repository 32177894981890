import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import userApi from '../../../api/user/actions';
import TradeTable from '../../../common/form/table/trade-table';
import Helpers from '../../../common/helpers/error-helpers';
import NotificationProvider from '../../../core/notification';
import { AFIILIATE_COLUMNS } from '../constants';

const guidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const UserAffiliate = (props, { t }) => {
  const [userAffiliates, setUserAffiliates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validationStates, setValidationStates] = useState({});
  const emptyRegex = '00000000-0000-0000-0000-000000000000';

  useEffect(() => {
    // Initialize validation states with all fields set to true
    const initialValidationStates = {};
    userAffiliates.forEach((data) => {
      initialValidationStates[data.userId] = true;
    });
    setValidationStates(initialValidationStates);
  }, [userAffiliates]);
  const columns = [
    ...AFIILIATE_COLUMNS,
    {
      field: 'userAffiliateId',
      headerName: 'USER-SETTINGS.AFFILIATE.AFFILIATE_ID',
      cellRenderer: (data) => {
        const defaultValue =
          data.userAffiliateId === emptyRegex ? '' : data.userAffiliateId;
        return (
          <div>
            <div className="mr-5 flex-column">
              <div className="input-group">
                <input
                  name="userAffiliateId"
                  defaultValue={defaultValue}
                  onChange={(e) => handleChange(e.target.value, data)}
                  onBlur={(e) => updateAffiliate(e.target.value, data)}
                  onFocus={(e) => handleChange(e.target.value, data)}
                  type="text"
                  className={`form-control ${
                    !validationStates[data.userId] ? 'is-invalid' : ''
                  }`}
                />
              </div>
              {!validationStates[data.userId] && (
                <p className="text-danger">
                  {t('EDIT_PROFILE.AFFILIATE.INVALID-AFFILIATE-ID')}
                </p>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const validateInput = (inputValue, data) => {
    return guidRegex.test(inputValue) || (inputValue === '' && data.userAffiliateId !== emptyRegex);
  };

  const handleChange = (inputValue, data) => {
    const isValid = validateInput(inputValue,data);

    // Update only the validation state for the current field
    setValidationStates((prevStates) => ({
      ...prevStates,
      [data.userId]: isValid,
    }));
  };

  useEffect(() => {
    getAffiliations();
  }, []);

  const getAffiliations = async () => {
    setIsLoading(true);
    try {
      const response = await userApi.getAllUsersAffiliates();
      setUserAffiliates(response.data);
      setIsLoading(false);
    } catch (err) {
      Helpers.parseError(err);
      setIsLoading(false);
    }
  };

  const updateAffiliate = async (newAffiliateId, data) => {
    try {
      if (!(newAffiliateId === '' && data.userAffiliateId !== emptyRegex)) {
        if (
          !newAffiliateId.match(guidRegex) ||
          newAffiliateId === data.userAffiliateId
        ) {
          setValidationStates((prevStates) => ({
            ...prevStates,
            [data.userId]: true,
          }));
          return;
        }
      }
      await userApi.updateAffiliate({
        affiliateId: newAffiliateId === '' ? emptyRegex : newAffiliateId,
        userId: data.userId,
      });
      setIsLoading(false);
      NotificationProvider.success('Affiliate Id sucessfully updated');
      getAffiliations();
    } catch (err) {
      Helpers.parseError(err);
      setIsLoading(false);
    }
  };

  return (
    <div className="user-settings-layout w-100">
      <TradeTable
        data={userAffiliates}
        columnDefs={columns}
        isLoading={isLoading}
      />
    </div>
  );
};

UserAffiliate.contextTypes = {
  t: PropTypes.func,
};

export default UserAffiliate;
