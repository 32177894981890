import React, { createContext, useContext, useRef, useState } from 'react';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const resolvePromiseRef = useRef(null);
  const cancelPromiseRef = useRef(null);

  const requestModal = (onSubmit, onCancel) => {
    setIsVisible(true);
    resolvePromiseRef.current = onSubmit;
    cancelPromiseRef.current = onCancel;
  };

  const cancelSubmit = () => {
    if (cancelPromiseRef.current) {
      cancelPromiseRef.current();
      setIsVisible(false);
      resolvePromiseRef.current = null;
      cancelPromiseRef.current = null;
    }
  }

  const submitDescription = (description) => {
    if (resolvePromiseRef.current) {
      resolvePromiseRef.current(description);
      setIsVisible(false);
      resolvePromiseRef.current = null;
      cancelPromiseRef.current = null;
    }
  };

  return (
    <ModalContext.Provider value={{ requestModal, submitDescription, cancelSubmit, isVisible }}>
      {children}
    </ModalContext.Provider>
  );
};