import {
  faBan,
  faEdit,
  faEllipsisV
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from 'react-router-dom';

import botOrderApi from "../../../api/trade-order/actions";
import { ORDER_TYPE, PAGE_SIZE_DEFAULT } from "../../../common/constants";
import { routes } from "../../../common/routes-constants";

import TradeTable from "../../../common/form/table/trade-table";
import { useHelpModus } from "../../../common/help-modus/help-modus-context";
import HelpModusModal from "../../../common/help-modus/help.modus-modal";
import Helpers from "../../../common/helpers/error-helpers";
import Loader from "../../../common/loader/components/common-loader";
import NotificationProvider from "../../../core/notification";
import AppPagination from "../../../shared/components/pagination/pagination";
import { SMART_TRADE_ORDER_COLUMNS } from "../constants";

import "../style/smart-trade.scss";

const SmartTradeOverview = (_ , { t }) => {

  const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT);
  const [pageNumber, setPageNumber] = useState(1);
  const pageNumberRef = useRef(pageNumber);
  const [isLoading, setIsLoading] = useState(true);
  const [ordersData, setOrdersData] = useState([]);

  let latestZIndex = 3;

  const [showHelpModusModal, setShowHelpModusModal] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [modalTitle, setModalTitle] = useState('Information');
  const { isHelpModus } = useHelpModus();

  const columns = useMemo(
    () => [
      ...SMART_TRADE_ORDER_COLUMNS,
      {
        field: "actions",
        headerName: "ORDER_OVERVIEW_TABLE.ACTIONS",
        type: "action",
        width: 68,
        cellRenderer: (data) => {
          return (
            <DropdownButton
              variant="secondary"
              id="dropdown-basic-button"
              title={<FontAwesomeIcon icon={faEllipsisV} />}
              drop={"left"}
              className="fix-dropdown-margin"
              onClick={(e) => {
                const td = e.target.closest("td");
                td.style.zIndex = latestZIndex;
                latestZIndex++;
              }}
            >
              <Dropdown.Item as={Link} to={`${routes.protectedRoutes.smartTradeEdit.path}/${data.id}`}>
                <FontAwesomeIcon icon={faEdit} /> Edit
              </Dropdown.Item>
              {![2, 3, 4].includes(data.status) && (
                <Dropdown.Item href="#" onClick={() => onCancelOrder(data.id)}>
                  <FontAwesomeIcon icon={faBan} /> {t("BUTTON.CANCEL")}
                </Dropdown.Item>
              )}
            </DropdownButton>
          );
        },
      },
    ],
    []
  );

  const getOrders = async () => {
    try {
      const response = await botOrderApi.getOrders({
        params: {
          orderTypeId: ORDER_TYPE.Limit,
          pageNumber: pageNumberRef.current,
          pageSize,
        },
      });
      setOrdersData(response.data);
      setIsLoading(false);
    } catch (err) {
      Helpers.parseError(err);
      setOrdersData([]);
    }
  };

  useEffect(() => {
    pageNumberRef.current = pageNumber;
    setIsLoading(true);
    getOrders();
  }, [pageNumber, pageSize]);

  const toggleHelpModusModal = (title, message) => {
    setShowHelpModusModal((showModal) => !showModal);
    setModalMessage(message);
    setModalTitle(title);
  };

  const onCancelOrder = async (id) => {
    botOrderApi.deleteOrder({ id })
      .then(() => {
        NotificationProvider.success("Order successfully canceled");
        setIsLoading(true);
        getOrders();
      })
      .catch((err) => {
        Helpers.parseError(err);
      });
  }

  return (
    <div className={`pt-3`}>
      <div className="d-flex align-items-center mb-2">
        <AppPagination
          page={pageNumber}
          totalPages={ordersData.totalPages}
          pageSize={pageSize}
          setPageSize={setPageSize}
          handlePaginationClick={(_, value) => setPageNumber(value)}
        />
      </div>
      <TradeTable
        data={ordersData.data}
        columnDefs={columns}
        conditionExpressions={{
          "gray-out": (data) => !data["isActive"],
        }}
        enableFilter
        tableHasVerticalScroll
      />
      {showHelpModusModal && (
        <HelpModusModal
          show={showHelpModusModal}
          onClose={toggleHelpModusModal}
          title={modalTitle}
          message={modalMessage}
        />
      )}
      <Loader loading={isLoading} type="global" />
    </div>
  );
};

SmartTradeOverview.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SmartTradeOverview;
