import { API_BASE_URL } from '../../common/constants';
import UserHelper from '../../common/helpers/user-helper';
import { Api } from '../../core/api';
import routesConstatns from './routes';

const userApi = {};

userApi.getUserAssets = async (params, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_USER_ASSETS}?apiKeyId=${params}`,
    {
      needLoader: true,
    },
    headers
  );
};

userApi.getAllUserAssets = async (headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_ALL_USER_ASSETS}`,
    {
      needLoader: true,
    },
    headers
  );
};

userApi.getAssetPrice = async (marketName, assetName, apiKeyId, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_ASSET_PRICE}?marketName=${marketName}&assetName=${assetName}&apiKeyId=${apiKeyId}`,
    {
      needLoader: true,
    },
    headers
  );
};

userApi.getAssetAmount = async (assetName, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_ASSET_AMOUNT}?assetName=${assetName}`,
    {
      needLoader: true,
    },
    headers
  );
};

userApi.getOrderBook = async (apiKeyId, symbol, headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_ORDER_BOOK}`,
    {
      params: { apiKeyId, symbol },
      needLoader: true,
    },
    headers
  );
};

userApi.getUserData = async (headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_USER_DATA}/${UserHelper.getId()}`,
    {
      needLoader: true,
    },
    headers
  );
};

userApi.updateUserData = async (params, headers) => {
  return await Api.put(
    `${API_BASE_URL}${routesConstatns.UPDATE_USER_DATA}/${UserHelper.getId()}`,
    params,
    {
      needLoader: true,
    },
    headers
  );
};

userApi.getUsers = async (headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_USER_DATA}`,
    {
      needLoader: false,
    },
    headers
  );
};

userApi.getRoles = async (headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_ROLES}`,
    {
      needLoader: true,
    },
    headers
  );
};

userApi.getUserAffiliateData = async (headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.USER_AFFILIATE_DATA}`,
    {
      needLoader: true,
    },
    headers
  );
};

userApi.updateAffiliate = async ({ affiliateId, userId }, headers) => {
  let apiUrl = `${API_BASE_URL}${routesConstatns.USER_AFFILIATE_DATA}/${affiliateId}`;

  // Check if userId is provided and append it to the URL as a query parameter
  if (userId) {
    apiUrl += `?userId=${userId}`;
  }

  return await Api.patch(apiUrl, { needLoader: true }, headers);
};

userApi.updateUserRole = async ({ userId, roleId }, headers) => {
  return await Api.put(
    `${API_BASE_URL}${routesConstatns.ASSIGN_ROLES}`,
    { userId, roleIds: [roleId] },
    {
      needLoader: true,
    },
    headers
  );
};

userApi.updateMyUserRole = async (normalizedName, headers) => {
  return await Api.put(
    `${API_BASE_URL}${routesConstatns.ASSIGN_ROLES}/update-role/${normalizedName}`,
    {
      needLoader: true,
    },
    headers
  );
};

userApi.updateUserCreateInvoice = async (userId, headers) => {
  return await Api.patch(
    `${API_BASE_URL}${routesConstatns.UPDATE_USER_CREATE_INVOICE_FIELD}/${userId}`,
    {
      needLoader: true,
    },
    headers
  );
};

userApi.verifyUser = async (id, headers) => {
  return await Api.patch(
    `${API_BASE_URL}${routesConstatns.VERIFY_USER}/${id}`,
    {
      needLoader: true,
    },
    headers
  );
};

userApi.getAllNicknames = async (headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_ALL_NICKNAMES}`,
    {
      needLoader: true,
    },
    headers
  );
};

userApi.getAllUsersAffiliates = async (headers) => {
  return await Api.get(
    `${API_BASE_URL}${routesConstatns.GET_ALL_USER_AFFILIATES}`,
    {
      needLoader: false,
    },
    headers
  );
};

export default userApi;
